import * as serviceProvider from '../../../apis/serviceProvider'
import * as cookies from '../../../utilities/cookies'
//import * as invitations from '../../../apis/serviceProviderInvitation'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import { getUser } from '../../../utilities/userUtilities'
import { fetchSubscriptionUsersCases } from '../subscriptions/index'

const ADD_SERVICEPROVIDER_REQUESTED = 'ADD_SERVICEPROVIDER_REQUESTED'
const ADD_SERVICEPROVIDER_SUCCESS = 'ADD_SERVICEPROVIDER_SUCCESS'
const ADD_SERVICEPROVIDER_FAILED = 'ADD_SERVICEPROVIDER_FAILED'

export const addServiceProvider = (name, status, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId) => (dispatch) => {
	dispatch({ type: ADD_SERVICEPROVIDER_REQUESTED, data: {} })

	serviceProvider.addServiceProvider(name, status, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId).then((serviceProvider) => {
		if (serviceProvider && serviceProvider.isSuccessful) {
			dispatch(fetchServiceProviders())
			dispatch(hideModal())
			dispatch(fetchSubscriptionUsersCases())
			return dispatch({ type: ADD_SERVICEPROVIDER_SUCCESS, data: serviceProvider })
		} else {
			dispatch({ type: ADD_SERVICEPROVIDER_FAILED, data: serviceProvider })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.serviceProviders.failedToCreateText`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const SET_SERVICEPROVIDER_MEMBER_REQUESTED = 'SET_SERVICEPROVIDER_MEMBER_REQUESTED'
const SET_SERVICEPROVIDER_MEMBER_SUCCESS = 'SET_SERVICEPROVIDER_MEMBER_SUCCESS'
const SET_SERVICEPROVIDER_MEMBER_FAILED = 'SET_SERVICEPROVIDER_MEMBER_FAILED'

export const addServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
    dispatch({ type: SET_SERVICEPROVIDER_MEMBER_REQUESTED, data: {} })

    return serviceProvider.addServiceProviderMember(serviceProviderId, userId).then((response) => {
        if (response && response.isSuccessful) {
            dispatch(getServiceProviderMembersById(serviceProviderId))
            dispatch({ type: SET_SERVICEPROVIDER_MEMBER_SUCCESS, data: response })
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: `app.general.success`, isSuccess: true }
            })
            return dispatch(hideModal())
        } else {
            dispatch({ type: SET_SERVICEPROVIDER_MEMBER_FAILED, data: {} })
            if (response && response.isIneligible) {
                return dispatch({
                    type: 'SET_SNACKBAR_MESSAGE',
                    data: { message: `app.serviceProviders.failedToInviteIneligibleMember`, isSuccess: false }
                })
            }
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: `app.serviceProviders.failedToAddMember`, isSuccess: false }
            })
        }
    })
    return
}

const FETCH_SPONSORED_SERVICEPROVIDERS_REQUESTED = 'FETCH_SPONSORED_SERVICEPROVIDERS_REQUESTED'
const FETCH_SPONSORED_SERVICEPROVIDERS_SUCCESS = 'FETCH_SPONSORED_SERVICEPROVIDERS_SUCCESS'
const FETCH_SPONSORED_SERVICEPROVIDERS_FAILED = 'FETCH_SPONSORED_SERVICEPROVIDERS_FAILED'

export const fetchSponsoredServiceProviders = (search) => (dispatch) => {
	dispatch({ type: FETCH_SPONSORED_SERVICEPROVIDERS_REQUESTED, data: {} })
	//serviceProvider.fetchSponsoredServiceProviders().then((response) => {
	//	if (response && response.isSuccessful) {
	//		return dispatch({
	//			type: FETCH_SPONSORED_SERVICEPROVIDERS_SUCCESS,
	//			data: response.serviceProviders
	//		})
	//	} else {
	//		return dispatch({ type: FETCH_SPONSORED_SERVICEPROVIDERS_FAILED, data: {} })
	//	}
	//})
	return
}

const FETCH_SERVICEPROVIDERS_REQUESTED = 'FETCH_SERVICEPROVIDERS_REQUESTED'
const FETCH_SERVICEPROVIDERS_SUCCESS = 'FETCH_SERVICEPROVIDERS_SUCCESS'
const FETCH_SERVICEPROVIDERS_FAILED = 'FETCH_SERVICEPROVIDERS_FAILED'

export const fetchServiceProviders = (search) => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDERS_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviders(search).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_SERVICEPROVIDERS_SUCCESS,
				data: {
					items: response.serviceProviders,
					field: 'name',
					isDescending: false
				}
			})
		} else {
			return dispatch({ type: FETCH_SERVICEPROVIDERS_FAILED, data: {} })
		}
	})
	return
}

export const fetchServiceProvidersISponsor = (search) => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDERS_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviders(search).then((response) => {
		if (response && response.isSuccessful) {
			let user = getUser()

			let data = response.serviceProviders.filter((f) => {
				if (f.isSponsored && f.isFounder) {
					return f
				}
			})
			return dispatch({
				type: FETCH_SERVICEPROVIDERS_SUCCESS,
				data: {
					items: data.length > 0 ? data : [],
					field: 'name',
					isDescending: false
				}
			})
		} else {
			return dispatch({ type: FETCH_SERVICEPROVIDERS_FAILED, data: {} })
		}
	})
	return
}

const SET_SERVICEPROVIDER_SORT_REQUESTED = 'SET_SERVICEPROVIDER_SORT_REQUESTED'

export const setServiceProviderSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.serviceProvider.serviceProviders.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_SERVICEPROVIDER_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_SPONSORED_SERVICEPROVIDER_SORT_REQUESTED = 'SET_SPONSORED_SERVICEPROVIDER_SORT_REQUESTED'

export const setSponsoredServiceProviderSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()
	let data = currentState.serviceProvider.sponsoredServiceProviders.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})

	return dispatch({
		type: SET_SPONSORED_SERVICEPROVIDER_SORT_REQUESTED,
		data: { items: [ ...data ], field: field, isDescending: isDescending }
	})
}

const FETCH_SERVICEPROVIDERS_WITH_CASE_REQUESTED = 'FETCH_SERVICEPROVIDERS_WITH_CASE_REQUESTED'
const FETCH_SERVICEPROVIDERS_WITH_CASE_SUCCESS = 'FETCH_SERVICEPROVIDERS_WITH_CASE_SUCCESS'
const FETCH_SERVICEPROVIDERS_WITH_CASE_FAILED = 'FETCH_SERVICEPROVIDERS_WITH_CASE_FAILED'

export const fetchServiceProvidersWithCaseCount = () => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDERS_WITH_CASE_REQUESTED, data: {} })
	//serviceProvider.fetchServiceProvidersWithCaseCount().then((serviceProviders) => {
	//	if (serviceProviders) {
	//		return dispatch({
	//			type: FETCH_SERVICEPROVIDERS_WITH_CASE_SUCCESS,
	//			data: serviceProviders.serviceProviders
	//		})
	//	} else {
	//		return dispatch({ type: FETCH_SERVICEPROVIDERS_WITH_CASE_FAILED, data: {} })
	//	}
	//})
	return
}

const FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED = 'FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED'
const FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS = 'FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS'
const FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_FAILED = 'FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_FAILED'

export const fetchServiceProviderAutoComplete = (searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviderAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.serviceProviders && response.serviceProviders.length > 0) {
				return dispatch({
					type: FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS,
					data: response.serviceProviders
				})
			} else {
				return dispatch({
					type: FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_SERVICEPROVIDERS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED = 'FETCH__SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED'
const FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS = 'FETCH__SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS'
const FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_FAILED = 'FETCH__SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_FAILED'

export const fetchSharedWithServiceProviderAutoComplete = (searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviderAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.serviceProviders && response.serviceProviders.length > 0) {
				return dispatch({
					type: FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS,
					data: response.serviceProviders
				})
			} else {
				return dispatch({
					type: FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const CLEAR_SERVICEPROVIDER_REQUESTED = 'FETCH_SERVICEPROVIDER_REQUESTED'

export const clearServiceProvider = () => (dispatch) => {
	return dispatch({ type: CLEAR_SERVICEPROVIDER_REQUESTED, data: {} })
}

const FETCH_SERVICEPROVIDER_REQUESTED = 'FETCH_SERVICEPROVIDER_REQUESTED'
const FETCH_SERVICEPROVIDER_SUCCESS = 'FETCH_SERVICEPROVIDER_SUCCESS'
const FETCH_SERVICEPROVIDER_FAILED = 'FETCH_SERVICEPROVIDER_FAILED'

export const fetchServiceProvider = (id) => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDER_REQUESTED, data: {} })
	serviceProvider.fetchServiceProvider(id).then((serviceProvider) => {
		if (serviceProvider && serviceProvider.isSuccessful) {
			return dispatch({ type: FETCH_SERVICEPROVIDER_SUCCESS, data: serviceProvider })
		} else {
			history.push('/serviceProviders')
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.serviceProviders.failedToFetch`, isSuccess: false }
			})
		}
	})
	return
}

const ARCHIVE_SERVICEPROVIDER_REQUESTED = 'ARCHIVE_SERVICEPROVIDER_REQUESTED'
const ARCHIVE_SERVICEPROVIDER_SUCCESS   = 'ARCHIVE_SERVICEPROVIDER_SUCCESS'
const ARCHIVE_SERVICEPROVIDER_FAILED    = 'ARCHIVE_SERVICEPROVIDER_FAILED'

export const archiveServiceProvider = (id) => (dispatch) => {
	dispatch({ type: ARCHIVE_SERVICEPROVIDER_REQUESTED, data: {} })

	return serviceProvider.archiveServiceProvider(id).then((reponse) => {
		if (reponse && reponse.isSuccessful) {
            dispatch(fetchServiceProviders());
        } else {
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.archiveFailed',
                    isSuccess: true
                }
            })
			return dispatch({ type: ARCHIVE_SERVICEPROVIDER_FAILED, data: {} })
        }
    })
}

const UNARCHIVE_SERVICEPROVIDER_REQUESTED = 'UNARCHIVE_SERVICEPROVIDER_REQUESTED'
const UNARCHIVE_SERVICEPROVIDER_SUCCESS = 'UNARCHIVE_SERVICEPROVIDER_SUCCESS'
const UNARCHIVE_SERVICEPROVIDER_FAILED = 'UNARCHIVE_SERVICEPROVIDER_FAILED'

export const unarchiveServiceProvider = (id) => (dispatch) => {
    dispatch({ type: UNARCHIVE_SERVICEPROVIDER_REQUESTED, data: {} })

    return serviceProvider.unarchiveServiceProvider(id).then((reponse) => {
        if (reponse && reponse.isSuccessful) {
            dispatch(fetchServiceProviders());
        } else {
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.unarchiveFailed',
                    isSuccess: true
                }
            })
			return dispatch({ type: UNARCHIVE_SERVICEPROVIDER_FAILED, data: {} })
        }
    })
}

const SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED'
const SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS'
const SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED'

export const inviteServiceProviderMember = (email, serviceProviderId, sponsored) => (dispatch) => {
	dispatch({ type: SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	//return invitations.inviteServiceProviderMember(user.id, email, serviceProviderId, sponsored).then((response) => {
	//	if (response && response.isSuccessful) {
	//		dispatch(getServiceProviderMembersById(serviceProviderId))
	//		dispatch({
	//			type: SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS,
	//			data: response
	//		})
	//		dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: `app.serviceProviders.inviteSuccessful`,
	//				isSuccess: true
	//			}
	//		})
	//		return dispatch(hideModal())
	//	} else {
	//		dispatch({
	//			type: SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED,
	//			data: {}
	//		})
	//		if (response && response.isIneligible) {
	//			return dispatch({
	//				type: 'SET_SNACKBAR_MESSAGE',
	//				data: {
	//					message: `app.serviceProviders.failedToInviteIneligibleMember`,
	//					isSuccess: false
	//				}
	//			})
	//		}
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: `app.serviceProviders.failedToInviteMember`,
	//				isSuccess: false
	//			}
	//		})
	//	}
	//})
	return
}

const FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED = 'FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED'
const FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS = 'FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS'
const FETCH_SERVICEPROVIDER_MEMBERS_FAILED = 'FETCH_SERVICEPROVIDER_MEMBERS_FAILED'

export const getServiceProviderMembersById = (id, searchPhrase) => (dispatch) => {
    if (!id) {
        return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED, data: {} })
    }

    return serviceProvider.fetchServiceProviderMembers(id, searchPhrase).then((members) => {
        if (members) {
            let data = members.members

            return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS, data: data })
        } else {
            return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_FAILED, data: {} })
        }
    })
    return
}

const FETCH_TREATMENT_REQUESTED = 'FETCH_TREATMENT_REQUESTED'
const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS'
const FETCH_TREATMENT_FAILED = 'FETCH_TREATMENT_FAILED'

export const getTreatmentById = (id) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_TREATMENT_SUCCESS, data: null });
	}
	else
	{
		//serviceProvider.fetchTreatment(id).then((response) => {
		//	if (response && response.isSuccessful) {
		//		if (response.treatment) {
		//			dispatch({ type: FETCH_TREATMENT_SUCCESS, data: response.treatment })
		//		}
		//	} else {
		//		dispatch({ type: FETCH_TREATMENT_FAILED, data: {} })
		//		return dispatch({
		//			type: 'SET_SNACKBAR_MESSAGE',
		//			data: { message: `app.treatments.loadingFailed`, isSuccess: false }
		//		})
		//	}
		//})
	}
	return
}

const FETCH_PATHOLOGY_REQUESTED = 'FETCH_PATHOLOGY_REQUESTED'
const FETCH_PATHOLOGY_SUCCESS = 'FETCH_PATHOLOGY_SUCCESS'
const FETCH_PATHOLOGY_FAILED = 'FETCH_PATHOLOGY_FAILED'

export const getPathologyById = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: null });
	}
	else
	{
		//serviceProvider.fetchPathology(id).then((response) => {
		//	if (response && response.isSuccessful) {
		//		if (response.pathology) {
		//			dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: response.pathology })
		//		}
		//	} else {
		//		dispatch({ type: FETCH_PATHOLOGY_FAILED, data: {} })
		//		return dispatch({
		//			type: 'SET_SNACKBAR_MESSAGE',
		//			data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
		//		})
		//	}
		//})
	}
	return
}

const FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_REQUESTED = 'FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_REQUESTED'
const FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_SUCCESS = 'FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_SUCCESS'
const FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_FAILED = 'FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_FAILED'

export const fetchServiceProviderMembersAutoComplete = (taskId, searchPhrase, noMatchText) => (dispatch) => {
    dispatch({ type: FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_REQUESTED, data: {} })

	serviceProvider.fetchServiceProviderMembersAutoComplete(taskId, searchPhrase).then((response) => {
        if (response && response.isSuccessful) {
			if (response.members && response.members.length > 0) {
                return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_SUCCESS, data: response.members })
            } else {
                return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_SUCCESS, data: [{ id: -1, email: noMatchText }] })
            }
        } else {
            return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_FAILED, data: {} })
        }
    })
    return
}

// const FETCH_SERVICEPROVIDERS_FILTER_REQUESTED = 'FETCH_SERVICEPROVIDERS_FILTER_REQUESTED'
// const FETCH_SERVICEPROVIDERS_FILTER_SUCCESS = 'FETCH_SERVICEPROVIDERS_FILTER_SUCCESS'
// const FETCH_SERVICEPROVIDERS_FILTER_FAILED = 'FETCH_SERVICEPROVIDERS_FILTER_FAILED'

// export const fetchServiceProviderFilters = () => (dispatch) => {
// 	dispatch({ type: FETCH_SERVICEPROVIDERS_FILTER_REQUESTED, data: {} })

// 	const options = [
// 		{ option: 'ALL', label: 'All ServiceProviders' },
// 		{ option: 'SPONSORED', label: 'Sponsored ServiceProviders' },
// 		{ option: 'MANAGE', label: 'ServiceProviders I Manage' },
// 		{ option: 'JOINED', label: 'Joined ServiceProviders' },
// 		{ option: 'ARCHIVED', label: 'Archived ServiceProviders' }
// 	]
// 	return dispatch({ type: FETCH_SERVICEPROVIDERS_FILTER_SUCCESS, data: options })
// }

const SET_SERVICEPROVIDERS_FILTER_REQUESTED = 'SET_SERVICEPROVIDERS_FILTER_REQUESTED'
const SET_SERVICEPROVIDERS_FILTER_SUCCESS = 'SET_SERVICEPROVIDERS_FILTER_SUCCESS'
const SET_SERVICEPROVIDERS_FILTER_FAILED = 'SET_SERVICEPROVIDERS_FILTER_FAILED'

export const setServiceProviderFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SERVICEPROVIDERS_FILTER_REQUESTED, data: {} })
	return dispatch({ type: SET_SERVICEPROVIDERS_FILTER_SUCCESS, data: filter })
}

const UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED'
const UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS'
const UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED'

export const setBackground = (entity, id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return serviceProvider.setBackgroundImage(entity, id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({
				type: UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS,
				data: response
			})
		} else {
			return dispatch({ type: UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED = 'RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED'
const RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS = 'RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS'
const RESET_SERVICEPROVIDER_BACKGROUND_FAILED = 'RESET_SERVICEPROVIDER_BACKGROUND_FAILED'

export const resetBackground = (entity, id, onImageUpdated) => (dispatch) => {
	dispatch({ type: RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED, data: {} })

	return serviceProvider.resetBackgroundImage(entity, id).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({
				type: RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS,
				data: response
			})
		} else {
			return dispatch({ type: RESET_SERVICEPROVIDER_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED = 'UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED'
const UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS = 'UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS'
const UPDATE_SERVICEPROVIDER_DETAILS_FAILED = 'UPDATE_SERVICEPROVIDER_DETAILS_FAILED'

export const updateServiceProviderDetailsById = (serviceProviderDetails) => (dispatch) => {
	dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED, data: {} })
	return serviceProvider.updateServiceProviderDetailsById(serviceProviderDetails).then((result) => {
		if (result) {
			dispatch(hideModal())
			if (result.isArchived) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.serviceProviders.serviceProviderArchived',
						isSuccess: true
					}
				})
				history.push('/serviceProviders')
			} else {
				return dispatch({
					type: UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS,
					data: result
				})
			}
		} else {
			return dispatch({
				type: UPDATE_SERVICEPROVIDER_DETAILS_FAILED,
				data: {}
			})
		}
	})
}

const UPDATE_SERVICEPROVIDER_TREATMENT_REQUESTED = 'UPDATE_SERVICEPROVIDER_TREATMENT_REQUESTED'
const UPDATE_SERVICEPROVIDER_TREATMENT_SUCCESS = 'UPDATE_SERVICEPROVIDER_TREATMENT_SUCCESS'
const UPDATE_SERVICEPROVIDER_TREATMENT_FAILED = 'UPDATE_SERVICEPROVIDER_TREATMENT_FAILED'

export const updateServiceProviderTreatment = (data) => (dispatch) => {
	dispatch({ type: UPDATE_SERVICEPROVIDER_TREATMENT_REQUESTED, data: {} })
	//return serviceProvider.updateServiceProviderTreatment(data).then((response) => {
	//	if (response) {
	//		dispatch(hideModal())
	//		return dispatch({
	//			type: UPDATE_SERVICEPROVIDER_TREATMENT_SUCCESS,
	//			data: data.treatmentId
	//		})
	//	} else {
	//		return dispatch({
	//			type: UPDATE_SERVICEPROVIDER_TREATMENT_FAILED,
	//			data: {}
	//		})
	//	}
	//})
}

const UPDATE_SERVICEPROVIDER_PATHOLOGY_REQUESTED = 'UPDATE_SERVICEPROVIDER_PATHOLOGY_REQUESTED'
const UPDATE_SERVICEPROVIDER_PATHOLOGY_SUCCESS = 'UPDATE_SERVICEPROVIDER_PATHOLOGY_SUCCESS'
const UPDATE_SERVICEPROVIDER_PATHOLOGY_FAILED = 'UPDATE_SERVICEPROVIDER_PATHOLOGY_FAILED'

export const updateServiceProviderPathology = (data) => (dispatch) => {
	dispatch({ type: UPDATE_SERVICEPROVIDER_PATHOLOGY_REQUESTED, data: {} })
	//return serviceProvider.updateServiceProviderPathology(data).then((response) => {
	//	if (response) {
	//		dispatch(hideModal())
	//		return dispatch({
	//			type: UPDATE_SERVICEPROVIDER_PATHOLOGY_SUCCESS,
	//			data: data.pathologyId
	//		})
	//	} else {
	//		return dispatch({
	//			type: UPDATE_SERVICEPROVIDER_PATHOLOGY_FAILED,
	//			data: {}
	//		})
	//	}
	//})
}

const RESEND_SERVICEPROVIDER_INVITATION_REQUESTED = 'RESEND_SERVICEPROVIDER_INVITATION_REQUESTED'
const RESEND_SERVICEPROVIDER_INVITATION_SUCCESS = 'RESEND_SERVICEPROVIDER_INVITATION_SUCCESS'
const RESEND_SERVICEPROVIDER_INVITATION_FAILED = 'RESEND_SERVICEPROVIDER_INVITATION_FAILED'

export const resendServiceProviderInvitationEmail = (invitationId, serviceProviderId) => (dispatch) => {
	dispatch({ type: RESEND_SERVICEPROVIDER_INVITATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	//return invitations.resendServiceProviderInvitation(invitationId, serviceProviderId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: 'app.userProfile.teamMembeResendInvitateSuccess',
	//				isSuccess: true
	//			}
	//		})
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: 'app.userProfile.teamMembeResendInvitateFailed',
	//				isSuccess: false
	//			}
	//		})
	//	}
	//})
	return
}

const SET_SERVICEPROVIDER_MEMBERS_KEY_REQUESTED = 'SET_SERVICEPROVIDER_MEMBERS_KEY_REQUESTED'
const SET_SERVICEPROVIDER_MEMBERS_KEY_SUCCESS = 'SET_SERVICEPROVIDER_MEMBERS_KEY_SUCCESS'
const SET_SERVICEPROVIDER_MEMBERS_KEY_FAILED = 'SET_SERVICEPROVIDER_MEMBERS_KEY_FAILED'

export const setServiceProviderMemberKey = (searchTerm) => (dispatch) => {
	dispatch({ type: SET_SERVICEPROVIDER_MEMBERS_KEY_REQUESTED, data: {} })
	return dispatch({ type: SET_SERVICEPROVIDER_MEMBERS_KEY_SUCCESS, data: searchTerm })
}

const REINSTATE_SERVICEPROVIDER_REQUESTED = 'REINSTATE_SERVICEPROVIDER_REQUESTED'
const REINSTATE_SERVICEPROVIDER_SUCCESS = 'REINSTATE_SERVICEPROVIDER_SUCCESS'
const REINSTATE_SERVICEPROVIDER_FAILED = 'REINSTATE_SERVICEPROVIDER_FAILED'

export const reinstateServiceProvider = (serviceProviderId) => (dispatch) => {
	dispatch({ type: REINSTATE_SERVICEPROVIDER_REQUESTED, data: {} })

	//return serviceProvider.reinstateServiceProviderArchive(serviceProviderId).then((serviceProvider) => {
	//	if (serviceProvider && serviceProvider.isSuccessful) {
	//		dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: 'app.serviceProviders.reinstatedSuccess',
	//				isSuccess: true
	//			}
	//		})
	//		return dispatch({ type: REINSTATE_SERVICEPROVIDER_SUCCESS, data: serviceProvider })
	//	} else {
	//		dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: 'app.serviceProviders.reinstatedFailed',
	//				isSuccess: true
	//			}
	//		})
	//		return dispatch({ type: REINSTATE_SERVICEPROVIDER_FAILED, data: {} })
	//	}
	//})
}

const MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED'
const MAKE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS'
const MAKE_SERVICEPROVIDER_ADMINISTRATOR_FAILED = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_FAILED'

export const makeAdministratorServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
	dispatch({ type: MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED, data: {} })
	
	return serviceProvider.makeAdminMemberByServiceProviderUserId(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {			
			
			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.assignAdministratorSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.assignAdministratorFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED'
const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS'
const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_FAILED = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_FAILED'

export const removeServiceProviderAdministrator = (serviceProviderId, userId) => (dispatch) => {
    dispatch({ type: REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED, data: {} })

	return serviceProvider.removeServiceProviderAdministrator(serviceProviderId, userId).then((response) => {
        if (response && response.isSuccessful) {

            dispatch(getServiceProviderMembersById(serviceProviderId));

            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.removeAdministratorSuccess',
                    isSuccess: true
                }
            })
        } else {
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
					message: 'app.serviceProviders.removeAdministratorFailed',
                    isSuccess: false
                }
            })
        }
    })
    return
}

const REMOVE_USER_SERVICEPROVIDER_REQUESTED = 'REMOVE_USER_SERVICEPROVIDER_REQUESTED'
const REMOVE_USER_SERVICEPROVIDER_SUCCESS = 'REMOVE_USER_SERVICEPROVIDER_SUCCESS'
const REMOVE_USER_SERVICEPROVIDER_FAILED = 'REMOVE_USER_SERVICEPROVIDER_FAILED'

export const removeUserServiceProviderMember = (serviceProviderId, userId) => (dispatch, getState) => {
	dispatch({ type: REMOVE_USER_SERVICEPROVIDER_REQUESTED, data: {} })
	
	return serviceProvider.removeUserServiceProviderById(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {
            dispatch(hideModal())
            dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.removeUserServiceProviderSuccess',
					isSuccess: true
				}
			})

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: REMOVE_USER_SERVICEPROVIDER_SUCCESS
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.removeUserServiceProviderFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED'
const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS'
const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED'

export const makeDefaultAssigneeServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
	dispatch({ type: MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED, data: {} })

	return serviceProvider.makeDefaultAssignee(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.makeDefaultAssigneeSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.makeDefaultAssigneeFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED'
const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS'
const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED'

export const clearServiceProviderDefaultAssignee = (serviceProviderId, userId) => (dispatch, getState) => {
	dispatch({ type: CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED, data: {} })

	return serviceProvider.clearServiceProviderDefaultAssignee(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.clearServiceProviderDefaultAssigneeSuccess',
					isSuccess: true
				}
			})

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.clearServiceProviderDefaultAssigneeFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const LEAVE_SERVICEPROVIDER_REQUESTED = 'LEAVE_SERVICEPROVIDER_REQUESTED'
const LEAVE_SERVICEPROVIDER_SUCCESS = 'LEAVE_SERVICEPROVIDER_SUCCESS'
const LEAVE_SERVICEPROVIDER_FAILED = 'LEAVE_SERVICEPROVIDER_FAILED'

export const leaveServiceProvider = (serviceProviderId) => (dispatch) => {
	dispatch({ type: LEAVE_SERVICEPROVIDER_REQUESTED, data: {} })
	//return serviceProvider.submitLeaveServiceProviderById(serviceProviderId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		dispatch(hideModal())
	//		return dispatch({ type: LEAVE_SERVICEPROVIDER_SUCCESS, data: serviceProviderId })
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: {
	//				message: 'app.serviceProviders.leaveServiceProviderFailed',
	//				isSuccess: false
	//			}
	//		})
	//	}
	//})
}

const CAN_LEAVE_SERVICEPROVIDER_REQUESTED = 'CAN_LEAVE_SERVICEPROVIDER_REQUESTED'
const CAN_LEAVE_SERVICEPROVIDER_SUCCESS = 'CAN_LEAVE_SERVICEPROVIDER_SUCCESS'
const CAN_LEAVE_SERVICEPROVIDER_FAILED = 'CAN_LEAVE_SERVICEPROVIDER_FAILED'

export const canLeaveServiceProvider = (serviceProviderId) => (dispatch) => {
	dispatch({ type: CAN_LEAVE_SERVICEPROVIDER_REQUESTED, data: {} })
	//return serviceProvider.canLeaveServiceProviderById(serviceProviderId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		return dispatch({ type: CAN_LEAVE_SERVICEPROVIDER_SUCCESS, data: response })
	//	} else {
	//		return dispatch({ type: CAN_LEAVE_SERVICEPROVIDER_FAILED, data: response })
	//	}
	//})
}

const DISTRIBUTE_SERVICEPROVIDER_RESOURCES_REQUESTED = 'DISTRIBUTE_SERVICEPROVIDER_RESOURCES_REQUESTED'
const DISTRIBUTE_SERVICEPROVIDER_RESOURCES_SUCCESS = 'DISTRIBUTE_SERVICEPROVIDER_RESOURCES_SUCCESS'
const DISTRIBUTE_SERVICEPROVIDER_RESOURCES_FAILED = 'DISTRIBUTE_SERVICEPROVIDER_RESOURCES_FAILED'

export const distributeResources = (serviceProviderId, numberUsers, numberCases) => (dispatch) => {
	dispatch({ type: DISTRIBUTE_SERVICEPROVIDER_RESOURCES_REQUESTED, data: {} })
	return resources.distribute(serviceProviderId, numberUsers, numberCases).then((response) => {
		if (response) {
			dispatch({
				type: DISTRIBUTE_SERVICEPROVIDER_RESOURCES_SUCCESS,
				data: response
			})
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'Distributed Successfully',
					isSuccess: true
				}
			})
		} else {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${response.errorCode}`,
					isSuccess: false
				}
			})
		}
	})
}

const initial = {
	serviceProviders: [],
	serviceProvider: null,
    serviceProviderMemberAutoCompleteSuggestions: [],
	filter: 0,
	searchTerm: '',
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	serviceProviderInvitations: [],
	isCreating: false,
	autoCompleteSuggestions: [],
	sharedWithAutoCompleteSuggestions: [],
	canLeaveServiceProvider: null,
	serviceProvidersWithCase: null,
	sponsoredServiceProviders: [],
	sortSponsoredField: 'modifiedDateSeconds',
	isSponsoredDescending: true,
	treatment: null,
	pathology: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_SPONSORED_SERVICEPROVIDERS_SUCCESS:
			return {
				...state,
				sponsoredServiceProviders: action.data
			}
        case SET_SERVICEPROVIDER_MEMBER_REQUESTED:
            return { ...state, isAdding: true }
        case SET_SERVICEPROVIDER_MEMBER_FAILED:
            return { ...state, isAdding: false }
        case SET_SERVICEPROVIDER_MEMBER_SUCCESS:
            return {
                ...state,
                serviceProviderMembers: [
                    ...state.serviceProviderMembers.slice(0, action.data),
                    action.data,
                    ...state.serviceProviderMembers.slice(action.pos)
                ], isAdding: false
            }
        case SET_SERVICEPROVIDER_SORT_REQUESTED:
			return {
				...state,
				serviceProviders: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_SPONSORED_SERVICEPROVIDER_SORT_REQUESTED:
			return {
				...state,
				sponsoredServiceProviders: [ ...action.data.items ],
				sortSponsoredField: action.data.field,
				isSponsoredDescending: action.data.isDescending
			}
		case SET_SERVICEPROVIDERS_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case FETCH_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }

		case FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_SUCCESS:
			return { ...state, sharedWithAutoCompleteSuggestions: action.data }
		case FETCH_SHARED_SERVICEPROVIDERS_AUTOCOMPLETE_REQUESTED:
			return { ...state, sharedWithAutoCompleteSuggestions: [] }

		case FETCH_SERVICEPROVIDER_MEMBER_AUTOCOMPLETE_SUCCESS:
			return { ...state, serviceProviderMemberAutoCompleteSuggestions: action.data }

		case ADD_SERVICEPROVIDER_SUCCESS:
			return {
				...state,
				isCreating: false,
				serviceProviderInvitations: [
					...state.serviceProviderInvitations.slice(0, action.data),
					action.data,
					...state.serviceProviderInvitations.slice(action.pos)
				]
			}
		case ADD_SERVICEPROVIDER_FAILED:
			return {
				...state,
				isCreating: false
			}
		case FETCH_SERVICEPROVIDERS_SUCCESS:
			return {
				...state,
				serviceProviders: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case FETCH_SERVICEPROVIDERS_WITH_CASE_SUCCESS:
			return { ...state, serviceProvidersWithCase: action.data }
		case FETCH_SERVICEPROVIDER_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case CLEAR_SERVICEPROVIDER_REQUESTED:
			return { ...state, serviceProvider: null }
        case FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS:
            return { ...state, serviceProviderMembers: action.data }
		// case SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS:
		// 	return {
		// 		...state,
		// 		serviceProviderInvitations: [
		// 			...state.serviceProviderInvitations.slice(0, action.data),
		// 			action.data,
		// 			...state.serviceProviderInvitations.slice(action.pos)
		// 		]
		// 	}
		case UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case UPDATE_SERVICEPROVIDER_TREATMENT_SUCCESS:
			return { ...state, serviceProvider: {...state.serviceProvider, treatmentId: action.data }}
		case UPDATE_SERVICEPROVIDER_PATHOLOGY_SUCCESS:
			return { ...state, serviceProvider: {...state.serviceProvider, pathologyId: action.data }}
		case FETCH_TREATMENT_SUCCESS:
			return { ...state, treatment: action.data }
		case FETCH_PATHOLOGY_SUCCESS:
			return { ...state, pathology: action.data }
		case RESEND_SERVICEPROVIDER_INVITATION_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case SET_SERVICEPROVIDER_MEMBERS_KEY_SUCCESS:
			return { ...state, searchTerm: action.data }
		case REINSTATE_SERVICEPROVIDER_SUCCESS:
			return {
				...state,
				serviceProviders: state.serviceProviders.map(
					(serviceProvider) =>
						serviceProvider.id === action.data.id ? { ...serviceProvider, isArchived: action.data.isArchived } : serviceProvider
				)
			}
		case LEAVE_SERVICEPROVIDER_SUCCESS:
			return {
				...state,
				serviceProviders: state.serviceProviders.filter((item) => item.id !== action.data)
			}		
		case DISTRIBUTE_SERVICEPROVIDER_RESOURCES_SUCCESS:
			return {
				...state,
				serviceProviders: state.serviceProviders.map(
					(serviceProvider) =>
						serviceProvider.id === action.data.id
							? {
									...serviceProvider,
									numberUsers: action.data.numberUsers,
									numberCases: action.data.numberCases
								}
							: serviceProvider
				)
			}
		case ADD_SERVICEPROVIDER_REQUESTED:
			return { ...state, isCreating: true }
		case CAN_LEAVE_SERVICEPROVIDER_SUCCESS:
			return { ...state, canLeaveServiceProvider: action.data }
		case CAN_LEAVE_SERVICEPROVIDER_FAILED:
			return { ...state, canLeaveServiceProvider: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.serviceProvider
const coreSelector = (state) => state.core
const stateSelector = (state) => state

export const isCreatingSelector = createSelector(mainSelector, (state) => state.isCreating)

export const serviceProvidersSelector = createSelector(mainSelector, (state) => state.serviceProviders)
export const serviceProviderSelector = createSelector(mainSelector, (state) => state.serviceProvider)
export const treatmentSelector = createSelector(mainSelector, (state) => state.treatment)
export const pathologySelector = createSelector(mainSelector, (state) => state.pathology)
export const serviceProviderInvitationsSelector = createSelector(mainSelector, (state) => {
	if (state && state.serviceProviderInvitations && state.searchTerm) {
		let searchTerm = state.searchTerm.toLowerCase()
		return state.serviceProviderInvitations.filter((f) => {
			if (f.memberName) {
				return f.email.toLowerCase().includes(searchTerm) || f.memberName.toLowerCase().includes(searchTerm)
			} else {
				return f.email.toLowerCase().includes(searchTerm)
			}
		})
	} else {
		return state.serviceProviderInvitations
	}
})
export const serviceProvidersFilterSelector = createSelector(mainSelector, (state) => state.filters)

export const canEditServiceProviderBackgroundImageSelector = createSelector(mainSelector, (state) => {
    let user = getUser()
	return user && state && state.serviceProvider && (state.serviceProvider.isAdmin || state.serviceProvider.isFounder);
})

export const canEditServiceProviderSelector = createSelector(mainSelector, (state) => {
    let user = getUser()
    return user && state && (state.serviceProvider.isAdmin || state.serviceProvider.isFounder);
})

//export const canAddServiceProviderMemberSelector = createSelector(mainSelector, (state) => {
//	let user = getUser()
//	if (user && state.serviceProvider) {
//		if (state.serviceProvider.administrator && (state.serviceProvider.administrator.id === user.id || state.serviceProvider.administrator.id === user.teamOwner)) {
//			return true
//		}
//	}

//	return false
//})

//export const canLeaveServiceProviderSelector = createSelector(mainSelector, (state) => state.canLeaveServiceProvider)

//export const serviceProvidersWithCaseSelector = createSelector(mainSelector, (state) => state.serviceProvidersWithCase)

// export const canLeaveServiceProviderSelector = createSelector(mainSelector, (state) => {
// let user = getUser()

// if (user && state)
// 	if (state.founder && state.founder.id !== user.id)
// 		return true
// 	if(state && state.roleName !== 'Founder')
// 		return true

// 	return false
// })
//export const canInviteMemberServiceProviderSelector = createSelector(stateSelector, (state) => {
//	// let user = getUser()
//	if (state && state.serviceProvider) {
//		// if (state.subscriptionUser && state.subscriptionUser.availableUsers === 0) return false
//		// if (state.serviceProvider.founder && state.serviceProvider.founder.id === user.id) return true
//		// if (state.serviceProvider.administrator && state.serviceProvider.administrator.id === user.id) return true
//		if (state.serviceProvider.roleName === 'Administrator' || state.serviceProvider.roleName === 'Founder') return true
//	}
//	return false
//})

//export const canEmailAdministratorSelector = createSelector(mainSelector, (state) => {
//	// let user = getUser()
//	// if (user && state) if (state.founder && state.founder.id !== user.id) return true

//	if (state && state.roleName !== 'Founder' && state.roleName !== 'Administrator') return true

//	return false
//})

//export const canReinstateServiceProviderSelector = createSelector(mainSelector, (state) => {
//	// let user = getUser()
//	// if (user && state) if (state.isArchived && state.founder && user.id === state.founder.id) return true
//	if (state && state.isArchived && state.roleName === 'Founder') return true
//	return false
//})

//export const invitedServiceProviderMemberSelector = createSelector(coreSelector, (state) => {
//	if (state && state.params && state.params.invitedServiceProvider) {
//		return state.params.invitedServiceProvider
//	}
//	return null
//})

export const canManageServiceProviderSelector = createSelector(mainSelector, (state) => {
    let user = getUser()
    return user && state && (state.isAdmin || state.isFounder);
})

//export const canMakeServiceProviderAdministratorSelector = createSelector(stateSelector, (state) => {
//	let user = getUser()
//	if (user && state)
//		if (state.serviceProvider && state.member)
//			if (state.serviceProvider.administrator && (state.serviceProvider.administrator.id === user.id || state.serviceProvider.administrator.id === user.teamOwner) && state.member.status === 1)
//				return true
//	return false
//})

//export const canResendEmailInvitationSelector = createSelector(stateSelector, (state) => {
//	let user = getUser()
//	if (user && state)
//		if (state.serviceProvider && state.member)
//			if (state.serviceProvider.administrator && (state.serviceProvider.administrator.id === user.id || state.serviceProvider.administrator.id === user.teamOwner) && state.member.status === 0)
//				return true
//	return false
//})

//export const canRemoveUserServiceProviderSelector = createSelector(stateSelector, (state) => {
//	let user = getUser()
//	if (user && state)
//		if (state.serviceProvider && state.member)
//			if (state.serviceProvider.administrator && (state.serviceProvider.administrator.id === user.id || state.serviceProvider.administrator.id === user.teamOwner)) return true
//	return false
//})

export const filteredServiceProvidersSelector = createSelector(mainSelector, (state) => {
	let data = state.serviceProviders ? state.serviceProviders : null

	if (data && data.length > 0) {
		switch (state.filter) {
			case 0:
                return data.filter((f) => !f.isArchived)
			case 1:
				return data.filter((f) => f.isFounder && (f.numberCases > 0 || f.numberUsers > 0))
			case 2:
				return data.filter((f) => f.isAdministrator)
			case 3:
				return data.filter((f) => !f.isFounder && !f.isAdministrator)
			case 4:
				return data.filter((f) => f.isArchived)
			default:
				return data
		}
	} else {
		return []
	}
})
export const filteredServiceProvidersWithCaseSelector = createSelector(mainSelector, (state) => {
	let data = state.serviceProvidersWithCase ? state.serviceProvidersWithCase : []

	if (data && data.length > 0) {
		if (data) {
			switch (state.filter) {
				case 0:
					return data.filter((f) => !f.isArchived)
				case 'SPONSORED':
					return data.filter((f) => f.isSponsored)
				case 'MANAGE':
					return data
				case 'JOINED':
					return data.filter((f) => f.dateJoined)
				case 'ARCHIVED':
					return data.filter((f) => f.isArchived)
				default:
					return data
			}
		}
	} else {
		return []
	}
})

//export const sharedWithServiceProviderAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
//	if (state && state.sharedWithAutoCompleteSuggestions) {
//		return state.sharedWithAutoCompleteSuggestions
//	}
//	return []
//})

export const serviceProviderAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const canViewServiceProviderMemberSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (
		state &&
		state.serviceProvider &&
		state.serviceProvider.isArchived & state.serviceProvider.founder &&
		state.serviceProvider.founder.id === user.id
	) {
		return false
	}
	return true
})
export const isAdminServiceProviderSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.serviceProvider) {
		if (state.serviceProvider.administrator && (state.serviceProvider.administrator.id === user.id || state.serviceProvider.administrator.id === user.teamOwner)) {
			return true
		}
	}
	return false
})

export const formattedServiceProviderProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviders && state.serviceProviders.length > 0
		? state.serviceProviders.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					observationalProtocol: r.observationalProtocol,
					label: r.observationProtocol ? `${r.observationProtocol.name} | ${r.name}` : r.name,
					id: r.id,
					protocolId: r.observationProtocol.id,
					isPhiFree: r.phi
				}
			})
		: []
})

export const formattedServiceProvidersSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviders && state.serviceProviders.length > 0
		? state.serviceProviders.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					label: r.founder ? `${r.name} | ${r.founder.firstName} ${r.founder.lastName}` : r.name,
					id: r.id,
					isPhiFree: r.phi
				}
			})
		: []
})

export const serviceProviderMembersSelector = createSelector(mainSelector, (state) => {
    if (state.serviceProviderMembers && state.searchTerm) {
        let searchTerm = state.searchTerm.toLowerCase()
        return state.serviceProviderMembers.filter(
            (f) =>
            f.email.toLowerCase().includes(searchTerm) ||
            f.firstName.toLowerCase().includes(searchTerm) ||
            f.lastName.toLowerCase().includes(searchTerm)
        )
    } else {
        return state.serviceProviderMembers
    }
})

export const canDistributeResourcesSelector = createSelector(mainSelector, (state) => {
	if (state) {
		if (state.isSponsored) return true
	}
	return false
})

export const sortServiceProviderDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortServiceProviderFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const sponsoredServiceProvidersSelector = createSelector(mainSelector, (state) => {
	return state.sponsoredServiceProviders
})

export const sortSponsoredServiceProviderDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isSponsoredDescending
})

export const sortSponsoredServiceProviderFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortSponsoredField
})

export const serviceProviderMemberAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.serviceProviderMemberAutoCompleteSuggestions) {
		return state.serviceProviderMemberAutoCompleteSuggestions
    }
    return []
})

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'

const renderCheckboxField = ({
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	...custom
}) => {
	return (
		<FormGroup data-testid="registration-checkbox">
			<FormControlLabel
				data-testid="registration-checkbox-control"
				control={<Checkbox value='checkedA' style={style} onChange={input.onChange} />}
				label={label}
				labelPlacement='end'
			/>
			{touched &&
			error && (
				<FormHelperText
					data-testid="registration-checkbox-error"
					style={{
						backgroundColor: '#011121',
						color: '#f44336',
						marginLeft: '1rem',
						fontSize: '13px',
					}}>
					{error}
				</FormHelperText>
			)}
		</FormGroup>
	)
}

export default ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	onChange
}) => (
	<Field
		data-testid="registration-checkbox-field"
		name={name}
		component={renderCheckboxField}
		label={label}
		inputLabelProps={inputLabelProps}
		inputProps={inputProps}
		style={style}
		fullWidth
		margin={margin}
		variant={variant}
		type={type}
		validate={validators}
		onChange={onChange}
	/>
)

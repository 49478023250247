export const fetchCurrentBackendVersion = () => {
    return fetch(`/api/utility/version`, {
        method: 'get'
    })
    .then(function (response) {
        return response.json()
    })
    .then(function (data) {
        return data
    })
}

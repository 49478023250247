import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Link } from 'react-router-dom'
import { isMobile } from './../../../../utilities/mobileDetector'

const YourSponsor = ({ intl, sponsor, circle }) => {
	const theme = useTheme()
    return (
        <div data-testid="your-sponsor" style={{ height: '99%', overflow: 'hidden' }}>
            <Grid container spacing={3}
                style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                {sponsor && <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant='h4'
                                style={{ color: theme.palette.secondary.main }}
                            >
                                {intl.formatMessage({ id: 'app.account.subscriptionSponsor.information' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '2em' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: '700',
                                            fontSize: '17px',
                                            lineHeight: '22px',
                                            letterSpacing: '0.51px'
                                        }}
                                    >
                                        {sponsor}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='body2'
                                        style={{
                                            opacity: 0.38,
                                            fontSize: '13px',
                                            lineHeight: '16px',
                                            letterSpacing: '2.08px',
                                            marginTop: '3px'
                                        }}>
                                        {intl.formatMessage({ id: 'app.general.sponsor' })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                {circle && <Grid item xs={12} md={6}>
                    <Grid container spacing={3} direction='column'>
                        <Grid item style={{ paddingBottom: '20px' }}>
                            <Typography
                                data-testid="your-sponsor-circle-info"
                                variant='h4' style={{ color: theme.palette.secondary.main }}>
                                {intl.formatMessage({ id: 'app.account.subscriptionSponsor.circleInformation' })}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }} spacing={3}>
                                <Grid item>
                                    <img
                                        data-testid="your-sponsor-circle-img"
                                        height='100px'
                                        width='100px'
                                        style={{
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                        src={circle.profileImageUrl}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography
                                                data-testid="your-sponsor-circle-name"
                                                variant='subtitle1'
                                                style={{ color: theme.palette.secondary.main }}>
                                                {circle.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Link data-testid="your-sponsor-circle-link" to={'circles/' + circle.id} style={{ textDecoration: 'none' }}>
                                                <Grid item>
                                                    <Typography
                                                        variant='body2'
                                                        style={{ color: theme.palette.primary.main }}>
                                                        {intl.formatMessage({
                                                            id: 'app.account.subscriptionSponsor.circleDetails'
                                                        })}
                                                        <ArrowForwardIcon />
                                                    </Typography>
                                                </Grid>
                                            </Link>
                                        </Grid>
                                        {circle.protocolAlias && <Grid item xs={12} style={{ marginTop: '2em' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            textAlign: 'left',
                                                            fontWeight: '700',
                                                            fontSize: '17px',
                                                            lineHeight: '22px',
                                                            letterSpacing: '0.51px'
                                                        }}
                                                    >
                                                        {circle.protocolAlias}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='body2'
                                                        style={{
                                                            opacity: 0.38,
                                                            fontSize: '13px',
                                                            lineHeight: '16px',
                                                            letterSpacing: '2.08px',
                                                            marginTop: '3px'
                                                        }}>
                                                        {intl.formatMessage({ id: 'app.protocol.alias.editAliasPlaceholder' })}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
        </div>
    )
}

const enhance = compose(injectIntl)

export default enhance(YourSponsor)

import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { StripeProvider, Elements, CardForm, CardElement, CardNumberElement } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
// import { injectStripe } from 'react-stripe-elements'
import SaveCardForm from './SaveCardForm'
import ConfirmPayment from './ConfirmPayment'
import './stripe.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchPaymentMethod } from '../../redux/actions'
import { paymentMethodSelector, awaitingPaymentMethodSelector } from '../../redux/selectors'

const Checkout = ({
	intl,
	handleSubmit,
	userCount,
	caseCount,
	totalPrice,
	getPaymentMethod,
	paymentMethod,
	stripePublishableKey,
	awaitingPaymentMethod,
	elements,
	stripe
}) => {
	const theme = useTheme()

	useEffect(() => {
		getPaymentMethod()
	}, [])

	const [ hasPaymentMethod, setHasPaymentMethod ] = useState(false)

	useEffect(
		() => {
			setHasPaymentMethod(paymentMethod ? true : false)
		},
		[ paymentMethod ]
	)

	return (
		<StripeProvider data-testid="checkout" apiKey={stripePublishableKey.stripePublishableKey}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{
					backgroundColor: theme.palette.secondary.main,
					padding: '4.01rem'
				}}>
				<Grid item>
					<Grid
						container
						direction='column'
						justifyContent='center'
					>

						{awaitingPaymentMethod && (
							<div style={{ margin: '0 auto' }}>
								<CircularProgress data-testid="checkout-progress" />
							</div>
						)}

						{!hasPaymentMethod &&
						!awaitingPaymentMethod && (
							<Elements data-testid="checkout-elements">
								<SaveCardForm data-testid="checkout-save-card-form" />
							</Elements>
						)}

						{hasPaymentMethod && (
							<ConfirmPayment
								data-testid="checkout-confirm-payment"
								userCount={userCount}
								caseCount={caseCount}
								totalPrice={totalPrice}
								paymentMethod={paymentMethod}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</StripeProvider>
	)
}

const mapStateToProps = (state, ownProps) => ({
	paymentMethod: paymentMethodSelector(state),
	awaitingPaymentMethod: awaitingPaymentMethodSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPaymentMethod: () => dispatch(fetchPaymentMethod())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(Checkout)

import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Reports = lazy(() => componentLoader(() => import('./components/Reports')))

export default () => (
    <div data-testid="report">
        <Suspense fallback={<div />}>
            <section>
                <Reports />
            </section>
        </Suspense>
    </div>
)

import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import Check from '../../assets/check'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { getCurrencyCode } from '../../utilities/CurrencyCodes'
import locale2 from 'locale2'
import {
	submitPayment,
	hideModal,
	fetchSubscription,
	fetchSubscriptionUsersCases,
	resetPaymentState
} from '../../redux/actions'
import { paymentSelector, priceSelector, isSubmittingSelector } from '../../redux/selectors'
import { stackOffsetDiverging } from "d3"

const ConfirmPayment = ({
	intl,
	handleSubmit,
	userCount,
	caseCount,
	totalPrice,
	paymentMethod,
	sendPayment,
	paymentCompleted,
	updateSubscription,
	updateSubscriptionUsersCases,
	resetPayment,
	close,
	price,
	isSubmitting
}) => {
	const theme = useTheme()

	const currency = 'USD' // getCurrencyCode(locale2)

	const onPay = () => {
		sendPayment(userCount, caseCount)
	}

	useEffect(
		() => {
			if (paymentCompleted) {
				updateSubscription()
				updateSubscriptionUsersCases()
				close()
				resetPayment()
			}
		},
		[ paymentCompleted ]
	)

	return (
		<Grid data-testid="confirm-payment" item>
			<Grid item>
				<Typography
					data-testid="confirm-payment-title"
					variant='h1'
					style={{
						color: '#fff'
					}}>
					{intl.formatMessage({ id: 'app.checkout.confirmChangesTitle' })}
				</Typography>
			</Grid>

			{caseCount != null &&
			caseCount > 0 && (
				<Grid data-testid="confirm-payment-cases-wrapper" container spacing={3} justifyContent='space-between' style={{ padding: '30px 0' }}>
					<Grid item xs={12} sm={6}>
						<Typography data-testid="payment-cases-title" variant='h2' style={{ color: theme.palette.light }}>
							{intl.formatMessage({ id: 'app.general.cases' })}
						</Typography>
						<Typography data-testid="payment-cases-text" variant='body2' style={{ color: theme.palette.light }}>
							{intl.formatMessage({ id: 'app.checkout.updatingTotalCases' })}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6}>
						<div style={{ display: 'flex', flexDirection: 'column', float: 'right', marginRight: '75px' }}>
							<Typography data-testid="payment-cases-count" variant='h2' style={{ color: theme.palette.primary.main }}>
								{caseCount}
							</Typography>
							<Typography data-testid="payment-cases-price" variant='subtitle1' style={{ color: theme.palette.primary.main }}>
								{intl.formatNumber(caseCount * price.casePrice, {
									style: 'currency',
									currency: currency,
									currencyDisplay: 'symbol'
								})}{' '}
								USD
							</Typography>
							<Typography data-testid="payment-cases-onetime" variant='body2' style={{ color: theme.palette.primary.main }}>
								{intl.formatMessage({ id: 'app.checkout.oneTimeCost' })}
							</Typography>
						</div>
					</Grid>
				</Grid>
			)}

			{userCount != null &&
			userCount > 0 && (
				<Grid data-testid="confirm-payment-users-wrapper" container spacing={3}>
					<Grid item xs={12} sm={6}>
						<Typography data-testid="payment-users-title" variant='h2' style={{ color: theme.palette.light }}>
							{intl.formatMessage({ id: 'app.general.users' })}
						</Typography>
						<Typography data-testid="payment-users-text" variant='body2' style={{ color: theme.palette.light }}>
							{intl.formatMessage({ id: 'app.checkout.updatingTotalUsers' })}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography data-testid="payment-users-count" variant='h2' style={{ color: theme.palette.primary.main }}>
							{userCount}
						</Typography>
						<Typography data-testid="payment-users-price" variant='subtitle1' style={{ color: theme.palette.primary.main }}>
							{intl.formatNumber(userCount * price.userPrice, {
								style: 'currency',
								currency: currency,
								currencyDisplay: 'symbol'
							})}{' '}
							USD
						</Typography>
						<Typography data-testid="payment-users-new-monthly" variant='body2' style={{ color: theme.palette.primary.main }}>
							{intl.formatMessage({ id: 'app.circle.manageAllocations.newMontlyCost' })}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography variant='h2' style={{ color: theme.palette.primary.main }}>
							&nbsp;
						</Typography>
						<Typography data-testid="payment-users-price-prorated" variant='subtitle1' style={{ color: theme.palette.primary.main }}>
							{intl.formatNumber(
								userCount * price.userPrice * price.prorateMultiplier < 1
									? 1.0
									: userCount * price.userPrice * price.prorateMultiplier,
								{
									style: 'currency',
									currency: currency,
									currencyDisplay: 'symbol'
								}
							)}{' '}
							USD
						</Typography>
						<Typography data-testid="payment-users-price-prorated-text" variant='body2' style={{ color: theme.palette.primary.main }}>
							{intl.formatMessage({ id: 'app.circle.manageAllocations.proratedMontlyCost' })}
						</Typography>
					</Grid>
				</Grid>
			)}

			<Grid item>
				<Typography data-testid="confirm-payment-text"
					variant='subtitle2'
					style={{
						color: '#fff'
					}}>
					{intl
						.formatMessage({ id: 'app.checkout.confirmChangesFooter' })
						.replace('%CASES%', caseCount)
						.replace('%CARD%', paymentMethod ? paymentMethod.brand : 'XXXX')
						.replace('%LAST4%', paymentMethod ? paymentMethod.last4 : 'XXXX')}
				</Typography>
			</Grid>

			<Grid
				item
				style={{
					width: '100%'
				}}>
				<div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
					<Button
						data-testid="confirm-payment-button-pay"
						variant="contained"
						color="primary"
						type='submit'
						size='medium'
						onClick={onPay}
						disabled={isSubmitting}>
						{intl.formatMessage({ id: 'app.checkout.pay' })}
					</Button>
				</div>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	paymentCompleted: paymentSelector(state),
	price: priceSelector(state),
	isSubmitting: isSubmittingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	sendPayment: (userCount, caseCount) => dispatch(submitPayment(userCount, caseCount)),
	updateSubscription: () => dispatch(fetchSubscription(true)),
	updateSubscriptionUsersCases: () => dispatch(fetchSubscriptionUsersCases(true)),
	close: () => dispatch(hideModal()),
	resetPayment: () => dispatch(resetPaymentState())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ConfirmPayment)

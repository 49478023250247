import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import { isMobile } from './../../../utilities/mobileDetector'

const CircleEmpty = ({ intl }) => {
	const theme = useTheme()
	return (
		<Grid data-testid="circle-empty" container direction='row' style={{ paddingBottom: '32px' }}>
            <Grid item xs={12} sm={6} lg={8} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px' }}>
				<Typography
					data-testid="circle-empty-no-circles"
					variant='h2'
					style={{ fontWeight: '300', color: theme.palette.primary.A800, paddingBottom: '12px' }}>
					{intl.formatMessage({ id: 'app.circle.noCircles' })}
				</Typography>
				<Typography
					data-testid="circle-empty-no-message"
					variant='body2' style={{ fontWeight: '600' }}>
					{intl.formatMessage({ id: 'app.circle.noCircleMessage' })}
				</Typography>
			</Grid>
            <Grid item  xs={12} sm={6} lg={4} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px', whiteSpace: 'nowrap' }}>
				<Typography
					data-testid="circle-empty-phi"
					variant='body2' style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px' }}>
					<Lock style={{ fontSize: isMobile ? '20px' : '32px', color: '#444e5e', transform: 'translateY(3px)' }} />{' '}
					{intl.formatMessage({ id: 'app.circle.phiCircle' }).toUpperCase()}
				</Typography>
				<Typography
					data-testid="circle-empty-phi-free"
					variant='body2' style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#86ceb4' }}>
					<LockOpen style={{ fontSize: isMobile ? '20px' : '32px', color: '#86ceb4', transform: 'translateY(3px)' }} />{' '}
					{intl.formatMessage({ id: 'app.circle.phiFreeCircle' }).toUpperCase()}
				</Typography>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(CircleEmpty)

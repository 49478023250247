import React, { Fragment, useEffect, useState } from 'react'
import LabelPaper from '../../../components/LabelPaper'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import TaskTable from './TaskTable'
import TaskEmpty from './TaskEmpty'
import { withStyles } from '@material-ui/core'
import { useDispatch, connect } from 'react-redux'
import { fetchServiceProviderTasks, fetchSubscription, setServiceProviderTaskSearch } from '../../../redux/actions'
import { serviceProviderTasksSelector, tasksRefreshRequestedSelector } from '../../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import ArrowLeftOutlined from '@material-ui/icons/ArrowLeftOutlined'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import SearchBar from './SearchBar'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import TaskFilters from './TaskFilters'

const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const MyTasks = ({ intl, classes, filters, setFiltered, tasks, getSubscription, setSearch, refreshRequested }) => {
	const dispatch = useDispatch()
	const [filter, setFilter] = useState(1)
	//const tasks = useSelector(tasksSelector, shallowEqual)

	useEffect(() => {
		dispatch(fetchServiceProviderTasks())
		//getSubscription()
	}, [])

	const filterSelected = (e) => {
		setFiltered(e.currentTarget.id)
		setFilter(e.currentTarget.id)
	}

	return (
		<div data-testid="mytasks-inner" style={{ margin: '3% 0', width: '100%' }}>
			<Grid className="wide-container" container style={{ marginTop: '30px', padding: '0 5% 0 5%', width: '100%' }}>
				<Grid item>
					<Link
						data-testid="mytasks-back-link"
						className="no-print"
						to="/dashboard" style={{ textDecoration: 'none', color: '#2e7df6' }}>
						<Typography
							variant="body2"
							style={{
								paddingBottom: '16px',
								fontSize: '16px',
								letterSpacing: '1.29px',
								lineHeight: '16px'
							}}
						>
							<ArrowLeftOutlined viewBox="0 0 24 15" />
							{intl.formatMessage({ id: 'app.dashboard.backToDashboardLabel' })}
						</Typography>
					</Link>
				</Grid>
				<Grid item style={{ width: '100%', paddingBottom: '32px' }}>
					<Grid container style={{ width: '100%' }} justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography
								data-testid="mytasks-title"
								variant='h1'>
								{intl.formatMessage({ id: 'app.dashboard.tasksHeader' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid container className="page-topbar" justifyContent="space-between">
					<Grid data-testid="mytasks-searchbar" item style={{ flex: 1 }}>
						<SearchBar onSearchChange={setSearch} />
					</Grid>
				</Grid>

				<Grid item style={{ width: '100%', marginBottom: '50px' }}>
				<LabelPaper
					data-testid="mytasks-filters-container"
					label={intl.formatMessage({ id: 'app.tasks.filterLabel' })}
					Component={TaskFilters}
					filters={filters}
					filterSelected={filterSelected}
					filter={filter}
					minHeight={100}
				/>
				</Grid>

				<Grid item style={{ width: '100%' }}>
					{!refreshRequested && (!tasks || tasks.length < 1) ? (
						<CompositeLabelPaper
							data-testid="mytasks-empty"
							classes={classes.gradient}
							Component={TaskEmpty}
							filters={filters}
							filterSelected={filterSelected}
							filter={filter}
						/>
					) : (
						<CompositeLabelPaper
							data-testid="mytasks-items"
							Component={TaskTable}
							intl={intl}
							data={tasks}
							tasks={tasks}
							filter={filter}
							filters={filters}
							filterSelected={filterSelected}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	tasks: serviceProviderTasksSelector(state),
	refreshRequested: tasksRefreshRequestedSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSubscription: () => dispatch(fetchSubscription()),
	setSearch: (value) => dispatch(setServiceProviderTaskSearch(value))
})


const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(MyTasks)

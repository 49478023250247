import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { sortDirectionSelector, sortFieldSelector, taskFilterSelector } from '../../../../redux/selectors'
import { fetchServiceProviderTasks, setServiceProviderTaskFilter, setServiceProviderTaskSort } from '../../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useConstant } from '../../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../../utilities/constants'
import { debounce } from '@material-ui/core'

const TaskHead = ({ intl, filter, setFilter, fetchTasks }) => {
	const fetchTasksHandler = useConstant(() =>
		debounce(fetchTasks, 500, { leading: false })
	)

	const changeSort = (field, direction) => {
		let newFilter = { ...filter }
		newFilter.sortField = field
		newFilter.sortDesc = newFilter.sortField === filter.sortField ? !newFilter.sortDesc : false

		setFilter(newFilter)
		fetchTasksHandler()
	}

	return (
		<TableRow data-testid="mytasks-surveys-head">
			<TableCell width="2.5%" size="small" align="left" style={{ maxWidth: '20px' }} colSpan={0} />

			<TableCell width="25%" data-testid="mytasks-surveys-head-case" align="left">
                <TableSortLabel
                    active={filter.sortField === 1}
                    direction={filter.sortField === 1 && filter.sortDesc ? 'desc' : 'asc'}
                    onClick={(e) => changeSort(1, !filter.sortDesc)}
                    IconComponent={ExpandMoreIcon}
                >
				<Typography
					variant="caption"
				>
                    {intl.formatMessage({ id: 'app.tasks.patientHeader' }).toUpperCase()}
					</Typography>
                </TableSortLabel>
			</TableCell>
			<TableCell width="2.5%" size="small" align="left" style={{ maxWidth: '20px' }} colSpan={0} />

			<TableCell width="20%" data-testid="mytasks-surveys-head-case" align="left">
                <TableSortLabel
                    active={filter.sortField === 2}
                    direction={filter.sortField === 2 && filter.sortDesc ? 'desc' : 'asc'}
                    onClick={(e) => changeSort(2, !filter.sortDesc)}
                    IconComponent={ExpandMoreIcon}
                >
				<Typography
					variant="caption"
				>
                    {intl.formatMessage({ id: 'app.tasks.caseHeader' }).toUpperCase()}
					</Typography>
                </TableSortLabel>
			</TableCell>

			<TableCell width="20%" data-testid="mytasks-surveys-head-task" align="left">
                <TableSortLabel
                    active={filter.sortField === 3}
                    direction={filter.sortField === 3 && filter.sortDesc ? 'desc' : 'asc'}
                    onClick={(e) => changeSort(3, !filter.sortDesc)}
                    IconComponent={ExpandMoreIcon}
                >{' '}
				<Typography
					variant="caption"
				>
					{intl.formatMessage({ id: 'app.tasks.taskHeader' }).toUpperCase()}
					</Typography>
                </TableSortLabel>
			</TableCell>

			<TableCell width="15%" data-testid="mytasks-surveys-head-due" align="left">
                <TableSortLabel
                    active={filter.sortField === 0}
                    direction={filter.sortField === 0 && filter.sortDesc ? 'desc' : 'asc'}
                    onClick={(e) => changeSort(0, !filter.sortDesc)}
                    IconComponent={ExpandMoreIcon}>
				<Typography
					variant="caption"
				>
					{intl.formatMessage({ id: 'app.tasks.dueHeader' }).toUpperCase()}
					</Typography>
                </TableSortLabel>
			</TableCell>

			<TableCell width="15%" data-testid="mytasks-surveys-head-delegate" align="left">
                <TableSortLabel
					active={filter.sortField === 4}
                    direction={filter.sortField === 4 && filter.sortDesc ? 'desc' : 'asc'}
					onClick={(e) => changeSort(4, !filter.sortDesc)}
                    IconComponent={ExpandMoreIcon}>
							<Typography
                                variant="caption"
							>
                                {intl.formatMessage({ id: 'app.tasks.delegate' }).toUpperCase()}
                            </Typography>
						</TableSortLabel>
			</TableCell>
			<TableCell align='left' style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	filter: taskFilterSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFilter: (id) => dispatch(setServiceProviderTaskFilter(id)),
    fetchTasks: () => dispatch(fetchServiceProviderTasks())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(TaskHead)

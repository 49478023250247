import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import useTheme from '@material-ui/core/styles/useTheme'
import DistributeResourcesAssignation from './components/DistributeResourcesAssignation'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router'
import { distributeResources } from '../../redux/actions'
import { subscriptionCaseSelector, subscriptionUserSelector } from '../../redux/modules/subscriptions'

const DistributeResources = ({ circle, intl, handleSubmit, submitAllocations, subscriptionCase, subscriptionUser }) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		//TODO: Add validation when backend is complete
		if (circle && circle.id) {
			submitAllocations(circle.id, values.numberUsers, values.numberCases)
		}
	}

	return (
		<form data-testid="form-distribute" onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				style={{
					padding: '4rem',
					backgroundColor: '#011020'
				}}
			>
				<Grid item style={{ textAlign: 'left' }}>
					<Typography
						variant='h1'
						style={{ color: '#fff', marginBottom: '4rem' }}
						data-testid="form-distribute-title"
					>
						{intl.formatMessage({ id: 'app.circles.distributeResources' })}
					</Typography>
					{circle &&
					circle.name && (
						<Typography variant='h3' style={{ color: '#fff', fontWeight: '400', paddingBottom: '40px' }}>
							{circle.name}
						</Typography>
					)}
				</Grid>test
				<Grid item data-testid="form-distribute-assignation" style={{ textAlign: 'center' }}>
					<DistributeResourcesAssignation
						circle={circle}
						subscriptionUser={subscriptionUser}
						subscriptionCase={subscriptionCase}
					/>
				</Grid>
				<Grid item>
					<Grid item style={{ textAlign: 'center' }}>
						<Button
							data-testid="form-distribute-button-submit"
							variant='contained'
							color='primary'
							style={{
								marginTop: '30px'
							}}
							type="submit"
							size="large"
						>
							{intl.formatMessage({ id: 'app.circles.distributeResourcesButton' })}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circle: state.core.params.circle,
	initialValues: state.core.params.circle,
	subscriptionUser: subscriptionUserSelector(state),
	subscriptionCase: subscriptionCaseSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitAllocations: (circleId, numberUsers, numberCases) => {
		dispatch(distributeResources(circleId, numberUsers, numberCases))
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'distributeResources'
	}),
	withRouter
)

export default enhance(DistributeResources)

import React, { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import '../../styles/index.scss'
import ManagePlan from './components/ManagePlan/ManagePlan'
import SubscriptionSponsor from './components/SubscriptionSponsor/SubscriptionSponsor'
import SubscriptionSponsorDetails from './components/SubscriptionSponsor/SubscriptionSponsorDetails'
import SponsoredCircles from './components/ManagePlan/SponsoredCircles'
import { fullUserSelector, subscriptionValiditySelector } from '../../redux/selectors'
import ApiStatusSection from './components/ApiStatusSection'

const mapStateToProps = (state, ownProps) => ({
    subscriptionValidity: subscriptionValiditySelector(state),
	fullUser: fullUserSelector(state)
})

const LeftNavigation = ({
	subscriptionValidity,
	fullUser
}) => {
	return (
		<div data-testid="account-components-wrapper">
			<Suspense fallback={<div />}>
				<section data-testid="account-components-inner">
					<ManagePlan />
					<SubscriptionSponsor />
					{
						fullUser?.openApi?.isApiEnabled
						&& <ApiStatusSection />
					}
					{
						//<SubscriptionSponsorDetails />
                    }
					{subscriptionValidity && <SponsoredCircles />}
				</section>
			</Suspense>
		</div>
	)
}

const enhance = compose(connect(mapStateToProps, null))

export default enhance(LeftNavigation)

import * as taskApi from '../../../apis/task'
import * as cookies from '../../../utilities/cookies'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import queryString from 'query-string'

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date;
}

const FETCH_TASK_REQUESTED = 'FETCH_TASK_REQUESTED'
const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS'
const FETCH_TASK_FAILED = 'FETCH_TASK_FAILED'

export const fetchTask = (id) => (dispatch) => {
	dispatch({ type: FETCH_TASK_REQUESTED, data: null })

    return taskApi.fetchTask(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_TASK_SUCCESS, data: response })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.tasks.fetchFailed`, isSuccess: false }
			})
		}
	})

	return
}

const FETCH_SERVICEPROVIDER_TASKS_REQUESTED = 'FETCH_SERVICEPROVIDER_TASKS_REQUESTED'
const FETCH_SERVICEPROVIDER_TASKS_SUCCESS = 'FETCH_SERVICEPROVIDER_TASKS_SUCCESS'
const FETCH_SERVICEPROVIDER_TASKS_FAILED = 'FETCH_SERVICEPROVIDER_TASKS_FAILED'

export const fetchServiceProviderTasks = (search) => (dispatch, getState) => {
	let state = getState()
    dispatch({ type: FETCH_SERVICEPROVIDER_TASKS_REQUESTED, data: {} })
	let searchTerm = search || state.task.searchTerm

    taskApi.fetchTasks(searchTerm, state.task.filter).then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({
                type: FETCH_SERVICEPROVIDER_TASKS_SUCCESS,
                data: {
                    items: response.tasks,
					field: 'dueDate',
                    isDescending: true,
					hasMore: response.hasMore
                }
            })
        } else {
            return dispatch({ type: FETCH_SERVICEPROVIDER_TASKS_FAILED, data: {} })
        }
    })
    return
}

const FETCH_ADDITIONAL_TASKS_REQUESTED = 'FETCH_ADDITIONAL_TASKS_REQUESTED'
const FETCH_ADDITIONAL_TASKS_SUCCESS = 'FETCH_ADDITIONAL_TASKS_SUCCESS'
const FETCH_ADDITIONAL_TASKS_FAILED = 'FETCH_ADDITIONAL_TASKS_FAILED'

export const fetchAdditionalTasks = (search, offset) => (dispatch, getState) => {
	let state = getState()
    dispatch({ type: FETCH_ADDITIONAL_TASKS_REQUESTED, data: {} })

    taskApi.fetchTasks(search, state.task.filter, offset).then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({
                type: FETCH_ADDITIONAL_TASKS_SUCCESS,
                data: {
                    items: response.tasks,
					hasMore: response.hasMore,
					field: 'dueDate',
                    isDescending: false
                }
            })
        } else {
            return dispatch({ type: FETCH_ADDITIONAL_TASKS_FAILED, data: {} })
        }
    })
    return
}

const CLOSE_TASK_REQUESTED = 'CLOSE_TASK_REQUESTED'
const CLOSE_TASK_SUCCESS = 'CLOSE_TASK_SUCCESS'
const CLOSE_TASK_FAILED = 'CLOSE_TASK_FAILED'

export const closeTask = (id) => (dispatch) => {
	dispatch({ type: CLOSE_TASK_REQUESTED, data: null })
	return taskApi.closeTask(id).then((response) => {
		if (response && response.isSuccessful) {
			let sp = ''
			if (history && history.location && history.location.search) {
				let params = queryString.parse(history.location.search)
				if (params && params.searchPhrase) {
					sp = params.searchPhrase
				}
			}
			dispatch(fetchServiceProviderTasks(sp))
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.tasks.closeTaskSuccess`, isSuccess: true }
			})
			return dispatch({ type: CLOSE_TASK_SUCCESS, data: null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.tasks.closeTaskFailed`, isSuccess: false }
			})
		}
	})
	return
}

const ARCHIVE_TASK_REQUESTED = 'ARCHIVE_TASK_REQUESTED'
const ARCHIVE_TASK_SUCCESS = 'ARCHIVE_TASK_SUCCESS'
const ARCHIVE_TASK_FAILED = 'ARCHIVE_TASK_FAILED'

export const archiveTask = (id) => (dispatch) => {
	dispatch({ type: ARCHIVE_TASK_REQUESTED, data: null })
	return taskApi.archiveTask(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.tasks.archiveTaskSuccess`, isSuccess: true }
			})
			history.push('/tasks')
			return dispatch({ type: ARCHIVE_TASK_SUCCESS, data: null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.tasks.fetchFailed`, isSuccess: false }
			})
		}
	})
	return
}

const DEACTIVATE_TASK_REQUESTED = 'DEACTIVATE_TASK_REQUESTED'
const DEACTIVATE_TASK_SUCCESS = 'DEACTIVATE_TASK_SUCCESS'
const DEACTIVATE_TASK_FAILED = 'DEACTIVATE_TASK_FAILED'

export const deactivateTask = (id) => (dispatch) => {
	dispatch({ type: DEACTIVATE_TASK_REQUESTED, data: {} })
	return taskApi.deactivateTask(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchServiceProviderTasks())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.tasks.successfullyDeactivated`,
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.tasks.deactivatedionFailed`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const ADD_TASK_REQUESTED = 'ADD_TASK_REQUESTED'
const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
const ADD_TASK_FAILED = 'ADD_TASK_FAILED'
const NEW_TASK_SUCCESS = 'NEW_TASK_SUCCESS'

export const addTask = (addTaskModel) => (dispatch) => {
	if (addTaskModel) {
		dispatch({ type: ADD_TASK_REQUESTED, data: {} })
		try {
			return taskApi.addTask(addTaskModel).then((caseModel) => {
				if (caseModel && caseModel.isSuccessful) {
					//dispatch(fetchTasks())
					history.push(`/tasks/${caseModel.id}`)
					dispatch(hideModal())
					return dispatch({ type: NEW_TASK_SUCCESS, data: caseModel })
					//return dispatch({ type: ADD_TASK_SUCCESS, data: caseModel })
				} else {
					dispatch({ type: ADD_TASK_FAILED, data: null })

					if (caseModel.errorCode === 6) {
						return dispatch({
							type: 'SET_SNACKBAR_MESSAGE',
							data: {
								message: 'app.case.duplicatePatient',
								isSuccess: false
							}
						})
					} else {
						return dispatch({
							type: 'SET_SNACKBAR_MESSAGE',
							data: {
								message: 'app.case.createTaskFailed',
								isSuccess: false
							}
						})
					}
				}
			})
		} catch (error) {
			dispatch({ type: ADD_TASK_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.case.createTaskFailed', isSuccess: false }
			})
		}
	}
	return dispatch({
		type: 'SET_SNACKBAR_MESSAGE',
		data: {
			message: 'app.case.createTaskFailed',
			isSuccess: false
		}
	})
}

const REASSIGN_TASK_REQUESTED = 'REASSIGN_TASK_REQUESTED'
const REASSIGN_TASK_SUCCESS = 'REASSIGN_TASK_SUCCESS'
const REASSIGN_TASK_FAILED = 'REASSIGN_TASK_FAILED'

export const reassignTask = (model) => (dispatch) => {
	dispatch({ type: REASSIGN_TASK_REQUESTED, data: {} })

	taskApi.reassignTask(model).then((taskModel) => {
		if (taskModel && taskModel.isSuccessful) {
			dispatch(fetchTask(model.taskId))
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.taskDetail.reassignFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

//const GET_CASES_REQUESTED = 'GET_CASES_REQUESTED'
//const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS'
//const GET_CASES_FAILED = 'GET_CASES_FAILED'

//export const fetchTasks = (searchPhrase) => (dispatch) => {
//	dispatch({ type: GET_CASES_REQUESTED, data: {} })

//	taskApi.fetchTasks(searchPhrase).then((response) => {
//		if (response && response.isSuccessful) {
//			if (response.tasks && response.tasks.length > 0) {
//				var data = response.tasks
//				return dispatch({
//					type: GET_CASES_SUCCESS,
//					data: {
//						items: data,
//						field: 'modifiedDateSeconds',
//						isDescending: true
//					}
//				})
//			} else {
//				return dispatch({
//					type: GET_CASES_SUCCESS,
//					data: {
//						items: [],
//						field: 'modifiedDateSeconds',
//						isDescending: true
//					}
//				})
//			}
//		} else {
//			return dispatch({
//				type: 'SET_SNACKBAR_MESSAGE',
//				data: {
//					message: 'app.case.fetchTasksFailed',
//					isSuccess: false
//				}
//			})
//		}
//	})
//	return
//}

const GET_TASK_PROTOCOL_REQUESTED = 'GET_TASK_PROTOCOL_REQUESTED'
const GET_TASK_PROTOCOL_SUCCESS = 'GET_TASK_PROTOCOL_SUCCESS'
const GET_TASK_PROTOCOL_FAILED = 'GET_TASK_PROTOCOL_FAILED'

export const fetchTaskSurvey = (id) => (dispatch) => {
	dispatch({ type: GET_TASK_PROTOCOL_REQUESTED, data: {} })

	taskApi.fetchTaskSurvey(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: GET_TASK_PROTOCOL_SUCCESS,
				data: response.survey
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.tasks.fetchFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}


const VIEW_PATIENT_RESULTS_REQUESTED = 'VIEW_PATIENT_RESULTS_REQUESTED'
const VIEW_PATIENT_RESULTS_SUCCESS = 'VIEW_PATIENT_RESULTS_SUCCESS'
const VIEW_PATIENT_RESULTS_FAILED = 'GET_TASK_PVIEW_PATIENT_RESULTS_FAILEDROTOCOL_FAILED'

export const viewPatientResults = (patientId) => (dispatch) => {
	dispatch({ type: VIEW_PATIENT_RESULTS_REQUESTED, data: {} })

	taskApi.viewPatientResults(patientId).then((response) => {
		if (response && response.isSuccessful) {
			const win = window.open(response.link, '_blank');
  			if (win != null) {
  			  win.focus();
  			}
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.tasks.viewPatientsResultsFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const SET_TASK_SORT_REQUESTED = 'SET_TASK_SORT_REQUESTED'

export const setServiceProviderTaskSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.task.tasks.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_TASK_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_TASKS_FILTER_REQUESTED = 'SET_TASKS_FILTER_REQUESTED'
const SET_TASKS_FILTER_SUCCESS = 'SET_TASKS_FILTER_SUCCESS'
const SET_TASKS_FILTER_FAILED = 'SET_TASKS_FILTER_FAILED'

export const setServiceProviderTaskFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_TASKS_FILTER_REQUESTED, data: {} })
	return dispatch({ type: SET_TASKS_FILTER_SUCCESS, data: filter })
}

const SET_TASKS_SEARCH_REQUESTED = 'SET_TASKS_SEARCH_REQUESTED'
const SET_TASKS_SEARCH_SUCCESS = 'SET_TASKS_SEARCH_SUCCESS'
const SET_TASKS_SEARCH_FAILED = 'SET_TASKS_SEARCH_FAILED'

export const setServiceProviderTaskSearch = (value) => (dispatch) => {
	dispatch({ type: SET_TASKS_SEARCH_REQUESTED, data: {} })
	return dispatch({ type: SET_TASKS_SEARCH_SUCCESS, data: value })
}

const FETCH_TASK_EMAIL_REQUESTED = 'FETCH_TASK_EMAIL_REQUESTED'
const FETCH_TASK_EMAIL_SUCCESS = 'FETCH_TASK_EMAIL_SUCCESS'
const FETCH_TASK_EMAIL_FAILED = 'FETCH_TASK_EMAIL_FAILED'

export const fetchTaskEmail = (id) => (dispatch) => {
	dispatch({ type: FETCH_TASK_EMAIL_REQUESTED, data: {} })

	taskApi.fetchTaskEmail(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_TASK_EMAIL_SUCCESS,
				data: response
			})
		} else {
			dispatch({ type: FETCH_TASK_EMAIL_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.tasks.fetchFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const initial = {
	tasks: null,
	task: null,
	sortField: 'dueDate',
	isDescending: false,
	isCreating: false,
	filter: {
		open: true,
		completed: false,
		allDelegates: true,
		investigator: false,
		patient: false,
		serviceProvider: false,
		fromPeriod: new Date().addDays(-7).toLocaleDateString('en-US'),
		toPeriod: new Date().addDays(1).toLocaleDateString('en-US'),
		sortField: 0,
		sortDesc: true,
		anyPatientRegistrationStatus: true,
		onlyRegisteredPatients: false,
		onlyNotRegisteredPatients: false
	},
	newTask: null,
    taskSurvey: null,
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	refreshRequested: false,
	taskEmail: null,
	taskEmailIsLoading: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case GET_TASK_PROTOCOL_FAILED:
		case GET_TASK_PROTOCOL_REQUESTED:
			return { ...state, taskSurvey: null }
		case GET_TASK_PROTOCOL_REQUESTED:
			return { ...state, taskSurvey: null }
		case GET_TASK_PROTOCOL_SUCCESS:
			return { ...state, taskSurvey: action.data }
		case SET_TASKS_FILTER_SUCCESS:
			return { 
				...state, 
				filter: action.data,
				refreshRequested: true 
			}
		case SET_TASKS_SEARCH_SUCCESS:
			return { ...state, searchTerm: action.data }
		case FETCH_SERVICEPROVIDER_TASKS_SUCCESS:
            return {
                ...state,
                tasks: [...action.data.items],
                sortField: action.data.field,
				hasMore: action.data.hasMore,
                isDescending: action.data.isDescending,
				refreshRequested: false
            }
		case FETCH_SERVICEPROVIDER_TASKS_FAILED:
			return {
				...state,
				refreshRequested: false
			}
		case FETCH_SERVICEPROVIDER_TASKS_REQUESTED:
			return {
				...state,
				refreshRequested: true
			}
		case FETCH_ADDITIONAL_TASKS_SUCCESS:
			return {
				...state,
				tasks: [...state.tasks, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_TASKS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_TASKS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
        case FETCH_TASK_REQUESTED:
		case FETCH_TASK_SUCCESS:
			return {
				...state,
                task: action.data
			}
		case FETCH_TASK_EMAIL_REQUESTED:
			return {
				...state,
				taskEmailIsLoading: true,
                taskEmail: { }
			}
		case FETCH_TASK_EMAIL_SUCCESS:
			return {
				...state,
				taskEmailIsLoading: false,
                taskEmail: {
					subject: action.data.subject,
					body: action.data.body
				}
			}
		case FETCH_TASK_EMAIL_FAILED:
			return {
				...state,
				taskEmailIsLoading: false
			}
        case ADD_TASK_SUCCESS:
			return {
				...state,
                task: action.data,
				isCreating: false,
				tasks: [ ...state.tasks, action.data ]
			}
		case ADD_TASK_FAILED:
			return { ...state, isCreating: false }
		case REASSIGN_TASK_SUCCESS:
			return {
				...state,
                task: action.data
			}
		//case GET_CASES_SUCCESS:
		//	return {
		//		...state,
		//		tasks: action.data.items,
		//		sortField: action.data.field,
		//		isDescending: action.data.isDescending
		//	}
		case SET_TASK_SORT_REQUESTED:
			return {
				...state,
				tasks: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case ADD_TASK_REQUESTED:
			return { ...state, isCreating: true }
		case NEW_TASK_SUCCESS:
			return { ...state, newTask: action.data, isCreating: false }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.task

export const newTaskSelector = createSelector(mainSelector, (state) => {
	return state.newTask
})

export const taskProtocolSurveySelector = createSelector(mainSelector, (state) => {
	return state && state.taskSurvey ? state.taskSurvey : null
})

export const isCreatingTaskSelector = createSelector(mainSelector, (state) => state.isCreating)

Date.prototype.yyyymmdd = function() {
	var mm = this.getMonth() + 1 // getMonth() is zero-based
	var dd = this.getDate()

	return [ this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd ].join('')
}

export const editGroupTaskSelector = createSelector(mainSelector, (state) => {
	if (state.task) {
		let dueDate = new Date(state.task.dueDate)
		let tDate = new Date(state.task.treatmentDate)
		let bDate = new Date(state.task.patientBirthDate)

		return {
			sponsorId: state.task.sponsorId,
			id: state.task.id,
			email: state.task.patientEmail,
			patientId: state.task.patientId,
			countryName: state.task.patientCountryName,
			countryId: state.task.patientCountryId,
			birthDate: bDate.yyyymmdd(),
			firstName: state.task.patientFirstName,
			lastName: state.task.patientLastName,
			phoneNumber: state.task.patientPhoneNumber,
			sponsorName: state.task.sponsorName,
			treatmentDate: tDate.yyyymmdd(),
			dueDate: dueDate.yyyymmdd(),
			protocolName: state.task.protocolName,
			sharedCircles:
				state.task.circles &&
				state.task.circles.map((r) => {
					if (r.id !== state.sponsorId) {
						return {
							name: r.name,
							id: r.id,
							isPhiFree: r.isPhiFree
						}
					}
				})
		}
	}
	return null
})

export const readonlyTaskSelector = createSelector(mainSelector, (state) => {
	if (state && state.task) {
		let dueDate = state.task.dueDate
		let tDate = state.task.treatmentDate
		let cDate = state.task.createDate
		let commencementDate = state.task.commencementDate

		return {
			id: state.task.id,
			canEdit: state.task.canEdit,
			canReassign: state.task.canReassign,
			canOpenCase: state.task.canOpenCase,
			expiresUtc: state.task.expiresUtc,
			canViewPhi: state.task.canViewPhi,
			phi: state.task.phi,
			patientId: state.task.patientId,
			patientId36: state.task.patientId36,
			patientPhone: state.task.patientPhoneNumber,
			patientEmail: state.task.patientEmail,
			sponsorName: state.task.sponsorName,
			name:
				state.task.patientFirstName && state.task.patientLastName
					? `${state.task.patientFirstName} ${state.task.patientMiddleName ? state.task.patientMiddleName + " " : ""}${state.task.patientLastName}`
					: state.task.patientEmail,
			taskCreatorName:
				state.task.creatorFirstName && state.task.creatorLastName
					? `${state.task.creatorFirstName} ${state.task.creatorLastName}`
					: state.task.creatorEmail,
			id36: state.task.id36,
			creatorCredential: state.task.creatorCredentials,
			creatorUserId: state.task.creatorId,
			treatmentDate: tDate,
			commencementDate: commencementDate,
			createDate: cDate,
			protocol: state.task.protocolName,
			protocolId: state.task.protocolId,
			clinician:
				state.task.creatorFirstName && state.task.creatorLastName
					? `${state.task.creatorFirstName} ${state.task.creatorLastName}${state.task.creatorCredentials &&
							`, ${state.task.creatorCredentials}`}`
					: state.task.creatorEmail,
			languageName: state.task.patientLanguageName,
			dueDate: dueDate,
			patientEmailSubject: state.task.patientEmailSubject,
			patientEmailBody: state.task.patientEmailBody,
			protocolVersionNumber: state.task.protocolVersionNumber,
			protocolVersionName: state.task.protocolVersionName,
			patientHasJoined: state.task.patientHasJoined,
			surveyInstanceId: state.task.surveyInstanceId,
			caseId: state.task.caseId,
			canResendReminder: state.task.canResendReminder,
			sendRegistrationSms: state.task.sendRegistrationSms,
			sendReminderSms: state.task.sendReminderSms,
			hasIssuesWithEmail: state.task.hasIssuesWithEmail,
			funderName: state.task.funderName,
			protocolIsBilateral: state.task.protocolIsBilateral,
			isBilateral: state.task.isBilateral,
			selectedAreaName: state.task.selectedAreaName,
		}
	}
	return null
})

export const taskSharedCirclesSelector = createSelector(mainSelector, (state) => {
	if (state && state.task) {
		
		let sharedWith = []
		if (state.task.circles) {
			let s = state.task.circles.filter((r) => {
				if (r && r.id !== state.task.sponsorId) return r
			})
			if (s && s.length > 0) {
				sharedWith = s.map((r) => {
					return {
						name: r.name,
						id: r.id,
						isPhiFree: r.isPhiFree
					}
				})
			}
		}

		return sharedWith
	}
	return null
})

export const sortDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const tasksSelector = createSelector(mainSelector, (state) => {
	if (state && state.tasks && state.tasks.length > 0) {
		let collection = state.tasks.map((r) => {
			return {
				id: r.id,
				patientName: r.patientName ? r.patientName : r.patientEmail,
				patientBirthDate: r.patientBirthDate,
				birthDateSeconds: r.birthDateSeconds,
				protocol:
					r.observationProtocolName && r.observationProtocolName.length > 64
						? r.observationProtocolName.substring(0, 64) + '...'
						: r.observationProtocolName,
				treatmentDate: r.treatmentDate,
				treatmentDateSeconds: r.treatmentDateSeconds,
				totalSurveys: r.totalSurveyCount,
				totalSurveysCompleted: r.totalCompletedCount,
				completed: r.isCompleted || r.totalSurveyCount === r.totalCompletedCount,
				completedPercent: r.completedPercent,
				modifiedDate: r.modifiedDate,
				modifiedName:
					r.modifiedName && r.modifiedName.length > 50
						? r.modifiedName.substring(0, 50) + '...'
						: r.modifiedName,
				modifiedDateSeconds: r.modifiedDateSeconds,
				canViewPhi: r.canViewPhi,
				phi: r.phi,
				dueDate: r.dueDate ? new Date(r.dueDate) : null
			}
		})
		return collection
	}
	return []
})

export const taskFilterSelector = createSelector(mainSelector, (state) => state.filter)

Date.prototype.getWeek = function (start = 1) {
    //Calcing the starting point
    start = start || 0;
    var today = new Date(this.setHours(0, 0, 0, 0));
    var day = today.getDay() - start;
    var date = today.getDate() - day;

    // Grabbing Start/End Dates
    var StartDate = new Date(today.setDate(date));
    var EndDate = new Date(today.setDate(date + 6));
    return [StartDate, EndDate];
}

export const serviceProviderTasksSelector = createSelector(mainSelector, (state) => {
    let data = state.tasks ? state.tasks : null
	if (data && data.length > 0) {
        return data
    } else {
        return []
    }
})

export const hasMoreTasksSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingTasksSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const tasksSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const tasksRefreshRequestedSelector = createSelector(mainSelector, (state) => {
	return state.refreshRequested
})

export const tasksEmailSelector = createSelector(mainSelector, (state) => {
	return state.taskEmail
})

export const tasksEmailIsLoadingSelector = createSelector(mainSelector, (state) => {
	return state.taskEmailIsLoading
})

// Bug fix for task I30-3628. This is not an obvious error when the address contains only caseId parameter. 

import React, { useEffect } from 'react'
import history from '../../utilities/history'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

const RedirectToCaseDetail = ({ match }) => {
	useEffect(
		() => {
			if (!!match.params.caseId) {
                history.push(`/cases/${match.params.caseId}/survey/0/instance/0/delegate/0/status/0`)
			}
		},
		[ match.params.caseId ]
    )
    
    return (<div></div>)
}

const enhance = compose(withRouter)
export default enhance(RedirectToCaseDetail);
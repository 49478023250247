import React from 'react'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import AvTimerIcon from '@material-ui/icons/AvTimer'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

export default ({ task, color }) => {
	return (
		<div data-testid="mytasks-survey-status-cell" className="status-cell">
			{task &&
			(task.type === 0 || task.type === 1) && (
				<AccessAlarmIcon style={{ color: color, transform: 'translateY(3px)' }} />
			)}
			{task &&
			(task.type === 2 || task.type === 3) && (
				<AvTimerIcon style={{ color: color, transform: 'translateY(3px)' }} />
			)}
			{task &&
			task.type === 4 && (
				<CheckCircleOutlineIcon style={{ color: color, transform: 'translateY(3px)' }} />
			)}
		</div>
	)
}

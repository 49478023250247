import cookie from 'react-cookies'
import { number } from './validators'

export const getUser = () => {
	return cookie.load('user')
}

export const kNumber = (number) => {
	if (Math.abs(number) > 9999999) {
		return Math.sign(number) * (Math.abs(number) / 100000).toFixed(0) + 'M'
	} else if (Math.abs(number) > 999999) {
		return Math.sign(number) * (Math.abs(number) / 100000).toFixed(1) + 'M'
	} else if (Math.abs(number) > 9999) {
		return Math.sign(number) * (Math.abs(number) / 1000).toFixed(0) + 'k'
	} else if (Math.abs(number) > 999) {
		return Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + 'k'
	} else {
		return Math.sign(number) * Math.abs(number)
	}
}

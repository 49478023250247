// export const submitSurvey = (protocolId, surveyId, questionAnswers) => {
// 	return fetch(`/api/survey/${surveyId}`, {
// 		method: 'POST',
// 		headers: { 'content-type': 'application/json' },
// 		body: JSON.stringify({ questionAnswers })
// 	})
// 		.then(function(response) {
// 			if (response.status === 200) {
// 				return response.json()
// 			}
// 		})
// 		.then(function(data) {
// 			return data
// 		})
// }
export const submitSurvey = (caseId, protocolId, instanceId, surveyId, patientId, questionAnswerSides, requestType, status) => {
	return fetch(`/api/survey/${requestType ? requestType + '/' : ''}${caseId}/${protocolId}/${instanceId}/${surveyId}/${patientId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionAnswerSides: questionAnswerSides,
			reOpened: status == 2,
			patientId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const changeSurveyCompletedDate = (caseId, instanceId, newDate) => {
	return fetch(`/api/survey/changecompleteddate/${caseId}/${instanceId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ date: newDate })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const checkCanResendSurveyReminders = (caseId) => {
	return fetch(`/api/survey/canResendSurveyReminders/${caseId}`, {
		method: 'GET'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const resendSurveyReminder = (caseId, instanceId) => {
	return fetch(`/api/survey/resendSurveyReminder/${caseId}/${instanceId}`, {
		method: 'POST'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}
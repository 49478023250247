import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import TeamMemberStatusIcon from '../../../components/member/MemberStatusIcon'
import TeamMemberMenu from './MemberMenu'
import TeamMemberDateJoined from '../../../components/member/MemberDateJoined'
import TeamMemberCell from '../../../components/member/MemberCell'
import Member2FAState from '../../../components/member/Member2FAState'

export default ({ member, user }) => {
	return (
		<TableRow data-testid="member-row">
			<TableCell align="left" style={{ padding: 0, position: 'relative' }}>
				<div
					data-testid="member-row-joined-status"
					style={{
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px',
						backgroundColor: `${member.dateJoined ? '#000' : '#7B889C'}`
					}}></div>
			</TableCell>
			<TableCell
				data-testid="member-row-status-icon"
				align="right" size="small">
				<TeamMemberStatusIcon member={member} />
			</TableCell>
			<TableCell
				data-testid="member-row-member"
				align="left" style={{ minWidth: '230px' }}>
				<TeamMemberCell member={member} />
			</TableCell>
            <TableCell
				data-testid="member-row-2fa-state"
                align="left">
				<Member2FAState member={member} />
            </TableCell>
			<TableCell
				data-testid="member-row-date-joined"
				align="left">
				<TeamMemberDateJoined member={member} />
			</TableCell>
			<TableCell
				data-testid="member-row-options"
				align="right" style={{ padding: '5px 15px 5px 5px' }}>
				<TeamMemberMenu member={member} user={user} />
			</TableCell>
		</TableRow>
	)
}

import React, { useEffect, Fragment, useState } from 'react'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import * as UI from '../../../../utilities/UIConstants'
import AddCasesUsersButton from './AddCasesUsersButton'
import YourPlan from './YourPlan'
import LabelPaper from '../../../../components/LabelPaper'
import CreateSubscriptionButton from '../../../dashboard/components/PersonalPlan/CreateSubscriptionButton'
import {
	checkoutPlan,
	fetchPricePromotion,
	fetchSubscription,
	fetchSubscriptionUsersCases,
	getFullUserById
} from '../../../../redux/actions'
import {
	promotionSelector,
	priceSelector,
	subscriptionSelector,
	subscriptionUserSelector,
	subscriptionCaseSelector,
	subscriptionUserCaseSuccessSelector,
	fullUserSelector
} from '../../../../redux/selectors'
import * as cookies from '../../../../utilities/cookies'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const ManagePlan = ({
	intl,
	getPricePromotion,
	getSubscription,
	getSubscriptionUsersCases,
	promotion,
	price,
	subscription,
	subscriptionUser,
	subscriptionCase,
	subscriptionUserCaseSuccess,
	classes,
	user,
	getUser
}) => {
	const [ requiresSubscription, setRequiresSubscription ] = useState(true)
	useEffect(() => {
		getUser()
		getPricePromotion()
		getSubscription()
		getSubscriptionUsersCases()
	}, [])

	useEffect(
		() => {
			if (user && user.role && user.role.id === 1) {
				setRequiresSubscription(false)
			}
		},
		[ user ]
	)

	// used when current user does not have an active subscription, init to zeroes
	const emptySubscriptionUsers = { totalUsers: 0, allocatedUsers: 0, availableUsers: 0 }
	const emptySubscriptionCases = { totalCases: 0, allocatedCases: 0, availableCases: 0 }

	return (
		<div data-testid="manage-plan" className="wide-container" style={{ padding: '5% 5% 0 5%' }}>
			{
				<div>
					<Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
						<Grid item style={{ marginBottom: '30px' }}>
							{user &&
							requiresSubscription && (
								<Typography data-testid="manage-plan-title" variant='h1'>
									{intl.formatMessage({ id: 'app.account.managePlan.headerText' })}
								</Typography>
							)}
						</Grid>
						{/* <Grid item data-testid="manage-plan-create-subscription" style={{ marginBottom: '30px' }}>
							{user &&
							requiresSubscription &&
							(!subscriptionUser || !subscriptionCase) && <CreateSubscriptionButton price={price} />}
						</Grid> */}
					</Grid>
					<LabelPaper
						data-testid="manage-plan-label"
						label={
							user && requiresSubscription ? (
								intl.formatMessage({ id: 'app.account.addCasesUsers.yourPlan' })
							) : (
								intl.formatMessage({ id: 'app.account.addCasesUsers.teamPlan' })
							)
						}
						classes={classes.gradient}
						Component={YourPlan}
					/>
				</div>
			}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	promotion: promotionSelector(state),
	price: priceSelector(state),
	user: fullUserSelector(state),
	subscription: subscriptionSelector(state),
	subscriptionUser: subscriptionUserSelector(state),
	subscriptionCase: subscriptionCaseSelector(state),
	subscriptionUserCaseSuccess: subscriptionUserCaseSuccessSelector(state)
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	getPricePromotion: () => dispatch(fetchPricePromotion()),
	getUser: () => dispatch(getFullUserById()),
	getSubscription: () => dispatch(fetchSubscription()),
	getSubscriptionUsersCases: () => dispatch(fetchSubscriptionUsersCases())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl, withStyles(styles))

export default enhance(ManagePlan)

import { ComparisonType } from "./reportBuilder/comparisonTypeEnum"
import { QuestionType } from "./reportBuilder/questionTypeEnum"
import { isMobile } from './mobileDetector'


export const itemsToShowLineChart = 5



export const chartWidth = () => {	
	if (document.getElementById('chart') !== null) {
		const chartWrap = document.getElementById('chart').parentNode.parentNode
		if (isMobile) { 			
			const chartWrap = document.getElementById('chart').parentNode.parentNode
			var padding = 42
			var widthForChart = 400
			if (chartWrap.clientWidth > widthForChart) {
				widthForChart = chartWrap.clientWidth - padding
			}
		} else {
			var widthForChart = 560
			var padding = 120
			if (chartWrap.clientWidth > widthForChart) {
				widthForChart = chartWrap.clientWidth - padding
			}
		}
		return widthForChart
	}
}

export const chartHeight = () => {
	if (document.getElementById('chart') !== null) {
		const chartWrap = document.getElementById('chart').parentNode.parentNode
		var padding = 32
		if (isMobile) { 
			var heightForChart = (400 - padding)/2
		} else {
			var heightForChart = (560 - padding)/2
			if (chartWrap.clientWidth > heightForChart) {
				heightForChart = (chartWrap.clientWidth - padding)/2
			}
		}
		return heightForChart
	}
}

export const getUnitLabel = (dueUnit) => {
	var options = [ 'd', 'w', 'm' ]
	return options[dueUnit]
}

export const getTreatmentLabel = (treatmentPhaseId) => {
	if (!treatmentPhaseId){
		return "Pre";
	}

	let treatmentPhase = [ 'Pre', 'Treatment' ]
	return treatmentPhase[treatmentPhaseId]
}

export const getXAxisLabel = (value, unit) => {
	return value + getUnitLabel(unit)
}

export const getDueSortOrder = (value, unit, treatmentPhaseId) => {
	let treatmentPhaseFactor = treatmentPhaseId * 10000
	switch(unit) {
		case 0:
			return value + treatmentPhaseFactor
		case 1:
			return value * 7 + treatmentPhaseFactor
		case 2:
			return value * 30 + treatmentPhaseFactor
	}
}

export const getCohortFilterDescription = (intl, filter) => {
	if (filter.isScoringGroup) {
		return getNumberQuestionDescription(intl, filter.primaryValue, filter.secondaryValue, filter.comparisonType)
	}
	else {
		switch (filter.questionType) {
			case QuestionType.Date:
				return getDateQuestionDescription(intl, filter.primaryDateValue, filter.secondaryDateValue, filter.comparisonType)
			case QuestionType.Number:
			case QuestionType.Analog:
			case QuestionType.Grouped:
				return getNumberQuestionDescription(intl, filter.primaryValue, filter.secondaryValue, filter.comparisonType)
			case QuestionType.Text:
				return getTextQuestionDescription(intl, filter.textValue, filter.comparisonType)
			case QuestionType.SingleValue:
			case QuestionType.MultipleValue:
				return getMultipleValueQuestionDescription(filter.answers)
			default:
				break
		}
	}
}

const getMultipleValueQuestionDescription = (answers) => {
	let labels = answers.filter(a => a.isSelected).map(a => a.answerLabel)
	return labels.join(', ')
}

const getNumberQuestionDescription = (intl, primaryValue, secondaryValue, comparisonType) => {
	switch (comparisonType) {
		case ComparisonType.LessThan:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.lessThan' }, { value: primaryValue })
		case ComparisonType.Between:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.between' }, { firstValue: primaryValue, secondValue: secondaryValue })
		case ComparisonType.GreaterThan:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.greaterThan' }, { value: primaryValue })
		case ComparisonType.GreaterThanOrEqual:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.greaterThanOrEqual' }, { value: primaryValue })
		case ComparisonType.LessThanOrEqual:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.lessThanOrEqual' }, { value: primaryValue })
		case ComparisonType.Equal:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.equal' }, { value: primaryValue })
	}
}

const getDateQuestionDescription = (intl, primaryValue, secondaryValue, comparisonType) => {
	switch (comparisonType) {
		case ComparisonType.LessThan:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.lessThan' }, { value: new Date(primaryValue).toDateString() })
		case ComparisonType.Between:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.between' }, { firstValue: new Date(primaryValue).toDateString(), secondValue: new Date(secondaryValue).toDateString() })
		case ComparisonType.GreaterThan:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.greaterThan' }, { value: new Date(primaryValue).toDateString() })
		case ComparisonType.GreaterThanOrEqual:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.greaterThanOrEqual' }, { value: new Date(primaryValue).toDateString() })
		case ComparisonType.LessThanOrEqual:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.lessThanOrEqual' }, { value: new Date(primaryValue).toDateString() })
		case ComparisonType.Equal:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.equal' }, { value: new Date(primaryValue).toDateString() })
	}
}

const getTextQuestionDescription = (intl, value, comparisonType) => {
	switch (comparisonType) {
		case ComparisonType.Contains:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.contains' }, { value: value })
		case ComparisonType.StartsWith:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.startsWith' }, { value: value })
		case ComparisonType.EndsWith:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.endsWith' }, { value: value })
		case ComparisonType.Equal:
			return intl.formatMessage({ id: 'app.report.cohortSettingsFilterComparisonLabel.textEqual' }, { value: value })
	}
}

export const populationSetup = (domainFilter) => {
	if (domainFilter === 0) {
		return [ 'allPopulation' ]
	} else if (domainFilter === 1) {
		return [ 'myPopulation' ]
	} else {
		return [ 'allPopulation', 'myPopulation' ]
	}
}

import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Pencil from '../../../assets/pencil'
import VpnKey from '@material-ui/icons/VpnKey'
import UpdateUserProfile from '../UpdateUserProfile'
import { showModal } from '../../../redux/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useTheme, withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { injectIntl } from 'react-intl'
import { getUser } from '../../../utilities/userUtilities'
import UploadImage from '../../../components/UploadImage'
import UploadSmallImage from '../../../components/UploadSmallImage'
import * as UI from '../../../utilities/UIConstants'
import ChangePassword from '../ChangePassword'
import UploadTeamLogo from './UploadTeamLogo'

const styles = (theme) => ({
	button: {
		marginLeft: '45px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
		},
		'&:hover': {
			backgroundColor: 'transparent'
		}
	}
})

const Header = ({
	onEditClick,
	userDetails,
	classes,
	intl,
	onImageUpdated,
	onLogoUpdated,
	onUpdatePasswordClick
}) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
		defaultMatches: true
	  });
	const [ canEdit, setCanEdit ] = useState(false)

	useEffect(() => {
		let user = getUser()
		if (user && userDetails) {
			if (user.id === userDetails.id) {
				setCanEdit(true)
			}
		}
	})

	return (
		<div	
			data-testid="user-profile-header"
			style={{ position: 'relative', width: '100%', transform: 'translate(0px, -45px)', marginBottom: '45px'  }}>
			<Paper
				elevation={UI.PAPER_ELEVATION}
				square={true}
				style={{
					minHeight: '350px',
					backgroundColor: '#fff'
				}}>
				<div style={{ padding: '0 3%' }}>
					<Grid container direction='column' style={{ color: '#000' }}>
						{userDetails && (
							<UploadImage
								data-testid="user-profile-header-ipload-img"
								entity='users'
								data={userDetails}
								canEdit={canEdit}
								onImageUpdated={onImageUpdated}
							/>
						)}
						<Grid item style={{ width: '100%' }}>
							<Grid container style={{ justifyContent: 'flex-end' }}>
								<Grid item xs={12} md={5} style={{ paddingTop: isMobile ? '50px' : '20px', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', width: '100%' }}>
									<IconButton
										data-testid="user-profile-header-button-edit"
										onClick={onEditClick}
										style={{ color: theme.palette.primary.A800 }}
										className={'no-print ${classes.button}'}>
										<Typography
											style={{
												paddingRight: '5px',
												fontSize: '15px',
												lineHeight: '16px',
												letterSpacing: '1.29px',
												color: '#007AFF'
											}}>
											{intl.formatMessage({
												id: 'app.profile.details.buttonText'
											})}
										</Typography>
										<Pencil fill={theme.palette.primary.main} height='18px' viewBox='0 0 30 30' />
									</IconButton>

									<IconButton
										data-testid="user-profile-header-button-update-pass"
										onClick={onUpdatePasswordClick}
										style={{ color: theme.palette.primary.A800 }}
										className={'no-print ${classes.button}'}>
										<Typography
											style={{
												paddingRight: '5px',
												fontSize: '15px',
												lineHeight: '16px',
												letterSpacing: '1.29px',
												color: '#007AFF'
											}}>
											{intl.formatMessage({
												id: 'app.profile.updatePasswordText'
											})}
										</Typography>
										<VpnKey fill={theme.palette.primary.A800} height='18px' viewBox='0 0 30 30' />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>

						<Grid item style={{ paddingTop: '16px' }}>
							<Grid container direction='row' style={{ alignItems: 'center' }}>
								<Grid item style={{ flex: 1 }}>
									<Grid
										container
										direction='column'
										style={{
											alignItems: 'center',
											textAlign: 'center',
											paddingBottom: '32px',
											borderBottom: '1px solid #ECECEC'
										}}>
										<Grid item style={{}}>
											<Typography
												data-testid="user-profile-header-credentials"
												variant='h2'
												style={{
													letterSpacing: 0.25,
													lineHeight: '44px',
													fontSize: '35px',
													fontWeight: '400',
													wordBreak: 'break-word',
													maxWidth: '100%',
												}}>
												{userDetails && userDetails.firstName}{' '}
												{userDetails && userDetails.lastName}
												{userDetails &&
													userDetails.credentials &&
													`, ${userDetails.credentials}`}{' '}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												data-testid="user-profile-header-name"
												variant='subtitle2'
												style={{
													fontWeight: '700',
													fontSize: '17px',
													lineHeight: '24px',
													letterSpacing: '0.24px',
													color: 'rgba(0,0,0,0.87)'
												}}>
												{userDetails && userDetails.roleName}{' '}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												data-testid="user-profile-header-email"
												variant='subtitle2'
												style={{
													fontSize: '17px',
													lineHeight: '28px',
													letterSpacing: '0.24px',
													color: '#011020',
													wordBreak: 'break-all'
												}}>
												{userDetails && userDetails.email}{' '}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction='row' spacing={3} style={{ width: '100%', margin: '0', alignitems: 'center', paddingTop: '32px', paddingBottom: '32px' }}>
								<Grid item xs={12} md={4} style={{ display: 'flex' }}>
									<Grid item>
										<div style={{ padding: '20px 24px 0 0', height: '95px' }}>
											{userDetails && (
												<UploadTeamLogo
													data-testid="user-profile-header-upload-team-logo"
													data={userDetails}
													canEdit={canEdit}
													onImageUpdated={onLogoUpdated}
													height={80}
													width={80}
													translate={-20}
													/>
											)}
										</div>
									</Grid>
									<Grid item>
										{userDetails && (
											<Typography
											data-testid="user-profile-header-team-name"
												variant='subtitle2'
												style={{
													fontWeight: '700',
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px',
													marginTop: '-5px'
												}}>
												{userDetails.teamName}
											</Typography>
										)}
										{userDetails &&
										!userDetails.teamId && (
											<Typography
												data-testid="user-profile-header-default-text"
												variant='subtitle2' style={{ fontWeight: '700' }}>
												{intl.formatMessage({
													id: 'app.profile.teamNameDefaultText'
												})}
											</Typography>
										)}
									</Grid>
								</Grid>
								<Grid item xs={12} md={2}>
									<Typography
										data-testid="user-profile-header-details-address"
										variant='body2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginBottom: '3px'
										}}>
										{intl.formatMessage({ id: 'app.profile.details.address' })}
									</Typography>									
									{userDetails &&
									(userDetails.streetAddress ||
										userDetails.countryName ||
										userDetails.city ||
										userDetails.state ||
										userDetails.postalCode) && (
										<Typography
											data-testid="user-profile-header-address"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{userDetails.streetAddress} {userDetails.city} {userDetails.state}{' '}
											{userDetails.countryName} {userDetails.postalCode}{' '}
										</Typography>
									)}
									{userDetails &&
									!userDetails.streetAddress &&
									!userDetails.country &&
									!userDetails.city &&
									!userDetails.state &&
									!userDetails.postalCode && (
										<Typography
											data-testid="user-profile-header-no-address"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{intl.formatMessage({
												id: 'app.profile.addressDefaultText'
											})}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} md={2}>
									<Typography
										data-testid="user-profile-header-phone"
										variant='body2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginBottom: '3px'
										}}>
										{intl.formatMessage({
											id: 'app.profile.details.phoneNumber'
										})}
									</Typography>									
									{
                                        userDetails &&
                                        userDetails.team &&
										userDetails.team.phoneNumber && (
										<Typography
											data-testid="user-profile-header-phone"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
												{userDetails.team.phoneNumber}
										</Typography>
									)}
									{
                                        userDetails &&
                                        userDetails.team &&
										(!userDetails.teamId || !userDetails.team.phoneNumber) && (
										<Typography
											data-testid="user-profile-header-phone-text"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{intl.formatMessage({
												id: 'app.profile.phoneNumberDefaultText'
											})}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} md={2}>
									<Typography
										data-testid="user-profile-header-jurisdiction-label"
										variant='body2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginBottom: '3px'
										}}>
										{intl.formatMessage({
											id: 'app.userProfile.jurisdictionLabel'
										})}
									</Typography>									
									{userDetails &&
									userDetails.jurisdiction && (
										<Typography
											data-testid="user-profile-header-jurisdiction"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{userDetails.jurisdiction}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} md={2}>
									<Typography
										data-testid="user-profile-header-language-label"
										variant='body2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginBottom: '3px'
										}}>
										{intl.formatMessage({
											id: 'app.userProfile.languageLabel'
										})}
									</Typography>									
									{userDetails &&
										userDetails.language && (
										<Typography
											data-testid="user-profile-header-language"
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{userDetails.language}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Paper>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onEditClick: () => dispatch(showModal(UpdateUserProfile)),
	onUpdatePasswordClick: () => dispatch(showModal(ChangePassword))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Header)

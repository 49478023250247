import moment from 'moment'
import translations from './languages/locales'
import * as cookies from './cookies'
import locale2 from 'locale2'
import { createIntl, createIntlCache } from 'react-intl'

var locale = 'en'
let localeCoockie = cookies.get('languageAbbreviation');

if (localeCoockie)
{
	locale = localeCoockie;
}
else if (locale2) {
	locale = locale2.substring(0, 5)
	if (locale !== 'en-GB') {
		locale = locale2.substring(0, 2)
	}
}

if (!translations[locale]) {
	locale = 'en'
}

const cache = createIntlCache();
const intl = createIntl({ locale, messages: translations[locale] }, cache)

export const required = (value) => (value != null && value != undefined ? undefined : intl.formatMessage({ id: 'app.validation.required' }))

export const analogRequired = (value) => (value || value === 0 ? undefined : intl.formatMessage({ id: 'app.validation.required' }))

export const checkboxRequired = (value) => {
	if (!value) return intl.formatMessage({ id: 'app.validation.required' })

	return value.length > 0 ? undefined : intl.formatMessage({ id: 'app.validation.required' })
}

export const isValidNumber = (value) => {
	if (!value) return undefined
	return !isNaN(value) ? undefined : intl.formatMessage({ id: 'app.validation.isValidNumber' })
}

export const requiredString = (value) => (value && value.length > 3 ? undefined : intl.formatMessage({ id: 'app.validation.required' }))

export const requiredId = (value) => (value && isNaN(Number(value)) ? intl.formatMessage({ id: 'app.validation.required' }) : undefined)

export const requiredNumber = (value) => (value || value === 0 ? undefined : intl.formatMessage({ id: 'app.validation.required' }))

export const passwordMatch = (matchName) => (value, allValues, props) => {
	return value && allValues[matchName] && value !== allValues[matchName] ? intl.formatMessage({ id: 'app.validation.passwordsMustMatch' }) : undefined
}

export const passwordStrength = (value) => {
	//special characters
	//var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
	var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
	if (value && value.indexOf(' ') > -1) {
		return intl.formatMessage({ id: 'app.validation.spacesNotAllowed' })
	}
	return value && strongRegex.test(value)
		? undefined
		: intl.formatMessage({ id: 'app.validation.passwordStrength' })
}

export const email = (value) => {
	var strongRegex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i
	return !value || strongRegex.test(value.trim()) ? undefined : intl.formatMessage({ id: 'app.validation.invalidEmail' })
}

export const birthDate = (value) => {
	if (value) {
		let d = new Date(value)
		var date = moment(d)
		var now = moment()
		if (date.isValid() && date.year() < 1900) {
			return intl.formatMessage({ id: 'app.validation.invalidDateTooEarly' })
		}

		if (date.isValid() && date.isAfter(now)) {
			return intl.formatMessage({ id: 'app.validation.invalidDateTooLate' }).replace('%%CurrentYear%%', now.format('YYYY/MM/DD'))
		}

		return date.isValid() ? undefined : intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
	}
}

export const date = (value) => {
	if (value) {
		var datePlainText = moment(value)
		var dateStandard = moment(value)
		if (dateStandard.isValid() && dateStandard.year() < 1900) {
			return intl.formatMessage({ id: 'app.validation.invalidDateTooEarly' })
		}
		if (dateStandard.isValid() && dateStandard.year() >= 2100) {
			return intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
		}
		if (datePlainText.isValid() && datePlainText.year() < 1900) {
			return intl.formatMessage({ id: 'app.validation.invalidDateTooEarly' })
		}
		if (datePlainText.isValid() && datePlainText.year() >= 2100) {
			return intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
		}
		var dpt = datePlainText.isValid() || dateStandard.isValid() ? undefined : intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
		var ds = dateStandard.isValid() ? undefined : intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
		return datePlainText.isValid() || dateStandard.isValid() ? undefined : intl.formatMessage({ id: 'app.validation.invalidDateGeneral' })
	}
}

export const number = (value) => (value && isNaN(Number(value)) ? intl.formatMessage({ id: 'app.validation.isValidNumber' }) : undefined)

export const integerNumber = (value) => {
	let intNum = parseInt(value, 10)
	let flNum = parseFloat(value, 10)

	if (
		!!value
		&& (
			isNaN(intNum)
			|| intNum !== flNum
		)
	) {
		return intl.formatMessage({ id: 'app.validation.isValidNumber' })
	}
	return undefined
}

// export const maxValue = (max) => (value) => {
// 	return value && value > max ? `Must be less than ${max}` : undefined
// }

export const maxValue = (matchName) => (value, allValues, props) => {
	return value && allValues[matchName] && parseInt(value, 10) > parseInt(allValues[matchName], 10)
		? intl.formatMessage({ id: 'app.validation.maximumValue' }).replace('%%Maximum%%', allValues[matchName])
		: undefined
}

export const minValue = (matchName) => (value, allValues, props) => {
	let num = parseInt(value, 10)
	let minNum = parseInt(allValues[matchName], 10)

	if (!isNaN(num) && !isNaN(minNum) && num < minNum) {
		return intl.formatMessage({ id: 'app.validation.minimumValue' }).replace('%%Minimum%%', minNum)
	}
	return undefined
}

export const maxValueCustomFieldComparison = (matchName, propName, fieldToCompare, fieldToShow) => (value, allValues, props) => {
	if(!allValues[propName]) {
		return undefined
	}
		
	let nodeToCompare = allValues[propName].find(x => x.id === allValues[matchName])
	let node = allValues[propName].find(x => x.id === value)
	return node && nodeToCompare && node[fieldToCompare] > nodeToCompare[fieldToCompare]
		? intl.formatMessage({ id: 'app.validation.maximumValue' }).replace('%%Maximum%%', nodeToCompare[fieldToShow])
		: undefined
}

export const minValueCustomFieldComparison = (matchName, propName, fieldToCompare, fieldToShow) => (value, allValues, props) => {
	if(!allValues[propName]) {
		return undefined
	}

	let nodeToCompare = allValues[propName].find(x => x.id === allValues[matchName])
	let node = allValues[propName].find(x => x.id === value)
	return node && nodeToCompare && node[fieldToCompare] < nodeToCompare[fieldToCompare]
		? intl.formatMessage({ id: 'app.validation.minimumValue' }).replace('%%Minimum%%', nodeToCompare[fieldToShow])
		: undefined
}

export const minDateValue = (matchName) => (value, allValues, props) => {
	return value && allValues[matchName] && new Date(value) < new Date(allValues[matchName])
		? intl.formatMessage({ id: 'app.validation.minimumValue' }).replace('%%Minimum%%', intl.formatDate(new Date(allValues[matchName]), {
			year:'numeric',
			month:'2-digit',
			day:'2-digit'
		}))
		: undefined
}

export const mustBeLessThanValue = (matchName) => (value, allValues, props) => {
	let upper = allValues[matchName]
	let lower = value
	if (!isNaN(upper) && !isNaN(lower)) {
		let u = parseFloat(upper)
		let l = parseFloat(lower)

		return l > u ? intl.formatMessage({ id: 'app.validation.maximumValue' }).replace('%%Maximum%%', u) : undefined
	}
	return undefined
}

export const upperLessThanValue = (matchName) => (value, allValues, props) => {
	return value && value > allValues[matchName] ? intl.formatMessage({ id: 'app.validation.minimumValue' }).replace('%%Minimum%%', allValues[matchName]) : undefined
}

export const phoneNumber = (value) => {
	if (value) {
		var startsWithZero = value.startsWith('0')
		return !startsWithZero && !isNaN(value) ? undefined : intl.formatMessage({ id: 'app.validation.phoneNumber' })
	}
}

export const minMaxValueRequired = (value, allValues, props, name) => {
	if (!value) return undefined
	var fieldName = name.split('.')
	fieldName = fieldName[2]
	fieldName = fieldName.replace('_', '')
	var result = props.questionCollection[0].find((item) => item.data.uniqueId == fieldName).data
	if (result.minimum != null) {
		if (value < result.minimum) return intl.formatMessage({ id: 'app.validation.minimumValue' }).replace('%%Minimum%%', result.minimum)
	}
	if (result.maximum != null) {
		if (value > result.maximum) return intl.formatMessage({ id: 'app.validation.maximumValue' }).replace('%%Maximum%%', result.maximum)
	}
}
import React, { useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import Logo from '../assets/logo'
import PersonAdd from '@material-ui/icons/PersonAdd'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Search from '@material-ui/icons/Search'
import { showModal, signOut, checkSubscriptionValidity, checkSubscriptionUpdates } from '../redux/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Logout from '../assets/Logout'
import { injectIntl } from 'react-intl'
import AddCaseModal from '../pages/cases/AddCaseModal'
import Drawer from './globalSearch'
import BadGatewayError from './BadGatewayError'
import { subscriptionValiditySelector, paymentSelector, badGatewaySelector } from '../redux/selectors'
import { getUser } from '../utilities/userUtilities'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { isMobile } from './../utilities/mobileDetector'
import { useWindowDimensions } from './../utilities/useWindowDimensions'

const useStyles = makeStyles((theme) => ({
	content: {
		paddingLeft: '20px',
		textAlign: 'left'
	},

	iconLink: {
		padding: '10px 5px 10px 29px',
		textAlign: 'left',
		color: theme.palette.primary.A100,
		borderLeft: `6px solid ${theme.palette.secondary.main}`,
		[theme.breakpoints.down('xs')]: {
			padding: '6px 5px 8px 29px',
		},
		opacity: 0.9,
		fontSize: '18px',
		"& p": {
			fontSize: '18px',
		}
	},
	actionLinkContainer: {
		padding: '10px 5px 10px 28px',
		textAlign: 'left',
		color: theme.palette.primary.A100,
		lineHeight: '2px',
		cursor: 'pointer',
		flexWrap: 'nowrap',
		opacity: 0.9,
		fontSize: '18px',
		"& p": {
			fontSize: '18px',
		}
	},
	iconLinkActive: {
		padding: '10px 5px 10px 29px',
		textAlign: 'left',
		color: theme.palette.primary.A100,
		borderLeft: `6px solid ${theme.palette.primary.dark}`,
		opacity: 0.9,
		fontSize: '18px',
		"& p": {
			fontSize: '18px',
		}
	},
	links: {
		padding: '10px 0 10px 52px',
		textAlign: 'left',
		color: theme.palette.primary.A100,		
		borderLeft: `6px solid ${theme.palette.secondary.main}`,
		opacity: 0.9,
		fontSize: '18px',
		"& p": {
			fontSize: '18px',
		}
	},
	
	linkActive: {
		padding: '10px 0 10px 52px',
		textAlign: 'left',
		color: theme.palette.primary.A100,
		borderLeft: `6px solid ${theme.palette.primary.dark}`,
		fontSize: '18px'
	},
	linkContainer: {
		minWidth: '100%',
	 },

	linkContainerActive: { minWidth: '100%', backgroundColor: 'rgb(255,255,255,0.15)' },
	menuPaper: {
		backgroundColor: theme.palette.secondary.main
	},
	menuList: {
		color: theme.palette.primary.A100,
		opacity: 0.6
	},
	menuListLink: {
		color: theme.palette.primary.A100
	},
	toggleMenuButton: {
		position: 'fixed',
		top: '0',
		left: '0',
		zIndex: '1111',
		background: '#011020',
		color: '#fff',
		borderRadius: '0',
		width: '100vw',
		justifyContent: 'flex-start',
		height: '45px',
		[theme.breakpoints.down('xs')]: {
			padding: '4px',
			height: '31px',
		},
		'&:hover, &:focus': {
			background: '#011020'
		}
	},
	logoWrapper: {
		paddingTop: '50px',
		alignSelf: 'center',
		paddingBottom: '50px',
		[theme.breakpoints.down('xs')]: {
			paddingTop: '40px',
			paddingBottom: '13px',
		}
	},
	leftNavFirstLink: {
		marginTop: '30px',
		[theme.breakpoints.down('xs')]: {
			marginTop: '0',
		},
	},
	leftNavBottom: {
		width: '100%',
		flexShrink: 0,
		marginTop: '20px',
		[theme.breakpoints.down('xs')]: {
			marginTop: '10px',
		}
	}
}))

const getLinkContainerClass = (link, location, classes) => {
	return location.pathname === link || location.pathname.startsWith(link) ? classes.linkContainerActive : classes.linkContainer
}

const getLinkClass = (link, location, classes) => {
	return location.pathname === link || location.pathname.startsWith(link) ? classes.linkActive : classes.links
}

const getIconLinkClass = (link, location, classes) => {
	return location.pathname === link ? classes.iconLinkActive : classes.iconLink
}

const onDemoClick = () => {
	window.open('https://kb.rgnmed.com/', '_blank')
}

const LeftNavigation = ({
	location, signOut, intl, getSubscriptionValidity,
	subscriptionValidity, paymentSubmitted, addCase,
	badGateway, showBadGatewayMessage, checkSubscriptionUpdates
}) => {
	const classes = useStyles()
	const childRef = useRef()

	useEffect(() => {
		getSubscriptionValidity()
	}, [])

	useEffect(() => {
		checkSubscriptionUpdates();

		let timer = setInterval(checkSubscriptionUpdates, 5 * 60 * 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	useEffect( () => { getSubscriptionValidity() }, [ paymentSubmitted ] )
	useEffect( () => { getSubscriptionValidity() }, [ location.pathname ] )
	useEffect( () => { if (badGateway) { showBadGatewayMessage() }}, [ badGateway ] )

	const handleLogoutClose = () => { signOut() }
	const handleSearchClicked = () => { if (childRef) { childRef.current.open() } }

	const { height, width } = useWindowDimensions();
	const [open, setOpen] = useState(isMobile || width<1280 ? false : true)
	const toggleMenu = () => {
		if (isMobile || width<1280) { setOpen(!open) }
	}
	var user = getUser();

    return (
		<div data-testid="left-navigation" className="left-navigation">
			<IconButton data-testid="left-navigation-button-toggle" disableRipple
				className={`${classes.toggleMenuButton} menuButton`}
				onClick={toggleMenu} style={{ display: isMobile || width<1280 ? 'block' : 'none' }}>
				<MenuIcon fontSize="large" />
			</IconButton>
			<Grid data-testid="left-navigation-inner" item className={ open ? 'navOpened': 'navClosed'}>

				<div style={{ minWidth: '220px' }}>

					<div className="styled-nav" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between',
						height: height, display: 'flex', overflowY: 'auto', 'WebkitOverflowScrolling': 'touch' }}
					>
						<Grid data-testid="left-navigation-top" item style={{ width: '100%', display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
							<Grid item>
								<Drawer data-testid="left-navigation-drawer" ref={childRef} />
							</Grid>
							<Grid className={classes.logoWrapper} item>
								<Logo data-testid="left-navigation-logo" />
							</Grid>
							<Grid item>
								<button data-testid="left-navigation-button-search" type="button" id="search"
									style={{ background: 'none', border: 'none', padding: 0, outline: 'none' }}
									onClick={(subscriptionValidity || user.isServiceProviderMember) ?  handleSearchClicked : null}
								>
									<Grid container alignItems="center" justifyContent="flex-start"
										className={classes.actionLinkContainer}
										style={{ cursor: subscriptionValidity || user.isServiceProviderMember ? 'pointer' : 'not-allowed', width: '100%' }}
									>
										<Grid item>
											<Search data-testid="left-navigation-search-container" style={{ color: '#fff', width: '30px', height: '20px' }} />
										</Grid>
										<Grid item>
											<Typography data-testid="left-navigation-search-text" variant="body2">
												{intl.formatMessage({ id: 'app.leftNavigation.search' })}
											</Typography>
										</Grid>
									</Grid>
								</button>
							</Grid>

							{
								(subscriptionValidity || !user.isServiceProviderMember) &&
                                <Grid data-testid="left-navigation-person-add-wrap" item style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid data-testid="left-nav-button-add-person" container alignItems="center" justifyContent="flex-start"
										className={classes.actionLinkContainer}
										onClick={() => subscriptionValidity && addCase()}
										style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}
									>
										<Grid item>
											<PersonAdd data-testid="left-navigation-person-add" style={{ color: '#fff', width: '30px', height: '20px' }} />
										</Grid>
										<Grid item>
											<Typography data-testid="left-navigation-add-case" variant="body2">
												{intl.formatMessage({ id: 'app.leftNavigation.addCase' })}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
                            }

							<Grid data-testid="left-nav-button-dashboard" onClick={toggleMenu}
								item className={`${getLinkContainerClass('/dashboard', location, classes)} ${classes.leftNavFirstLink}`}>
								<Link to="/dashboard" style={{ textDecoration: 'none' }}>
									<Typography variant="body2" className={getLinkClass('/dashboard', location, classes)}>
										{intl.formatMessage({ id: 'app.leftNavigation.dashboard' })}
									</Typography>
								</Link>
							</Grid>

							{(subscriptionValidity || !user.isServiceProviderMember) &&
								<div style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid data-testid="left-nav-button-cases" onClick={toggleMenu} item className={getLinkContainerClass('/cases', location, classes)}
										style={{ pointerEvents: subscriptionValidity ? 'inherit' : 'none' }}
									>
										<Link id="casesLink" to="/cases" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/cases', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.cases' })}
											</Typography>
										</Link>
									</Grid>
								</div>
							}

							<div style={{ cursor: subscriptionValidity || user.isServiceProviderMember ? 'pointer' : 'not-allowed', width: '100%' }}>
                                <Grid
									data-testid="left-nav-button-tasks"
                                    item onClick={toggleMenu}
                                    className={getLinkContainerClass('/tasks', location, classes)}
									style={{ pointerEvents: subscriptionValidity || user.isServiceProviderMember ? 'inherit' : 'none' }}
                                >
                                    <Link id="tasksLink" to="/tasks" style={{ textDecoration: 'none' }}>
                                        <Typography variant="body2" className={getLinkClass('/tasks', location, classes)}>
                                            {intl.formatMessage({ id: 'app.leftNavigation.tasks' })}
                                        </Typography>
                                    </Link>
                                </Grid>
                            </div>

							{/* <Grid onClick={toggleMenu} item className={getLinkContainerClass('/protocols', location, classes)}>
								<Link to="/protocols" style={{ textDecoration: 'none' }}>
									<Typography variant="body2" className={getLinkClass('/protocols', location, classes)}>
										{intl.formatMessage({ id: 'app.leftNavigation.protocols' })}
									</Typography>
								</Link>
							</Grid> */}

							{
								(subscriptionValidity || !user.isServiceProviderMember) &&
								<div style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid
										data-testid="left-nav-button-reports"
										onClick={toggleMenu} item className={getLinkContainerClass('/reports', location, classes)}
										style={{ pointerEvents: subscriptionValidity ? 'inherit' : 'none' }}
									>
										<Link to="/reports" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/reports', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.reports' })}
											</Typography>
										</Link>
									</Grid>
								</div>
							}

							{
								(subscriptionValidity || !user.isServiceProviderMember) &&
								<div style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid
										data-testid="left-nav-button-circles"
										onClick={toggleMenu} item className={getLinkContainerClass('/circles', location, classes)}
										style={{ pointerEvents: subscriptionValidity ? 'inherit' : 'none' }}
									>
										<Link id="circlesLink" to="/circles" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/circles', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.circles' })}
											</Typography>
										</Link>
									</Grid>
								</div>
							}
							{
								user.isServiceProviderMember &&
									(<div style={{ cursor: true ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid
										data-testid="left-nav-button-service-providers"
										item
										className={getLinkContainerClass('/serviceProviders', location, classes)}
										style={{ pointerEvents: true ? 'inherit' : 'none' }}
									>
										<Link id="serviceProvidersLink" to="/serviceProviders" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/serviceProviders', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.serviceProviders' })}
											</Typography>
										</Link>
									</Grid>
								</div>)
                            }

							{(subscriptionValidity || !user.isServiceProviderMember) &&
								<div style={{ cursor: subscriptionValidity ? 'pointer' : 'not-allowed', width: '100%' }}>
									<Grid
										data-testid="left-nav-button-patient"
										onClick={toggleMenu} item className={getLinkContainerClass('/patients', location, classes)}
										style={{ flex: 0, pointerEvents: subscriptionValidity ? 'inherit' : 'none' }}
									>
										<Link id="patientsLink" to="/patients" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/patients', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.patients' })}
											</Typography>
										</Link>
									</Grid>
								</div>
                            }

							<Grid
								data-testid="left-nav-button-schedule-demo"
								item onClick={toggleMenu}>
								<Grid container className={classes.links} onClick={onDemoClick}
									alignItems="center" justifyContent="flex-start" style={{ cursor: 'pointer' }}
								>
									<Grid item>
										<Typography variant="body2">
											{intl.formatMessage({ id: 'app.leftNavigation.scheduleDemo' })}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>


						<Grid data-testid="left-navigation-bottom" item className={classes.leftNavBottom}>
							<div style={{ cursor: true ? 'pointer' : 'not-allowed', width: '100%' }}>
								<Grid item style={{ backgroundColor: '#00264F', width: '100%',
										pointerEvents: true ? 'inherit' : 'none'
									}}
								>
									<Link
										data-testid="left-nav-button-account"
										to="/account" style={{ textDecoration: 'none' }}>
										<Grid onClick={toggleMenu} container className={getIconLinkClass('/account', location, classes)}
											alignItems="center" justifyContent="flex-start" style={{ borderLeft: 'none' }}
										>
											<Grid item>
												<MonetizationOn
													data-testid="left-nav-button-account-monetization"
													style={{ color: '#fff', width: '30px', height: '20px', marginTop: '4px' }}
												/>
											</Grid>
											<Grid item>
												<Typography variant="body2">
													{intl.formatMessage({ id: 'app.leftNavigation.account' })}
												</Typography>
											</Grid>
										</Grid>
									</Link>
								</Grid>
							</div>
							<div style={{ cursor: true ? 'pointer' : 'not-allowed', width: '100%' }}>
								<Grid item style={{ backgroundColor: '#00264F', width: '100%',
									pointerEvents: true ? 'inherit' : 'none'
									}}
								>
									<Link
										data-testid="left-nav-button-userprofile"
										to="/userprofile" style={{ textDecoration: 'none' }}>
										<Grid onClick={toggleMenu} container className={getIconLinkClass('/userprofile', location, classes)}
											alignItems="center" justifyContent="flex-start" style={{ borderLeft: 'none' }}
										>
											<Grid item>
												<AccountCircle
													data-testid="left-nav-button-userprofile-account-circle"
													style={{ color: '#fff', width: '30px', height: '20px', marginTop: '4px' }}
												/>
											</Grid>
											<Grid item>
												<Typography variant="body2">
													{intl.formatMessage({ id: 'app.leftNavigation.manageProfile' })}
												</Typography>
											</Grid>
										</Grid>
									</Link>
								</Grid>
							</div>
							<Grid item style={{ backgroundColor: '#00264F', width: '100%', borderLeft: 'none' }}>
								<Grid
									data-testid="left-nav-button-logout"
									onClick={handleLogoutClose} style={{ textDecoration: 'none', borderLeft: 'none' }}>
									<Grid container
										className={getIconLinkClass('/userprofile', location, classes)}
										alignItems="center" justifyContent="flex-start" style={{ borderLeft: 'none' }}
									>
										<Grid item style={{ marginTop: '4px', marginLeft: '3px' }}>
											<Logout fill='#fff' />
										</Grid>
										<Grid item>
											<Typography variant="body2"
												style={{ borderLeft: 'none', cursor: 'pointer', marginLeft: '2px' }}
											>
												{intl.formatMessage({ id: 'app.leftNavigation.logout' })}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	subscriptionValidity: subscriptionValiditySelector(state),
	paymentSubmitted: paymentSelector(state),
	badGateway: badGatewaySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    signOut: () => dispatch(signOut()),
	getSubscriptionValidity: () => dispatch(checkSubscriptionValidity()),
	addCase: () => dispatch(showModal(AddCaseModal)),
	showBadGatewayMessage: () => dispatch(showModal(BadGatewayError)),
	checkSubscriptionUpdates: () => dispatch(checkSubscriptionUpdates())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl)

export default enhance(LeftNavigation)

import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { getUser } from '../../../../utilities/userUtilities'
import PersonIcon from '@material-ui/icons/Person'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'

const SurveyAlertPatientCell = ({ task, intl, setHeightCallback }) => {
	let ref = React.createRef()

	const user = getUser()
	
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				setHeightCallback(ref.current.offsetHeight)
			}
		},
		[ ref ]
	)
	return (
		<div data-testid="mytasks-survey-alert-patient-cell" style={{ maxWidth: '300px' }} ref={ref}>
			{task && task.patientFirstName && task.patientLastName &&
				<Typography
					variant="subtitle1"
					data-testid="survey-alert-patient-info"
					className="tracking-hide-content"
					style={{
						wordBreak: 'break-all',
					}}
				>
					{`${task.patientFirstName} ${task.patientMiddleName ? task.patientMiddleName + " " : ""}${task.patientLastName}`}
				</Typography>
			}
			{task && task.patientEmail &&
				<Typography
					variant="subtitle1"
					data-testid="survey-alert-patient-info"
					className="tracking-hide-content"
					style={{
						wordBreak: 'break-all',
					}}
				>
					{task.patientEmail}
				</Typography>
			}
			<Typography
				data-testid="survey-alert-patient-cell-id"
				style={{
					textAlign: 'left',
					fontWeight: '400',
					fontSize: '13px',
					lineHeight: '16px',
					wordBreak: 'break-all',
					letterSpacing: '0.16px'
				}}
			>
				{task.canAccessCase && (task.patientHasJoined
					? <PersonIcon style={{ transform: 'translateY(20%)' }} />
					: <PersonOutlineIcon style={{ transform: 'translateY(20%)' }} />)
				}
				{`${intl.formatMessage({ id: 'app.general.patientId' })}: ${task.patientId36}`}
			</Typography>
		</div>
	)
}

const enhance = injectIntl

export default enhance(SurveyAlertPatientCell)

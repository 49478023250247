import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const TaskDetails = lazy(() => componentLoader(() => import('./components/TaskDetails')))

export default () => (
	<div data-testid="task-details">
		<Suspense fallback={<div />}>
			<section>
				<TaskDetails />
			</section>
		</Suspense>
	</div>
)

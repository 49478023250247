import { authenticatedFetch } from './base'



export const addServiceProvider = (
	name,
	founderId,
	administratorId
) => {
	return authenticatedFetch(`/api/serviceProvider/admin`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name,
			founderId,
			administratorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(serviceProvider) {
			return serviceProvider
		})
}

export const fetchServiceProviders = (searchPhrase) => {
	var url = `/api/serviceProvider`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProviders) {
			return serviceProviders
		})
}


//export const generateCircleLink = (id) => {
//	return authenticatedFetch(`/api/serviceProvider/${id}/generatelink`, { method: 'get' })
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(response) {
//			return response
//		})
//}

export const fetchServiceProvider = (id) => {
	return authenticatedFetch(`/api/serviceProvider/${id}/admin`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProvider) {
			return serviceProvider
		})
}

export const fetchServiceProviderAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(`/api/serviceProvider/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProvider) {
			return serviceProvider
		})
}


export const fetchServiceProviderMembersAutoComplete = (taskId, searchPhrase = '') => {
	return authenticatedFetch(`/api/serviceProvider/task/${taskId}/members/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, {
            method: 'get'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (members) {
            return members
        })
}

export const archiveServiceProvider = (id) => {
    return authenticatedFetch(`/api/serviceProvider/${id}`, {
            method: 'delete'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (data) {
            return data
        })
}

export const unarchiveServiceProvider = (id) => {
    return authenticatedFetch(`/api/serviceProvider/${id}/restore`, {
            method: 'put',
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (data) {
            return data
        })
}

//export const fetchCircleByProtocolAutoComplete = (protocolId, searchPhrase = '') => {
//	return authenticatedFetch(`/api/serviceProvider/protocol/${protocolId}/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, { method: 'get' })
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(circle) {
//			return circle
//		})
//}

export const fetchServiceProviderMembers = (id, searchPhrase) => {
	var url = `/api/serviceProvider/${id}/members`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'get' }).then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
}

export const setBackgroundImage = (entity, id, photo) => {
	//users or serviceProvider
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProvider) {
			return serviceProvider
		})
}

export const resetBackgroundImage = (entity, id) => {
	//users or serviceProvider
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProvider) {
			return serviceProvider
		})
}

export const updateServiceProviderDetailsById = (serviceProvider) => {
	return authenticatedFetch(`/api/serviceProvider/${serviceProvider.id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(serviceProvider)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (serviceProvider) {
			return serviceProvider
		})
}

export const addServiceProviderMember = (id, email, serviceProviderId, sponsored) => {
	return authenticatedFetch(`/api/serviceProvider/addmember/${serviceProviderId}/${encodeURIComponent(email)}`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ sponsored: sponsored })
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (member) {
            return member
        })
}

//export const fetchServiceProviderAutoComplete = (searchPhrase = '') => {
//	return authenticatedFetch(`/api/serviceProvider/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, {
//            method: 'get'
//        })
//        .then(function (response) {
//            if (response.status === 200) {
//                return response.json()
//            }
//        })
//		.then(function (serviceProvider) {
//			return serviceProvider	
//        })
//}


//export const updateTerms = (id, terms) => {
//	return authenticatedFetch(`/api/serviceProvider/${id}/terms`, {
//		method: 'POST',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify({ terms: terms })
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(circle) {
//			return circle
//		})
//}

//export const reinstateCircleArchive = (circleId) => {
//	return authenticatedFetch(`/api/serviceProvider/${circleId}/reinstate`, {
//		method: 'post',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify({})
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(circle) {
//			return circle
//		})
//}

export const makeAdminMemberByServiceProviderUserId = (serviceProviderId, userId) => {
	return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/member/${userId}/administrator`, {
		method: 'post'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const removeServiceProviderAdministrator = (serviceProviderId, userId) => {
    return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/member/${userId}/administrator`, {
            method: 'delete'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}

export const removeUserServiceProviderById = (serviceProviderId, userId) => {
	return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/member/${userId}`, {
		method: 'delete'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const makeDefaultAssignee = (serviceProviderId, userId) => {
	return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/member/${userId}/defaultAssignee`, {
            method: 'post'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}

export const clearServiceProviderDefaultAssignee = (serviceProviderId, userId) => {
    return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/member/${userId}/defaultAssignee`, {
            method: 'delete'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}

//export const submitLeaveCircleById = (circleId) => {
//	return authenticatedFetch(`/api/serviceProvider/${circleId}/leave`, {
//		method: 'post',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify({})
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(user) {
//			return user
//		})
//}

//export const fetchTreatment = (id) => {
//	var url = `/api/treatment/getSingle/${id}`

//	return authenticatedFetch(url, {
//		method: 'GET',
//		headers: { 'content-type': 'application/json' }
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(data) {
//			return data
//		})
//}

//export const fetchPathology = (id) => {
//	var url = `/api/pathologies/getSingle/${id}`

//	return authenticatedFetch(url, {
//		method: 'GET',
//		headers: { 'content-type': 'application/json' }
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(data) {
//			return data
//		})
//}

//export const updateCircleTreatment = (data) => {
//	return authenticatedFetch(`/api/serviceProvider/${data.circleId}/updateTreatment/${data.treatmentId}`, {
//		method: 'put',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify()
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(data) {
//			return data
//		})
//}

//export const updateCirclePathology = (data) => {
//	return authenticatedFetch(`/api/serviceProvider/${data.circleId}/updatePathology/${data.pathologyId}`, {
//		method: 'put',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify()
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response.json()
//			}
//		})
//		.then(function(data) {
//			return data
//		})
//}

//export const updatePlan = (userCount, caseCount, circleId) => {
//	return authenticatedFetch(`/api/serviceProvider/${circleId}/updatePlan`, {
//		method: 'PUT',
//		headers: { 'content-type': 'application/json' },
//		body: JSON.stringify({ userCount: userCount, caseCount: caseCount })
//	})
//		.then(function(response) {
//			if (response.status === 200) {
//				return response
//			}
//		})
//}
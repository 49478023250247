import React from "react"
import { Tooltip, Typography } from "@material-ui/core"
// import { PriorityHighRounded } from "@material-ui/icons"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const defaultTitleStyle = {
    fontSize: '14px'
}

const defaultIconStyle = {
    color: 'white',
    fontSize: '24px'
}

const InfoTooltip = ({
    title,
    titleStyle = defaultTitleStyle,
    iconStyle = defaultIconStyle
}) => {
    return (
        <Tooltip
            title={
                <Typography color="inherit" style={titleStyle}>
                    {title}
                </Typography>
            }
            arrow
            placement="top"
        >
            <HelpOutlineIcon
                fontSize='large'
                style={iconStyle}
            />
        </Tooltip>
    )
}

export default InfoTooltip

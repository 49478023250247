import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

const useStyles = makeStyles((theme) => ({
	root: {
		transform: 'translateZ(0)',
		height: 300,
		flexGrow: 1
	},
	modal: {
		display: 'flex',
		padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center'
	},
	paper: {
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3)
	}
}))

export default function() {
	const classes = useStyles()
	const rootRef = React.useRef(null)

	return (
		<div data-testid="progress-indicator" className={classes.root} ref={rootRef}>
			<Modal
				disablePortal
				disableEnforceFocus
				disableAutoFocus
				open
				aria-labelledby="server-modal-title"
				aria-describedby="server-modal-description"
				className={classes.modal}
				container={() => rootRef.current}
			>
				<div />
			</Modal>
		</div>
	)
}

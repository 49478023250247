import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import CircleCasesUsageRow from './CircleCasesUsageRow'

export default ({ data }) => {
    const intl = useIntl()

    return (
        <div data-testid="sponsor-circle-cases-table" className="label-paper-inner" style={{ margin: '20px -32px 0' }} >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            data-testid="sponsor-circle-cases-circle-name"
                            align='left'>
                            <Typography variant="caption">
                                {intl.formatMessage({ id: 'app.sponsor.circleCasesTable.circleHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>
                        <TableCell align='left'>
                            <Typography variant="caption"
                                data-testid="sponsor-circle-cases-cases-count"
                            >
                                {intl.formatMessage({ id: 'app.sponsor.circleCasesTable.casesCountHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>
                        <TableCell align='left'>
                            <Typography
                                data-testid="sponsor-circle-cases-sms-count"
                                variant='caption'>
                                {intl.formatMessage({ id: 'app.sponsor.circleCasesTable.smsCountHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((circle, index) =>
                        <CircleCasesUsageRow data={circle} key={index} />)
                    }
                </TableBody>
            </Table>
        </div>
    )
}
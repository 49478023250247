import { authenticatedFetch } from './base'

export const fetchTask = (id) => {
	return authenticatedFetch(`/api/task/${id}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const closeTask = (id) => {
	return authenticatedFetch(`/api/task/${id}/close`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const archiveTask = (id) => {
	return authenticatedFetch(`/api/task/${id}/archive`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const addTask = (addTaskModel) => {
	return authenticatedFetch(`/api/task`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(addTaskModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const deactivateTask = (id) => {
	return authenticatedFetch(`/api/task/${id}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const reassignTask = (model) => {
	return authenticatedFetch(`/api/task/${model.taskId}/reassign/${model.serviceProviderMemberId}`,
            {
                method: 'PUT'
            })
        .then(function(response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function(task) {
            return task
        })
}

export const fetchTasks = (searchPhrase, filter, offset) => {
	let url = `/api/task`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	if(filter){
		let serializedFilter = new URLSearchParams(filter).toString()
		url += `${urlHasParams ? '&' : '?'}${serializedFilter}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchTaskSurvey = (id) => {
	var url = `/api/task/${id}/survey`
	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const viewPatientResults = (patientId) => {
	var url = `/api/task/patient/${patientId}/generatelink`
	return authenticatedFetch(url, { method: 'GET' })
	.then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
	.then(function(patient) {
		return patient
	})
}

export const fetchTaskEmail = (id) => {
	var url = `/api/task/${id}/email`
	return authenticatedFetch(url, { method: 'GET' })
	.then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
	.then(function(response) {
		return response
	})
}

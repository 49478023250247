import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { changePassword } from '../../redux/modules/auth'
import { reduxForm } from 'redux-form'
import { useTheme } from '@material-ui/core/styles'
import FormPassword from '../../components/FormPassword'
import { required, passwordStrength, passwordMatch } from '../../utilities/validators'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { emailSelector } from '../../redux/selectors'

const styles = {
	root: {},
	input: {
		color: '#fff',
		fontSize: '1.7rem'
	}
}

const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('newPassword')

const ChangePassword = ({ classes, intl, handleSubmit, submitRequest, email }) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		submitRequest(email, values.existingPassword, values.newPassword)
	}

	return (
		<form data-testid="change-password" onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				style={{
					padding: '4rem',
					backgroundColor: theme.palette.secondary.main
				}}
			>
				<Grid item style={{
					[theme.breakpoints.up('lg')]: {
						width: '65rem',
						margin: '0 auto'
					},
				}}>
					<Grid container direction="column" justifyContent="center">
						<Grid item>
							<Typography
								data-testid="change-password-title"
								variant='h1'
								style={{
									color: '#fff',
									textAlign: 'center',
									fontFamily: 'Source Sans Pro',
									fontSize: '5.3rem',
									marginBottom: '4rem'
								}}
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.title' })}
							</Typography>
						</Grid>

						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormPassword
								data-testid="change-password-existing"
								label={intl.formatMessage({
									id: 'app.changePassword.existingPasswordLabel'
								})}
								fullWidth
								name="existingPassword"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid item>
							<Typography
								data-testid="change-password-text"
								variant="caption" style={{ color: '#fff' }}>
								{intl.formatMessage({ id: 'app.registration.passwordHelpText' })}
							</Typography>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormPassword
								data-testid="change-password-new"
								label={intl.formatMessage({
									id: 'app.changePassword.newPasswordLabel'
								})}
								fullWidth
								name="newPassword"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator, passwordStrengthValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormPassword
								data-testid="change-password-reenter"
								label={intl.formatMessage({
									id: 'app.confirmPassword.confirmPasswordLabel'
								})}
								fullWidth
								name="confirmPassword"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator, passwordMatchValidator ]}
							/>
						</Grid>

						<Grid
							item
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<Button
								data-testid="change-password-button-submit"
								variant="contained"
								color="primary"
								type="submit"
								size="large"
							>
								{intl.formatMessage({ id: 'app.changePassword.buttonLabel' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	email: emailSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (email, oldPassword, newPassword) => dispatch(changePassword(email, oldPassword, newPassword))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({ form: 'changePassword' }),
	withStyles(styles)
)

export default enhance(ChangePassword)

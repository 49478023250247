import * as responseRate from '../../../apis/responseRate'
import { createSelector } from 'reselect'
import { NaturePeopleOutlined } from '@material-ui/icons'


const FETCH_RESPONSE_RATE_REQUESTED = 'FETCH_RESPONSE_RATE_REQUESTED'
const FETCH_RESPONSE_RATE_SUCCESS = 'FETCH_RESPONSE_RATE_SUCCESS'
const FETCH_RESPONSE_RATE_FAILED = 'FETCH_RESPONSE_RATE_FAILED'

export const fetchResponseRate = (circleId) => (dispatch) => {
	dispatch({
		type: FETCH_RESPONSE_RATE_REQUESTED, data: {
			sgFilter: null,
			sgFilters: null,
			sgCharts: null,
			sgChart: null,
			circleCasesRate: null
		}
	})
	responseRate.fetchResponseRate(circleId).then((response) => {
		if (response && response.isSuccessful) {
			let sgFilter = 0
			let sgFilters = new Array(0)
			let sgSchema = response.schema
			let sgChart = null

			if (sgSchema && sgSchema.length > 0) {
				const population = ["sg.myRate", "sg.allRate"]
				const originalPopulation = population.reduce((a, v) => ({ ...a, [v]: v}), {})

				sgSchema = sgSchema.map((item, index) => {
					let resultItem = {
						...item,
						coordinates: item.coordinates.map(pointItem => {
							let populationPointItem = {
								xAxis: pointItem.xAxis,
								labelTreatmentPhaseId: pointItem.labelTreatmentPhaseId,
								labelUnit: pointItem.labelUnit,
								labelValue: pointItem.labelValue,
								"sg.myRate": pointItem.myRate,
								"sg.allRate": pointItem.allRate,
								myCases: pointItem.myCases,
								allCases: pointItem.allCases,
								originalPopulation: originalPopulation,
							}

							return populationPointItem
						}),
						population: population,
						expectedPopulation: null,
						cases: ["myCases", "allCases"],
						restricted: item.hasRestrictions ? ["sg.allRate"] : [],
						colors: {
							"sg.myRate": "#007aff",
							"sg.allRate": "black"
						}
					}

					sgFilters.push({ option: resultItem.scoringGroupName + resultItem.scoringGroupId, label: resultItem.scoringGroupName })

					return resultItem
				})
			}

			sgFilters = sgFilters.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
			if (sgFilters.length > 0) {
				sgFilter = sgFilters[0]
				sgChart = sgSchema.find(x => x.scoringGroupName + x.scoringGroupId === sgFilter.option)
			}

			return dispatch({
				type: FETCH_RESPONSE_RATE_SUCCESS,
				data: {
					sgFilter: sgFilter,
					sgFilters: sgFilters,
					sgCharts: sgSchema,
					sgChart: sgChart
				}
			})
		} else {
			return dispatch({ type: FETCH_RESPONSE_RATE_FAILED, data: {} })
		}
	})
}

const SET_SG_CHART_FILTER_REQUESTED = 'SET_SG_CHART_FILTER_REQUESTED'
const SET_SG_CHART_FILTER_SUCCESS = 'SET_SG_CHART_FILTER_SUCCESS'
const SET_SG_CHART_FILTER_FAILED = 'SET_SG_CHART_FILTER_FAILED'
export const setSGChartFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SG_CHART_FILTER_REQUESTED, data: {} })

	if (!!filter) {
		dispatch({ type: SET_SG_CHART_FILTER_SUCCESS, data: { sgFilter: filter } })
	} else {
		return dispatch({ type: SET_SG_CHART_FILTER_FAILED, data: {} })
	}

}

const FETCH_CIRCLE_CASES_RATE_REQUESTED = 'FETCH_CIRCLE_CASES_RATE_REQUESTED'
const FETCH_CIRCLE_CASES_RATE_SUCCESS = 'FETCH_CIRCLE_CASES_RATE_SUCCESS'
const FETCH_CIRCLE_CASES_RATE_FAILED = 'FETCH_CIRCLE_CASES_RATE_FAILED'

export const fetchCircleCasesRate = (circleId) => (dispatch) => {
	dispatch({
		type: FETCH_CIRCLE_CASES_RATE_REQUESTED, data: {
			sgFilter: null,
			sgFilters: null,
			sgCharts: null,
			sgChart: null,
			circleCasesRate: null
		}
	})
	responseRate.fetchCircleCasesRate(circleId).then((response) => {
		handleComplianceRateResponce(dispatch, response)
	})
}

const handleComplianceRateResponce = (dispatch, response) => {
	if (response && response.isSuccessful) {
		let complianceFilter = 0
		let complianceFilters = new Array(0)
		let complianceSchema = response.schema
		let complianceChart = null

		if (complianceSchema.length > 0) {

			const COMPLIANCE_TYPE = {
				CASES: 1,
				PATIENTS: 2,
				BASELINE: 3
			}

			complianceSchema = complianceSchema.map(item => {
				const mainKey = item.complianceType === COMPLIANCE_TYPE.CASES
					? "cases"
					: item.complianceType === COMPLIANCE_TYPE.PATIENTS
						? "patients"
						: item.complianceType === COMPLIANCE_TYPE.BASELINE
							? "baseline"
							: "default"
	
				let schemaInfos = []
				if (item.complianceType === COMPLIANCE_TYPE.BASELINE) {
					schemaInfos = item.schemaInfo.map(info => {
						const myRateKey = `${mainKey}.${info.delegateId}.myRate`
						const myExpectedRateKey = `${mainKey}.${info.delegateId}.myExpectedRate`
						const allRateKey = `${mainKey}.${info.delegateId}.allRate`
						const allExpectedRateKey = `${mainKey}.${info.delegateId}.allExpectedRate`

						let colors = {}
						colors[myRateKey] = info.delegateId === 0
							? "#007aff"
							: info.delegateId === 1
								? "#9ca9bf"
								: "#199cb5"
						colors[allRateKey] = info.delegateId === 0
							? "#000000"
							: info.delegateId === 1
								? "#5da510"
								: "#c197cb"
	
						return {
							...info,
							myRateKey: myRateKey,
							myExpectedRateKey: myExpectedRateKey,
							allRateKey: allRateKey,
							allExpectedRateKey: allExpectedRateKey,
							schemaKey: "delegateId",
							colors: colors
						}
					})
				}
				else {
					const myRateKey = `${mainKey}.myRate`
					const myExpectedRateKey = `${mainKey}.myExpectedRate`
					const allRateKey = `${mainKey}.allRate`
					const allExpectedRateKey = `${mainKey}.allExpectedRate`

					let colors = {}
					colors[myRateKey] = "#007aff"
					colors[allRateKey] = "#000000"

					schemaInfos.push({
						myRateKey: myRateKey,
						myExpectedRateKey: myExpectedRateKey,
						allRateKey: allRateKey,
						allExpectedRateKey: allExpectedRateKey,
						myRate: item.myCases,
						allRate: item.allCases,
						schemaKey: null,
						colors: colors
					})
				}

				complianceFilters.push({
					option: item.complianceType,
					subOption: item.baselineComplianceType ?? null
				})

				const schemaResult = compileComplianceSchema(item, schemaInfos)

				return schemaResult
			})
		}

		if (complianceFilters.length > 0) {
			complianceFilter = complianceFilters[0]
			complianceChart = complianceSchema.find(x => x.complianceType === complianceFilter.option)
		}

		return dispatch({
			type: FETCH_CIRCLE_CASES_RATE_SUCCESS,
			data: {
				complianceFilter: complianceFilter,
				complianceFilters: complianceFilters,
				complianceCharts: complianceSchema,
				complianceChart: complianceChart
			}
		})
	} else {
		return dispatch({ type: FETCH_CIRCLE_CASES_RATE_FAILED, data: {} })
	}
}

const compileComplianceSchema = (item, schemaInfos) => {
	let coordinates = []
	let population = []
	let restricted = []
	let colors = {}
	let resultPointItem = null

	let subIndex = 0

	item.coordinates.map(pointItem => {
		if (
			!!resultPointItem
			&& resultPointItem.xAxis !== pointItem.xAxis
		) {
			coordinates.push({...resultPointItem})

			resultPointItem = null
			subIndex = 0
		}

		if (!resultPointItem) {
			resultPointItem = {
				xAxis: pointItem.xAxis,
				labelTreatmentPhaseId: pointItem.treatmentPhaseId ?? null,
				labelUnit: pointItem.labelUnit ?? null,
				labelValue: pointItem.labelValue,
				population: [],
				originalPopulation: {},
				expectedPopulation: {},
			}
		}

		let schemaInfo = schemaInfos.find(schemaInfo =>
				!schemaInfo.schemaKey
				|| (
					!!schemaInfo.schemaKey
					&& pointItem[schemaInfo.schemaKey] === schemaInfo[schemaInfo.schemaKey]
				)
			)

		if (!!schemaInfo) {
			const myRateKey = `${schemaInfo.myRateKey}__${subIndex}`
			const myExpectedRateKey = `${schemaInfo.myExpectedRateKey}__${subIndex}`
			const allRateKey = `${schemaInfo.allRateKey}__${subIndex}`
			const allExpectedRateKey = `${schemaInfo.allExpectedRateKey}__${subIndex}`
		
			resultPointItem[myRateKey] = pointItem.myRate
			resultPointItem[myExpectedRateKey] = pointItem.myExpectedRate
			resultPointItem[allRateKey] = pointItem.allRate
			resultPointItem[allExpectedRateKey] = pointItem.allExpectedRate

			resultPointItem.originalPopulation[myRateKey] = schemaInfo.myRateKey
			resultPointItem.originalPopulation[myExpectedRateKey] = schemaInfo.myExpectedRateKey
			resultPointItem.originalPopulation[allRateKey] = schemaInfo.allRateKey
			resultPointItem.originalPopulation[allExpectedRateKey] = schemaInfo.allExpectedRateKey

			resultPointItem.population.push(myRateKey)
			if (!item.hasRestrictions) {
				resultPointItem.population.push(allRateKey)
			}

			resultPointItem.expectedPopulation[myRateKey] = myExpectedRateKey
			resultPointItem.expectedPopulation[allRateKey] = allExpectedRateKey
		}

		subIndex++
	})

	if (!!resultPointItem) {
		coordinates.push(resultPointItem)
	}

	let totalRates = {}
	let expectedPopulation = {}
	let delegates = {}

	schemaInfos.map(schemaInfo => {
		population.push(schemaInfo.myRateKey)
		population.push(schemaInfo.allRateKey)

		if (item.hasRestrictions) {
			restricted.push(schemaInfo.allRateKey)
		}

		expectedPopulation[schemaInfo.myRateKey] = schemaInfo.myExpectedRateKey
		expectedPopulation[schemaInfo.allRateKey] = schemaInfo.allExpectedRateKey

		colors[schemaInfo.myRateKey] = schemaInfo.colors[schemaInfo.myRateKey]
		colors[schemaInfo.allRateKey] = schemaInfo.colors[schemaInfo.allRateKey]

		totalRates[schemaInfo.myRateKey] = schemaInfo.myRate
		totalRates[schemaInfo.allRateKey] = schemaInfo.allRate

		if (!Number.isNaN(parseInt(schemaInfo.delegateId))) {
			delegates[schemaInfo.delegateId] = [
				schemaInfo.myRateKey,
				schemaInfo.allRateKey
			]
		}
	})

	let result = {
		complianceType: item.complianceType,
		baselineComplianceType: item.baselineComplianceType ?? null,
		coordinates: coordinates,
		population: population,
		expectedPopulation: expectedPopulation,
		cases: [...population],
		restricted: restricted,
		colors: colors,
		delegates: delegates,
		minScore: item.minScore,
		maxScore: item.maxScore,
		...totalRates
	}

	return result
}

const SET_COMPLIANCE_CHART_FILTER_REQUESTED = 'SET_COMPLIANCE_CHART_FILTER_REQUESTED'
const SET_COMPLIANCE_CHART_FILTER_SUCCESS = 'SET_COMPLIANCE_CHART_FILTER_SUCCESS'
const SET_COMPLIANCE_CHART_FILTER_FAILED = 'SET_COMPLIANCE_CHART_FILTER_FAILED'
export const setComplianceChartFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_COMPLIANCE_CHART_FILTER_REQUESTED, data: {} })

	if (!!filter) {
		dispatch({ type: SET_COMPLIANCE_CHART_FILTER_SUCCESS, data: { complianceFilter: filter } })
	} else {
		return dispatch({ type: SET_COMPLIANCE_CHART_FILTER_FAILED, data: {} })
	}

}


const initial = {
	sgCharts: [],
	sgFilter: 0,
	sgFilters: [],
	sgChart: null,
	complianceCharts: [],
	complianceFilter: 0,
	complianceFilters: [],
	complianceChart: null,
	isLoadingCircleAnalytics: true
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_RESPONSE_RATE_SUCCESS:
			return {
				...state,
				sgCharts: action.data.sgCharts,
				sgFilters: action.data.sgFilters,
				sgFilter: action.data.sgFilter,
				sgChart: action.data.sgChart,
				isLoadingCircleAnalytics: false
			}
		case FETCH_RESPONSE_RATE_REQUESTED:
			return { 
				...state, 
				sgCharts: null, 
				sgFilters: null, 
				sgFilter: null, 
				sgChart: null,
				isLoadingCircleAnalytics: true
			}
		case FETCH_RESPONSE_RATE_FAILED:
			return {
				...state,
				isLoadingCircleAnalytics: false
			}
		case SET_SG_CHART_FILTER_SUCCESS:
			return { 
				...state, 
				sgFilter: action.data.sgFilter, 
				sgChart: state.sgCharts.find(({ scoringGroupName, scoringGroupId }) => scoringGroupName + scoringGroupId === action.data.sgFilter.option) 
			}
		case FETCH_CIRCLE_CASES_RATE_SUCCESS:
			return {
				...state,
				complianceCharts: action.data.complianceCharts,
				complianceFilters: action.data.complianceFilters,
				complianceFilter: action.data.complianceFilter,
				complianceChart: action.data.complianceChart,
				isLoadingCircleAnalytics: false
			}
		case FETCH_CIRCLE_CASES_RATE_REQUESTED:
			return {
				...state,
				complianceCharts: null,
				complianceFilters: null,
				complianceFilter: null,
				complianceChart: null,
				isLoadingCircleAnalytics: true
			}
		case FETCH_CIRCLE_CASES_RATE_FAILED:
			return {
				...state,
				isLoadingCircleAnalytics: false
			}
		case SET_COMPLIANCE_CHART_FILTER_SUCCESS:
			return {
				...state,
				complianceFilter: action.data.complianceFilter,
				complianceChart: state.complianceCharts.find(
					({ complianceType, baselineComplianceType }) =>
						complianceType === action.data.complianceFilter.option
						&& baselineComplianceType === action.data.complianceFilter.subOption
				)
			}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.chart

export const sgSchemaSelector = createSelector(mainSelector, (state) => {
	if (state && state.sgCharts && state.sgCharts.length > 0)
		return state.sgCharts
	else
		return null
})

export const sgFilterSelector = createSelector(mainSelector, (state) => {
	return state && !!state.sgFilter?.option && state.sgFilter
})

export const selectedSGChartSelector = createSelector(mainSelector, (state) => {
	return state && state.sgChart
})

export const sgFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.sgFilters
})

export const sampleResultSelector = createSelector(mainSelector, (state) => {
	return [
		{
			scoringGroupId: 1,
			scoringGroupName: 'Sample Results',
			population: ['My Patients', 'All Circle Patients'],
			minScore: 75,
			maxScore: 100,
			coordinates: [
				{
					xAxis: 20190320,
					'My Patients': 30,
					'All Circle Patients': 20,
				},
				{
					xAxis: 20190321,
					'My Patients': 40,
					'All Circle Patients': 35,
				},
				{
					xAxis: 20190322,
					'My Patients': 65,
					'All Circle Patients': 55,
				},
				{
					xAxis: 20190323,
					'My Patients': 60,
					'All Circle Patients': 50,
				},
				{
					xAxis: 20190324,
					'My Patients': 65,
					'All Circle Patients': 50,
				},
				{
					xAxis: 20190325,
					'My Patients': 75,
					'All Circle Patients': 65,
				},
			],
		},
	]
})

export const selectedCompianceChartSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceChart
})

export const complianceFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceFilters
})

export const complianceFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceFilter
})

export const isLoadingCircleAnalyticsSelector = createSelector(mainSelector, (state) => {
	return state && state.isLoadingCircleAnalytics
})

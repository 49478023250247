import React, { useState, useEffect, Fragment } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrencyCode } from '../../utilities/CurrencyCodes'
import locale2 from 'locale2'

const Discount = ({ intl, rawPrice, caseCount, promotion, onApplyDiscount }) => {
	const currency = 'USD' //getCurrencyCode(locale2);
	const theme = useTheme()
	const color_gray = 'rgba(255,255,255,0.6)'

	const [ discountAmount, setDiscountAmount ] = useState(0)

	useEffect(
		() => {
			if (promotion && caseCount >= promotion.thresholdVolume) {
				setDiscountAmount(promotion.percentDiscount * rawPrice)
			} else {
				setDiscountAmount(0)
				onApplyDiscount(0) // called because if userCount changes, discount amount goes from 0 => 0 and need to force re-render
			}
		},
		[ caseCount, rawPrice ]
	)

	useEffect(
		() => {
			onApplyDiscount(discountAmount)
		},
		[ discountAmount ]
	)

	return (
		<div data-testid="discount">
			{/* { discountAmount > 0 &&  */}
			{false && (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} />
					<Grid item xs={12} sm={3} style={{
							borderBottom: '1px solid',
							borderTop: '1px solid',
							borderColor: theme.palette.light
						}}
					>
						<Typography data-testid="discount-title" variant="subtitle1" style={{ color: color_gray }}>
							{promotion.percentDiscount * 100}%{' '}
							{intl.formatMessage({ id: 'app.account.addCasesUsers.discount' })}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={3}
						style={{
							borderBottom: '1px solid',
							borderTop: '1px solid',
							borderColor: theme.palette.light
						}}
					>
						<Typography data-testid="discount-text" variant='h3' style={{ color: color_gray }}>
							{intl.formatNumber(discountAmount, {
								style: 'currency',
								currency: currency,
								currencyDisplay: 'symbol'
							})}{' '}
							USD
						</Typography>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter)

export default enhance(Discount)

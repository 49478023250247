import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

export default ({ height = 450, width = 700 }) => {
	const canvas = useRef(null)

	useEffect(() => {
		if (canvas && canvas.current) lineChart()
	}, [])

	const lineChart = () => {
		var margin = { top: 20, right: 80, bottom: 30, left: 50 },
			width = 780 - margin.left - margin.right,
			height = 220 - margin.top - margin.bottom

		var parseDate = d3.timeParse('%Y%m%d')
		var parseMonthYear = d3.timeFormat('%b %Y')

		var x = d3.scaleTime().range([ 0, width ])
		var y = d3.scaleLinear().range([ height, 0 ])

		const svg = d3.select(canvas.current).append('svg').attr('width', 780).attr('height', 320)

		var color = d3.scaleOrdinal().domain([ 'My Patients', 'All Patients' ]).range([ '#057AFE', '#010E23' ])

		var xAxis = d3.axisBottom().scale(x)

		var yAxis = d3.axisRight().scale(y)

		var line = d3
			.line()
			.x(function(d) {
				return x(d.date)
			})
			.y(function(d) {
				return y(d.responseRate)
			})
			.curve(d3.curveLinear)

		var filterData = { 'My Patients': true, 'All Patients': true }

		function drawChart(filterData) {
			var data = [
				{
					date: 20190101,
					'My Patients': 0,
					'All Patients': 10
				},
				{
					date: 20190201,
					'My Patients': 58,
					'All Patients': 59.9
				},
				{
					date: 20190301,
					'My Patients': 53.3,
					'All Patients': 59.1
				},
				{
					date: 20190401,
					'My Patients': 55.7,
					'All Patients': 58.8
				},
				{
					date: 20190501,
					'My Patients': 63.4,
					'All Patients': 62.7
				},
				{
					date: 20190601,
					'My Patients': 58,
					'All Patients': 59.9
				},
				{
					date: 20190701,
					'My Patients': 53.3,
					'All Patients': 59.1
				},
				{
					date: 20190801,
					'My Patients': 40,
					'All Patients': 0
				},
				{
					date: 20190901,
					'My Patients': 63.4,
					'All Patients': 62.7
				},
				{
					date: 20191001,
					'My Patients': 58,
					'All Patients': 59.9
				},
				{
					date: 20191101,
					'My Patients': 53.3,
					'All Patients': 59.1
				},
				{
					date: 20191201,
					'My Patients': 40,
					'All Patients': 0
				}
			]

			color.domain(
				d3.keys(data[0]).filter(function(key) {
					return key !== 'date'
				})
			)

			data.forEach(function(d) {
				d.date = parseDate(d.date)
			})

			var rates = color.domain().map(function(name) {
				return {
					name: name,
					values: data.map(function(d) {
						return { date: d.date, responseRate: +d[name] }
					})
				}
			})

			x.domain(
				d3.extent(data, function(d) {
					return d.date
				})
			)

			y.domain([
				d3.min(rates, function(c) {
					return d3.min(c.values, function(v) {
						return 0
					})
				}),
				d3.max(rates, function(c) {
					return d3.max(c.values, function(v) {
						return 100
					})
				})
			])

			svg.selectAll('*').remove()

			var legend = svg
				.selectAll('g')
				.data(rates)
				.enter()
				.append('g')
				.attr('class', 'legend')
				.attr('transform', 'translate(10,10)')

			legend
				.append('circle')
				.attr('transform', function(d, i) {
					return 'translate(10, ' + (i * 20 + 5) + ')'
				})
				.attr('r', 6)
				.style('fill', function(d) {
					return color(d.name)
				})

			legend
				.append('text')
				.attr('x', 35)
				.attr('y', function(d, i) {
					return i * 20 + 10
				})
				.text(function(d) {
					return d.name.toUpperCase()
				})
				.style('fill', function(d) {
					return color(d.name)
				})

			legend.on('click', function(d) {
				reDraw(d.name)
			})

			// GRID
			svg
				.append('g')
				.attr('class', 'grid')
				.style('stroke', '#C3CFDA')
				.style('stroke-opacity', '0.2')
				.style('shape-rendering', 'crispEdges')
				.attr('transform', 'translate(40, 80)')
				.style('stroke-dasharray', '3, 3')
				.call(d3.axisLeft(y).ticks(5).tickSize(-width).tickFormat(''))

			svg
				.append('g')
				.attr('class', 'x axis')
				.attr('transform', 'translate(40,250)')
				.call(xAxis.tickSize(0).tickPadding(8).tickFormat(d3.timeFormat('%b')))
				.append('text')
				.attr('fill', '#7E7E7E')
				.attr('transform', 'translate(' + width / 2 + ', 50)')
				.text('Month')

			svg
				.append('g')
				.attr('class', 'y axis')
				.attr('transform', 'translate(' + (width + 40) + ', 80)')
				.call(yAxis.ticks(3).tickPadding(15).tickSize(0).tickFormat(''))
				.append('text')
				.attr('transform', 'translate(' + width + ', 0)')
				.attr('transform', 'rotate(-270)')
				.attr('fill', '#7E7E7E')
				.attr('y', -50)
				.attr('x', 120)
				.attr('dy', '.50em')
				.style('text-anchor', 'end')
				.text('Response Rate %')

			// s.select('.domain').attr('stroke-width', 0)

			var rate = svg
				.selectAll('.rate')
				.data(
					rates.filter(function(d) {
						return filterData[d.name] == true
					})
				)
				.enter()
				.append('g')

			rate
				.append('path')
				.attr('class', 'line')
				.attr('transform', 'translate(40, 80)')
				.attr('d', function(d) {
					return line(d.values)
				})
				.style('stroke', function(d) {
					return color(d.name)
				})
				.style('stroke-width', 3)
				.style('fill', 'none')

			// var tooltip = d3.select('body')
			//     .append('div')
			//     .style('opacity', 0)

			//     .style('position', 'absolute')
			//     .style('text-align', 'center')
			//     .style('width', '80px')
			//     .style('height', '60px')
			//     .style('padding', '2px')
			//     .style('font', '12px sans-serif')
			//     .style('background', '#fff')
			//     .style('border', '0px')
			//     .style('pointer-events', 'none')

			rate
				.selectAll('circle')
				.data(function(d) {
					return d.values
				})
				.enter()
				.append('circle')
				.attr('transform', 'translate(40, 80)')
				.attr('r', 5)
				.attr('fill', function(d, i, j) {
					return color(this.parentNode.__data__.name)
				})
				.attr('cx', function(d, i, j) {
					return x(d.date)
				})
				.attr('cy', function(d) {
					return y(d.responseRate)
				})
				.on('mouseover', function(d) {
					d3.select(this).attr('fill', '#fff')
					d3.select(this).attr('stroke', color(this.parentNode.__data__.name))
					d3.select(this).attr('stroke-width', 5)
					d3.select(this).attr('r', 7)

					// tooltip.transition()
					//     .duration(200)
					//     .style("opacity", 1)

					// tooltip.html("<div style='background-color: "
					//     +color(this.parentNode.__data__.name)
					//     +"; border: 1px solid "
					//     +color(this.parentNode.__data__.name)
					//     +"; padding: 4px; font-size: 10px; color: white;'>"+this.parentNode.__data__.name
					//     +"</div><div style='font-size: 14px; font-weight: bold;'>"
					//     + d.responseRate + "%"
					//     + "</div><div>"+parseMonthYear(d.date)+"</div><br>")

					//     .style("left", (d3.event.pageX) + "px")
					//     .style("top", (d3.event.pageY - 28) + "px")
				})
				.on('mouseout', function(d) {
					// tooltip.transition()
					//     .duration(500)
					//     .style("opacity", 0)

					d3.select(this).attr('fill', color(this.parentNode.__data__.name))
					d3.select(this).attr('stroke', 'none')
					d3.select(this).attr('stroke-width', 0)
					d3.select(this).attr('r', 5)
				})
		}

		drawChart(filterData)

		function reDraw(name) {
			filterData[name] = !filterData[name]
			drawChart(filterData)
		}
	}
	return <div data-testid="component-line-chart" style={{ overflow: 'auto' }} ref={canvas} />
}

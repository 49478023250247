import React, { Fragment, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import Check from '../../assets/check'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { StripeProvider, Elements, CardForm, CardElement } from 'react-stripe-elements'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { injectStripe } from 'react-stripe-elements'
import poweredByStripe from '../../assets/checkout/powered_by_stripe.png'
import { fetchStripeIntent, createPaymentMethod } from '../../redux/actions'
import { stripeIntentSelector } from '../../redux/selectors'

const SaveCardForm = ({ intl, elements, stripe, getstripeIntent, stripeIntent, setPaymentMethod }) => {
	const theme = useTheme()

	useEffect(() => {
		getstripeIntent()
	}, [])

	const [ confirmingCardSetup, setConfirmingCardSetup ] = useState(0)
	const [ saveCardError, setSaveCardError ] = useState()

	const onPay = () => {
		const cardElement = elements.getElement('card')

		setConfirmingCardSetup(1)
		setSaveCardError()

		stripe
			.confirmCardSetup(stripeIntent.client_secret, {
				payment_method: {
					card: cardElement
				}
			})
			.then(function(result) {
				if (result.error) {
					setSaveCardError(result.error.message)
					setConfirmingCardSetup(0)
				} else {
					setPaymentMethod(result.setupIntent.payment_method)
					setConfirmingCardSetup(0)
				}
			})
	}

	return (
		<div data-testid="save-card-form">
			<Typography data-testid="save-card-form-title" variant='subtitle1' style={{ color: '#fff' }}>
				{intl.formatMessage({ id: 'app.checkout.saveCardHeader' })}
			</Typography>

			<CardElement data-testid="save-card-form-element" />
			<img data-testid="save-card-form-img" style={{ width: '100px', margin: '0 auto' }} src={poweredByStripe} />

			<Typography data-testid="save-card-form-disclaimer" variant='body2' style={{ color: '#fff' }}>
				{intl.formatMessage({ id: 'app.checkout.saveCardDisclaimer' })}
			</Typography>

			<div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
				{saveCardError && (
					<Typography data-testid="save-card-form-error" variant='caption' style={{ color: 'red', display: 'block' }}>
						{' '}
						{saveCardError}{' '}
					</Typography>
				)}

				{confirmingCardSetup && <CircularProgress />}
				{!confirmingCardSetup && (
					<Button
						data-testid="save-card-form-button-save"
						onClick={onPay}
						variant="contained"
						color="primary"
						type='submit'
						size='medium'>
						{intl.formatMessage({ id: 'app.checkout.saveCard' })}
					</Button>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	stripeIntent: stripeIntentSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getstripeIntent: () => dispatch(fetchStripeIntent()),
	setPaymentMethod: (payment_method) => dispatch(createPaymentMethod(payment_method))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(injectStripe(SaveCardForm))

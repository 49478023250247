import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { change } from 'redux-form'

import { injectIntl } from 'react-intl'
import FormTextField from '../../../components/FormTextField'
import { required } from '../../../utilities/validators'
import PhiSelection from '../../../components/PhiSelection'

const requiredValidator = required

const AddNewCase = ({
	intl,
	patient,
	handleSubmit,
	loadCountries,
	countries,
	add,
	update,
	change,
	mode,
	setPhi,
	...rest
}) => {
	useEffect(() => {
		setPhi('1')
	}, [])

	return (
		<Grid data-testid="add-new-case" container direction='column' alignItems='center' justifyContent='center' style={{ width: '100%' }}>
			<Grid item style={{ width: '100%' }}>
				<FormTextField
					data-testid="add-new-case-input"
					label={intl.formatMessage({ id: 'app.circles.name' }) + '*'}
					fullWidth
					type='text'
					name='name'
					maxLength={255}
					validators={[ requiredValidator ]}
					variant='filled'
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<PhiSelection data-testid="add-new-case-phi" validators={[ requiredValidator ]} />
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setPhi: (code) => dispatch(change('addCase', 'phi', code))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AddNewCase)

import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Cases = lazy(() => componentLoader(() => import('./components/Cases')))

export default () => (
	<div data-testid="cases">
		<Suspense data-testid="cases-inner" fallback={<div />}>
			<section>
				<Cases />
			</section>
		</Suspense>
	</div>
)

import 'intersection-observer'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import AlertHead from './AlertHead'
import InvitationAlertRow from './invitation/InvitationAlertRow'
import OutlierAlertRow from './outliers/OutlierAlertRow'
import { compose } from 'recompose'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { hasMoreAlertsSelector, isLoadingAlertsSelector } from '../../../redux/selectors'
import { fetchAdditionalAlerts } from '../../../redux/actions'
import { CircularProgress } from '@material-ui/core'

const renderRow = (alert, index) => {
	if (alert) {
		switch (alert.alertTypeId) {
			case 0:
				return <InvitationAlertRow alert={alert} key={index} />
			case 1:
				return <OutlierAlertRow alert={alert} key={index} />
		}
	}
}

const AlertTable = ({ alerts, hasMore, getAlerts, loading }) => {
	const getAdditionalAlerts = () => {
		getAlerts(alerts.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalAlerts,
		delayInMs: 1000
	});

	return (
		<div data-testid="myalerts-alert-table">
			<Table className="label-paper-inner">
				<TableHead>
					<AlertHead />
				</TableHead>
				<TableBody>{alerts && alerts.map((alert, index) => renderRow(alert, index))}</TableBody>
			</Table>
			{alerts && alerts.length > 0 && (loading || hasMore) && (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingAlertsSelector(state),
	hasMore: hasMoreAlertsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getAlerts: (offset) => dispatch(fetchAdditionalAlerts(offset))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(AlertTable)

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Pencil from '../assets/pencil'
import { setPhoto } from '../redux/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, withStyles } from '@material-ui/core'

const styles = (theme) => ({
	gridImageStyle: {
		height: '128px',
		width: '128px',
		zIndex: '1',
		cursor: 'pointer',
		borderRadius: '50%',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('sm')]: {
			height: '96px',
			width: '96px',
		}
	},
	imageStyle: {
		height: '128px',
		width: '128px',
		objectFit: 'cover',
		boxShadow: 'grey 3px 6px 12px -4px',
		borderRadius: '50%',
		[theme.breakpoints.down('sm')]: {
			height: '96px',
			width: '96px',
		}
	},
	pencilStyle: {
		position: 'absolute',
		transform: 'translate(40px, 35px)',
		borderRadius: '50%',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			transform: 'translate(20px, 17px)',
		},
		"& > svg": {
			height: '64px',
			width: '64px',
			[theme.breakpoints.down('sm')]: {
				height: '32px',
				width: '32px',
			},
		}
	}
})

const UploadImage = ({
	entity,
	data,
	uploadPhoto,
	onImageUpdated,
	canEdit,
	classes
}) => {
	const theme = useTheme()

	const [ imageOpacity, setImageOpacity ] = useState(1)
	const [ pencilIndex, setPencilOpacity ] = useState(0)
	const [ imageLoaded, setImageLoaded ] = useState(false)

	const uploadImage = (e) => {
		var file = e.target.files[0]

		uploadPhoto(entity, data.id, file)
		e.target.files = null
	}

	return (
		<Grid data-testid="upload-img-wrap" item style={{ width: '100%' }}>
			<Grid container justifyContent='center' style={{ maxHeight: '10px' }}>
				<Grid
					data-testid="upload-img"
					className={classes.gridImageStyle}
					item
					onMouseOver={() => {
						canEdit && setImageOpacity(0.4)
						canEdit && setPencilOpacity(1)
					}}
					onMouseOut={() => {
						canEdit && setImageOpacity(1)
						canEdit && setPencilOpacity(0)
					}}
					onClick={() => canEdit && document.getElementById('file-input').click()}>
					<input
						data-testid="upload-img-input"
						id='file-input'
						type='file'
						accept='.jpg, .png,.bmp'
						name='name'
						style={{ display: 'none' }}
						onChange={uploadImage}
					/>
					{imageLoaded && (
						<div data-testid="upload-img-pencil" className={classes.pencilStyle}
							style={{ opacity: setPencilOpacity }}>
							<Pencil fill={theme.palette.primary.main} viewBox='0 0 30 30' />
						</div>
					)}

					{entity === 'circle' && (
						<Avatar
							data-testid="upload-img-avatar-circle"
							elevation={10}
							variant='circular'
							onLoad={() => setImageLoaded(true)}
							className={classes.imageStyle}
							style={{
								opacity: imageOpacity,
							}}
							src={data.profileImageUrl ? data.profileImageUrl : '/camera-square.png'}
						/>
					)}

					{entity === 'serviceProvider' && (
						<Avatar
							data-testid="upload-img-avatar-service"
							elevation={10}
							variant='serviceProvider'
							onLoad={() => setImageLoaded(true)}
							className={classes.imageStyle}
							style={{
								opacity: imageOpacity,
							}}
							src={data.profileImageUrl ? data.profileImageUrl : '/camera-square.png'}
						/>
					)}

					{entity === 'users' && (
						<Avatar
							data-testid="upload-img-avatar-user"
							elevation={10}
							variant='circular'
							onLoad={() => setImageLoaded(true)}
							className={classes.imageStyle}
							style={{
								opacity: imageOpacity,
							}}
							src={data.profileImageUrl ? data.profileImageUrl : '/camera-square.png'}
						/>
					)}
					{entity === 'teams' && (
						<Avatar
							data-testid="upload-img-avatar-team"
							elevation={10}
							variant='circular'
							onLoad={() => setImageLoaded(true)}
							className={classes.imageStyle}
							style={{
								opacity: imageOpacity,
							}}
							src={data.teamImageUrl ? data.teamImageUrl : '/camera-square.png'}
						/>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	uploadPhoto: (entity, id, photo) => {
		dispatch(setPhoto(entity, id, photo, ownProps.onImageUpdated))
	}
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(UploadImage)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import SponsoredValue from './SponsoredValue'

const SponsorSelection = ({ intl, availableUsers, availableCases }) => {

	return (
		<Grid data-testid="circle-sponsor-selection" container direction="row" spacing={2}>
			<Grid item xs={12} sm={6}>
				<Paper
					data-testid="circle-sponsor-selection-paper"
					elevation={0}
					style={{
						borderRadius: 0,
						padding: 0,
						margin: 0,
						backgroundColor: 'rgb(24, 35, 48)'
					}}
				>
					<SponsoredValue
						data-testid="circle-sponsor-selection-value"
						label={intl.formatMessage({ id: 'app.general.cases' })}
						entityType={intl.formatMessage({ id: 'app.general.cases' })}
						available={availableCases}
						name="numberCases"
					/>
				</Paper>
			</Grid>
			{/* <Grid item xs={12} sm={6}>
				<Paper
					elevation={0}
					style={{
						borderRadius: 0,
						padding: 0,
						margin: 0,
						height: '110px',
						backgroundColor: 'rgb(24, 35, 48)'
					}}
				>
					<SponsoredValue
						label={intl.formatMessage({ id: 'app.general.users' })}
						entityType={intl.formatMessage({ id: 'app.general.users' })}
						available={availableUsers}
						name="numberUsers"
					/>
				</Paper>
			</Grid> */}
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SponsorSelection)

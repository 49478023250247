import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'

const AlertTypeCell = ({ alert, intl, setHeightCallback }) => {
	let ref = React.createRef()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				setHeightCallback(ref.current.offsetHeight)
			}
		},
		[ ref ]
	)
	return (
		<div data-testid="myalerts-outlier-type-cell">
			{alert && (
				<div ref={ref}>
					<Typography
						data-testid="myalerts-outlier-type-cell-label"
						variant="subtitle2">
						{intl.formatMessage({ id: 'app.alerts.outlierTypeLabel' })}
					</Typography>
					<Grid container alignItems="center">
						<Grid item>
							<Typography
								data-testid="myalerts-outlier-type-cell-name"
								variant="body2"
								style={{ textAlign: 'left', letterSpacing: '0.38px' }}
							>
								{alert && alert.shortName}
							</Typography>
						</Grid>
						<Grid item style={{ padding: '0 5px' }}>
							|
						</Grid>
						{alert && alert.bilateralAreaName && <Fragment>
							<Grid item>
								<Typography
									data-testid="myalerts-outlier-type-cell-bilateral-area-name"
									variant="body2"
									style={{ textAlign: 'left', letterSpacing: '0.38px' }}
								>
									{alert.bilateralAreaName}
								</Typography>
							</Grid>
							<Grid item style={{ padding: '0 5px' }}>
								|
							</Grid>
						</Fragment>}
						<Grid item>
							{alert.isUpperBreach && (
								<Typography
									data-testid="myalerts-outlier-type-cell-upper-fence"
									variant="body2"
									style={{
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0.38px'
									}}
								>
									{intl.formatMessage({ id: 'app.alerts.outlierUpperLimitLabel' })}
									{' > '}
									{intl.formatNumber(alert.upperFence)}
								</Typography>
							)}
							{alert.isLowerBreach && (
								<Typography
									data-testid="myalerts-outlier-type-cell-lower-fence"
									variant="body2"
									style={{
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0.38px'
									}}
								>
									{intl.formatMessage({ id: 'app.alerts.outlierLowerLimitLabel' })}
									{' < '}
									{intl.formatNumber(alert.lowerFence)}
								</Typography>
							)}
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	)
}

const enhance = injectIntl

export default enhance(AlertTypeCell)

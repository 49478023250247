import { authenticatedFetch } from './base'

export const fetchCase = (id) => {
	return authenticatedFetch(`/api/case/${id}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const closeCase = (id) => {
	return authenticatedFetch(`/api/case/${id}/close`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const archiveCase = (id) => {
	return authenticatedFetch(`/api/case/${id}/archive`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const addCase = (addCaseModel) => {
	return authenticatedFetch(`/api/case`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(addCaseModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const deactivateCase = (id) => {
	return authenticatedFetch(`/api/case/${id}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const updateCase = (updateCaseModel) => {
	return authenticatedFetch(`/api/case/${updateCaseModel.id}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(updateCaseModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCases = (searchPhrase, filter, offset) => {
	let url = `/api/case`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	if(Number.isInteger(filter)){
		url += `${urlHasParams ? '&' : '?'}filter=${filter}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCasesByCircle = (circleId, searchPhrase) => {
	var url = `/api/case/getByCircle/${circleId}`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCaseProtocol = (id) => {
	var url = `/api/case/${id}/protocol`
	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const viewPatientResults = (patientId, caseId) => {
	var url = `/api/case/${caseId}/patient/${patientId}/generatelink`
	return authenticatedFetch(url, { method: 'GET' })
	.then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
	.then(function(patient) {
		return patient
	})
}

export const getHl7Messages = (caseId) => {
	var url = `/api/case/gethl7messages/${caseId}`
	return authenticatedFetch(url, { method: 'GET' })
	.then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
	.then(function(result) {
		return result
	})
}
import { authenticatedFetch } from './base'

export const fetchCountries = () => {
	return authenticatedFetch(`/api/country`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { addCircle } from '../../redux/actions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../components/FormTextField'
import FormCheckBox from '../../components/FormCheckBox'
import { required, requiredId, requiredString } from '../../utilities/validators'
import NumberedRow from '../../components/NumberedRow'
import Button from '@material-ui/core/Button'
import PhiSelection from '../../components/PhiSelection'
import SponsorSelection from './components/SponsorSelection'
import ProtocolSelector from '../cases/components/ProtocolSelector'
import PathologySelector from '../cases/components/PathologySelector'
import {
	subscriptionCaseSelector,
	subscriptionUserSelector,
	isCreatingSelector,
	availableCasesSelector,
	planSelector
} from '../../redux/selectors'
import { fetchAvailableCases, fetchPlan } from '../../redux/actions'
import NumberedRowTwoColumns from '../../components/NumberedRowTwoColumns'
import TreatmentSelector from '../cases/components/TreatmentSelector'
import CalmButton from '../../components/CalmButton'

const requiredValidator = required
const requiredIdValidator = requiredId
const requiredStringValidator = requiredString

const selector = formValueSelector('addCircle')

const AddCircles = ({
	intl,
	handleSubmit,
	add,
	setPhi,
	isSponsored,
	resetCases,
	resetUsers,
	subscriptionUser,
	subscriptionCase,
	isCreating,
	getAvailableCases,
	availableCases
}) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		var x = { ...values }

		add(x.name, x.phi === '2', x.sponsoredCases, x.sponsoredUsers, x.isSponsored, x.protocolId, x.pathologyId, x.treatmentId)
	}

	useEffect(() => {
		setPhi('1')
		getAvailableCases()
	}, [])

	const onSponsorSelectionChanged = (e) => {
		if (!e.target.checked) {
			resetCases()
			resetUsers()
		}
	}

	return (
		<form data-testid="form-add-circles" onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				style={{  padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item>
					<Typography
						data-testid="form-add-circles-title"
						variant='h1'
						style={{
							color: '#fff',
							marginBottom: '4rem'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.addCircleText' })}
					</Typography>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
					<NumberedRow data-testid="form-add-circles-name" number={1} label={`${intl.formatMessage({ id: 'app.general.name' })}*`} />
				</Grid>
				<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
					<Grid container direction="row" alignItems="center" style={{ paddingLeft: '20px' }}>
						<Grid item style={{ width: '100%' }}>
							<FormTextField
								data-testid="form-add-circles-name-field"
								label={intl.formatMessage({ id: 'app.circles.name' }) + '*'}
								fullWidth
								type="text"
								name="name"
								maxLength={255}
								validators={[ requiredStringValidator ]}
								variant="filled"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '15px' }}>
					<NumberedRow
						data-testid="form-add-circles-info"
						number={2}
						label={`${intl.formatMessage({ id: 'app.circles.patientHealthInformation' })}*`}
					/>
				</Grid>
				<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
					<div style={{ marginLeft: '20px' }}>
						<Grid container direction="column">
							<Grid data-testid="form-add-circles-phi" item>
								<PhiSelection validators={[ requiredValidator ]} />
							</Grid>
						</Grid>
					</div>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
					<Grid container direction="column">
						<Grid item>
							<Grid container direction="row">
								<Grid data-testid="form-add-circles-sponsored" item style={{}}>
									<NumberedRow
										number={3}
										label={`${intl.formatMessage({ id: 'app.circles.sponsored' })}`}
									/>
								</Grid>
								<Grid item>
									<FormCheckBox
										data-testid="form-add-circles-sponsored-checkbox"
										style={{ color: '#fff' }}
										name="isSponsored"
										onChange={onSponsorSelectionChanged}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRow
						data-testid="form-add-circles-protocol"
						number={4}
						label={`${intl.formatMessage({ id: 'app.general.observationalProtocol' })}*`}
					/>
					<Grid item style={{ borderLeft: '5px solid #2F3945', paddingTop: '6px', width: '100%' }}>
						<div style={{ marginLeft: '20px', minHeight: '20px' }}>
							<ProtocolSelector data-testid="form-add-circles-protocol-selector" validators={[ required ]} />
						</div>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRowTwoColumns
						data-testid="form-add-circles-treatment-pathology"
						number={5}
						labelFirst={`${intl.formatMessage({ id: 'app.general.treatment' })}`}
						labelSecond={`${intl.formatMessage({ id: 'app.general.pathology' })}`}
					/>
					<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%', marginTop: '8px' }}>
						<div style={{ marginLeft: '20px' }}>
						<Grid container direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
							<Grid item  xs={6} sm={6}>
								<TreatmentSelector
									data-testid="form-add-circles-treatment-selector"
									name='treatmentId'
									label={intl.formatMessage({ id: 'app.circles.treatmentLabel' })}
									noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
								/>
							</Grid>
							<Grid item  xs={6} sm={6}>
								<PathologySelector
									data-testid="form-add-circles-pathology-selector"
									name='pathologyId'
									label={intl.formatMessage({ id: 'app.circles.pathologyLabel' })}
									noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
								/>

							</Grid>
						</Grid>
					</div>
					</Grid>
				</Grid>
				<Grid item>
					<CalmButton
						data-testid="form-add-circles-button-create"
						label="Password"
						style={{
							marginTop: '40px'
						}}
						color="primary"
						variant="contained"
						type="submit"
						size="large"
						disabled={isCreating}
					>
						{intl.formatMessage({ id: 'app.circles.create' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isSponsored: selector(state, 'isSponsored'),
	subscriptionUser: subscriptionUserSelector(state),
	subscriptionCase: subscriptionCaseSelector(state),
	isCreating: isCreatingSelector(state),
	availableCases: planSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setPhi: (code) => dispatch(change('addCircle', 'phi', code)),
	resetCases: () => dispatch(change('addCircle', 'numberCases', '')),
	resetUsers: () => dispatch(change('addCircle', 'numberUsers', '')),
	getAvailableCases: () => dispatch(fetchPlan()),
	add: (name, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId) =>
		dispatch(addCircle(name, 0, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addCircle'
	}),
	withRouter
)

export default enhance(AddCircles)

import { authenticatedFetch } from './base'

export const fetchStripePublishableKey = () => {
	return authenticatedFetch(`/api/payment/stripePublishableKey`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(stripePublishableKey) {
			return stripePublishableKey
		})
}

export const fetchPaymentMethod = () => {
	return authenticatedFetch(`/api/payment`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(payment) {
			return payment
		})
}

export const createPaymentMethod = (payment_method) => {
	return authenticatedFetch(`/api/payment/method`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ paymentMethod: payment_method })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(payment) {
			return payment
		})
}

export const fetchStripeIntent = () => {
	return authenticatedFetch(`/api/payment/stripeIntent`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(intent) {
			return intent
		})
}

export const fetchStripePublicKey = () => {
	return authenticatedFetch(`/api/payment/stripePublicKey`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(stripePublicKey) {
			return stripePublicKey
		})
}

export const submitPayment = (userCount, caseCount, circleId) => {
	return authenticatedFetch(`/api/payment`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ userCount: userCount, caseCount: caseCount, circleId: circleId })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(payment) {
			return payment
		})
}

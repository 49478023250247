import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import FormNumberTextField from '../../../components/FormNumberTextField'
import { number, maxValue } from '../../../utilities/validators'
import { useDispatch } from 'react-redux'
import { Field, change } from 'redux-form'
const maxValueValidator = maxValue('available')

const SponsoredValue = ({ intl, label, available = 0, entityType, name }) => {
	const dispatch = useDispatch()
	dispatch(change('addCircle', 'available', available))

	return (
		<Grid data-testid="circle-sponsored-value" container direction="row" style={{ paddingTop: '10px' }}>
			<Grid item xs={12} sm={6} style={{ color: '#fff',paddingLeft: '10px' }}>
				<Typography style={{ color: '#fff' }}>{label}</Typography>
				<Typography data-testid="circle-sponsored-value-available" style={{ color: '#a2a7ab' }}>
					{available} {intl.formatMessage({ id: 'app.general.available' })}
				</Typography>
				<Typography data-testid="circle-sponsored-value-onplan" style={{ color: '#a2a7ab', fontSize: '0.9em' }}>{`${entityType} ${intl.formatMessage({
					id: 'app.general.onPlan'
				})}`}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} style={{ color: '#fff', paddingRight: '20px' }}>
				<Grid container direction="column">
					<Grid item>
						<FormNumberTextField
							data-testid="circle-sponsored-value-field"
							type="text"
							name={'sponsored'+entityType}
							maxLength={4}
							variant="filled"
							validators={[ maxValueValidator ]}
						/>
						<Typography
							data-testid="circle-sponsored-value-allotments"
							style={{ color: '#a2a7ab', fontSize: '0.9em' }}>
							{`${entityType} ${intl.formatMessage({
								id: 'app.general.allotments'
							})}`}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SponsoredValue)

import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'

const AlertTypeCell = ({ alert, intl, setHeightCallback }) => {
	let ref = React.createRef()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				setHeightCallback(ref.current.offsetHeight)
			}
		},
		[ ref ]
	)
	return (
		<div data-testid="myalerts-invitation-type-cell">
			{alert && (
				<Typography
					data-testid="myalerts-invitation-type-cell-invitation"
					variant="subtitle2"
					style={{
						wordBreak: 'break-all',
					}}
				>
					{intl.formatMessage({ id: 'app.circle.invitation' })}
				</Typography>
			)}
			{alert &&
			alert.circleType === 0 && (
				<Typography
					data-testid="myalerts-invitation-type-cell-phifree"
					variant="body2"
					style={{
						wordBreak: 'break-all',
						fontSize: '13px',
					}}
				>
					{intl.formatMessage({ id: 'app.circles.phiFree' })}
				</Typography>
			)}
			{alert &&
			alert.circleType === 1 && (
				<Typography
					data-testid="myalerts-invitation-type-cell-phi"
					variant="body2"
					style={{
						wordBreak: 'break-all',
						fontSize: '13px',
					}}
				>
					{intl.formatMessage({ id: 'app.circles.phi' })}
				</Typography>
			)}
		</div>
	)
}

const enhance = injectIntl

export default enhance(AlertTypeCell)

import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CircleCell from './CircleCell'
import MemberCell from './MemberCell'
import CaseCell from './CaseCell'
import DateAddedCell from './DateSponsorCell'
import Options from './Options'
import StatusIcon from './StatusIcon'
import SponsoredMembersCell from './SponsoredMembersCell'
import UsedCasesCell from './UsedCasesCell'

const CircleRow = ({ circle, user, subscriptionUser }) => {
	const [ minHeight, setMinHeight ] = useState(100)

	return (
		<TableRow data-testid="sponsored-circles-row" key={circle.id} id={circle.id}>
			<TableCell data-testid="sponsored-circles-row-status"  align="left" size="small" style={{ position: 'relative' }}>
				<div style={{
						backgroundColor: `${circle.phi ? '#444e5e' : '#86ceb4'}`,
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px'
				}}></div>
				<div>
					<StatusIcon circle={circle} />
				</div>
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-circle" align='left' size='small'  style={{ minWidth: '180px' }}>
				<CircleCell circle={circle} setHeightCallback={setMinHeight} />
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-sponsored-member" size='small'>
				<SponsoredMembersCell circle={circle} />
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-member" size='small'>
				<MemberCell circle={circle} />
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-used-cases"  size='small'>
				<UsedCasesCell circle={circle} />
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-case" size='small'>
				<CaseCell circle={circle} />
			</TableCell>

			<TableCell data-testid="sponsored-circles-row-date" size='small'>
				<DateAddedCell circle={circle} />
			</TableCell>
			<TableCell data-testid="sponsored-circles-row-options" align='right' size='small' style={{ padding: '5px 15px 5px 5px' }}>
				<Options circle={circle} user={user} subscriptionUser={subscriptionUser} />
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(CircleRow)

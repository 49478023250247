import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import Check from '../../assets/check'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { StripeProvider, Elements, CardForm, CardElement, CardNumberElement } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { injectStripe } from 'react-stripe-elements'
import UpdateCardForm from './UpdateCardForm'
import ConfirmPayment from './ConfirmPayment'
import './stripe.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchPaymentMethod } from '../../redux/actions'
import { paymentMethodSelector, awaitingPaymentMethodSelector } from '../../redux/selectors'

const UpdatePaymentMethod = ({
	intl,
	handleSubmit,
	userCount,
	caseCount,
	totalPrice,
	getPaymentMethod,
	paymentMethod,
	stripePublishableKey,
	awaitingPaymentMethod,
	elements,
	stripe
}) => {
	const theme = useTheme()

	useEffect(() => {
		getPaymentMethod()
	}, [])

	// const [ hasPaymentMethod, setHasPaymentMethod ] = useState(false)

	// useEffect(() => {
	// 	setHasPaymentMethod(paymentMethod ? true : false)
	// }, [paymentMethod])

	return (
		<StripeProvider data-testid="update-payment-method" apiKey={stripePublishableKey.stripePublishableKey}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{
					backgroundColor: theme.palette.secondary.main,
					padding: '4.01rem'
				}}>
				<Grid item>
					<Grid
						container
						direction='column'
						justifyContent='center'
					>

						{/* { awaitingPaymentMethod &&
								<div style = {{margin: '0 auto' }}>
									<CircularProgress />
								</div>
							} */}

						{/* { !hasPaymentMethod && !awaitingPaymentMethod && */}
						<Elements data-testid="update-payment-update-card">
							<UpdateCardForm paymentMethod={paymentMethod} />
						</Elements>
						{/* // } */}

						{/* { hasPaymentMethod &&
							<ConfirmPayment userCount={userCount} caseCount={caseCount} totalPrice={totalPrice} paymentMethod={paymentMethod} /> } */}
					</Grid>
				</Grid>
			</Grid>
		</StripeProvider>
	)
}

const mapStateToProps = (state, ownProps) => ({
	paymentMethod: paymentMethodSelector(state),
	awaitingPaymentMethod: awaitingPaymentMethodSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPaymentMethod: () => dispatch(fetchPaymentMethod())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(UpdatePaymentMethod)

import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'

const AlertHead = ({ intl }) => {
	return (
		<TableRow data-testid="myalerts-alert-head">
			<TableCell size="small" align="left" />
			<TableCell align="left">
				<Typography
					data-testid="myalerts-alert-head-source"
					variant="caption"
				>
					{intl.formatMessage({ id: 'app.alerts.source' }).toUpperCase()}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Typography
					data-testid="myalerts-alert-head-type"
					variant="caption"
				>
					{intl.formatMessage({ id: 'app.alerts.type' }).toUpperCase()}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Typography
					data-testid="myalerts-alert-head-date"
					variant="caption"
				>
					{intl.formatMessage({ id: 'app.alerts.alertDate' }).toUpperCase()}
				</Typography>
			</TableCell>
			{/* <TableCell align="left">
                <Typography variant="caption">
                    {intl.formatMessage({id: 'app.alerts.delegate'}).toUpperCase()}
                </Typography>
            </TableCell> */}
			<TableCell />
		</TableRow>
	)
}

const enhance = compose(injectIntl)

export default enhance(AlertHead)

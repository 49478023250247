import React, { Fragment } from 'react'
import LabelPaper from '../../../components/LabelPaper'
import Grid from '@material-ui/core/Grid'
import CurrentPatient from './CurrentPatient'
import LineChart from '../../../components/LineChart'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const PatientResponse = ({ intl }) => (
	<div data-testid="dashboard-patient-response" className="print-wrapper" style={{ padding: '0% 5% 5% 5%' }}>
		<div>
			<Grid container style={{ direction: 'row' }} spacing={3}>
				<Grid item xs={12} md={9}>
					<LabelPaper
						data-testid="dashboard-patient-response-paper"
						label={intl.formatMessage({ id: 'app.dashboard.patientResponseRate' })}
						Component={LineChart}
						minHeight={310}
					/>
				</Grid>
				<Grid item xs={12} md={3} style={{ display: 'flex' }}>
					<LabelPaper
						data-testid="dashboard-patient-response-current"
						Component={CurrentPatient} currentResponseRate={75}/>
				</Grid>
			</Grid>
		</div>
	</div>
)

const enhance = compose(injectIntl)

export default enhance(PatientResponse)

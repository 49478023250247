import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import loginBackground from '../../assets/shared/splash-screen/arrows-dashboard@2x.jpg'
import { FormattedDate, useIntl } from 'react-intl'
import { refreshIntlLocale, fetchSponsorStatistics } from '../../redux/actions'
import { localeIsUpdatedSelector } from '../../redux/selectors'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Container from '@material-ui/core/Container'
import { sponsorStatisticsSelector } from '../../redux/modules/sponsor'
import CircleCasesUsage from './components/CircleCasesUsage'
import CompositeLabelPaper from '../../components/CompositeLabelPaper'
import InvestigatorsUsage from './components/InvestigatorsUsage'
import SubscriptionsUsage from './components/SubscriptionsUsage'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { Button } from '@material-ui/core'
import { isMobile } from '../../utilities/mobileDetector'


const renderTableLabel = (intl, label, periodStart, periodEnd) => (
	<Grid container direction='column'>
		<Grid item>
			<Typography
				data-testid="composite-label-paper-title"
				style={{
					padding: '14px 0 0 24px',
					textAlign: 'left',
					color: 'white',
					fontSize: '20px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '700'
				}}
			>
				{label}
			</Typography>
		</Grid>
		<Grid item>
			<Typography
				data-testid="composite-label-paper-title"
				style={{
					padding: '14px 24px',
					textAlign: 'left',
					color: 'white',
					fontSize: '16px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '400'
				}}
			>
				{intl.formatMessage({ id: 'app.sponsor.billingPeriodLabel' }).toUpperCase()}
				<FormattedDate year='numeric' month='short' day='2-digit' value={periodStart} /> - <FormattedDate year='numeric' month='short' day='2-digit' value={periodEnd} />
			</Typography>
		</Grid>
	</Grid>
)

const getMailToLink = () => {
	let messageBody = `I would like to request adjustments to the billable seats. 
	\n
	\n 
	ADD NEW BILLABLE SEATS 
	\n 
	Please read more information about the Circle member and Team Member access in this article https://kb.rgnmed.com/selecting-your-circle-roles
	\n 
	YOU MUST SPECIFY EMAIL AND THE CIRCLE OR TEAM TO WHERE THE USER MUST BE INVITED \n 
	1. 
	\n
	\n 
	REMOVE BILLABLE SEATS 
	\n 
	Remove all the seats from the list below 
	\n
	1. 
	\n
	\n`

	return "mailto:support@rgnmed.com?subject=Request Subscriptions reorganization&body=" + encodeURIComponent(messageBody)
}


const CircleCasesLabel = ({}) => {
	const intl = useIntl()
	const statistics = useSelector(sponsorStatisticsSelector, shallowEqual)
	return renderTableLabel(intl, intl.formatMessage({ id: 'app.sponsor.circleCasesUsageHeader' }), statistics.currentPeriodStart, statistics.currentPeriodEnd)
}

const InvestigatorsCasesLabel = ({}) => {
	const intl = useIntl()
	const statistics = useSelector(sponsorStatisticsSelector, shallowEqual)
	return renderTableLabel(intl, intl.formatMessage({ id: 'app.sponsor.investigatorUsageHeader' }), statistics.currentPeriodStart, statistics.currentPeriodEnd)
}

const SubscriptionsLabel = ({}) => {
	const intl = useIntl()
	const statistics = useSelector(sponsorStatisticsSelector, shallowEqual)
	return renderTableLabel(intl, intl.formatMessage({ id: 'app.sponsor.subscriptionsUsageHeader' }), statistics.currentPeriodStart, statistics.currentPeriodEnd)
}

const SponsorStatistics = ({ match }) => {
	const intl = useIntl()
	const statistics = useSelector(sponsorStatisticsSelector, shallowEqual)
	const localeIsUpdated = useSelector(localeIsUpdatedSelector, shallowEqual)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(refreshIntlLocale())
	}, [])

	useEffect(() => {
		dispatch(fetchSponsorStatistics(match.params.token))
	}, [match.params.token])

	return (
		localeIsUpdated && statistics && <div
			data-testid="sponsor-statistics"
			style={{
				minHeight: '100vh',
				backgroundImage: `url(${loginBackground})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				display: 'flex',
				justifyContent: 'center',
				padding: '5% 0px',
			}}>

			<Container>
				<div className="page-wrapper" style={{ padding: '5% 10%' }}>
					<div>
						<Grid container direction="column" alignItems="center" style={{ textAlign: 'center' }}>
							<Grid item style={{ paddingBottom: '72px' }}>
								<Typography
									data-testid="sponsor-statistics-title"
									variant='h1' style={{ color: '#fff', fontSize: '35px', fontWeight: '600', letterSpacing: '0.25px' }}>
									{intl.formatMessage({ id: 'app.sponsor.statisticsPageHeader' }).replace('%%SponsorName%%', statistics.sponsorName)}
								</Typography>
							</Grid>
						</Grid>
					</div>
				</div>

				<div style={{ paddingBottom: '80px' }}>
					<CompositeLabelPaper
						data-testid="sponsor-circle-cases"
						label={intl.formatMessage({ id: 'app.sponsor.circleCasesUsageHeader' })}
						Filter={CircleCasesLabel}
						Component={CircleCasesUsage}
						data={statistics && statistics.circleCases}
					/>
				</div>

				<div style={{ paddingBottom: '80px' }}>
					<CompositeLabelPaper
						data-testid="sponsor-investigators"
						label={intl.formatMessage({ id: 'app.sponsor.investigatorUsageHeader' })}
						Filter={InvestigatorsCasesLabel}
						Component={InvestigatorsUsage}
						data={statistics && statistics.investigators}
					/>
				</div>

				<div style={{ paddingBottom: '80px', position: 'relative' }}>
					{statistics && <Grid justifyContent='flex-end' style={{ position: 'absolute', right: '12px', top: isMobile ? '50px' : '14px' }}>
						<a href={getMailToLink(statistics.subscriptions)} style={{ display: 'block', textDecoration: 'none' }}>
							<Button
								variant="contained"
								color="primary"
								style={{ margin: '0'}}
							>
								{intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.requestChangesButtonLabel' }).toUpperCase()}
							</Button>
						</a>
					</Grid>}
					<CompositeLabelPaper
						data-testid="sponsor-subscriptions"
						label={intl.formatMessage({ id: 'app.sponsor.subscriptionsUsageHeader' })}
						Filter={SubscriptionsLabel}
						Component={SubscriptionsUsage}
						data={statistics && statistics.subscriptions}
					/>
				</div >
			</Container >
		</div >
	)
}

const enhance = compose(withRouter)
export default enhance(SponsorStatistics);

import { authenticatedFetch } from './base'

export const fetchAlerts = (offset) => {
	let url = '/api/alert'

	if(offset){
		url += `?offset=${offset}`
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchCaseAlerts = (id) => {
	return authenticatedFetch(`/api/alert/cases/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

//action 1 is accept, decline is 0
export const processInvitation = (invitationId, action) => {
	var url = `/api/alert/circle/${invitationId}`
	var method = 'POST'
	if (action === 1) {
		url = url + '/accept'
	} else {
		method = 'DELETE'
		url = url + '/decline'
	}
	return authenticatedFetch(url, {
		method: method,
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(alert) {
			return alert
		})
}

export const dismissOutlierAlert = (id) => {
	return authenticatedFetch(`/api/alert/${id}/dismiss`, {
		method: 'POST'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

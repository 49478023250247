import React from "react";
import { useIntl } from "react-intl";


const LateralityValue = ({isBilateral}) => {
    const intl = useIntl()

    return (
        <span>
            {
                isBilateral
                    ? intl.formatMessage({ id: 'app.laterality.bilateralLabel' })
                    : intl.formatMessage({ id: 'app.laterality.unilateralLabel' })
            }
        </span>
    )
}

export default LateralityValue
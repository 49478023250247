import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const renderCheckboxField = ({
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	...custom
}) => {
	return (
		<FormGroup data-testid="component-form-checkbox" row>
			<FormControlLabel
				data-testid="component-form-checkbox-formcontrol"
				control={<Checkbox value="checkedA" style={style} onChange={input.onChange} />}
				label={label}
				labelPlacement="start"
			/>
		</FormGroup>
	)
}

export default ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	onChange
}) => (
	<Field
		data-testid="component-form-checkbox-field"
		name={name}
		component={renderCheckboxField}
		label={label}
		inputLabelProps={inputLabelProps}
		inputProps={inputProps}
		style={style}
		fullWidth
		margin={margin}
		variant={variant}
		type={type}
		validate={validators}
		onChange={onChange}
	/>
)

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'

const AlertDelegateCell = ({ task, intl }) => (
	<div data-testid="mytasks-survey-alert-delegate">
		{task &&
			task.delegateId === 0 && (
				<Typography
					data-testid="mytasks-survey-alert-delegate-patient"
					variant="subtitle2"
					style={{ textAlign: 'left' }}
				>
					{intl.formatMessage({ id: 'app.general.delegatePatientLabel' })}
				</Typography>
			)}
		{task &&
			task.delegateId === 1 && (
				<Typography
					data-testid="mytasks-survey-alert-delegate-clinician"
					variant="subtitle2"
					style={{ textAlign: 'left' }}
				>
					{intl.formatMessage({ id: 'app.general.delegateClinicianLabel' })}
				</Typography>
			)}
		{task &&
			task.delegateId === 2 && (
				<Typography
					data-testid="mytasks-survey-alert-delegate-provider"
					variant="subtitle2"
					style={{ textAlign: 'left', wordBreak: 'break-all' }}
				>
					{intl.formatMessage({ id: 'app.general.delegateServiceProviderLabel' })}
					{
						//task && task.delegateId === 2 && <Grid item style={{ 'font-weight': '600' }}>{`Task ID : ${task.taskId36}`}</Grid>
					}
				</Typography>
			)}
		{task &&
			task.firstName &&
			task.lastName &&
			task.delegateId > 0 && (
				<Typography
					data-testid="mytasks-survey-alert-delegate-contact"
					variant="body2"
					style={{
						wordBreak: 'break-all',
						color: 'rgba(0,0,0,0.87)'
					}}
				>
					{`${task.firstName} ${task.lastName}`}
				</Typography>
			)}
	</div>
)

const enhance = injectIntl

export default enhance(AlertDelegateCell)

import React, { useState } from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import ProtocolSelectorControl from './ProtocolSelectorControl'
// import ChildProtocolSelectorControl from './ChildProtocolSelectorControl'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { formattedProtocolsSelector, childProtocolListSelector } from '../../../redux/selectors'
import { requiredId } from '../../../utilities/validators'

const requiredIdValidator = requiredId

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const ProtocolSelector = ({ intl, protocols, loadSubProtocol, subProtocols, validators }) => {
	const [ parentProtocolId, setParentProtocolId ] = useState(null)

	return (
		<Grid data-testid="protocol-selector" container>
			<Grid item style={{ width: '100%', paddingBottom: '15px', height: '75px' }}>
				<ProtocolSelectorControl
					data-testid="protocol-selector-inner"
					name='protocolId'
					label={intl.formatMessage({ id: 'app.cases.protocolLabel' })}
					suggestions={protocols && protocols.filter((r) => r.isActive)}
					validators={validators}
					onProtocolSelected={setParentProtocolId}
					noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
				/>
			</Grid>

			<Grid item style={{ paddingLeft: '50px', width: '100%' }}>
				{/* {parentProtocolId &&
				parentProtocolId > 0 && (
					<Grid container style={{ width: '100%', paddingTop: '10px' }}>
						<Grid item>
							<div
								style={{
									borderBottom: 'dashed 1px white',
									borderLeft: 'dashed 1px white',
									height: 'calc(100% - 50px)',
									marginLeft: '-35px'
								}}
							>
								&nbsp;
							</div>
						</Grid>
						<Grid item style={{ width: '100%', paddingLeft: '20px', paddingTop: '15px' }}>
							<Grid container direction="column">
								<Grid item>
									<Grid
										item
										style={{
											textAlign: 'right',
											alignSelf: 'flex-end',
											color: '#fff',
											width: '100%'
										}}
									/>
								</Grid>
								<Grid item style={{ height: '75px' }}>
									<ChildProtocolSelectorControl
										name="subProtocolId"
										label=""
										parentId={parentProtocolId}
										label={intl.formatMessage({ id: 'app.cases.relatedProtocolLabel' })}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)} */}
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	protocols: formattedProtocolsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), injectIntl)

export default enhance(ProtocolSelector)

import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CircularProgress, useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { tasksEmailIsLoadingSelector, tasksEmailSelector } from '../../../../redux/selectors'


export default ({ task }) => {
	const intl = useIntl()
	const theme = useTheme()
	const dispatch = useDispatch()
	const taskEmail = useSelector(tasksEmailSelector, shallowEqual)
	const taskEmailIsLoading = useSelector(tasksEmailIsLoadingSelector, shallowEqual)

	const onCopy = (id) => {
		let copyText = document.getElementById(id)
		try {
			copyText.select()
			document.execCommand('copy')
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.general.copiedToClipboardText',
					isSuccess: true
				}
			})
		} catch (error) {}
	}

	const emailLink = `mailto:${task.patientEmail}?subject=${taskEmail && taskEmail.subject || ''}&body=${taskEmail && taskEmail.body || ''}`

	return (
		<Grid
			data-testid='mytasks-info-modal'
			container
			direction='column'
			justifyContent='flex-start'
			style={{ padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main, color: '#fff' }}>
			<Grid item>
				<Typography
					data-testid='mytasks-info-contact-title'
					variant='h1'
					style={{  marginBottom: '4rem', textAlign: 'center'}}
				>
					{intl.formatMessage({ id: 'app.tasks.contactHeader' })}
				</Typography>
			</Grid>
			<Grid item style={{ marginBottom: '10px' }}>
				<Grid container spacing={1} direction='row' alignItems='center'>
					<Grid item>
						<Typography variant='subtitle2'>
							{intl.formatMessage({ id: 'app.tasks.contactEmailLabel' })}
						</Typography>
					</Grid>
					<Grid item>
						<Typography 
							id='task-info-patient-email' 
							variant='subtitle2' 
							style={{ wordBreak: 'break-all'}}
							className="tracking-hide-content"
							>
							{task.patientEmail || intl.formatMessage({ id: 'app.tasks.contactNoEmail' })}
						</Typography>
					</Grid>
					<Grid item style={{ marginLeft: 'auto' }}>
						{task.patientEmail &&
							<Typography variant='subtitle2'>
								<Grid container direction='row' spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
									<Grid item>
										{taskEmailIsLoading && <CircularProgress size={20} style={{ color: '#fff', marginRight: '8px' }} />}

										{!taskEmailIsLoading && <a href={emailLink} 
											className="button-icon"
											tabindex="0"
											aria-label="Phone"
										>
											<EmailOutlinedIcon className="button-icon-svg" style={{ color: '#fff'}} />
										</a>}
									</Grid>
									<Grid item>
										<div onClick={() => onCopy('task-info-patient-email-input')}
											className="button-icon"
											tabindex="0"
											aria-label="Copy to clipboard"
										>
											<FileCopyOutlinedIcon className="button-icon-svg" style={{ color: '#fff'}} />
											<input
												data-testid="phi-section-input-email"
												type="text"
												id='task-info-patient-email-input'
												name="email"
												value={task.patientEmail}
												style={{ position: 'absolute', left: '-9999px' }}
											/>
										</div>
									</Grid>
								</Grid>
							</Typography>}
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ marginBottom: '10px' }}>
				<Grid container spacing={1} direction='row' alignItems='center'>
					<Grid item>
						<Typography variant='subtitle2'>
							{intl.formatMessage({ id: 'app.tasks.contactPhoneLabel' })}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							id='task-info-patient-phone'
							variant='subtitle2'
							className="tracking-hide-content"
							>
							{task.patientPhoneNumber || intl.formatMessage({ id: 'app.tasks.contactNoPhone' })}
						</Typography>
					</Grid>
					<Grid item style={{ marginLeft: 'auto' }}>
						{task.patientPhoneNumber &&
							<Typography variant='subtitle2'>
								<Grid container direction='row' spacing={1}>
									<Grid item>
										<a href={`tel:${task.patientPhoneNumber}`} 
											className="button-icon"
											tabindex="0"
											aria-label="Phone"
										>
											<PhoneEnabledOutlinedIcon className="button-icon-svg" style={{ color: '#fff'}}/>
										</a>
									</Grid>
									<Grid item>
										<div onClick={() => onCopy('task-info-patient-phone-input')} 
											className="button-icon"
											tabindex="0"
											aria-label="Copy to clipboard"
										>
											<FileCopyOutlinedIcon className="button-icon-svg" style={{ color: '#fff'}} />
											<input
												data-testid="task-info-patient-email"
												type="text"
												id='task-info-patient-phone-input'
												name="email"
												className="tracking-hide-content"
												value={task.patientPhoneNumber}
												style={{ position: 'absolute', left: '-9999px' }}
											/>
										</div>
									</Grid>
								</Grid>
							</Typography>}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

import 'intersection-observer'
import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TaskHead from './surveys/TaskHead'
import { CircularProgress, TableCell, TableRow } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { hasMoreTasksSelector, isLoadingTasksSelector, tasksSearchTermSelector, tasksRefreshRequestedSelector } from '../../../redux/selectors'
import { fetchAdditionalTasks } from '../../../redux/actions'
import { compose } from 'recompose'
import TaskRow from './surveys/TaskRow'

const TaskTable = ({ tasks, intl, search, hasMore, getTasks, loading, refreshRequested }) => {
	const getAdditionalTasks = () => {
		getTasks(search, tasks.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalTasks,
		delayInMs: 1000
	  });

	return (
		<div data-testid="mytasks-task-table" className="label-paper-inner" style={{ margin: '20px -32px 0' }} >
			<Table size="small">
				<TableHead>
					<TaskHead />
				</TableHead>
				<TableBody>
					{!refreshRequested && tasks && tasks.map((task, index) =>
						<TaskRow task={task} key={index} intl={intl} />)
					}
					{refreshRequested &&
						<TableRow>
							<TableCell colspan='7' style={{ borderBottom: 'none' }}>
								<div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
									<CircularProgress />
								</div>
							</TableCell>
						</TableRow>
					}
				</TableBody>
			</Table>
			{!refreshRequested && tasks && tasks.length > 0 && (loading || hasMore) && (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingTasksSelector(state),
	hasMore: hasMoreTasksSelector(state),
	search: tasksSearchTermSelector(state),
	refreshRequested: tasksRefreshRequestedSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getTasks: (search, offset) => dispatch(fetchAdditionalTasks(search, offset))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(TaskTable)

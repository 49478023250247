import React, { useState, useEffect } from 'react'
import { Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import FormTextField from './FormTextField'
import LanguageSelectorV2 from '../components/LanguageSelectorV2'
import { email, required } from '../utilities/validators'
import useTheme from '@material-ui/core/styles/useTheme'
import { injectIntl, FormattedDate } from 'react-intl'
import { compose } from 'recompose'
import { useSelector, shallowEqual, useDispatch, connect } from 'react-redux'
import { fetchLanguages, fetchCircle } from '../redux/actions'
import { isInvitingSelector, languagesSelector, circleSelector, isInvitingCircleMemberSelector } from '../redux/selectors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { checkCanSponsor } from '../apis/subscription'
import CalmButton from './CalmButton'
import InfoIcon from '@material-ui/icons/Info'

const CASE_FUNDING_TYPE = {
	CIRCLE: 0,
	SELF: 1
}

const InvitationForm = ({ handleSubmit, onSubmitForm, intl, availableCredits, circle, isTeamMember = false }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isInviting = useSelector(isInvitingSelector, shallowEqual)
	const isInvitingCircleMember = useSelector(isInvitingCircleMemberSelector, shallowEqual)
	const languages = useSelector(languagesSelector, shallowEqual)

    const [ sponsored, setSponsored ] = useState(false)
	const [ errorText, setErrorText ] = useState()
	const [ disableButton, setDisableButton ] = useState(false)
	const [ caseFunding, setCaseFunding] = useState(0)

	useEffect(() => {
        dispatch(fetchLanguages())
	}, [])

	useEffect(() => {
        if(circle) {
			setCaseFunding(circle.defaultCaseFunding)
		}
    }, [circle && circle.id, circle && circle.defaultCaseFunding])

	const handleChange = (e) => {
		if (e && e.target && e.target.checked === true) {
			setSponsored(true)
		} else {
			setSponsored(false)
		}
	}

	const prepSubmit = async (values) => {
		setErrorText()
		values.sponsored = sponsored
		values.caseFunding = caseFunding
		if (sponsored) {
			setDisableButton(true)

			const canSponsorEmail = await checkCanSponsor(values.email, circle.id)

			setDisableButton(false)
			
			if (!canSponsorEmail || !canSponsorEmail.isSuccessful)
			{
				let messageId, params = {};

				if (canSponsorEmail.errorCode === 29) {
                    params = { CircleName: canSponsorEmail.errorMessage };
                    messageId = 'app.invitation.cannotSponsor.circleDoesNotHaveSubsctiption';
                } else {
                    messageId = 'app.invitation.cannotSponsor';
                }

				setErrorText(intl.formatMessage({ id: messageId }, params))
			}
			else
            {
				onSubmitForm(values)
			}

		}
		else
        {
			onSubmitForm(values)
		}
	}

	return (
		<form data-testid="form-invitation" onSubmit={handleSubmit(prepSubmit)}>
			<Grid
				container
				direction="column"
				style={{
					padding: '4rem',
					backgroundColor: theme.palette.secondary.main
				}}
			>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						data-testid="form-invitation-title"
						variant='h1'
						style={{ color: '#fff', marginBottom: '4rem',  }}
					>
						{intl.formatMessage({ id: isTeamMember ? 'app.userProfile.inviteTeamMemberHeader' : 'app.circle.inviteCircleMemberHeader' })}
					</Typography>
				</Grid>

				<Grid data-testid="info-message" item className="infoAlert">
					<InfoIcon />
					<span className="infoAlertText">
						{intl.formatMessage({ id: isTeamMember ? 'app.userProfile.teamMembersInfo' : 'app.circle.circleMembersInfo' })}
					</span>
				</Grid>

				<Grid item style={{ width: '100%', minHeight: '90px' }}>
					<FormTextField
						data-testid="form-invitation-email"
						label={intl.formatMessage({ id: 'app.general.email' }) + '*'}
						fullWidth
						type="text"
						name="email"
						variant="filled"
						validators={[ required, email ]}
					/>
				</Grid>

				<Grid item style={{ width: '100%', minHeight: '90px' }}>
					<LanguageSelectorV2
							label={intl.formatMessage({ id: 'app.general.communicationOnLabel' })}
                            fullWidth
							data={languages}
							name="languageId"
                            variant="filled"
                        />
				</Grid>

				{!isTeamMember && (
					<Grid
						item
						style={{
							marginTop: '10px',
							width: '100%',
							textAlign: 'center'
						}}
					>
						<FormControlLabel
							data-testid="form-invitation-label-wrap"
							style={{
								color: '#fff',
								cursor: !circle || circle.subscriptionExpirationDate < new Date().getTime() ? 'not-allowed' : 'default'
							}}
							labelPlacement="end"
							control={
								<Checkbox
									data-testid="form-invitation-checkbox"
									onChange={handleChange}
									value="sponsored"
									color="secondary"
									disabled={ !circle || circle.subscriptionExpirationDate < new Date().getTime() }
									style={{
										cursor: !circle || circle.subscriptionExpirationDate < new Date().getTime() ? 'not-allowed' : 'default'
									}}
								/>
							}
							label={
								<Typography
									data-testid="form-invitation-label"
									variant='h3'
									style={{
										color: '#fff'
									}}
								>
									{intl.formatMessage({ id: 'app.circle.sponsorUser' })}
								</Typography>
							}
						/>
						<Typography
							data-testid="form-invitation-sponsor"
							variant="subtitle2"
							style={{ color: '#fff', paddingTop: '30px', paddingBottom: '5px' }}
						>
							{intl.formatMessage({ id: 'app.circle.sponsorExplanation' })}
						</Typography>

                        {
							circle &&
							<Typography data-testid="form-invitation-available" variant="body2" style={{ color: '#fff', paddingBottom: '40px' }}>
								{
									circle.subscriptionExpirationDate
										? <FormattedDate year='numeric' month='long' day='2-digit' value={circle.subscriptionExpirationDate} />
										: 'Not defined'
								}
							</Typography>
						}

					</Grid>
				)}
				{!isTeamMember && circle && circle.canChangeCaseFunding && (
					<Grid item style={{ marginTop: '10px', width: '100%' }}>
						<Typography
							variant="body1"
							style={{ color: 'white', paddingBottom: '5px' }}
						>
							{intl.formatMessage({ id: 'app.circles.casesFundedByLabel' })}
						</Typography>
						<RadioGroup>
						<Grid
							container
							spacing={2}
							direction='column'
							style={{ width: '100%' }}
						>
							<Grid item xs="auto">
								<FormControlLabel
									label={intl.formatMessage({ id: 'app.circles.casesFundedByCircleLabel' })}
									control={
										<Radio
											onChange={()=>setCaseFunding(0)}
											checked={caseFunding == CASE_FUNDING_TYPE.CIRCLE}
										/>
									}
								/>
							</Grid>
							<Grid item xs="auto">
								<FormControlLabel
									label={intl.formatMessage({ id: 'app.circles.casesFundedBySelfLabel' })}
									control={
										<Radio
											onChange={()=>setCaseFunding(1)}
											checked={caseFunding == CASE_FUNDING_TYPE.SELF}
										/>
									}
								/>
							</Grid>
						</Grid>
					</RadioGroup>
					</Grid>
				)}
				<Grid
					item
					style={{
						paddingBottom: errorText ? '10px' : '10px',
						width: '100%',
						marginTop: '15px',
						textAlign: 'center'
					}}
				>
					<CalmButton
						data-testid="form-invitationbutton-submit"
						color="primary"
						variant="contained"
						type="submit"
						size="large"
						disabled={isInviting || isInvitingCircleMember || disableButton}
					>
						{intl.formatMessage({ id: 'app.invitation.send' })}
					</CalmButton>
				</Grid>

				{errorText && (
					<Grid item style={{ paddingBottom: '40px', width: '100%', textAlign: 'center' }}>
						<Typography data-testid="form-invitation-error" variant="caption" style={{ color: 'red' }}>
							{errorText}
						</Typography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
    circle: circleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getCircle: (circleId) => dispatch(fetchCircle(circleId)),
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(InvitationForm)

import { authenticatedFetch } from './base'

export const fetchTerms = (language) => {
	var url = `/api/terms/languages/${language}/types/2`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(terms) {
			return terms
		})
}

import { authenticatedFetch } from './base'

export const fetchResponseRate = (circleId) => {
	return authenticatedFetch(`/api/responseRate/circle/${circleId}`, {
		method: 'get',
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (obj) {
			return obj
		})
}

export const fetchCircleCasesRate = (circleId) => {
	return authenticatedFetch(`/api/responseRate/circle/${circleId}/casesRate`, {
		method: 'get',
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (obj) {
			return obj
		})
}

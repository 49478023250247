import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Avatar from '@material-ui/core/Avatar'

const NumberedRowTwoColumns = ({ number, labelFirst, labelSecond }) => {
	return (
		<Grid data-testid="numbered-row-two-columns" container direction="column">
			<Grid item style={{marginTop: '-20px' }}>
				<Grid container direction="row" alignItems="center" style={{ flexWrap: 'nowrap' }}>
					<Grid item style={{ marginLeft: '-7px' }}>
						<Avatar
							data-testid="numbered-row-two-columns-number"
							style={{
								width: 20,
								height: 20,
								color: '#000',
								backgroundColor: '#7AB5E0'
							}}
						>
							{number}
						</Avatar>
					</Grid>
					<Grid item style={{ paddingLeft: '10px', width: '100%', flex: 1}}>
						<Grid container spacing={2} direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2} style={{width: '100%', margin: '0' }}>
							<Grid item xs={6} sm={6}>
								<Typography
									data-testid="numbered-row-two-columns-label-first"
									style={{
										color: '#fff',
										fontWeight: '400',
										fontSize: '22px'
									}}
									align="left"
								>
									{labelFirst}
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography
									data-testid="numbered-row-two-columns-label-second"
									style={{
										color: '#fff',
										fontWeight: '400',
										fontSize: '22px'
									}}
									align="left"
								>
									{labelSecond}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(NumberedRowTwoColumns)

import * as circle from '../../../apis/circle'
import * as cookies from '../../../utilities/cookies'
import * as invitations from '../../../apis/circleInvitation'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import { getUser } from '../../../utilities/userUtilities'
import { fetchSubscriptionUsersCases } from '../subscriptions/index'

const ADD_CIRCLE_REQUESTED = 'ADD_CIRCLE_REQUESTED'
const ADD_CIRCLE_SUCCESS = 'ADD_CIRCLE_SUCCESS'
const ADD_CIRCLE_FAILED = 'ADD_CIRCLE_FAILED'

export const addCircle = (name, status, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId) => (dispatch) => {
	dispatch({ type: ADD_CIRCLE_REQUESTED, data: {} })

	circle.addCircle(name, status, phiValue, numberCases, numberUsers, isSponsored, protocolId, pathologyId, treatmentId).then((circle) => {
		if (circle && circle.isSuccessful) {
			dispatch(fetchCircles())
			dispatch(hideModal())
			dispatch(fetchSubscriptionUsersCases())
			return dispatch({ type: ADD_CIRCLE_SUCCESS, data: circle })
		} else {
			dispatch({ type: ADD_CIRCLE_FAILED, data: circle })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.circle.failedToCreateText`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const FETCH_SPONSORED_CIRCLES_REQUESTED = 'FETCH_SPONSORED_CIRCLES_REQUESTED'
const FETCH_SPONSORED_CIRCLES_SUCCESS = 'FETCH_SPONSORED_CIRCLES_SUCCESS'
const FETCH_SPONSORED_CIRCLES_FAILED = 'FETCH_SPONSORED_CIRCLES_FAILED'

export const fetchSponsoredCircles = (search) => (dispatch) => {
	dispatch({ type: FETCH_SPONSORED_CIRCLES_REQUESTED, data: {} })
	circle.fetchSponsoredCircles().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_SPONSORED_CIRCLES_SUCCESS,
				data: response.circles
			})
		} else {
			return dispatch({ type: FETCH_SPONSORED_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_REQUESTED = 'FETCH_CIRCLES_REQUESTED'
const FETCH_CIRCLES_SUCCESS = 'FETCH_CIRCLES_SUCCESS'
const FETCH_CIRCLES_FAILED = 'FETCH_CIRCLES_FAILED'

export const fetchCircles = (search, protocolVersionId = null) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: FETCH_CIRCLES_REQUESTED, data: {} })
	circle.fetchCircles(search, state.circle.filter, protocolVersionId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_CIRCLES_SUCCESS,
				data: {
					items: response.circles,
					hasMore: response.hasMore,
					field: 'name',
					isDescending: false
				}
			})
		} else {
			return dispatch({ type: FETCH_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_CIRCLES_REQUESTED = 'FETCH_ADDITIONAL_CIRCLES_REQUESTED'
const FETCH_ADDITIONAL_CIRCLES_SUCCESS = 'FETCH_ADDITIONAL_CIRCLES_SUCCESS'
const FETCH_ADDITIONAL_CIRCLES_FAILED = 'FETCH_ADDITIONAL_CIRCLES_FAILED'

export const fetchAdditionalCircles = (search, offset) => (dispatch, getState) => {
	dispatch({ type: FETCH_ADDITIONAL_CIRCLES_REQUESTED, data: {} })
	let state = getState()
	circle.fetchCircles(search, state.circle.filter, offset).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				var data = response.circles
				return dispatch({
					type: FETCH_ADDITIONAL_CIRCLES_SUCCESS,
					data: {
						items: data,
						hasMore: response.hasMore
					}
				})

				//return dispatch(setCircleSort(state.circle.sortField, state.circle.isDescending))
			} else {
				return dispatch({
					type: FETCH_ADDITIONAL_CIRCLES_SUCCESS,
					data: {
						items: [],
						hasMore: false
					}
				})
			}
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_CIRCLES_FAILED, data: {}})
		}
	})
	return
}

export const fetchCirclesISponsor = (search) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_REQUESTED, data: {} })
	circle.fetchCircles(search).then((response) => {
		if (response && response.isSuccessful) {
			let user = getUser()

			let data = response.circles.filter((f) => {
				if (f.isSponsored && f.isFounder) {
					return f
				}
			})
			return dispatch({
				type: FETCH_CIRCLES_SUCCESS,
				data: {
					items: data.length > 0 ? data : [],
					field: 'name',
					isDescending: false
				}
			})
		} else {
			return dispatch({ type: FETCH_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const SET_CIRCLE_SORT_REQUESTED = 'SET_CIRCLE_SORT_REQUESTED'

export const setCircleSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.circle.circles.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_CIRCLE_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_INVITATIONS_SORT_REQUESTED = 'SET_INVITATIONS_SORT_REQUESTED'

const applyCircleMemberSort = (items, field, isDescending) => {
    let data = items.sort((a, b) => {
		if (isDescending) {
            return a[field] > b[field] ? -1 : 1
        } else {
            return b[field] > a[field] ? -1 : 1
        }
    });

    return { items: data, field: field, isDescending: isDescending };
}

export const setInvitationsSort = (field, isDescending) => (dispatch, state) => {
    let currentState = state()

    let d = [...currentState.circle.circleInvitations]

    let response = applyCircleMemberSort(d, field, isDescending);

    return dispatch({
		type: SET_INVITATIONS_SORT_REQUESTED,
        data: { items: response.items, field: response.field, isDescending: response.isDescending }
    })
}

const SET_SPONSORED_CIRCLE_SORT_REQUESTED = 'SET_SPONSORED_CIRCLE_SORT_REQUESTED'

export const setSponsoredCircleSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()
	let data = currentState.circle.sponsoredCircles.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})

	return dispatch({
		type: SET_SPONSORED_CIRCLE_SORT_REQUESTED,
		data: { items: [ ...data ], field: field, isDescending: isDescending }
	})
}

const FETCH_CIRCLES_WITH_CASE_REQUESTED = 'FETCH_CIRCLES_WITH_CASE_REQUESTED'
const FETCH_CIRCLES_WITH_CASE_SUCCESS = 'FETCH_CIRCLES_WITH_CASE_SUCCESS'
const FETCH_CIRCLES_WITH_CASE_FAILED = 'FETCH_CIRCLES_WITH_CASE_FAILED'

export const fetchCirclesWithCaseCount = () => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_WITH_CASE_REQUESTED, data: {} })
	circle.fetchCirclesWithCaseCount().then((circles) => {
		if (circles) {
			return dispatch({
				type: FETCH_CIRCLES_WITH_CASE_SUCCESS,
				data: circles.circles
			})
		} else {
			return dispatch({ type: FETCH_CIRCLES_WITH_CASE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchCircleAutoComplete = (searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchSharedWithCircleAutoComplete = (searchPhrase, noMatchMessage, protocolVersionId = null) => (dispatch) => {
	dispatch({ type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleAutoComplete(searchPhrase, protocolVersionId).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const CLEAR_CIRCLE_REQUESTED = 'FETCH_CIRCLE_REQUESTED'

export const clearCircle = () => (dispatch) => {
	return dispatch({ type: CLEAR_CIRCLE_REQUESTED, data: {} })
}

const FETCH_CIRCLE_REQUESTED = 'FETCH_CIRCLE_REQUESTED'
const FETCH_CIRCLE_SUCCESS = 'FETCH_CIRCLE_SUCCESS'
const FETCH_CIRCLE_FAILED = 'FETCH_CIRCLE_FAILED'

export const fetchCircle = (id) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLE_REQUESTED, data: {} })
	circle.fetchCircle(id).then((circle) => {
		if (circle && circle.isSuccessful) {
			return dispatch({ type: FETCH_CIRCLE_SUCCESS, data: circle })
		} else {
			history.push('/circles')
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.circle.failedToFetch`, isSuccess: false }
			})
		}
	})
	return
}

const SET_CIRCLE_INVITATION_MEMBER_REQUESTED = 'SET_CIRCLE_INVITATION_MEMBER_REQUESTED'
const SET_CIRCLE_INVITATION_MEMBER_SUCCESS = 'SET_CIRCLE_INVITATION_MEMBER_SUCCESS'
const SET_CIRCLE_INVITATION_MEMBER_FAILED = 'SET_CIRCLE_INVITATION_MEMBER_FAILED'

export const inviteCircleMember = (email, circleId, languageId, sponsored, caseFunding) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.inviteCircleMember(user.id, email, circleId, languageId, sponsored, caseFunding).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch({
				type: SET_CIRCLE_INVITATION_MEMBER_SUCCESS,
				data: response
			})
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.circle.inviteSuccessful`,
					isSuccess: true
				}
			})
			return dispatch(hideModal())
		} else {
			dispatch({
				type: SET_CIRCLE_INVITATION_MEMBER_FAILED,
				data: {}
			})
			if (response && response.isIneligible) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: `app.circle.failedToInviteIneligibleMember`,
						isSuccess: false
					}
				})
			}
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: response && response.errorCode === 15 
					? 'app.circle.failedToInviteMemberNTO' 
					: `app.circle.failedToInviteMember`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const SEND_AND_ACCEPT_CIRCLE_INVITATION_REQUESTED = 'SEND_AND_ACCEPT_CIRCLE_INVITATION_REQUESTED'
const SEND_AND_ACCEPT_CIRCLE_INVITATION_SUCCESS = 'SEND_AND_ACCEPT_CIRCLE_INVITATION_SUCCESS'
const SEND_AND_ACCEPT_CIRCLE_INVITATION_FAILED = 'SEND_AND_ACCEPT_CIRCLE_INVITATION_FAILED'

export const sendAndAcceptCircleInvitation = (data) => (dispatch) => {
	dispatch({ type: SEND_AND_ACCEPT_CIRCLE_INVITATION_REQUESTED, data: {} })

	return invitations.sendAndAcceptCircleInvitation(data).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: SEND_AND_ACCEPT_CIRCLE_INVITATION_SUCCESS, data: {} })
		} else {
			dispatch({
				type: SEND_AND_ACCEPT_CIRCLE_INVITATION_FAILED,
				data: {}
			})
			if (response && response.isIneligible) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: `app.circle.failedToInviteIneligibleMember`,
						isSuccess: false
					}
				})
			} else if (response && response.errorCode === 18) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: `app.circle.failedToInviteNoSubscriptions`,
						isSuccess: false
					}
				})
			} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: response && response.errorCode === 15
							? 'app.circle.failedToInviteMemberNTO'
							: `app.circle.failedToInviteMember`,
						isSuccess: false
					}
				})
			}
		}

		history.push('/')
	})
}

const FETCH_CIRCLE_INVITATIONS_REQUESTED = 'FETCH_CIRCLE_INVITATIONS_REQUESTED'
const FETCH_CIRCLE_INVITATIONS_SUCCESS = 'FETCH_CIRCLE_INVITATIONS_SUCCESS'
const FETCH_CIRCLE_INVITATIONS_FAILED = 'FETCH_CIRCLE_INVITATIONS_FAILED'

export const getCircleMembersById = (id, entity) => (dispatch, state) => {
	if (!id) {
		return dispatch({ type: FETCH_CIRCLE_INVITATIONS_REQUESTED, data: {} })
	}
	return circle.fetchCircleMembers(id, entity).then((response) => {
		if (response && response.isSuccessful) {

            let currentState = state()

			let items = applyCircleMemberSort(response.members, currentState.circle.memberSortField, currentState.circle.memberIsDescending);

            return dispatch({
				type: FETCH_CIRCLE_INVITATIONS_SUCCESS,
				data: items.items
			})
		} else {
			return dispatch({ type: FETCH_CIRCLE_INVITATIONS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_TREATMENT_REQUESTED = 'FETCH_TREATMENT_REQUESTED'
const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS'
const FETCH_TREATMENT_FAILED = 'FETCH_TREATMENT_FAILED'

export const getTreatmentById = (id) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_TREATMENT_SUCCESS, data: null });
	}
	else
	{
		circle.fetchTreatment(id).then((response) => {
			if (response && response.isSuccessful) {
				if (response.treatment) {
					dispatch({ type: FETCH_TREATMENT_SUCCESS, data: response.treatment })
				}
			} else {
				dispatch({ type: FETCH_TREATMENT_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.treatments.loadingFailed`, isSuccess: false }
				})
			}
		})
	}
	return
}

const FETCH_PATHOLOGY_REQUESTED = 'FETCH_PATHOLOGY_REQUESTED'
const FETCH_PATHOLOGY_SUCCESS = 'FETCH_PATHOLOGY_SUCCESS'
const FETCH_PATHOLOGY_FAILED = 'FETCH_PATHOLOGY_FAILED'

export const getPathologyById = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: null });
	}
	else
	{
		circle.fetchPathology(id).then((response) => {
			if (response && response.isSuccessful) {
				if (response.pathology) {
					dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: response.pathology })
				}
			} else {
				dispatch({ type: FETCH_PATHOLOGY_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
				})
			}
		})
	}
	return
}

// const FETCH_CIRCLES_FILTER_REQUESTED = 'FETCH_CIRCLES_FILTER_REQUESTED'
// const FETCH_CIRCLES_FILTER_SUCCESS = 'FETCH_CIRCLES_FILTER_SUCCESS'
// const FETCH_CIRCLES_FILTER_FAILED = 'FETCH_CIRCLES_FILTER_FAILED'

// export const fetchCircleFilters = () => (dispatch) => {
// 	dispatch({ type: FETCH_CIRCLES_FILTER_REQUESTED, data: {} })

// 	const options = [
// 		{ option: 'ALL', label: 'All Circles' },
// 		{ option: 'SPONSORED', label: 'Sponsored Circles' },
// 		{ option: 'MANAGE', label: 'Circles I Manage' },
// 		{ option: 'JOINED', label: 'Joined Circles' },
// 		{ option: 'ARCHIVED', label: 'Archived Circles' }
// 	]
// 	return dispatch({ type: FETCH_CIRCLES_FILTER_SUCCESS, data: options })
// }

const SET_CIRCLES_FILTER_REQUESTED = 'SET_CIRCLES_FILTER_REQUESTED'
const SET_CIRCLES_FILTER_SUCCESS = 'SET_CIRCLES_FILTER_SUCCESS'
const SET_CIRCLES_FILTER_FAILED = 'SET_CIRCLES_FILTER_FAILED'

export const setCircleFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_CIRCLES_FILTER_REQUESTED, data: {} })
	dispatch({ type: SET_CIRCLES_FILTER_SUCCESS, data: filter })
	return dispatch(fetchCircles(state.circle.circleSearchTerm))
}

const UPLOAD_CIRCLE_BACKGROUND_REQUESTED = 'UPLOAD_CIRCLE_BACKGROUND_REQUESTED'
const UPLOAD_CIRCLE_BACKGROUND_SUCCESS = 'UPLOAD_CIRCLE_BACKGROUND_SUCCESS'
const UPLOAD_CIRCLE_BACKGROUND_FAILED = 'UPLOAD_CIRCLE_BACKGROUND_FAILED'

export const setBackground = (entity, id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_CIRCLE_BACKGROUND_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return circle.setBackgroundImage(entity, id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({
				type: UPLOAD_CIRCLE_BACKGROUND_SUCCESS,
				data: response
			})
		} else {
			return dispatch({ type: UPLOAD_CIRCLE_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const RESET_CIRCLE_BACKGROUND_REQUESTED = 'RESET_CIRCLE_BACKGROUND_REQUESTED'
const RESET_CIRCLE_BACKGROUND_SUCCESS = 'RESET_CIRCLE_BACKGROUND_SUCCESS'
const RESET_CIRCLE_BACKGROUND_FAILED = 'RESET_CIRCLE_BACKGROUND_FAILED'

export const resetBackground = (entity, id, onImageUpdated) => (dispatch) => {
	dispatch({ type: RESET_CIRCLE_BACKGROUND_REQUESTED, data: {} })

	return circle.resetBackgroundImage(entity, id).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({
				type: RESET_CIRCLE_BACKGROUND_SUCCESS,
				data: response
			})
		} else {
			return dispatch({ type: RESET_CIRCLE_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_CIRCLE_DETAILS_REQUESTED = 'UPDATE_CIRCLE_DETAILS_REQUESTED'
const UPDATE_CIRCLE_DETAILS_SUCCESS = 'UPDATE_CIRCLE_DETAILS_SUCCESS'
const UPDATE_CIRCLE_DETAILS_FAILED = 'UPDATE_CIRCLE_DETAILS_FAILED'

export const updateCircleDetailsById = (circleDetails) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_DETAILS_REQUESTED, data: {} })
	return circle.updateCircleDetailsById(circleDetails).then((circleDetails) => {
		if (circleDetails) {
			dispatch(hideModal())
			if (circleDetails.isArchived) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.circle.circleArchived',
						isSuccess: true
					}
				})
				history.push('/circles')
			} else {
				return dispatch({
					type: UPDATE_CIRCLE_DETAILS_SUCCESS,
					data: circleDetails
				})
			}
		} else {
			return dispatch({
				type: UPDATE_CIRCLE_DETAILS_FAILED,
				data: {}
			})
		}
	})
}

const UPDATE_CIRCLE_TREATMENT_REQUESTED = 'UPDATE_CIRCLE_TREATMENT_REQUESTED'
const UPDATE_CIRCLE_TREATMENT_SUCCESS = 'UPDATE_CIRCLE_TREATMENT_SUCCESS'
const UPDATE_CIRCLE_TREATMENT_FAILED = 'UPDATE_CIRCLE_TREATMENT_FAILED'

export const updateCircleTreatment = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TREATMENT_REQUESTED, data: {} })
	return circle.updateCircleTreatment(data).then((response) => {
		if (response) {
			dispatch(hideModal())
			return dispatch({
				type: UPDATE_CIRCLE_TREATMENT_SUCCESS,
				data: data.treatmentId
			})
		} else {
			return dispatch({
				type: UPDATE_CIRCLE_TREATMENT_FAILED,
				data: {}
			})
		}
	})
}

const UPDATE_CIRCLE_PATHOLOGY_REQUESTED = 'UPDATE_CIRCLE_PATHOLOGY_REQUESTED'
const UPDATE_CIRCLE_PATHOLOGY_SUCCESS = 'UPDATE_CIRCLE_PATHOLOGY_SUCCESS'
const UPDATE_CIRCLE_PATHOLOGY_FAILED = 'UPDATE_CIRCLE_PATHOLOGY_FAILED'

export const updateCirclePathology = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_PATHOLOGY_REQUESTED, data: {} })
	return circle.updateCirclePathology(data).then((response) => {
		if (response) {
			dispatch(hideModal())
			return dispatch({
				type: UPDATE_CIRCLE_PATHOLOGY_SUCCESS,
				data: data.pathologyId
			})
		} else {
			return dispatch({
				type: UPDATE_CIRCLE_PATHOLOGY_FAILED,
				data: {}
			})
		}
	})
}

const RESEND_CIRCLE_INVITATION_REQUESTED = 'RESEND_CIRCLE_INVITATION_REQUESTED'
const RESEND_CIRCLE_INVITATION_SUCCESS = 'RESEND_CIRCLE_INVITATION_SUCCESS'
const RESEND_CIRCLE_INVITATION_FAILED = 'RESEND_CIRCLE_INVITATION_FAILED'

export const resendCircleInvitationEmail = (invitationId, circleId) => (dispatch) => {
	dispatch({ type: RESEND_CIRCLE_INVITATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.resendCircleInvitation(invitationId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMembeResendInvitateSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMembeResendInvitateFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const SET_CIRCLE_MEMBERS_KEY_REQUESTED = 'SET_CIRCLE_MEMBERS_KEY_REQUESTED'
const SET_CIRCLE_MEMBERS_KEY_SUCCESS = 'SET_CIRCLE_MEMBERS_KEY_SUCCESS'
const SET_CIRCLE_MEMBERS_KEY_FAILED = 'SET_CIRCLE_MEMBERS_KEY_FAILED'

export const setCircleMemberKey = (searchTerm) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_MEMBERS_KEY_REQUESTED, data: {} })
	return dispatch({ type: SET_CIRCLE_MEMBERS_KEY_SUCCESS, data: searchTerm })
}

const SET_CIRCLE_SEARCH_TERM_REQUESTED = 'SET_CIRCLE_SEARCH_TERM_REQUESTED'
const SET_CIRCLE_SEARCH_TERM_SUCCESS = 'SET_CIRCLE_SEARCH_TERM_SUCCESS'
const SET_CIRCLE_SEARCH_TERM_FAILED = 'SET_CIRCLE_SEARCH_TERM_FAILED'

export const setCircleSearchTerm = (searchTerm) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_CIRCLE_SEARCH_TERM_SUCCESS, data: searchTerm })
}

const REINSTATE_CIRCLE_REQUESTED = 'REINSTATE_CIRCLE_REQUESTED'
const REINSTATE_CIRCLE_SUCCESS = 'REINSTATE_CIRCLE_SUCCESS'
const REINSTATE_CIRCLE_FAILED = 'REINSTATE_CIRCLE_FAILED'

export const reinstateCircle = (circleId) => (dispatch) => {
	dispatch({ type: REINSTATE_CIRCLE_REQUESTED, data: {} })

	return circle.reinstateCircleArchive(circleId).then((circle) => {
		if (circle && circle.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circles.reinstatedSuccess',
					isSuccess: true
				}
			})
			return dispatch({ type: REINSTATE_CIRCLE_SUCCESS, data: circle })
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circles.reinstatedFailed',
					isSuccess: true
				}
			})
			return dispatch({ type: REINSTATE_CIRCLE_FAILED, data: {} })
		}
	})
}

const MAKE_CIRCLE_ADMINISTRATOR_REQUESTED = 'MAKE_CIRCLE_ADMINISTRATOR_REQUESTED'
const MAKE_CIRCLE_ADMINISTRATOR_SUCCESS = 'MAKE_CIRCLE_ADMINISTRATOR_SUCCESS'
const MAKE_CIRCLE_ADMINISTRATOR_FAILED = 'MAKE_CIRCLE_ADMINISTRATOR_FAILED'

export const makeAdministratorMember = (userId, circleId) => (dispatch) => {
	dispatch({ type: MAKE_CIRCLE_ADMINISTRATOR_REQUESTED, data: {} })
	return circle.makeAdminMemberByCircleUserId(userId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.assignAdministratorSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.assignAdministratorFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}
const REMOVE_USER_CIRCLE_REQUESTED = 'REMOVE_USER_CIRCLE_REQUESTED'
const REMOVE_USER_CIRCLE_SUCCESS = 'REMOVE_USER_CIRCLE_SUCCESS'
const REMOVE_USER_CIRCLE_FAILED = 'REMOVE_USER_CIRCLE_FAILED'

export const removeUserCircleMember = (member, circleId) => (dispatch, getState) => {
	dispatch({ type: REMOVE_USER_CIRCLE_REQUESTED, data: {} })
	let state = getState()
	if (
		state &&
		state.circle &&
		state.circle.circle &&
		state.circle.circle.id === circleId &&
		state.circle.circle.founder &&
		state.circle.circle.administrator &&
		member
	) {
		let circleItem = state.circle.circle
		if (member.userId === circleItem.founder.id || member.userId === circleItem.administrator.id) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.removeUserCircleFailed',
					isSuccess: false
				}
			})
		}
	}
	return circle.removeUserCircleById(member.email, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.removeUserCircleSuccess',
					isSuccess: true
				}
			})
			return dispatch({
				type: REMOVE_USER_CIRCLE_SUCCESS,
				data: member.email
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.removeUserCircleFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const LEAVE_CIRCLE_REQUESTED = 'LEAVE_CIRCLE_REQUESTED'
const LEAVE_CIRCLE_SUCCESS = 'LEAVE_CIRCLE_SUCCESS'
const LEAVE_CIRCLE_FAILED = 'LEAVE_CIRCLE_FAILED'

export const leaveCircle = (circleId) => (dispatch) => {
	dispatch({ type: LEAVE_CIRCLE_REQUESTED, data: {} })
	return circle.submitLeaveCircleById(circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({ type: LEAVE_CIRCLE_SUCCESS, data: circleId })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.circle.leaveCircleFailed',
					isSuccess: false
				}
			})
		}
	})
}

const CAN_LEAVE_CIRCLE_REQUESTED = 'CAN_LEAVE_CIRCLE_REQUESTED'
const CAN_LEAVE_CIRCLE_SUCCESS = 'CAN_LEAVE_CIRCLE_SUCCESS'
const CAN_LEAVE_CIRCLE_FAILED = 'CAN_LEAVE_CIRCLE_FAILED'

export const canLeaveCircle = (circleId) => (dispatch) => {
	dispatch({ type: CAN_LEAVE_CIRCLE_REQUESTED, data: {} })
	return circle.canLeaveCircleById(circleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: CAN_LEAVE_CIRCLE_SUCCESS, data: response })
		} else {
			return dispatch({ type: CAN_LEAVE_CIRCLE_FAILED, data: response })
		}
	})
}

const DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED = 'DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED'
const DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS = 'DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS'
const DISTRIBUTE_CIRCLE_RESOURCES_FAILED = 'DISTRIBUTE_CIRCLE_RESOURCES_FAILED'

export const distributeResources = (circleId, numberUsers, numberCases) => (dispatch) => {
	dispatch({ type: DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED, data: {} })
	return resources.distribute(circleId, numberUsers, numberCases).then((response) => {
		if (response) {
			dispatch({
				type: DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS,
				data: response
			})
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'Distributed Successfully',
					isSuccess: true
				}
			})
		} else {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${response.errorCode}`,
					isSuccess: false
				}
			})
		}
	})
}

const CHANGE_CASE_FUNDING_REQUESTED = 'CHANGE_CASE_FUNDING_REQUESTED'
const CHANGE_CASE_FUNDING_SUCCESS = 'CHANGE_CASE_FUNDING_SUCCESS'
const CHANGE_CASE_FUNDING_FAILED = 'CHANGE_CASE_FUNDING_FAILED'

export const changeCaseFunding = (circleId, userId, value) => (dispatch) => {
	dispatch({ type: CHANGE_CASE_FUNDING_REQUESTED, data: {} })
	return circle.changeCaseFunding(circleId, userId, value).then((response) => {
		if (response) {
			dispatch(getCircleMembersById(circleId))
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingSuccess', isSuccess: true }
			})

			return dispatch({
				type: CHANGE_CASE_FUNDING_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingError', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_CASE_FUNDING_FAILED,
				data: {}
			})
		}
	})
}

const FETCH_CASE_FUNDING_SETTINGS_REQUESTED = 'FETCH_CASE_FUNDING_SETTINGS_REQUESTED'
const FETCH_CASE_FUNDING_SETTINGS_SUCCESS = 'FETCH_CASE_FUNDING_SETTINGS_SUCCESS'
const FETCH_CASE_FUNDING_SETTINGS_FAILED = 'FETCH_CASE_FUNDING_SETTINGS_FAILED'

export const fetchCircleCaseFundingSettings = (id) => (dispatch) => {
	dispatch({ type: FETCH_CASE_FUNDING_SETTINGS_REQUESTED, data: {} })
	circle.fetchCircleCaseFundingSettings(id).then((circle) => {
		if (circle && circle.isSuccessful) {
			return dispatch({ type: FETCH_CASE_FUNDING_SETTINGS_SUCCESS, data: circle })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.circle.failedToFetch`, isSuccess: false }
			})
		}
	})
	return
}

const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED'
const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS'
const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED'

export const getCircleMemberPatientInvitationLink = (circleId, member) => (dispatch) => {
	dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED, data: {} })
	
	circle.fetchCircleMemberPatientInvitationLink(circleId, member.userId)
		.then((response) => {
			if (
				response
				&& response.isSuccessful
			) {
				navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                    if (result.state == 'granted' || result.state == 'prompt') {
                        navigator.clipboard.writeText(response.link).then(
                            function () {
                                return dispatch({
                                    type: 'SET_SNACKBAR_MESSAGE',
                                    data: { message: 'app.circle.members.generatedLinkCopiedToClipboard', isSuccess: true }
                                })
                            },
                            function () {
                                /* clipboard write failed */
                            }
                        )
                    } else {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circle.members.generateLinkAccessToClipboardIsDenied', isSuccess: false }
                        })
                    }
                }).catch(() => {
                    let successful = false;

                    try
                    {
                        var textArea = document.createElement("textarea");
                        textArea.value = response.link;

                        textArea.style.top = "0";
                        textArea.style.left = "0";
                        textArea.style.position = "fixed";

                        document.body.appendChild(textArea);

                        textArea.focus();
                        textArea.select();

                        successful = document.execCommand('copy');

                        document.body.removeChild(textArea);
                    }
                    catch (err)
                    {
                        console.error('Unable to copy', err);
                    }

                    if (successful) {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circle.members.generatedLinkCopiedToClipboard', isSuccess: true }
                        })
                    }
                    else
                    {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circle.members.generateLinkFailedToGenerateLink', isSuccess: false }
                        })
                    }
                })

				return dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS, data: {} })
			} else {
				return dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED, data: {} })
			}
		})
}

const SET_MEMBER_CASES_RATE_REQUESTED = 'SET_MEMBER_CASES_RATE_REQUESTED'
const SET_MEMBER_CASES_RATE_SUCCESS = 'SET_MEMBER_CASES_RATE_SUCCESS'
const SET_MEMBER_CASES_RATE_FAILED = 'SET_MEMBER_CASES_RATE_FAILED'

export const setMemberCasesRate = (circleId, member, casesRate) => (dispatch) => {
	dispatch({ type: SET_MEMBER_CASES_RATE_REQUESTED, data: {} })

	circle.changeMemberCasesRate(circleId, member.userId, casesRate)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: SET_MEMBER_CASES_RATE_SUCCESS, data: {} })

				dispatch(hideModal())

				dispatch(fetchCircle(circleId))

				dispatch(getCircleMembersById(circleId))

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.members.casesRateSuccessfullySaved', isSuccess: true }
				})
			} else {
				dispatch({ type: SET_MEMBER_CASES_RATE_FAILED, data: {} })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.members.failedToSaveCasesRate', isSuccess: false }
				})
			}
		})
}

const EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED'
const EXPORT_NON_COMPLIANT_SUBSCRIBERS_SUCCESS = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_SUCCESS'
const EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED'

export const exportNonComplainSubscribers = (circleId, startDate, endDate) => (dispatch) => {
	dispatch({ type: EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED, data: {} })

	circle.exportNonComplainSubscribers(circleId, startDate, endDate)
		.then((response) => {
			if (
				!!response
				&& !!response.isSuccessful
			) {
				dispatch({ type: EXPORT_NON_COMPLIANT_SUBSCRIBERS_SUCCESS })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.exportSuccess', isSuccess: true }
				})
			} else {
				dispatch({ type: EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.exportFailed', isSuccess: false }
				})
			}
		})
}


const initial = {
	circles: [],
	circle: null,
	filter: 0,
	searchTerm: '',
    memberSortField: 'dateJoinedSeconds',
    memberIsDescending: false,
	sortField: 'modifiedDateSeconds',
    isDescending: true,
	circleInvitations: [],
	isCreating: false,
	autoCompleteSuggestions: [],
	sharedWithAutoCompleteSuggestions: [],
	canLeaveCircle: null,
	circlesWithCase: null,
	sponsoredCircles: [],
	sortSponsoredField: 'modifiedDateSeconds',
	isSponsoredDescending: true,
	treatment: null,
	pathology: null,
	hasMore: true,
	isLoading: false,
	circleSearchTerm: '',
	isInviting: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_SPONSORED_CIRCLES_SUCCESS:
			return {
				...state,
				sponsoredCircles: action.data
			}
		case SET_CIRCLE_SORT_REQUESTED:
			return {
				...state,
				circles: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_INVITATIONS_SORT_REQUESTED:
			return {
				...state,
				circleInvitations: [ ...action.data.items ],
                memberSortField: action.data.field,
                memberIsDescending: action.data.isDescending
			}
		case SET_SPONSORED_CIRCLE_SORT_REQUESTED:
			return {
				...state,
				sponsoredCircles: [ ...action.data.items ],
				sortSponsoredField: action.data.field,
				isSponsoredDescending: action.data.isDescending
			}
		case SET_CIRCLES_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }

		case FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, sharedWithAutoCompleteSuggestions: action.data }
		case FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED:
			return { ...state, sharedWithAutoCompleteSuggestions: [] }

		case ADD_CIRCLE_SUCCESS:
			return {
				...state,
				isCreating: false,
				circleInvitations: [
					...state.circleInvitations.slice(0, action.data),
					action.data,
					...state.circleInvitations.slice(action.pos)
				]
			}
		case ADD_CIRCLE_FAILED:
			return {
				...state,
				isCreating: false
			}
		case FETCH_CIRCLES_SUCCESS:
			return {
				...state,
				circles: [ ...action.data.items ],
				hasMore: action.data.hasMore,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case FETCH_ADDITIONAL_CIRCLES_SUCCESS:
			return {
				...state,
				circles: [...state.circles, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_CIRCLES_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_CIRCLES_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_CIRCLES_WITH_CASE_SUCCESS:
			return { ...state, circlesWithCase: action.data }
		case FETCH_CIRCLE_SUCCESS:
			return { ...state, circle: action.data }
		case CLEAR_CIRCLE_REQUESTED:
			return { ...state, circle: null }
		case FETCH_CIRCLE_INVITATIONS_SUCCESS:
			return { ...state, circleInvitations: action.data }
		case SET_CIRCLE_INVITATION_MEMBER_REQUESTED:
			return { ...state, isInviting: true }
		case SET_CIRCLE_INVITATION_MEMBER_SUCCESS:
			return { ...state, isInviting: false }
		case SET_CIRCLE_INVITATION_MEMBER_FAILED:
			return { ...state, isInviting: false }
		case UPDATE_CIRCLE_DETAILS_SUCCESS:
			return { ...state, circle: action.data }
		case UPDATE_CIRCLE_TREATMENT_SUCCESS:
			return { ...state, circle: {...state.circle, treatmentId: action.data }}
		case UPDATE_CIRCLE_PATHOLOGY_SUCCESS:
			return { ...state, circle: {...state.circle, pathologyId: action.data }}
		case FETCH_TREATMENT_SUCCESS:
			return { ...state, treatment: action.data }
		case FETCH_PATHOLOGY_SUCCESS:
			return { ...state, pathology: action.data }
		case RESEND_CIRCLE_INVITATION_SUCCESS:
			return { ...state, circle: action.data }
		case SET_CIRCLE_MEMBERS_KEY_SUCCESS:
			return { ...state, searchTerm: action.data }
		case SET_CIRCLE_SEARCH_TERM_SUCCESS:
			return { ...state, circleSearchTerm: action.data }
		case REINSTATE_CIRCLE_SUCCESS:
			return {
				...state,
				circles: state.circles.map(
					(circle) =>
						circle.id === action.data.id ? { ...circle, isArchived: action.data.isArchived } : circle
				)
			}
		case LEAVE_CIRCLE_SUCCESS:
			return {
				...state,
				circles: state.circles.filter((item) => item.id !== action.data)
			}
		case REMOVE_USER_CIRCLE_SUCCESS:
			return {
				...state,
				circleInvitations: state.circleInvitations.filter((item) => item.email !== action.data)
			}
		case DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS:
			return {
				...state,
				circles: state.circles.map(
					(circle) =>
						circle.id === action.data.id
							? {
									...circle,
									numberUsers: action.data.numberUsers,
									numberCases: action.data.numberCases
								}
							: circle
				)
			}
		case ADD_CIRCLE_REQUESTED:
			return { ...state, isCreating: true }
		case CAN_LEAVE_CIRCLE_SUCCESS:
			return { ...state, canLeaveCircle: action.data }
		case CAN_LEAVE_CIRCLE_FAILED:
			return { ...state, canLeaveCircle: action.data }
		case FETCH_CASE_FUNDING_SETTINGS_SUCCESS:
			return { ...state, circle: { ...state.circle, canChangeCaseFunding: action.data.canChangeCaseFunding, defaultCaseFunding: action.data.defaultCaseFunding }}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.circle
const coreSelector = (state) => state.core
const stateSelector = (state) => state

export const isCreatingSelector = createSelector(mainSelector, (state) => state.isCreating)

export const circlesSelector = createSelector(mainSelector, (state) => state.circles)
export const circleSelector = createSelector(mainSelector, (state) => state.circle)
export const treatmentSelector = createSelector(mainSelector, (state) => state.treatment)
export const pathologySelector = createSelector(mainSelector, (state) => state.pathology)
export const circleInvitationsSelector = createSelector(mainSelector, (state) => {
	if (state && state.circleInvitations && state.searchTerm) {
		let searchTerm = state.searchTerm.toLowerCase()
		return state.circleInvitations.filter((f) => {
			if (f.memberName) {
				return f.email && f.email.toLowerCase().includes(searchTerm) || f.memberName.toLowerCase().includes(searchTerm)
			} else {
				return f.email && f.email.toLowerCase().includes(searchTerm)
			}
		})
	} else {
		return state.circleInvitations
	}
})
export const circlesFilterSelector = createSelector(mainSelector, (state) => state.filters)
export const canEditCircleBackgroundImageSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) {
			return true
		}
	}
	return false
})

export const canEditCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) {
			return true
		}
	}
	return false
})

export const canAddCircleMemberSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) {
			return true
		}
	}

	return false
})

export const canManageCirclePlanSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) {
			return true
		}
	}

	return false
})
export const canLeaveCircleSelector = createSelector(mainSelector, (state) => state.canLeaveCircle)

export const circlesWithCaseSelector = createSelector(mainSelector, (state) => state.circlesWithCase)

// export const canLeaveCircleSelector = createSelector(mainSelector, (state) => {
// let user = getUser()

// if (user && state)
// 	if (state.founder && state.founder.id !== user.id)
// 		return true
// 	if(state && state.roleName !== 'Founder')
// 		return true

// 	return false
// })
export const canInviteMemberCircleSelector = createSelector(stateSelector, (state) => {
	// let user = getUser()
	if (state && state.circle) {
		// if (state.subscriptionUser && state.subscriptionUser.availableUsers === 0) return false
		// if (state.circle.founder && state.circle.founder.id === user.id) return true
		// if (state.circle.administrator && state.circle.administrator.id === user.id) return true
		if (state.circle.roleName === 'Administrator' || state.circle.roleName === 'Founder') return true
	}
	return false
})
export const canEmailAdministratorSelector = createSelector(mainSelector, (state) => {
	// let user = getUser()
	// if (user && state) if (state.founder && state.founder.id !== user.id) return true

	if (state && state.roleName !== 'Founder' && state.roleName !== 'Administrator') return true

	return false
})
export const isAdministratorSelector = createSelector(mainSelector, (state) => {
	if (state && state.roleName === 'Administrator') return true

	return false
})
export const canReinstateCircleSelector = createSelector(mainSelector, (state) => {
	// let user = getUser()
	// if (user && state) if (state.isArchived && state.founder && user.id === state.founder.id) return true
	if (state && state.isArchived && state.roleName === 'Founder') return true
	return false
})
export const invitedCircleMemberSelector = createSelector(coreSelector, (state) => {
	if (state && state.params && state.params.invitedCircle) {
		return state.params.invitedCircle
	}
	return null
})
export const canMakeAdministratorSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner) && state.member.status === 1)
				return true
	return false
})
export const canResendEmailInvitationSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner) && state.member.status === 0)
				return true
	return false
})
export const canRemoveUserCircleSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) return true
	return false
})
export const canChangeCircleCaseFundingSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member && state.circle.canChangeCaseFunding)
			if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) return true
	return false
})

export const filteredCirclesSelector = createSelector(mainSelector, (state) => {
	let data = state.circles ? state.circles : null

	if (data && data.length > 0) {
		switch (state.filter) {
			case 0:
				return data.filter((f) => !f.isArchived)
			case 1:
				return data.filter((f) => f.isFounder && (f.numberCases > 0 || f.numberUsers > 0))
			case 2:
				return data.filter((f) => f.isAdministrator)
			case 3:
				return data.filter((f) => !f.isFounder && !f.isAdministrator)
			case 4:
				return data.filter((f) => f.isArchived)
			default:
				return data
		}
	} else {
		return []
	}
})
export const filteredCirclesWithCaseSelector = createSelector(mainSelector, (state) => {
	let data = state.circlesWithCase ? state.circlesWithCase : []

	if (data && data.length > 0) {
		if (data) {
			switch (state.filter) {
				case 0:
					return data.filter((f) => !f.isArchived)
				case 'SPONSORED':
					return data.filter((f) => f.isSponsored)
				case 'MANAGE':
					return data
				case 'JOINED':
					return data.filter((f) => f.dateJoined)
				case 'ARCHIVED':
					return data.filter((f) => f.isArchived)
				default:
					return data
			}
		}
	} else {
		return []
	}
})

export const sharedWithCircleAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.sharedWithAutoCompleteSuggestions) {
		return state.sharedWithAutoCompleteSuggestions
	}
	return []
})

export const circleAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const canViewCircleMemberSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (
		state &&
		state.circle &&
		state.circle.isArchived & state.circle.founder &&
		state.circle.founder.id === user.id
	) {
		return false
	}
	return true
})
export const isAdminCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && (state.circle.administrator.id === user.id || state.circle.administrator.id === user.teamOwner)) {
			return true
		}
	}
	return false
})

export const formattedCircleProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.circles && state.circles.length > 0
		? state.circles.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					observationalProtocol: r.observationalProtocol,
					label: r.observationProtocol ? `${r.observationProtocol.name} | ${r.name}` : r.name,
					id: r.id,
					protocolId: r.observationProtocol.id,
					isPhiFree: r.phi
				}
			})
		: []
})

export const formattedCirclesSelector = createSelector(mainSelector, (state) => {
	return state.circles && state.circles.length > 0
		? state.circles.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					label: r.founder ? `${r.name} | ${r.founder.firstName} ${r.founder.lastName}` : r.name,
					id: r.id,
					isPhiFree: r.phi
				}
			})
		: []
})
export const canDistributeResourcesSelector = createSelector(mainSelector, (state) => {
	if (state) {
		if (state.isSponsored) return true
	}
	return false
})

export const sortCircleDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortCircleMembersDirectionSelector = createSelector(mainSelector, (state) => {
	return state.memberIsDescending
})

export const sortCircleMembersFieldSelector = createSelector(mainSelector, (state) => {
	return state.memberSortField
})

export const sortCircleFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const sponsoredCirclesSelector = createSelector(mainSelector, (state) => {
	return state.sponsoredCircles
})

export const sortSponsoredCircleDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isSponsoredDescending
})

export const sortSponsoredCircleFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortSponsoredField
})

export const circleHasRestrictionsSelector = createSelector(mainSelector, (state) => {
	return state.circle && state.circle.hasRestrictions
})

export const hasMoreCirclesSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingCirclesSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const circleSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.circleSearchTerm
})

export const isInvitingCircleMemberSelector = createSelector(mainSelector, (state) => {
	return state.isInviting
})
import React from 'react'
import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import AlertStatusCell from './OutlierStatusCell'
import AlertCell from './OutlierAlertCell'
import AlertTypeCell from './OutlierTypeCell'
import AlertDateCell from './OutlierAlertDateCell'
import AlertDelegateCell from './OutlierAlertDelegateCell'
import Menu from './OutlierAlertMenu'
import history from '../../../../utilities/history'

export default ({ alert }) => {
	const gotoCase = () => {
		history.push(
			`/cases/${alert.caseId}/survey/${alert.surveyId}/instance/${alert.surveyInstanceId}/delegate/${alert.delegateId}/status/1`
		)
	}

	return (
		<TableRow data-testid="myalerts-outlier-alert-row" style={{ cursor: 'pointer' }}>
			<TableCell
				data-testid="myalerts-outlier-alert-row-status"
				align="left" style={{ padding: '0', position: 'relative' }} onClick={gotoCase}>
				<div
					style={{
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px',
						backgroundColor: 'rgb(255, 133, 86)'
					}}></div>
				<div style={{ textAlign: 'center', paddingLeft: '16px' }}>
					<AlertStatusCell alert={alert} />
				</div>
			</TableCell>
			<TableCell
				data-testid="myalerts-outlier-alert-row-delegate"
				style={{ minWidth: '230px' }}
				size="small" align="left" onClick={gotoCase}>
				<AlertDelegateCell alert={alert} />
			</TableCell>
			<TableCell
				data-testid="myalerts-outlier-alert-row-type"
				size="small" onClick={gotoCase}>
				<AlertTypeCell alert={alert} />
			</TableCell>
			<TableCell
				data-testid="myalerts-outlier-alert-row-date"
				size="small" onClick={gotoCase}>
				<AlertDateCell alert={alert} />
			</TableCell>
			{/* <TableCell size="small" onClick={gotoCase}>
				<AlertDelegateCell alert={alert} />
			</TableCell> */}
			<TableCell
				data-testid="myalerts-outlier-alert-row-options"
				size="small" align="right">
				<Menu alert={alert} />
			</TableCell>
		</TableRow>
	)
}

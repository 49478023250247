import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { resendInvitation, resendSurveyReminder, showModal } from '../../../../redux/actions'
import { MenuItem } from '@material-ui/core'
import PatientHasIssuesWithEmailModal from '../../../caseDetails/components/PatientHasIssuesWithEmailModal'

const ITEM_HEIGHT = 48

export default ({ task }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()
	const intl = useIntl()

	const resendReminderHandler = () => {
		if (task) {
			if (task.hasIssuesWithEmail) {
				dispatch(showModal(PatientHasIssuesWithEmailModal, {
					callback: () => dispatch(resendSurveyReminder(task.caseId, task.instanceId)),
					sendSurveyReminderSms: task.sendReminderSms
				}))
			} else {
				dispatch(resendSurveyReminder(task.caseId, task.instanceId))
			}

			handleClose()
		}
	}

	const resendInvitationHandler = () => {
		if (task) {
			if(task.hasIssuesWithEmail) {
				dispatch(showModal(PatientHasIssuesWithEmailModal, {
					callback: () => dispatch(resendInvitation(task.patientId)),
					sendRegistrationSms: task.sendRegistrationSms, 
					isRegistration: true
				}))
			} else {
				dispatch(resendInvitation(task.patientId))
			}
			
			handleClose()
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div data-testid="cases-menu-wrapper">
			{task && (task.canResendReminder || task.canResendInvitation) && <div>
				<IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id='long-menu'
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200,
							backgroundColor: '#243041',
							color: '#fff'
						}
					}}>
					{task && task.canResendReminder &&
						<MenuItem
							data-testid="resend-reminder-menu-item"
							onClick={resendReminderHandler}>
							{intl.formatMessage({ id: 'app.tasks.resendReminderMenuItemLabel' })}
						</MenuItem>}
					{task && task.canResendInvitation &&
						<MenuItem
							data-testid="resend-invitation-menu-item"
							onClick={resendInvitationHandler}>
							{intl.formatMessage({ id: 'app.patients.resendInvitation' })}
						</MenuItem>}
				</MuiMenu>
			</div>}
		</div>
	)
}

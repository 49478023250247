import React, { useEffect, lazy, Suspense } from 'react'

import ProgressIndicator from '../../components/ProgressIndicator'
import componentLoader from '../../utilities/componentLoader'

const Patients = lazy(() => componentLoader(() => import('./components/Patients')))

export default () => (
	<div data-testid="patient">
		<Suspense fallback={<div />}>
			<section>
				<Patients />
			</section>
		</Suspense>
	</div>
)

import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Warning from '@material-ui/icons/Warning'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import * as cookies from '../utilities/cookies'
import { showModal, hideModal, signOut, redirectToUrl, refreshToken } from '../redux/actions'

const AutoLogoutAlertModal = ({ intl, hide, signOut, redirectToUrl, refreshAuthToken }) => {
    const dispatch = useDispatch()

    const inactivity_time_in_minutes_before_logout = 30;
    const inactivity_time_in_minutes_alert_starts_before_logout = 15;

    const [signedOut, setSignedOut] = useState(false)
    const [showingModal, setShowingModal] = useState(false)
    const [realLastActivity, setRealLastActivity] = useState(new Date())
    const [lastActivity, setLastActivity] = useState(new Date())
    const [activityUpdated, setActivityUpdated] = useState(new Date())

    let processLastActivity = () => {
        setLastActivity(new Date());
    }

    useEffect(
        () => {

            let user = cookies.get('user');

            if (!user || user.rememberMe) {
                return;
            }

            document.body.addEventListener('mousedown', processLastActivity);
            document.body.addEventListener('mousemove', processLastActivity);
            document.body.addEventListener('keydown', processLastActivity);

            const f = () => dispatch(function () {

                let user = cookies.get('user');

                if (!user || !user.rememberMe) {
                    setActivityUpdated(new Date());
                }
            });

            f();

            let timer = setInterval(f, 10000)

            return () => {
                window.removeEventListener('mousedown', processLastActivity);
                window.removeEventListener('mousemove', processLastActivity);
                window.removeEventListener('keydown', processLastActivity);

                clearInterval(timer)
            }
        }
        , []
    )

    useEffect(() => {

        let rla;

        if (!showingModal)
        {
            rla = lastActivity;

            setRealLastActivity(rla);
        }

        let minutes = (((new Date() - realLastActivity) % 86400000) % 3600000) / 60000;

        if (!signedOut) {
            if (minutes >= inactivity_time_in_minutes_before_logout) {
                signOut();
                setSignedOut(true);
            }
            else if (minutes >= inactivity_time_in_minutes_alert_starts_before_logout) {
                setShowingModal(true);
            }
        }

        if (signedOut ||
            minutes >= inactivity_time_in_minutes_before_logout ||
            minutes >= inactivity_time_in_minutes_alert_starts_before_logout) {
            dispatch(
                showModal(Modal,
                    {
                        intl,
                        minutes,
                        setLastActivity,
                        setRealLastActivity,
                        inactivity_time_in_minutes_before_logout,
                        hide,
                        setShowingModal,
                        signedOut,
                        redirectToUrl,
                        modalContainerStyle: { fullWidth: false },
                        refreshAuthToken
                    },
                    false))
        }
    },
        [activityUpdated]);

   return (<div></div>)
}

const Modal = ({
    intl,
    minutes,
    inactivity_time_in_minutes_before_logout,
    setLastActivity,
    setRealLastActivity,
    hide,
    setShowingModal,
    signedOut,
    redirectToUrl,
    refreshAuthToken
}) => {
    const theme = useTheme()

    let h = () => {
        let now = new Date();
        setShowingModal(false);
        setLastActivity(now);
        setRealLastActivity(now)
        hide();
        setShowingModal(false);
        setLastActivity(now);
        setRealLastActivity(now)
    }

    return (
        signedOut
            ? (<Grid
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='center'
                style={{ width: '100%', maxWidth: '400px', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.primary.light }}>
                <Grid item style={{ alignSelf: 'center' }}>
                    <Typography variant='subtitle2' style={{ textAlign: 'center' }}>
                        {intl.formatMessage({ id: 'app.general.sessionExpiration.expired.title' })}
                    </Typography>
                </Grid>
                <Grid item style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                    <Button
						variant="contained"
						color="primary"
                        type='button'
                        size='large'
                        onClick={() => {
                            h();
                            redirectToUrl(`/auth/login?returnUrl=${window.location.pathname}`);
                        }}
                    >
                        {intl.formatMessage({ id: 'app.general.sessionExpiration.expired.button' })}
                    </Button>
                </Grid>
            </Grid>)
            : (minutes &&
                <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='center'
                    style={{ width: '100%', maxWidth: '400px', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.primary.light }}>
                    <Grid item style={{ alignSelf: 'center' }}>
                        <Typography variant='h2' style={{ fontSize: '29px', marginBottom: '4rem' }}>
                            {intl.formatMessage({ id: 'app.general.sessionExpiration.title' })} 
                        </Typography>
                    </Grid>
                    <Grid item container style={{ textAlign: 'center', 'justify-content': 'center' }}>
                        <Warning style={{ fontSize: '20px', 'margin-right': '8px' }} />
                        <Typography variant='subtitle2' style={{ textAlign: 'center' }}>
                            {intl.formatMessage({ id: 'app.general.sessionExpiration.message' }, { n: Math.round(inactivity_time_in_minutes_before_logout - minutes) })}
                        </Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='button'
                            size='large'
                            onClick={() => {
                                refreshAuthToken();
                                h();
                            }}
                        >
                            {intl.formatMessage({ id: 'app.general.sessionExpiration.button' })}
                        </Button>
                    </Grid>
                </Grid>
            ))
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut(null, true)),
    hide: () => dispatch(hideModal()),
    redirectToUrl: (url) => dispatch(redirectToUrl(url)),
    refreshAuthToken: () => dispatch(refreshToken())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AutoLogoutAlertModal)

import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
	canEmailAdministratorSelector,
	canLeaveCircleSelector,
	canInviteMemberCircleSelector,
	canReinstateCircleSelector,
	canDistributeResourcesSelector
} from '../../../../redux/selectors'
import { reinstateCircle, showModal } from '../../../../redux/actions'
import InviteCircleMember from './InviteCircleMember'

const ITEM_HEIGHT = 48

const Options = ({
	circle,
	circleId,
	onInviteUserClick,
	intl,
	canViewDetails,
	canInviteMember,
	onReinstateCircleClick,
	user,
	onLeaveCircleClick,
	canLeave,
	canEmailAdmin,
	canInvite,
	canReinstate,
	canDistributeResources,
	onDistributeResourcesClick
}) => {
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleInviteClick = () => {
		if (circle) onInviteUserClick(circle.id, circle.numberUsers - circle.sponsoredUsers)
		handleClose()
	}

	const onMailAdministrator = () => {
		if (circle && circle.administratorEmail) window.location.href = `mailto:${circle.administratorEmail}`
		handleClose()
	}

	return (
		<div data-testid="sponsored-circles-options">
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				data-testid="sponsored-circles-options-menu"
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: 270, backgroundColor: '#243041' } }}
			>
				<MenuItem style={{color: '#fff'}}>
					<Link
						data-testid="view-details"
						to={`/circles/${circle.id}`}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24',
							width: '100%'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.viewDetails' })}
					</Link>
				</MenuItem>
				{canInvite && (
					<MenuItem
						data-testid="invite-member"
						onClick={handleInviteClick}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.inviteMember' })}
					</MenuItem>
				)}

				{canEmailAdmin && (
					<MenuItem
						data-testid="mail-admin"
						onClick={onMailAdministrator}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.mailAdministrator' })}
					</MenuItem>
				)}
			</Menu>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	canInvite: canInviteMemberCircleSelector(ownProps),
	canLeave: canLeaveCircleSelector(ownProps),
	canEmailAdmin: canEmailAdministratorSelector(ownProps),
	canReinstate: canReinstateCircleSelector(ownProps),
	canDistributeResources: canDistributeResourcesSelector(ownProps)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onInviteUserClick: (circleId, availableCredits) =>
		dispatch(showModal(InviteCircleMember, { circleId: circleId, availableCredits: availableCredits }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(Options)

import React from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

const MemberTableHeader = ({ intl }) => {
	return (
		<TableHead data-testid="member-table-head">
			<TableRow>
				<TableCell align='left' style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
				<TableCell size='small' align='left' style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
				<TableCell data-testid="member-table-head-header" align='left'>
					<Typography variant='caption'>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberNameHeader' })}
					</Typography>
				</TableCell>
                <TableCell data-testid="member-table-head-2fa-state" align='left'>
                    <Typography variant='caption'>
                        {intl.formatMessage({ id: 'app.userProfile.teamMemberTableMember2FAStateHeader' })}
                    </Typography>
                </TableCell>
				<TableCell data-testid="member-table-head-date-joined" align='left'>
					<Typography variant='caption'>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberDateJoinedHeader' })}
					</Typography>
				</TableCell>
				<TableCell align='right' />
			</TableRow>
		</TableHead>
	)
}

const enhance = compose(injectIntl)

export default enhance(MemberTableHeader)

import React, { useEffect, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearCircle } from '../../../redux/actions'

const CircleCell = ({ intl, circle, setHeightCallback }) => {
	let ref = React.createRef()
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				setHeightCallback(ref.current.offsetHeight)
			}
		},
		[ ref ]
	)

	return (
		<Grid data-testid="circle-cell" container direction='column' ref={ref} alignContent='flex-start' alignItems='flex-start'>
			<Grid item>
				<Link
					data-testid="circle-cell-link"
					id={`link${circle.id}`}
					onClick={() => dispatch(clearCircle())}
					to={`/circles/${circle.id}`}
					style={{
						display: 'block',
						textDecoration: 'none',
						fontWeight: '700',
						fontSize: '17px',
						lineHeight: '20px',
						letterSpacing: '0.16px',
						color: '#011020',
						marginBottom: '5px'
					}}>
					{circle && circle.name}
				</Link>
			</Grid>
			<Grid item>
				{circle &&
				circle.founderName && (
					<div>
						<Typography
							data-testid="circle-cell-founder-name"
							style={{
								textAlign: 'left',
								fontSize: '13px',
								letterSpacing: 0.16,
								lineHeight: '16px',
								wordBreak: 'break-word',
								color: '#011020'
							}}
							id={`founderName${circle.id}`}>
							{intl.formatMessage({ id: 'app.circle.founded' })} {circle.founderName}
							{circle.founderCredentials ? `, ${circle.founderCredentials}` : null}
						</Typography>
						<Typography
							data-testid="circle-cell-founder-email"
							style={{
								textAlign: 'left',
								fontSize: '13px',
								letterSpacing: 0.16,
								lineHeight: '16px',
								wordBreak: 'break-word',
								color: '#011020'
							}}
							id={`founderEmail${circle.id}`}>
							{circle.founderEmail}
						</Typography>
					</div>
				)}
			</Grid>
		</Grid>
	)
}

export default injectIntl(CircleCell)

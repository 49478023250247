import React from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Check from '../assets/check'
import { clearSnackbarMessage } from '../redux/actions'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

const styles = (theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
		zIndex: 111
	}
})

const Snackbar = ({ clearMessage, message, intl, success }) => {
	const onCloseSnack = () => {
		clearMessage()
	}

	return (
		<MuiSnackbar
			data-testid="snackbar"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			autoHideDuration={success === true ? 2000 : 5000}
			open={message && intl.formatMessage({ id: message })}
			onClose={onCloseSnack}
			onExiting={() => clearMessage()}
			style={{ backgroundColor: '#000' }}
			ContentProps={{
				style: { backgroundColor: '#000' }
			}}
			message={
				<Grid data-testid="snackbar-inner" container alignItems='center'>
					<Grid item style={{ paddingRight: '25px' }}>
						{success === true ? <Check data-testid="snackbar-check" fillColor='#49E783' /> : null}
						{success === false ? <ErrorOutline data-testid="snackbar-error" style={{ color: 'red' }} /> : null}
					</Grid>
					<Grid item>
						<Typography data-testid="snackbar-text" variant='subtitle2' style={{ color: '#fff', fontSize: '1.5rem' }}>
							{message && intl.formatMessage({ id: message })}
						</Typography>
					</Grid>
				</Grid>
			}
		/>
	)
}

const mapStateToProps = (state, ownProps) => ({
	message: state.core.message,
	success: state.core.isSnackMessageSuccess
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	clearMessage: () => dispatch(clearSnackbarMessage())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Snackbar)

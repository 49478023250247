import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { injectIntl, useIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import enGBLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";
import itLocale from "date-fns/locale/it";
import deLocale from "date-fns/locale/de";
import daLocale from "date-fns/locale/da";
import plLocale from "date-fns/locale/pl";
import trLocale from "date-fns/locale/tr";
import nlLocale from "date-fns/locale/nl";

const useInputStyles = makeStyles({
	root: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		marginTop: '0',
		marginBottom: '0',
		'&$disabled': {
			color: '#6C737B',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	focused: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'white',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	disabled: {
		color: '#6C737B',
		backgroundColor: 'rgb(36, 48, 65)',
		marginTop: '0',
		marginBottom: '0'
	}
})

const useLabelStyles = makeStyles({
	root: {
		color: 'white',
		'&$disabled': {
			color: '#6C737B'
		},
		marginLeft: '0'
	},
	focused: {
		color: 'white',
		'&$focused': {
			color: 'white',
		}
	},
	disabled: {
		color: '#6C737B',
		marginLeft: '0'
	}
})

const useHelperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})

const localeMap = {
	en: enLocale,
	es: esLocale,
	fr: frLocale,
	ru: ruLocale,
	it: itLocale,
	de: deLocale,
	da: daLocale,
	pl: plLocale,
	tr: trLocale,
	'en-GB': enGBLocale,
	nl: nlLocale,
}

class LocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
		return format(date, "LLLL", { locale: this.locale });
	}

	getDatePickerHeaderText(date) {
		return format(date, useIntl().formatMessage({ id: 'app.general.datePickerHeaderFormat' }), { locale: this.locale });
	}
}

const transformDate = (date) => {
	if(date){
		return date.toLocaleDateString('en-US')
	}

	return date
}


const LocalizedDatePicker = ({
	defaultDate,
	onDateChange,
	label,
	disabled,
	error,
	externalInputStyles,
	externalLabelStyles,
	externalHelperStyles,
	inputProps,
	openToYear,
	intl,
	touched
}) => {
	const [changed, setChanged] = useState(false)
	const [currentDate, setCurrentDate] = useState(defaultDate ? transformDate(new Date(defaultDate)) : null)

	useEffect(() => {
		setChanged(touched)
	}, [touched])

	useEffect(() => {
		if (defaultDate) {
			var dateToSet = transformDate(new Date(defaultDate))
			setCurrentDate(dateToSet)

			if (onDateChange) {
				onDateChange(dateToSet)
			}
		}
	}, [defaultDate])

	var inputStyles = externalInputStyles ?? useInputStyles()
	var labelStyles = externalLabelStyles ?? useLabelStyles()
	var helperStyles = externalHelperStyles ?? useHelperTextStyles()

	const handleDateChange = (date) => {
		setChanged(false)
		const newDate = transformDate(date)
		setCurrentDate(newDate)
		if (onDateChange) {
			onDateChange(newDate)
		}
	}

	const checkError = () => {
		return error === 'Required' && currentDate ? null : error
	}

	return (
		<MuiPickersUtilsProvider utils={LocalizedUtils} locale={localeMap[intl.locale] || enLocale}>
			<KeyboardDatePicker
				data-testid="survey-change-date-modal-date-picker"
				autoOk
				variant='inline'
				format={intl.formatMessage({ id: 'app.general.datePickerFormat' })}
				margin='normal'
				id='date-picker-inline'
				label={label}
				value={currentDate}
				disabled={disabled}
				placeholder={intl.formatMessage({ id: 'app.general.datePickerPlaceholder' })}
				onChange={handleDateChange}
				inputVariant='filled'
				helperText={changed && checkError()}
				InputProps={{
					classes: {
						root: inputStyles.root,
						disabled: inputStyles.disabled
					}
				}}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused,
						disabled: labelStyles.disabled
					}
				}}
				classes={{
					root: inputStyles.root,
					disabled: inputStyles.disabled
				}}
				FormHelperTextProps={{
					classes: {
						root: helperStyles.root,
						contained: helperStyles.root
					}
				}}
				inputProps={inputProps}
				error={changed && checkError()}
				onBlur={() => setChanged(true)}
				views={['year', 'month', 'date']}
				openTo={openToYear ? 'year' : 'date'}
				KeyboardButtonProps={disabled ? {
					style: { display: 'none' }
				} : inputProps}
			/>
		</MuiPickersUtilsProvider>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(LocalizedDatePicker)
import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import InviteTeamMember from '../InviteTeamMember'

const styles = (theme) => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
	}
})

const AddTeamMembers = ({ onShowModal, classes, intl }) => {
	const theme = useTheme()
	const [ circleId, setCircleId ] = useState(null)

	const onAddClick = () => {
		onShowModal(circleId)
	}

	return (
		<IconButton
			data-testid="add-member-button"
			onClick={onAddClick} style={{ color: theme.palette.primary.A800 }} 
			className={'no-print ${classes.button}'}>
			<Typography style={{ paddingRight: '5px', fontSize: '15px', textTransform: 'uppercase' }}>
				{intl.formatMessage({ id: 'app.userProfile.addTeamMemberButtonText' })}
			</Typography>
			<Add height="64px" style={{ height: '24px' }} />
		</IconButton>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onShowModal: (circleId) => dispatch(showModal(InviteTeamMember, { circleId: circleId }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(AddTeamMembers)

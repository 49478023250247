import 'intersection-observer'
import React from 'react'
import { CircularProgress, TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CircleHeader from './CircleHeader'
import CircleRow from './CircleRow'
import TableBody from '@material-ui/core/TableBody'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { circleSearchTermSelector, hasMoreCirclesSelector, isLoadingCirclesSelector } from '../../../redux/selectors'
import { fetchAdditionalCircles } from '../../../redux/actions'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const CircleTable = ({ data, user, subscriptionUser, search, hasMore, getCircles, loading }) => {

	const getAdditionalCircles = () => {
		getCircles(search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalCircles,
		delayInMs: 1000
	  });

	return (
		<div>
		<Table data-testid="circle-table" className="label-paper-inner">
			<TableHead>
				<CircleHeader />
			</TableHead>
			<TableBody>
				{data &&
					data.map((circle) => (
						<CircleRow circle={circle} user={user} subscriptionUser={subscriptionUser} key={circle.id} />
					))}
			</TableBody>
		</Table>
		{data && data.length > 0 && (loading || hasMore) && (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingCirclesSelector(state),
	hasMore: hasMoreCirclesSelector(state),
	search: circleSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getCircles: (search, offset) => dispatch(fetchAdditionalCircles(search, offset))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(CircleTable)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { isMobile } from './../../../utilities/mobileDetector'

const MemberEmpty = ({ intl }) => {
	const theme = useTheme()

	return (
		<Grid
			data-testid="member-empty"
			container direction='column'>
			<Grid item xs={12} sm={6} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px' }}>
				<Typography
					data-testid="member-empty-header"
					variant='h2'
					style={{ fontWeight: '300', color: theme.palette.primary.A800, paddingBottom: '12px' }}>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyHeader' })}
				</Typography>
				<Typography
					data-testid="member-empty-text"
					variant='body2' style={{ fontWeight: '600' }}>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyParagraph' })}
				</Typography>
				<Button
					data-testid="member-empty-button-link"
					to='/circles'
					component={Link}
					variant="text"
					color="primary"
				>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyCircleLinkText' })}
				</Button>
			</Grid>
		</Grid>
	)
}

export default injectIntl(MemberEmpty)

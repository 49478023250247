import React, { Fragment, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import { fetchPatients } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { useConstant } from '../../../utilities/useConstant'
import { fetchServiceProviderTasks, setSort } from '../../../redux/actions'
import { debounceMilliseconds, minimumSearchCharacters } from '../../../utilities/constants'
import { Typography } from '@material-ui/core'
var debounce = require('lodash/debounce')

const SearchBar = ({ intl, onSearchChange }) => {
	const dispatch = useDispatch()

	const onChange = (value) => {
		onSearchRequested(value)
		if(onSearchChange) {
			onSearchChange(value)
		}
	}

	const handleSuggestionsFetchRequested = (value) => {
		if (value && value.length > minimumSearchCharacters) {
			dispatch(fetchServiceProviderTasks(value))
		}
		if (!value || value.length === 0) {
			dispatch(fetchServiceProviderTasks())
		}
	}
	const onSearchRequested = useConstant(() =>
		debounce(handleSuggestionsFetchRequested, debounceMilliseconds, { leading: false })
	)

	return (
		<div data-testid="mytasks-searchbar-inner">
			<div style={{ width: '100%' }}>
				<Paper style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}>
					<InputBase
						 data-testid="mytasks-searchbar-input"
						fullWidth
						placeholder={intl.formatMessage({ id: 'app.circle.searchPlaceholder' })}
						startAdornment={
							<div style={{ paddingRight: '10px' }}>
								<SearchIcon />
							</div>
						}
						onChange={(e) => onChange(e.target.value)}
						style={{ minHeight: '34px', lineHeight: 'normal' }}
					/>
				</Paper>
				<Typography
					data-testid="mytasks-searchbar-text"
					variant="body2"
					style={{ paddingTop: '4px', paddingBottom: '16px' }}>
					{intl.formatMessage({ id: 'app.tasks.search' })}
				</Typography>
			</div>
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(SearchBar)

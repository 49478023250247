import React, { useEffect, useState } from 'react'
import { Field, change } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import LocalizedDatePicker from './LocalizedDatePicker'

const DateField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error, form, dispatch },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	maxLength,
	setHasFocus,
	hasFocus,
	disabled,
	openToYear,
	defaultDate,
	...custom
}) => {
	const [internalDefaultDate, setInternalDefaultDate] = useState(null)


	useEffect(
		() => {
			updateInternalDefaultDate()
		},
		[input, defaultDate]
	)


	const updateInternalDefaultDate = () => {
		let result = input && input.value?.length > 0 && input.value || defaultDate

		if (!result?.length) {
			setInternalDefaultDate(null)
		}
		else {
			setInternalDefaultDate(new Date(result))
		}
	}
	
	const handleChange = (date) => {
		dispatch(change(form, input.name, date));
	}

    return (
		<div data-testid="component-form-date" className="global-input-wrapper">
			<FormControl
				data-testid="component-form-date-formcontrol"
				// style={{ color: '#fff', backgroundColor: 'rgb(36, 48, 65,0.86)' }}
				error={touched && invalid}
				fullWidth 
			>
				<LocalizedDatePicker
					label={label}
					onDateChange={handleChange}
					disabled={disabled}
					error={error}
					defaultDate={internalDefaultDate}
					inputProps={{
						style: { color: '#fff' }
					}}
					openToYear={openToYear}
					touched={touched}
				/>
			</FormControl>
		</div>
	)
}

const FormDateField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	disabled,
	openToYear,
	maxLength = 10,
	defaultDate
}) => {

	const [ hasFocus, setHasFocus ] = useState(defaultDate)

	return (
		<Field
			data-testid="component-form-date-field"
			name={name}
			hasFocus={hasFocus}
			setHasFocus={setHasFocus}
			component={DateField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			disabled={disabled}
			openToYear={openToYear}
			defaultDate={defaultDate}
		/>
	)
}

export default FormDateField

import React, { Fragment, useEffect } from 'react'
import LabelPaper from '../../../components/LabelPaper'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import AlertTable from './AlertTable'
import AlertEmpty from './AlertEmpty'
import withStyles from '@material-ui/core/styles/withStyles'
import { alertsSelector, fetchAlert } from '../../../redux/modules/dashboard'
import { connect } from 'react-redux'
import ArrowLeftOutlined from '@material-ui/icons/ArrowLeftOutlined'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const Alerts = ({ intl, classes, loadAlerts, alerts }) => {
	useEffect(() => {
		loadAlerts()
	}, [])

	return (
		<div data-testid="myalerts-alerts" style={{ margin: '3% 0', width: '100%' }}>
			<Grid className="wide-container" container style={{ marginTop: '30px', padding: '0 5% 0 5%', width: '100%' }}>
				<Grid item>
					<Link
						data-testid="myalerts-alerts-back-link"
						className="no-print"
						to="/dashboard" style={{ textDecoration: 'none', color: '#2e7df6' }}>
						<Typography
							variant="body2"
							style={{
								paddingBottom: '16px',
								fontSize: '16px',
								letterSpacing: '1.29px',
								lineHeight: '16px'
							}}
						>
							<ArrowLeftOutlined viewBox="0 0 24 15" />
							{intl.formatMessage({ id: 'app.dashboard.backToDashboardLabel' })}
						</Typography>
					</Link>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<Grid container style={{ width: '100%' }} justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography
								data-testid="myalerts-alerts-header"
								variant='h1'>
								{intl.formatMessage({ id: 'app.dashboard.alertsHeader' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>{' '}
				<Grid item style={{ width: '100%', marginTop: '57px' }}>
					{alerts.length === 0 && (
						<LabelPaper
							data-testid="myalerts-alerts-empty"
							label={intl.formatMessage({ id: 'app.dashboard.alerts' })}
							Component={AlertEmpty}
							classes={classes.gradient}
						/>
					)}
					{alerts.length > 0 && (
						<LabelPaper
							data-testid="myalerts-alerts-inner"
							label={intl.formatMessage({ id: 'app.dashboard.alerts' })}
							Component={AlertTable}
							alerts={alerts}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	alerts: alertsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadAlerts: () => dispatch(fetchAlert())
})
const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Alerts)

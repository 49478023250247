import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import loginBackground from '../../assets/login/arrows-bright.jpg'
import { useIntl } from 'react-intl'
import { useTheme, Backdrop } from '@material-ui/core'
import { fetchTerms, pageLoadHandler } from '../../redux/actions'
import { localeIsUpdatedSelector, termsSelector } from '../../redux/selectors'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Container from '@material-ui/core/Container'

export default ({}) => {
	const theme = useTheme()
	const intl = useIntl()
	const terms = useSelector(termsSelector, shallowEqual)
	const localeIsUpdated = useSelector(localeIsUpdatedSelector, shallowEqual)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(pageLoadHandler())
	}, [])

	useEffect(() => {
		if (localeIsUpdated) dispatch(fetchTerms(intl.locale))
	}, [localeIsUpdated])

	return (
		<div
			data-testid="terms"
			style={{
				minHeight: '100vh',
				backgroundImage: `url(${loginBackground})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				display: 'flex',
				justifyContent: 'center',
				padding: '5% 0px'
			}}>
			<Container maxWidth="md">
				<Grid container spacing={8} style={{ backgroundColor: '#fff' }}>

					<Grid xs={12} item style={{ alignSelf: 'center' }}>
						  <Typography
							data-testid="terms-title"
							variant='h1'
							style={{
								color: theme.palette.secondary.main,
								textAlign: 'left',
								paddingBottom: '5%'
							}}>
							{intl.formatMessage({ id: 'app.terms.title' })}
						</Typography>
					</Grid>
					<Grid xs={12} item>
						{terms && (
							<Typography data-testid="terms-body" variant='body2'>
								<div
									dangerouslySetInnerHTML={{
										__html: terms
									}}
								/>
							</Typography>
						)}
						{!terms && <Backdrop data-testid="terms-backdrop" />}
					</Grid>

				</Grid>
			</Container>
		</div>
	)
}

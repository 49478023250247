import React, { useState } from 'react'
import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import SurveyStatusCell from './SurveyStatusCell'
import SurveyAlertCell from './SurveyAlertCell'
import SurveyAlertDateCell from './SurveyAlertDateCell'
import SurveyAlertDelegateCell from './SurveyAlertDelegateCell'
import SurveyAlertCaseCell from './SurveyAlertCaseCell'
import SurveyAlertPatientCell from './SurveyAlertPatientCell'
import SurveyAlertInfoCell from './SurveyAlertInfoCell'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import Menu from './Menu'

const useStyles = makeStyles((theme) => ({
	formControl: {
		backgroundColor: 'rgba(0, 0, 0, 0.09)',
		'& .MuiInputLabel-root': {
			marginLeft: '0'
		}
	}
}))

const colorMap = {
	0: '#007AFF',
	1: '#6ed1b2', 
	2: '#B50D2C',
	3: '#B50D2C',
	4: '#000000'
}

export default ({ task, intl }) => {
	const theme = useTheme()
	let classes = useStyles()

	const goToTask = () => {
		let link = task.taskId
            ? `/tasks/${task.taskId}`
			: `/cases/${task.caseId}/survey/${task.surveyId}/instance/${task.instanceId}/delegate/${task.delegateId}/status/0/t`
		
		window.open(link, '_blank')
	}
	
	const [isHover, setIsHover] = useState(null)
	const onMouseOver = () => {setIsHover(true)}
	const onMouseOut = () => {setIsHover(false)}

	return (
		<TableRow data-testid="mytasks-row"
			style={{
				backgroundColor: isHover ? theme.palette.lightCell : '#fff',
				cursor: isHover ? 'pointer' : 'default'
			}}>
			<TableCell
				data-testid="mytasks-row-status"
				onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver}
				width="2.5%"
				align="left" style={{ padding: '0', position: 'relative' }}>				
					<div>
						<div
							style={{
								width: '4px',
								height: 'calc(100% - 16px)',
								position: 'absolute',
								left: 0,
								top: '8px',
								backgroundColor: colorMap[task.type]
							}}
						></div>
						<div style={{ textAlign: 'center', paddingLeft: '16px' }}>
							<SurveyStatusCell task={task} color={colorMap[task.type]} />
						</div>
					</div>
			</TableCell>
			<TableCell
				data-testid="-task-row-patient"
				width="25%"
				align="left" onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver} style={{ minWidth: '230px' }}>
				<SurveyAlertPatientCell task={task} />
			</TableCell>
			<TableCell
				data-testid="-task-row-patient"
				width="2.5%"
				align="left" style={{ padding: '0', position: 'relative' }}>
				<SurveyAlertInfoCell task={task} />
			</TableCell>
			<TableCell
				data-testid="mytasks-row-case"
				width="20%"
				align="left" onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver} style={{ minWidth: '200px' }}>
				<SurveyAlertCaseCell task={task} />
			</TableCell>
			<TableCell
				data-testid="mytasks-row-alert"
				width="20%"
				align="left" onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver} style={{ minWidth: '150px' }}>
				<SurveyAlertCell task={task} />
			</TableCell>
			<TableCell
				data-testid="mytasks-row-date"
				width="15%"
				size="small" onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
				<SurveyAlertDateCell task={task} color={colorMap[task.type]} />
			</TableCell>
			<TableCell
				data-testid="mytasks-delegate"
				width="15%"
				size="small" onClick={goToTask} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
				<SurveyAlertDelegateCell task={task} />
			</TableCell>
			<TableCell data-testid="mytasks-row-options" 
				align='right' 
				size='small' 
				style={{ padding: '5px 15px 5px 5px' }}>
				<Menu task={task} />
			</TableCell>
		</TableRow>
	)
}

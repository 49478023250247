import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FormattedDate, injectIntl } from 'react-intl'

const DateSponsorCell = ({ intl, circle }) => (
	<Grid container>
		<Grid data-testid="date-sponsor-cell" item align='left'>
			{circle && (
				<div>
					<Typography
						data-testid="date-sponsor-cell-date"
						style={{
							fontSize: '17px',
							lineHeight: '23px',
							letterSpacing: '0.51px',
							color: 'rgba(0,0,0,0.87)'
						}}>
						<FormattedDate year='numeric' month='short' day='2-digit' value={circle.createDate} />
					</Typography>
					<Typography
						data-testid="date-sponsor-cell-message"
						variant='body2' style={{ fontSize: '13px' }} >{intl.formatMessage({ id: 'app.circles.dateFounded' })}</Typography>
				</div>
			)}
		</Grid>
	</Grid>
)

export default injectIntl(DateSponsorCell)

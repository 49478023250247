import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = {
}

const useLabelStyles = makeStyles({
	// root: {
	// 	marginLeft: 0,
	// 	'&$disabled': {
	// 		color: '#6C737B'
	// 	}
	// },
	// focused: {
	// 	'&$focused': {
	// 		color: '#fff'
	// 	}
	// },
	// disabled: {
	// 	color: '#6C737B'
	// }
})

const useInputStyles = makeStyles({
	// root: {
	// 	'&$disabled': {
	// 		color: '#6C737B'
	// 	}
	// },
	// focused: {
	// 	'&$focused': {
	// 	}
	// },
	// disabled: {
	// 	color: '#6C737B'
	// }
})

const FormHelperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})

const renderTextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	color,
	type,
	labelStyles,
	inputStyles,
	helperStyles,
	maxLength,
	disabled,
	// backgroundColor,
	// fontColor,
	...custom
}) => {
	return (
		<div className="global-input-wrapper">
			<TextField
				data-testid="component-form-text"
				label={label}
				placeholder={label}
				error={touched && invalid}
				helperText={touched && error}
				{...input}
				margin="dense"
				// InputLabelProps={{
				// 	classes: {
				// 		root: labelStyles.root,
				// 		focused: labelStyles.focused,
				// 		disabled: labelStyles.disabled
				// 	}
				// }}
				// InputProps={{
				// 	classes: {
				// 		root: inputStyles.root,
				// 		focused: inputStyles.focused,
				// 		disabled: inputStyles.disabled
				// 	}
				// }}
				disabled={disabled}
				FormHelperTextProps={{ classes: { root: helperStyles.root, contained: helperStyles.root } }}
				InputProps={{
					...inputProps,
					inputProps: { maxLength: maxLength }
				  }}
				// style={{ ...style, backgroundColor: backgroundColor, color: fontColor }}
				// classes={{ root: inputStyles.root, error: inputStyles.error, disabled: inputStyles.disabled }}
				fullWidth
				margin={margin}
				variant={variant}
				color={color}
				{...custom}
				type={type}
				shrink="true"
			/>
		</div>
	)
}

const FormTextField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	// variant,
	color = 'secondary',
	type,
	validators,
	disabled,
	maxLength = 255,
	variant = 'filled',
	// backgroundColor = 'rgb(36, 48, 65)',
	// fontColor = '#fff',
	...custom
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	var helperStyles = FormHelperTextStyles()
	return (
		<Field
			data-testid="component-form-text-field"
			name={name}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			color={color}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			labelStyles={labelStyles}
			inputStyles={inputStyles}
			helperStyles={helperStyles}
			disabled={disabled}
			// fontColor={fontColor}
			// backgroundColor={backgroundColor}
			{...custom}
		/>
	)
}

export default withStyles(styles)(FormTextField)

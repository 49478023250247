import { authenticatedFetch } from './base'

export const getObservationalProtocolByVersion = (id, versionId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

// TODO: DEPRECATED
export const getObservationalProtocolByCircle = (id, circleId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/circle/${circleId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const cloneObservationalProtocol = (id, name, description) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/clone`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id, name: name, description: description })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createObservationalProtocol = (name, description, isPrivate) => {
	return authenticatedFetch(`/api/observationalProtocol`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ name: name, description: description, isPrivate: parseInt(isPrivate) })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveObservationalProtocol = (id, name, description) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, name: name, description: description })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveObservationalProtocolAlias = (protocolId, circleId, alias) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/circle/${circleId}/alias`, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
		    body: JSON.stringify({ alias })
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (patient) {
            return patient
        })
}

export const updateSurveyPositions = (id, surveys) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/survey/swap`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, surveys: surveys })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createSurvey = (protocolId, name, treatmentPhase, language) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			observationalProtocolId: protocolId,
			name: name,
			treatmentPhaseId: treatmentPhase,
			languageId: language
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurvey = (protocolId, surveyId, questions, settings) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ questions: questions, settings: settings })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocolsWithMetrics = () => {
	return authenticatedFetch(`/api/observationalProtocol/withmetrics`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocols = () => {
	return authenticatedFetch(`/api/observationalProtocol`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getSurvey = (protocolId, surveyId) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getSurveyValues = (protocolId, surveyId, instanceId, caseId, patientId) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}/instance/${instanceId}/case/${caseId}/patient/${patientId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getTaskSurveyValues = (taskId) => {
	return authenticatedFetch(`/api/observationalProtocol/task/${taskId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getSurveyQuestionsByProtocolVersion = (observationProtocolSurveyId, protocolVersionId) => {
	return authenticatedFetch(`/api/observationalProtocol/ops/${observationProtocolSurveyId}/version/${protocolVersionId}/questions`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

// TODO: DEPRECATED
export const getSurveyQuestionsByCircle = (observationProtocolSurveyId, circleId) => {
	return authenticatedFetch(`/api/observationalProtocol/ops/${observationProtocolSurveyId}/circle/${circleId}/questions`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchProtocolAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(
		`/api/observationalProtocol/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchChildProtocolAutoComplete = (id, searchPhrase = '') => {
	return authenticatedFetch(
		`/api/observationalProtocol/${id}/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchVersionsForCircle = (circleId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/circle/${circleId}/versions`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchBilateralAreas = (versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/bilateralAreas`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

import React, { Fragment, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import Check from '../../assets/check'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { StripeProvider, Elements, CardForm, CardElement } from 'react-stripe-elements'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { injectStripe } from 'react-stripe-elements'
import poweredByStripe from '../../assets/checkout/powered_by_stripe.png'
import { fetchStripeIntent, createPaymentMethod, hideModal, resetPaymentState } from '../../redux/actions'
import { stripeIntentSelector, paymentMethodSelector } from '../../redux/selectors'

const UpdateCardForm = ({
	intl,
	elements,
	stripe,
	getstripeIntent,
	stripeIntent,
	setPaymentMethod,
	paymentMethod,
	paymentCompleted,
	resetPayment,
	close
}) => {
	const theme = useTheme()

	const [ awaitingNewPaymentMethod, setAwaitingNewPaymentMethod ] = useState(false)

	useEffect(() => {
		getstripeIntent()
	}, [])

	useEffect(
		() => {
			if (awaitingNewPaymentMethod) {
				close()
				resetPayment()
				setAwaitingNewPaymentMethod(false)
			}
		},
		[ paymentCompleted ]
	)

	const [ confirmingCardSetup, setConfirmingCardSetup ] = useState(0)
	const [ saveCardError, setSaveCardError ] = useState()

	const onPay = () => {
		const cardElement = elements.getElement('card')

		setConfirmingCardSetup(1)
		setSaveCardError()

		stripe
			.confirmCardSetup(stripeIntent.client_secret, {
				payment_method: {
					card: cardElement
				}
			})
			.then(function(result) {
				setConfirmingCardSetup(0)
				if (result.error) {
					setSaveCardError(result.error.message)
					setConfirmingCardSetup(0)
				} else {
					setAwaitingNewPaymentMethod(true)
					setPaymentMethod(result.setupIntent.payment_method)
				}
			})
	}

	return (
		<div data-testid="update-card-form">
			<Typography data-testid="update-card-form-title" variant='subtitle1' style={{ color: '#fff' }}>
				{intl.formatMessage({ id: 'app.checkout.updateCardHeader' })}
			</Typography>
			<Typography data-testid="update-card-form-subtitle" variant='body2' style={{ color: '#fff' }}>
				{paymentMethod &&
					intl
						.formatMessage({ id: 'app.checkout.updateCardReplacingCard' })
						.replace('%CARD%', paymentMethod.brand)
						.replace('%LAST4%', paymentMethod.last4)}
			</Typography>

			<CardElement data-testid="update-card-form-element" />
			<img data-testid="update-card-form-img" style={{ width: '100px', margin: '0 auto' }} src={poweredByStripe} />

			<Typography data-testid="update-card-form-disclaimer" variant='body2' style={{ color: '#fff' }}>
				{intl.formatMessage({ id: 'app.checkout.saveCardDisclaimer' })}
			</Typography>

			<div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
				{saveCardError && (
					<Typography data-testid="update-card-form-error" variant='caption' style={{ color: 'red', display: 'block' }}>
						{' '}
						{saveCardError}{' '}
					</Typography>
				)}

				{confirmingCardSetup && <CircularProgress data-testid="update-card-form-progress" />}
				{!confirmingCardSetup && (
					<Button
						data-testid="update-card-button-update"
						onClick={onPay}
						variant="contained"
						color="primary"
						type='submit'
						size='medium'>
						{intl.formatMessage({ id: 'app.checkout.saveCard' })}
					</Button>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	stripeIntent: stripeIntentSelector(state),
	paymentCompleted: paymentMethodSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getstripeIntent: () => dispatch(fetchStripeIntent()),
	setPaymentMethod: (payment_method) => dispatch(createPaymentMethod(payment_method)),
	close: () => dispatch(hideModal()),
	resetPayment: () => dispatch(resetPaymentState())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(injectStripe(UpdateCardForm))

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default ({ circle }) => (
	<Grid data-testid="circle-member-cell" container>
		<Grid item>
			<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
				{circle ? circle.numberUsers : 0}
			</Typography>
		</Grid>
	</Grid>
)

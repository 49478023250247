import React from "react"
import { injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"
import { Button, FilledInput, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { fullUserSelector, openApiKeySelector, openApiKeyOperationStartedSelector } from "../../../../redux/selectors"
import { generateApiKey, activateApiKey, deactivateApiKey } from "../../../../redux/actions"
import { FileCopy } from "@material-ui/icons"


const ApiStatusWidget = ({
    intl,
    opanApiKey,
    openApiKeyOperationStarted,
    fullUser,
    generateApiKey,
    activateApiKey,
    deactivateApiKey
}) => {
    const dispatch = useDispatch()
    const isKeyEnabled = fullUser?.openApi?.isKeyEnabled
    const isReadonlyKey = !isKeyEnabled || !opanApiKey
    const currentKey = !!isKeyEnabled && opanApiKey || fullUser?.openApi?.strippedKey

    const keyFieldHelperText = () => {
        let result = ""

        if (!currentKey) {
            result = intl.formatMessage({ id: "app.api.keyIsEmptyLabel" })
        }
        else if (!isKeyEnabled) {
            result = intl.formatMessage({ id: "app.api.activateKeyLabel" })
        }
        else if (!!currentKey) {
            result = intl.formatMessage({ id: "app.api.keyNeedRegenerateLabel" })
        }
        //                     ? intl.formatMessage({ id: "app.api.keyIsEmptyLabel" })
        //                     : !!currentKey && isReadonlyKey
        //                         ? intl.formatMessage({ id: "app.api.keyNeedRegenerateLabel" })
        //                         : null
        // isKeyEnabled

        return result
    }

    const onCopy = () => {
        if (!!isReadonlyKey) {
            return
        }

		try {
            navigator.clipboard.writeText(opanApiKey)
                .then(() => {
                    dispatch({
                        type: 'SET_SNACKBAR_MESSAGE',
                        data: {
                            message: 'app.general.copiedToClipboardText',
                            isSuccess: true
                        }
                    })
                })
		} catch (error) {}
	}

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <Alert
                    severity="info"
                >
                    <a
                        style={{
                            fontSize: "16px",
                            color: "blue"
                        }}
                        href="/swagger/index.html"
                        target="blank"
                    >
                        {
                            intl.formatMessage({ id: "app.api.documentationLinkLabel" })
                        }
                    </a>
                </Alert>
            </Grid>

            <Grid
                item
                xs={6}
                className="api-key-styled"
            >
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "app.api.keyFieldLabel" })}
                    placeholder={intl.formatMessage({ id: "app.api.keyFieldLabel" })}
                    value={currentKey}
                    disabled={!currentKey}
                    error={!!currentKey && isReadonlyKey}
                    helperText={keyFieldHelperText()}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={onCopy}
                                    edge="end"
                                    disabled={isReadonlyKey}
                                >
                                    <FileCopy />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>

            <Grid
                item
                xs={6}
            >
                <Grid
                    container
                    justifyContent="flex-end"
                    style={{
                        width: "100%"
                    }}
                >
                    <Grid
                        item
                    >
                        {
                            !currentKey
                                ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={generateApiKey || openApiKeyOperationStarted}
                                    >
                                        {
                                            intl.formatMessage({ id: "app.api.generateKeyButtonLabel" })
                                        }
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!isKeyEnabled || openApiKeyOperationStarted}
                                        onClick={generateApiKey}
                                    >
                                        {
                                            intl.formatMessage({ id: "app.api.regenerateKeyButtonLabel" })
                                        }
                                    </Button>
                                )
                       }
                    </Grid>
                    {
                        !!currentKey
                        && <Grid
                            item
                        >
                            {
                                !isKeyEnabled
                                    ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={activateApiKey}
                                            disabled={openApiKeyOperationStarted}
                                        >
                                            {
                                                intl.formatMessage({ id: "app.api.activateKeyButtonLabel" })
                                            }
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={deactivateApiKey}
                                            disabled={openApiKeyOperationStarted}
                                        >
                                            {
                                                intl.formatMessage({ id: "app.api.deactivateKeyButtonLabel" })
                                            }
                                        </Button>
                                    )
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
    opanApiKey: openApiKeySelector(state),
    openApiKeyOperationStarted: openApiKeyOperationStartedSelector(state),
	fullUser: fullUserSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    generateApiKey: () => dispatch(generateApiKey()),
    activateApiKey: () => dispatch(activateApiKey()),
    deactivateApiKey: () => dispatch(deactivateApiKey())
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(ApiStatusWidget)

import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import SubscriptionsUsageRow from './SubscriptionsUsageRow'
import { Button, Grid } from '@material-ui/core'

export default ({ data }) => {
    const intl = useIntl()

    

    return (
        <div data-testid="sponsor-subscriptions-table" className="label-paper-inner" style={{ margin: '20px -32px 0' }} >
            
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            data-testid="sponsor-subscriptions-user-name"
                            align='left'>
                            <Typography variant="caption">
                                {intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>

                        <TableCell align='left'>
                            <Typography variant="caption"
                                data-testid="sponsor-subscriptions-user-role"
                            >
                                {intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userRoleHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>

                        <TableCell align='left'>
                            <Typography variant="caption"
                                data-testid="sponsor-subscriptions-user-last-login"
                            >
                                {intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userLastLoginHeader' }).toUpperCase()}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((sub, index) =>
                        <SubscriptionsUsageRow data={sub} key={index} />)
                    }
                </TableBody>
            </Table>
        </div>
    )
}
import React from 'react'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { requestForgotPasswordEmail } from '../../redux/actions'
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm } from 'redux-form'
import { required, email } from '../../utilities/validators'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import Container from '@material-ui/core/Container'
import { isMobile } from './../../utilities/mobileDetector'

const styles = {
	root: {},
	input: {
		color: "#fff",
		fontSize: "1.7rem",
	},
	whiteLink: {
		color: "#fff !important",
		textDecoration: "none",
		fontSize: "13px",
		fontWeight: "400",
		textTransform: "uppercase",
		display: "table",
		letterSpacing: "1.28px",
		margin: "0 0 20px",
		"&:hover, &:focus": {
			color: "#fff !important",
			textDecoration: "none",
		},
	},
};

const ForgotPassword = ({ classes, history, submitRequest, handleSubmit, pristine, reset, submitting, intl }) => {
	const theme = useTheme()
	const onSubmitForm = (values) => {
		submitRequest(values.email)
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmitForm)}
			style={{ width: "100%", margin: "15px 0" }}
		>
			<Container maxWidth="sm">
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
					spacing={isMobile ? 0 : 10}
					style={{
						backgroundColor: theme.palette.secondary.main,
						marginTop: "0",
						marginBottom: "0",
					}}
				>
					<Grid
						item
						xs={isMobile ? 9 : 11}
						style={{
							width: "100%",
							paddingTop: isMobile ? "30px" : "60px",
							paddingBottom: isMobile ? "30px" : "60px",
						}}
					>
						<Typography
							variant="h1"
							style={{
								color: "#fff",
								textAlign: "center",
								marginBottom: "40px",
							}}
						>
							{intl.formatMessage({ id: "app.forgotPass.title" })}
						</Typography>

						<Grid
							item
							style={{
								width: "100%",
								paddingBottom: "10px",
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: "app.login.email" })}
								fullWidth
								type="text"
								name="email"
								validators={[required, email]}
								variant="filled"
							/>
						</Grid>
						<Grid
							item
							style={{
								width: "100%",
							}}
						>
							<Link
								to="/auth/login"
								className={classes.whiteLink}
								component={RouterLink}
								color="secondary"
							>
								{intl.formatMessage({ id: "app.forgotPass.backLabel" })}
							</Link>
						</Grid>
						<Grid item style={{ width: "100%" }}>
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
								style={{ width: "calc(100% + 20px)", margin: "0 -10px" }}
							>
								<Grid item style={{ margin: "10px" }}>
									{/* <Button
										component={RouterLink}
										to="/auth/register"
										color="primary"
										variant="outlined"
									>
										{intl.formatMessage({
											id: "app.forgotPass.signUpButton",
										})}
									</Button> */}
									<a href="https://www.rgnmed.com/support/request-a-demo">
										<Button
											color="primary"
											variant="outlined"
										>
											{intl.formatMessage({
												id: "app.forgotPass.signUpButton",
											})}
										</Button>
									</a>
								</Grid>

								<Grid item style={{ margin: "10px" }}>
									<Button
										label="Password"
										variant="contained"
										color="primary"
										type="submit"
									>
										{intl.formatMessage({
											id: "app.forgotPass.submitButton",
										})}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</form>
	);
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (email) => dispatch(requestForgotPasswordEmail(email))
})

const enhance = compose(
	connect(null, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'login'
	}),
	withRouter,
	withStyles(styles)
)

export default enhance(ForgotPassword)

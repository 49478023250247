import { authenticatedFetch } from './base'

export const fetchJurisdictions = (languageId) => {
	let url = `/api/jurisdiction`;

	if(languageId)
	{
		url += `/${languageId}`;
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(jurisdiction) {
			return jurisdiction
		})
}
export const fetchJurisdictionsByLocale = (locale) => {
	let url = `/api/jurisdiction/locale/${locale}`;

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(jurisdiction) {
			return jurisdiction
		})
}

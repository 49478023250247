import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router'
import { injectIntl, useIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Filter from '../../../circle/components/Filter'
import { getUser } from '../../../../utilities/userUtilities'
import { fetchSponsoredCircles } from '../../../../redux/actions'
import { sponsoredCirclesSelector } from '../../../../redux/selectors'
import CircleContainer from '../../../circle/components/CircleContainer'
import LabelPaper from '../../../../components/LabelPaper'
import CircleTable from '../SponsoredCircles/CircleTable'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36,48,65)'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
}

export default ({}) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	const circles = useSelector(sponsoredCirclesSelector)

	useEffect(() => {
		dispatch(fetchSponsoredCircles())
	}, [])

	return (
		<div data-testid="sponsored-circles-wrap" style={{ marginTop: '61px', width: '100%' }}>
			<Grid className="wide-container" container style={{ marginTop: '30px', padding: '0 5% 0 5%', width: '100%' }}>
				<Grid item style={{ width: '100%' }}>
					<Grid container style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
						<Grid item>
							<Typography data-testid="sponsored-circles-sponsor" variant='h1'>
								{intl.formatMessage({ id: 'app.account.circleISponsor' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', marginTop: '46px', marginBottom: '80px' }}>
					<LabelPaper
						data-testid="sponsored-circles-label"
						label={intl.formatMessage({ id: 'app.account.sponsoredCircleLabel' })}
						Component={CircleTable}
						data={circles}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

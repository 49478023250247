import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Container from '@material-ui/core/Container'
import { palette } from '@material-ui/system';

const BadGatewayError = ({
	intl,
}) => {

	const theme = useTheme()

	return (
    <Container maxWidth="md" style={{ backgroundColor: theme.palette.secondary.main }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid xs={12} item>
          <Typography
            variant='h1'
            style={{
              color: palette.light,
              marginBottom: '25px',
              fontWeight: '400'
            }}
            align="left"
          >
            {intl.formatMessage({ id: 'app.error.badGatewayTitle' })}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            style={{
              color: palette.light,
              marginBottom: '25px',
              fontWeight: '400'
            }}
            align="left"
          >
            {intl.formatMessage({ id: 'app.error.badGatewayBody' })}
          </Typography>
        </Grid>
      </Grid>
    </Container>
	)
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	withRouter
)

export default enhance(BadGatewayError)

import React, { useEffect } from 'react'
import { reduxForm, change } from 'redux-form'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import InvitationForm from '../../components/InvitationForm'
import { checkSubscriptionStatusByEmail, inviteCircleMember, fetchCirclePlan } from '../../redux/actions'
import { subscriptionStatusSelector, subscriptionUserSelector, circleSelector, circlePlanSelector } from '../../redux/selectors'

const formName = 'inviteCircleMember'

const InviteCircleMember = ({
	circle,
	circleId,
	handleSubmit,
	submitCircleInvitationRequest,
	getSubscriptionStatusByEmail,
	availableCredits,
	getPlan,
	changeLanguage,
	plan
}) => {
	useEffect(() => {
		getPlan(circleId)
	}, [])

	useEffect(() => {
		if(circle && circle.administrator)
			changeLanguage(circle.administrator.languageId)
	},
	 circle && circle.administrator && circle.administrator.languageId)

	const onSubmitInvitationForm = (values) => {
		submitCircleInvitationRequest(values.email, circleId, values.languageId, values.sponsored, values.caseFunding)
	}

	return (
		<div data-testid="invite-circle-member">
			{plan && (
				<InvitationForm
					availableCredits={plan.totalUserCreditsAllocatedInCircle - plan.totalUsedUsers}
					handleSubmit={handleSubmit}
					onSubmitForm={onSubmitInvitationForm}
				/>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circle: circleSelector(state),
	circleId: state.core.params.circleId,
	subscriptionStatus: subscriptionStatusSelector(state),
	subscriptionUser: subscriptionUserSelector(state),
	plan: circlePlanSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitCircleInvitationRequest: (email, circleId, languageId, sponsored, caseFunding) => {
		dispatch(inviteCircleMember(email, circleId, languageId, sponsored, caseFunding))
	},
	getSubscriptionStatusByEmail: (circleId, email) => {
		dispatch(checkSubscriptionStatusByEmail(circleId, email))
	},
	getPlan: (circleId) => dispatch(fetchCirclePlan(circleId)),
	changeLanguage: (languageId) => dispatch(change(formName, 'languageId', languageId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: formName
	}),
	injectIntl
)

export default enhance(InviteCircleMember)

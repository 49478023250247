import React, { useState, useEffect, Fragment } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { Field, change } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { fetchSharedWithCircleAutoComplete } from '../../../redux/actions'
import { sharedWithCircleAutoCompleteSuggestionSelector } from '../../../redux/selectors'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../utilities/constants'

var debounce = require('lodash/debounce')

const useASStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'WebkitOverflowScrolling': 'touch',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
	divider: {
		height: theme.spacing(2)
	},
	chip: {
		color: '#000',
		backgroundColor: '#8CCFFF',
		fontSize: '13px',
		height: 'auto',
		minHeight: '32px',
		'& .MuiChip-label': {
			whiteSpace: 'normal',
		}
	},
	paper: {
		backgroundColor: 'rgb(36, 48, 65)',
		marginTop: '15px',
		padding: '20px',
		overflowY: 'auto',
		'WebkitOverflowScrolling': 'touch',
		maxHeight: '100px'
	}
}))

const styles = {
	input: {
		color: '#fff',
		fontSize: '1rem',
		backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		color: '#fff'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		color: '#fff',
		marginLeft: '-2px',
	}
})

const useInputStyles = makeStyles({
	root: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
})

export const SharedWithSelectorControl = ({ label, meta: { initial, form }, input, noMatchText, caseModel, protocolVersionId }) => {
	const suggestions = useSelector(sharedWithCircleAutoCompleteSuggestionSelector, shallowEqual)

	const dispatch = useDispatch()
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(false)
	const [ stateSuggestions, setSuggestions ] = useState(suggestions)
	const classes = useASStyles()
	const [ anchorEl, setAnchorEl ] = useState(null)
	const [ circles, setCircles ] = useState([])
	const [ state, setState ] = useState({
		label: ''
	})

	const handleSuggestionsFetchRequested = ({ value }) => {
		dispatch(fetchSharedWithCircleAutoComplete(value, noMatchText, caseModel?.protocolVersionId ?? protocolVersionId))
	}

	const onSuggestionRequested = useConstant(() =>
		debounce(handleSuggestionsFetchRequested, debounceMilliseconds, { leading: true })
	)

	useEffect(() => {
		if (initial) {
			setCircles(initial)
		}
	}, [])

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem data-testid="shared-with-selector-menu-item" selected={isHighlighted} component='div'>
				<div>{suggestion.name}</div>
			</MenuItem>
		)
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps

		return (
			<TextField
				data-testid="shared-with-selector"
				fullWidth
				autoComplete={false}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.root,
						shrink: labelStyles.root
					}
				}}
				classes={{
					root: inputStyles.root,
					error: inputStyles.error
				}}
				style={styles}
				InputProps={{
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					}
				}}
				inputProps={{ autocomplete: 'dont' }}
				shrink='true'
				variant='filled'
				{...other}
			/>
		)
	}
	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (suggestion && suggestion.id > -1) {
			if (circles && circles.length > 0) {
				dispatch(
					change(form, input.name, [
						...circles.filter((r) => r && r.id != suggestion.id).map((r) => r.id),
						suggestion.id
					])
				)
			} else {
				dispatch(change(form, input.name, [ suggestion.id ]))
			}

			setNoMatch(false)
			setMatchSelected(true)
			setCircles([ ...circles.filter((r) => r && r.id != suggestion.id), suggestion ])
			setState({
				...state,
				value: null,
				label: ''
			})
		} else {
			setState({
				...state,
				value: null,
				label: ''
			})
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const autosuggestProps = {
		renderInputComponent,
		onSuggestionsFetchRequested: onSuggestionRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: onSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	}

	const handleChange = (name) => (event, { newValue }) => {
		setState({
			...state,
			value: newValue,
			label: newValue && newValue.name ? newValue.name : newValue
		})
	}

	const handleDeleteChange = (data) => {
		if (data) {
			let updatedList = circles.filter((r) => r && r.id != data.id)
			setCircles([ ...updatedList ])
			dispatch(change(form, input.name, [ ...updatedList ].map((r) => r.id)))
		}
	}

	return (
		<div className={classes.root}>
			<Autosuggest
				data-testid="shared-with-selector-autosuggest"
				{...autosuggestProps}
				suggestions={suggestions}
				inputProps={{
					classes,
					id: input.name,
					label: label,
					value: state.label,
					onChange: handleChange(),
					onBlur: (e, x) => {
						setAnchorEl(null)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: null,
								label: ''
							})
							dispatch(change(form, input.name, circles ? JSON.stringify(circles) : null))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={(options) => (
					<Popper data-testid="shared-with-selector-popper" anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 1300 }}
                		placement="bottom-start"
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}	
					>
						<Paper
							data-testid="shared-with-selector-paper"
							square
							{...options.containerProps}
							className={classes.optionStyle}
							style={{
								maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
								width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
								marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined }}>
							{options.children} 
						</Paper>
					</Popper>
				)}
			/>

			{circles &&
			circles.length > 0 && (
				<div className={classes.paper}>
					{circles.sort((a, b) => (a.name > b.name ? 1 : -1)).map((data) => {
						return (
							data && (
								<div key={data.id} className="selector-chip-wrapper" style={{ padding: '2px', display: 'inline-block' }}>
									<Chip
										data-testid="shared-with-selector-chip"										
										avatar={
											data.isPhiFree ? (
												<Avatar
													style={{
														backgroundColor: '#fff',
														height: '24px',
														width: '24px',
														marginLeft: '5px'
													}}>
													<Lock style={{ color: '#000' }} />
												</Avatar>
											) : (
												<Avatar
													style={{
														backgroundColor: '#fff',
														height: '24px',
														width: '24px',
														marginLeft: '5px'
													}}>
													<LockOpen style={{ color: '#6ED1B2' }} />
												</Avatar>
											)
										}
										onDelete={() => handleDeleteChange(data)}
										label={data.name}
										disabled={caseModel && data.id === caseModel.sponsorId}
										className={classes.chip}
									/>
								</div>
							)
						)
					})}
				</div>
			)}
		</div>
	)
}

export default ({ label, name, validators, maxLength = 255, noMatchText = 'No Entries Found', protocolVersionId, ...other }) => {
	return (
		<Field
			data-testid="shared-with-selector-field"
			name={name}
			component={SharedWithSelectorControl}
			label={label}
			maxLength={maxLength}
			fullWidth
			validate={validators}
			noMatchText={noMatchText}
			protocolVersionId={protocolVersionId}
			{...other}
		/>
	)
}

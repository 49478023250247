import React from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'

const AlertDelegateCell = ({ alert, intl }) => (
	<div data-testid="myalerts-invitation-alert-delegate">
		{alert &&
		alert.sourceFirstName &&
		alert.sourceLastName && (
			<Typography
				data-testid="invitation-alert-delegate-contact"
				variant="subtitle1"
				style={{
					textAlign: 'left',
					fontWeight: '700',
					wordBreak: 'break-all',
					fontSize: '17px',
					lineHeight: '24px',
					letterSpacing: '0.16px'
				}}
			>
				{`${alert.sourceFirstName} ${alert.sourceLastName}`}
			</Typography>
		)}
		{alert &&
		alert.delegateType === 0 && (
			<Typography
				data-testid="invitation-alert-delegate-message"
				variant="body2" style={{ fontSize: '13px' }}>
				{intl.formatMessage({ id: 'app.general.healthcareProfessional' })}
			</Typography>
		)}
	</div>
)

const enhance = injectIntl

export default enhance(AlertDelegateCell)

import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

function mapStateToProps(state) {

    const { locale, messages } = state.core.locales;
 
    return { locale: locale, messages };

}

export default connect(mapStateToProps)(IntlProvider);
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import Lock from '@material-ui/icons/Lock'
import AvTimer from "@material-ui/icons/AvTimer"
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline"
import { isMobile } from './../../../utilities/mobileDetector'

const TaskEmpty = ({ intl }) => {
    const theme = useTheme()
    return (
        <Grid
            data-testid="mytasks-task-empty"
            container spacing={3} direction="row" style={{ padding: '32px 0' }}>
            <Grid item xs={12} sm={7} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px' }}>
                <Typography
                    data-testid="mytasks-task-empty-no-task"
                    variant='h2'
                    style={{ fontWeight: '300', color: theme.palette.primary.A800, paddingBottom: '12px' }}
                >
                    {intl.formatMessage({id: 'app.tasks.noTask'})}
                </Typography>
                <Typography
                    data-testid="mytasks-task-empty-desc"
                    variant="body2" style={{ fontWeight: '600' }}>
                    {intl.formatMessage({id: 'app.tasks.myTasks.descriptionEmpty'})}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={5} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px' }}>
                <Typography
                    data-testid="mytasks-task-empty-overdue"
                    variant="body2" style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#b0001f' }}>
                    <Lock style={{ fontSize: isMobile ? '20px' : '32px', color: '#b0001f', transform: 'translateY(3px)' }} />{' '}
                    {intl.formatMessage({id: 'app.tasks.overdue'}).toUpperCase()}
                </Typography>
                <Typography
                    data-testid="mytasks-task-empty-upcoming"
                    variant="body2" style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#8CCEFF' }}>
                    <AvTimer style={{ fontSize: isMobile ? '20px' : '32px', color: '#8CCEFF', transform: 'translateY(3px)' }} />{' '}
                    {intl.formatMessage({id: 'app.tasks.upcoming'}).toUpperCase()}
                </Typography>
                <Typography
                    data-testid="mytasks-task-empty-completed"
                    variant="body2" style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#020F20' }}>
                    <CheckCircleOutline style={{ fontSize: isMobile ? '20px' : '32px', color: '#020F20', transform: 'translateY(3px)' }} />{' '}
                    {intl.formatMessage({id: 'app.tasks.completed'}).toUpperCase()}
                </Typography>
            </Grid>
        </Grid>
    )
}

const enhance = compose(injectIntl)

export default enhance(TaskEmpty)

import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';

const CheckoutSummary = ({ intl, users, cases}) => {

  const theme = useTheme();
  const textColor = 'rgba(0,0,0,0.87)';

  return (
    <Grid
      data-testid="checkout-summary"
      item
      style={{width: '100%', marginTop: '40px' }}
    >
      <div style={{ backgroundColor: theme.palette.light, padding: '10px', display: 'flex' }}>
        <InfoIcon data-testid="checkout-summary-icon" style={{ fontSize: '24px', color: textColor, marginTop: '-1px', paddingRight: '10px' }} />
        <Typography data-testid="checkout-summary-text" variant="body2" style={{ color: textColor }}>
          {intl.formatMessage({ id: 'app.account.addCasesUsers.checkoutSummary1' })}
          {/* {users}
          {intl.formatMessage({ id: 'app.account.addCasesUsers.checkoutSummary2' })} */}
          {cases}
          {intl.formatMessage({ id: 'app.account.addCasesUsers.checkoutSummary3' })}
        </Typography>
      </div>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	withRouter
)

export default enhance(CheckoutSummary)

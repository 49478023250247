import * as states from '../../../apis/state'

const FETCH_STATES_REQUESTED = 'FETCH_STATES_REQUESTED'
const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS'
const FETCH_STATES_FAILED = 'FETCH_STATES_FAILED'

export const fetchStates = (countryId) => (dispatch) => {
    dispatch({type: FETCH_STATES_REQUESTED, data: {}})

    states.fetchStates(countryId).then((states) => {
        if(states){
            return dispatch({type: FETCH_STATES_SUCCESS, data: states})
        }else{
            return dispatch({type: FETCH_STATES_FAILED, data: {}})
        }
    })
    return
}


const initial = {
    states: []
}

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case FETCH_STATES_SUCCESS:
            return { ...state, states: action.data }
        default:
            return { ...state }
    }
}

export const statesSelector = (state) => {
    if(state && state.states && state.states.states)
        return state.states.states
    return null
}
import React from 'react'
import { TableCell, Typography, Grid } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { FormattedDate, useIntl } from 'react-intl'

export default ({ data }) => {
	const intl = useIntl()
	return (
		<TableRow data-testid="sponsor-subscriptions-usage-row">
			<TableCell
				data-testid="sponsor-subscriptions-usage-row-circle"
				width="60%"
				align="left" >
				{data && data.circleName &&
					<Typography
						variant='subtitle1'
						data-testid="cases-patient-full-name"
						className="tracking-hide-content"
						style={{ wordBreak: 'break-word' }}>
						{data.circleName}
					</Typography>
				}
				{data && data.userEmail &&
					<Grid container direction='column'>
						<Grid item>
							<Typography
								variant='subtitle1'
								data-testid="sponsor-subscriptions-usage-row-user-name"
								className="tracking-hide-content"
								style={{ wordBreak: 'break-word' }}>
								{data.userName}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								data-testid="sponsor-subscriptions-usage-row-user-email"
								variant='body2'>
								{data.userEmail}
							</Typography>
						</Grid>
					</Grid>}
			</TableCell>
			<TableCell
				data-testid="sponsor-subscriptions-usage-row-subscriptions-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.role === 1 && intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userTeamMemberLabel' })}
					{data && data.role === 2 && intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userSubscriberLabel' })}
				</Typography>
			</TableCell>
			<TableCell
				data-testid="sponsor-subscriptions-usage-row-subscriptions-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.lastLoginDate && <FormattedDate year='numeric' month='short' day='2-digit' value={data.lastLoginDate} />}
					{data && !data.lastLoginDate && intl.formatMessage({ id: 'app.sponsor.subscriptionsTable.userLastLoginPendingLabel' })}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Avatar from '@material-ui/core/Avatar'

const NumberedRow = ({ intl, number, label }) => {

	return (
		<Grid data-testid="numbered-row" container direction="column">
			<Grid item>
				<Grid container direction="row" alignItems="center" style={{ flexWrap: 'nowrap' }}>
					<Grid item style={{ marginLeft: '-7px' }}>
						<Avatar
							data-testid="numbered-row-number"
							style={{
								width: 20,
								height: 20,
								color: '#000',
								backgroundColor: '#7AB5E0'
							}}
						>
							{number}
						</Avatar>
					</Grid>
					<Grid item style={{ paddingLeft: '10px' }}>
						<Typography
							data-testid="numbered-row-text"
							style={{
								color: '#fff',
								fontWeight: '400',
								fontSize: '22px'
							}}
							align="left"
						>
							{label}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(NumberedRow)

import { authenticatedFetch } from './base'

export const fetchPathologies = () => {
	return authenticatedFetch(`/api/pathologies`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchProms = (id) => {
	return authenticatedFetch(`/api/pathologies/${id}/proms`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchPathologyAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(
		`/api/pathologies/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(pathology) {
			return pathology
		})
}

import en from './en.json'
import es from './es.json'
import ru from './ru.json'
import fr from './fr.json'
import it from './it.json'
import de from './de.json'
import da from './da.json'
import pl from './pl.json'
import tr from './tr.json'
import enGB from './en-GB.json'
import nl from './nl.json'

export default { en, es, ru, fr, it, de, da, pl, tr, 'en-GB': enGB, nl }
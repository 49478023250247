import * as api from '../../../apis/terms'
import { createSelector } from 'reselect'

const FETCH_TERMS_REQUESTED = 'FETCH_TERMS_REQUESTED'
const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS'
const FETCH_TERMS_FAILED = 'FETCH_TERMS_FAILED'

export const fetchTerms = (language) => (dispatch) => {
	dispatch({ type: FETCH_TERMS_REQUESTED, data: {} })
	api.fetchTerms(language).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_TERMS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_TERMS_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	terms: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_TERMS_SUCCESS:
			return { ...state, terms: action.data.body }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.terms

export const termsSelector = createSelector(mainSelector, (state) => {
	return state && state.terms
})

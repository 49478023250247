import { Grid } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormDateField from '../../../components/FormDateField'
import InfoTooltip from '../../../components/InfoTooltip'
import { required, date, minDateValue } from '../../../utilities/validators'

const requiredValidator = required
const dateValidator = date
const treatmentMinValueValidator = minDateValue('commencementDate')

const CaseFormInputDates = ({
	intl,
	defaultTreatmentDate,
	defaultCommencementDate
}) => {
    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12} sm={6} data-testid="add-case-modal-commencement-date">
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        className="input-with-tooltip"
                    >
                        <FormDateField
                            label={intl.formatMessage({ id: 'app.addcase.commencementDateLabel' })}
                            fullWidth
                            type='text'
                            name='commencementDate'
                            validators={[requiredValidator, dateValidator]}
                            variant='filled'
                            defaultDate={defaultCommencementDate}
                        />
                        <span className="input-with-tooltip-svg">
                        <InfoTooltip title={ intl.formatMessage({ id: "app.cases.commencementDateTooltip" }) } />
                        </span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} data-testid="add-case-modal-treatment-date">
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        className="input-with-tooltip"
                    >
                        <FormDateField
                            label={intl.formatMessage({ id: 'app.addcase.treatmentDateLabel' })}
                            fullWidth
                            type='text'
                            name='treatmentDate'
                            validators={[requiredValidator, dateValidator, treatmentMinValueValidator]}
                            variant='filled'
                            defaultDate={defaultTreatmentDate}
                        />
                        <span className="input-with-tooltip-svg">
                            <InfoTooltip title={ intl.formatMessage({ id: "app.cases.threatmentDateTooltip" }) } />
                        </span>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

const enhance = compose(
	injectIntl
)

export default enhance(CaseFormInputDates)

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormNumberTextField from '../../../components/FormNumberTextField'
import { number, maxValue } from '../../../utilities/validators'
import Link from "@material-ui/core/Link";
const numberValidator = number
const maxValueValidator = maxValue

const DistributeValue = ({ intl, label, available = 0, entityType, name, current = 0 }) => {
    return (
        <Grid data-testid="distribute-value" container direction="row" spacing={3} style={{ width: '100%', margin: '0' }}>
            <Grid item xs={12} sm={8} style={{ color: '#fff' }}>
                <Grid container>
                    <Grid item style={{paddingRight: '40px' }}>
                        <Typography
                            data-testid="distribute-value-label"
                            style={{
                                color: '#fff',
                                fontWeight: '300',
                                fontSize: '22px'
                            }}
                            align="left"
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            data-testid="distribute-value-current"
                            style={{ color: '#a2a7ab', fontSize:'14px' }}>
                            {`${current} ${intl.formatMessage({id: 'app.general.current'})} ${entityType}`}
                        </Typography>
                        <Typography
                            data-testid="distribute-value-available"
                            style={{ color: '#a2a7ab', fontSize:'14px' }}>
                            {`${available} ${intl.formatMessage({id: 'app.general.available'})} ${entityType}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {available &&
            (<Grid
                data-testid="distribute-value-is-available"
                item xs={12} sm={4} style={{ color: '#fff' }}>
                <Grid container direction="column">
                    <Grid item>
                        <FormNumberTextField
                            type="text"
                            name={name}
                            maxLength={4}
                            variant="filled"
                            validators={[ numberValidator, maxValueValidator(9999) ]}
                        />
                        <Typography style={{ color: '#a2a7ab', fontSize: '0.9em' }}>
                            {`${intl.formatMessage({id: 'app.general.assign'})} ${entityType}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>)}
            {!available &&
            (
                <Grid
                data-testid="distribute-value-not-available"
                    item xs={12} sm={4} style={{ color: '#fff' }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Link to="/plan">
                                <Typography style={{ color: '#a2a7ab', fontSize: '0.9em', textDecoration: 'none', paddingTop: '6px' }}>
                                    Get more {entityType.toLowerCase()}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

const enhance = compose(injectIntl)

export default enhance(DistributeValue)

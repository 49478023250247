import React, { useState, useEffect, Fragment } from 'react'
import Person from '@material-ui/icons/Person'
import PersonOutlined from '@material-ui/icons/PersonOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const MemberStatusIcon = ({ member, intl }) => {
	const [ tooltip, setToolTip ] = useState('')

	useEffect(() => {
		if (member && member.dateJoined && moment(member.dateJoined).isValid()) {
			setToolTip(intl.formatMessage({ id: 'app.general.active' }))
		} else {
			setToolTip(intl.formatMessage({ id: 'app.general.pending' }))
		}
	}, [])

	return (
		<Tooltip data-testid="member-status-icon-tooltip" title={tooltip} disableFocusListener disableTouchListener>
			<div>
				{member && !member.isOwner && member.lastLogin && <Person data-testid="tooltip-person" style={{ fontSize: '24px' }} />}
				{member && !member.isOwner && !member.lastLogin && <PersonOutlined  data-testid="tooltip-person-outlined" style={{ fontSize: '24px' }} />}
				{member && member.isOwner && <SupervisorAccountIcon  data-testid="tooltip-supervisor" style={{ fontSize: '24px' }} />}
			</div>
		</Tooltip>
	)
}

export default injectIntl(MemberStatusIcon)

import { authenticatedFetch } from './base'

export const fetchTreatmentAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(
		`/api/treatment/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(pathology) {
			return pathology
		})
}

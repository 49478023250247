import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core'
import theme from './utilities/theme'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './redux/reducers'
import thunk from 'redux-thunk'
import Root from './pages/index'
import PrivateRoute from './routes/PrivateRoute'
import SecureRoot from './pages/SecureRoot'
import Terms from './pages/terms'
import CircleAutoInvite from './pages/circleAutoInvite'
import SponsorStatistics from './pages/sponsorStatistics'
import ConnectedIntlProvider from './utilities/ConnectedIntlProvider'
import { composeWithDevTools } from 'redux-devtools-extension'
import history from './utilities/history'
import AppUpdatesChecker from './utilities/AppUpdatesChecker'
import { ErrorLogger } from './components/ErrorLogger'

if (!global.Intl || !global.Intl.PluralRules) {
	console.log('Loading polyfill');
	require('@formatjs/intl-pluralrules/polyfill')
	require('@formatjs/intl-pluralrules/dist/locale-data/en') // Add locale data for en
	require('@formatjs/intl-pluralrules/dist/locale-data/es') // Add locale data for es
	require('@formatjs/intl-pluralrules/dist/locale-data/fr') // Add locale data for fr
	require('@formatjs/intl-pluralrules/dist/locale-data/ru') // Add locale data for ru
	require('@formatjs/intl-pluralrules/dist/locale-data/it') // Add locale data for it
	require('@formatjs/intl-pluralrules/dist/locale-data/de') // Add locale data for de
    require('@formatjs/intl-pluralrules/dist/locale-data/da') // Add locale data for da
	require('@formatjs/intl-pluralrules/dist/locale-data/pl') // Add locale data for pl
    require('@formatjs/intl-pluralrules/dist/locale-data/tr') // Add locale data for tr
    require('@formatjs/intl-pluralrules/dist/locale-data/nl') // Add locale data for nl
}

if (!global.Intl || !global.Intl.RelativeTimeFormat) {
	console.log('Loading polyfill');
	require('@formatjs/intl-relativetimeformat/polyfill')
	require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for en
	require('@formatjs/intl-relativetimeformat/dist/locale-data/es') // Add locale data for es
	require('@formatjs/intl-relativetimeformat/dist/locale-data/fr') // Add locale data for fr
	require('@formatjs/intl-relativetimeformat/dist/locale-data/ru') // Add locale data for ru
	require('@formatjs/intl-relativetimeformat/dist/locale-data/it') // Add locale data for it
	require('@formatjs/intl-relativetimeformat/dist/locale-data/de') // Add locale data for de
    require('@formatjs/intl-relativetimeformat/dist/locale-data/da') // Add locale data for da
    require('@formatjs/intl-relativetimeformat/dist/locale-data/pl') // Add locale data for pl
    require('@formatjs/intl-relativetimeformat/dist/locale-data/tr') // Add locale data for tr
    require('@formatjs/intl-relativetimeformat/dist/locale-data/nl') // Add locale data for nl
}

	console.log('passed');

const logger = (store) => (next) => (action) => {
	console.info('dispatching', action)
	let result = next(action)
	console.info('next state', store.getState())
	return result
}

// manually load scripts (available on window obj)
const head = document.querySelector('head')
const script = document.createElement('script')

script.setAttribute('src', 'https://js.stripe.com/v3/')
head.appendChild(script)

// const CLARITY_ID = process.env.REACT_APP_CLARITY_ID
// const clarityScript = document.createElement('script')
// clarityScript.setAttribute('type', 'text/javascript')
// clarityScript.innerHTML = `
// (function(c,l,a,r,i,t,y){
// 	c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
// 	t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
// 	y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
// })(window, document, "clarity", "script", "${CLARITY_ID}");`

// head.appendChild(clarityScript)

const middlewares = [thunk]
if (process.env.NODE_ENV === 'development') {
	middlewares.push(logger)
}

const middlewareEnhancer = applyMiddleware(...middlewares)
const composedEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools(middlewareEnhancer) : compose(middlewareEnhancer)

const store = createStore(reducer, composedEnhancers)

//const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk, logger)))

ReactDOM.render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<ConnectedIntlProvider>
					<AppUpdatesChecker>
						<Router history={history}>
							<Switch>
								<Route path='/auth' component={SecureRoot} />
								<Route path='/terms' component={Terms} />
								<Route path='/circleinvitation' component={CircleAutoInvite} />
								<Route exact={true} path="/sponsor/:token" component={SponsorStatistics} />
								<PrivateRoute path='/' component={Root} />
							</Switch>
						</Router>
					</AppUpdatesChecker>
				</ConnectedIntlProvider>
			</MuiThemeProvider>
		</Provider>,
	document.getElementById('root')
)

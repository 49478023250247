import * as api from '../../../apis/globalSearch'
import { createSelector } from 'reselect'

const GLOBAL_SEARCH_REQUESTED = 'GLOBAL_SEARCH_REQUESTED'
const GLOBAL_SEARCH_SUCCESS = 'GLOBAL_SEARCH_SUCCESS'
const GLOBAL_SEARCH_FAILED = 'GLOBAL_SEARCH_FAILED'

export const search = (searchPhrase) => (dispatch) => {
	if (!searchPhrase) {
		return dispatch({ type: GLOBAL_SEARCH_REQUESTED, data: null })
	}

	api.search(searchPhrase).then((results) => {
		if (results && results.isSuccessful) {
			return dispatch({
				type: GLOBAL_SEARCH_SUCCESS,
				data: results
			})
		} else {
			dispatch({ type: GLOBAL_SEARCH_FAILED })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.global.searchFailed', isSuccess: false }
			})
		}
	})
	return
}

const initial = {
	surveyResults: [],
	hasSurveys: false,
	caseResults: [],
	hasCases: false,
	circleResults: [],
	hasCircles: false,
	protocolResults: [],
	hasProtocols: false,
    serviceProviders: [],
	hasServiceProviders: false,
    serviceProviderTasks: [],
    hasServiceProviderTasks: false,
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case GLOBAL_SEARCH_SUCCESS:
			return {
				...state,
				hasSurveys:
					action.data.surveys && action.data.surveys.surveys && action.data.surveys.surveys.length > 0,
				surveyResults: action.data.surveys && action.data.surveys.surveys,
				hasProtocols:
					action.data.protocols &&
					action.data.protocols.protocols &&
					action.data.protocols.protocols.length > 0,
				protocolResults: action.data.protocols && action.data.protocols.protocols,
				hasCircles:
					action.data.circles && action.data.circles.circles && action.data.circles.circles.length > 0,
				circleResults: action.data.circles && action.data.circles.circles,
				hasCases: action.data.cases && action.data.cases.cases && action.data.cases.cases.length > 0,
				caseResults: action.data.cases && action.data.cases.cases,
				hasServiceProviders: action.data.serviceProviders && action.data.serviceProviders.serviceProviders.length > 0,
				serviceProviderResults: action.data.serviceProviders && action.data.serviceProviders.serviceProviders,
				hasServiceProviderTasks: action.data.serviceProviderTasks && action.data.serviceProviderTasks.serviceProviderTasks.length > 0,
				serviceProviderTasksResults: action.data.serviceProviderTasks && action.data.serviceProviderTasks.serviceProviderTasks
			}
		case GLOBAL_SEARCH_REQUESTED:
			return {
				...state,
				hasSurveys: false,
				surveyResults: [],
				hasProtocols: false,
				protocolResults: [],
				hasCircles: false,
				circleResults: [],
				hasCases: false,
				caseResults: [],
                serviceProviders: [],
                hasServiceProviders: false,
                serviceProviderTasks: [],
                hasServiceProviderTasks: false,
			}

		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.search

export const globalSearchCasesSelector = createSelector(mainSelector, (state) => {
	return state.caseResults
})

export const globalSearchCirclesSelector = createSelector(mainSelector, (state) => {
	return state.circleResults
})

export const globalSearchServiceProvidersSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviderResults
})

export const globalSearchServiceProviderTasksSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviderTasksResults
})

export const globalSearchProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.protocolResults
})

export const globalSearchSurveysSelector = createSelector(mainSelector, (state) => {
	return state.protocolResults
})

export const hasSearchCasesSelector = createSelector(mainSelector, (state) => {
	return state.hasCases
})

export const hasSearchCirclesSelector = createSelector(mainSelector, (state) => {
	return state.hasCircles
})

export const hasSearchProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.hasProtocols
})

export const hasSearchSurveysSelector = createSelector(mainSelector, (state) => {
	return state.hasSurveys
})

export const hasSearchServiceProvidersSelector = createSelector(mainSelector, (state) => {
	return state.hasServiceProviders
})

export const hasSearchServiceProviderTasksSelector = createSelector(mainSelector, (state) => {
	return state.hasServiceProviderTasks
})

import React from 'react'
import { TableCell, Typography, Grid } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'

export default ({ data }) => {
	return (
		<TableRow data-testid="sponsor-circle-investigators-row">
			<TableCell
				data-testid="sponsor-circle-investigators-row-investigator"
				width="20%"
				align="left">
				<Grid container direction='column'>
					<Grid item>
						<Typography
							variant='subtitle1'
							data-testid="sponsor-subscriptions-usage-row-user-name"
							className="tracking-hide-content"
							style={{ wordBreak: 'break-word' }}>
							{data.userName}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							data-testid="sponsor-subscriptions-usage-row-user-email"
							variant='body2'>
							{data.userEmail}
						</Typography>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell
				data-testid="sponsor-circle-investigators-row-cases-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.casesCount}
				</Typography>
			</TableCell>
			<TableCell
				data-testid="sponsor-circle-investigators-row-sms-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.smsCount}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

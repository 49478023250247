import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { Field, change } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import { fetchTreatmentAutoComplete } from '../../../redux/actions'
import { useSelector, shallowEqual } from 'react-redux'
import { treatmentAutoCompleteSuggestionSelector } from '../../../redux/selectors'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds, minimumSearchCharacters } from '../../../utilities/constants'

var debounce = require('lodash/debounce')

const useASStyles = makeStyles((theme) => ({
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
}))

export const TreatmentSelectorControl = ({
	label,
	meta: { initial, dispatch, form, touched, error, invalid },
	input,
	noMatchText,
	initialValue
}) => {
	const treatments = useSelector(treatmentAutoCompleteSuggestionSelector, shallowEqual)

	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(initialValue && initialValue.length > 0)
	const [ stateSuggestions, setSuggestions ] = useState(treatments)
	const classes = useASStyles()
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const [ state, setState ] = React.useState({
		label: initialValue ? initialValue : ''
	})

	const handleSuggestionsFetchRequested = ({ value }) => {
		if (value && value.length > minimumSearchCharacters) {
			dispatch(fetchTreatmentAutoComplete(value, noMatchText))
		}
	}

	const onSuggestionRequested = useConstant(() =>
		debounce(handleSuggestionsFetchRequested, debounceMilliseconds, { leading: true })
	)

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem data-testid="treatment-selector-menu-item" selected={isHighlighted} component='div'>
				<div>
					{suggestion.internalName && suggestion.internalName.length > 50 ? (
						suggestion.internalName.substring(0, 70) + '...'
					) : (
						suggestion.internalName
					)}
				</div>
			</MenuItem>
		)
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps

		return (
			<TextField
				data-testid="treatment-selector"
				fullWidth
				error={touched && invalid}
				helperText={touched && error}
				color="secondary"
				InputProps={{
					...inputProps,
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					}
				}}
				inputProps={{ autoComplete: 'dont' }}
				shrink='true'
				variant='filled'
				{...other}
			/>
		)
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (suggestion && suggestion.id > -1) {
			dispatch(change(form, input.name, suggestion.id))
			setNoMatch(false)
			setMatchSelected(true)
		} else {
			setState({
				...state,
				value: null,
				label: ''
			})
			dispatch(change(form, input.name, ''))
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: treatments,
		onSuggestionsFetchRequested: onSuggestionRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: onSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	}

	const handleChange = (event, { newValue }) => {
		if (newValue && newValue.id && newValue.id > 0) {
			dispatch(change(form, input.name, newValue.id))
			setNoMatch(false)
			setMatchSelected(true)
		} else {
			dispatch(change(form, input.name, null))
			setNoMatch(true)
			setMatchSelected(false)
		}
		setState({
			...state,
			value: newValue,
			label: newValue && newValue.internalName ? newValue.internalName : newValue
		})
	}

	return (
		<div>
			<Autosuggest
				data-testid="treatment-selector-autosuggest"
				{...autosuggestProps}
				inputProps={{
					id: input.name,
					label: label,
					value: state.label,
					onChange: handleChange,
					onBlur: (e, x) => {
						setAnchorEl(null)

						//input.onChange(e.target.value)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: '',
								label: ''
							})
							dispatch(change(form, input.name, ''))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={(options) => (
					<Popper data-testid="treatment-selector-popper" anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 1300 }}
						placement="bottom-start"
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}
					>
						<Paper
							data-testid="treatment-selector-paper"
							square
							{...options.containerProps}
							className={classes.optionStyle}
							style={{
								maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
								width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
								marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined
								}}>
								{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	)
}

export default ({ label, name, validators, maxLength = 255, noMatchText = 'No Entries Found', ...other }) => {
	return (
		<Field
			data-testid="treatment-selector-field"
			name={name}
			component={TreatmentSelectorControl}
			label={label}
			maxLength={maxLength}
			fullWidth
			validate={validators}
			noMatchText={noMatchText}
			{...other}
		/>
	)
}

import React from 'react'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

export default ({ alert }) => {
	return (
		<div data-testid="myalerts-invitation-status-cell" className="status-cell">
			{alert && alert.circleType === 0 && <LockOpen style={{ color: '#86ceb4' }} />}
			{alert && alert.circleType === 1 && <Lock style={{ color: '#444e5e' }} />}
		</div>
	)
}

import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import Check from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'
import { Chip, debounce, makeStyles } from '@material-ui/core'
import { taskFilterSelector } from '../../../redux/selectors'
import { fetchServiceProviderTasks, setServiceProviderTaskFilter, fetchHasGlobalAccess } from '../../../redux/actions'
import { connect, useDispatch } from 'react-redux'
import LocalizedDatePicker from '../../../components/LocalizedDatePicker'
import { useState } from 'react'
import { Fragment } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { KeyboardArrowUp } from '@material-ui/icons'
import { getUser } from '../../../utilities/userUtilities'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../utilities/constants'
import { hasGlobalAccessSelector } from '../../../redux/modules/user'

const useStyles = makeStyles((theme) => ({
    chip: {
        color: theme.palette.text.primary,
        backgroundColor: '#fff',
        borderRadius: '3px',
        border: '1px solid  rgba(0, 0, 0, 0.42)',
        fontSize: '15px',
        padding: '10px 20px',
        "&:hover, &:focus": {
            backgroundColor: 'inherit',
        },
    },
    selectedChip: {
        color: '#000',
        borderColor: '#e0e0e0',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontSize: '15px',
        padding: '10px 20px',
        borderRadius: '3px',
        "& .MuiChip-icon": {
            color: '#000',
            position: 'absolute',
            left: '6px',
            top: '8px'
        },
    },
    filterLabel: {
        marginBottom: '15px',
        marginTop: '5px',
        fontWeight: '600'
    },
    filterRow: {
        paddingBottom: '10px',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
		},
        '@media print': {
            flexWrap: 'nowrap'
        }
    },
    columnStatus: {
        maxWidth: '228px',
        [theme.breakpoints.up('md')]: {
            borderRight: '1px rgba(0, 0, 0, 0.05) solid',
		}
    },
    columnDelegate: {
        [theme.breakpoints.up('md')]: {
            borderRight: '1px rgba(0, 0, 0, 0.05) solid',
		}
    },
    columnPeriod: {
        [theme.breakpoints.up('md')]: {
            minWidth: '410px'
		}
    },
    inputDate: {
        width: '183px'
    },

}))

const useLabelStyles = makeStyles({
    root: {
        color: '#000',
        marginLeft: '0'
    },
    focused: {
        color: '#fff',
        '&$focused': {
            color: '#000',
        }
    }
})

const useInputStyles = makeStyles({
    root: {
        color: '#000',
        position: 'relative',
        fontSize: '16px',
        transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        'border-top-left-radius': '4px',
        'border-top-right-radius': '4px',
        marginTop: '0',
        marginBottom: '0'
    },
    focused: {
        color: '#000',
        backgroundColor: 'rgb(36, 48, 65)',
        '&$focused': {
            color: '#000',
            backgroundColor: 'rgb(36, 48, 65)'
        }
    }
})

const useHelperStyles = makeStyles({
    root: {
        backgroundColor: '#FFF',
        marginLeft: '0px',
        paddingTop: '6px',
        padding: '0',
        fontSize: '14px',
        marginTop: '0px'
    }
})

const TaskFilters = ({ intl, setFilter, filter, fetchTasks, hasGlobalAccess }) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()
    var labelStyles = useLabelStyles()
    var inputStyles = useInputStyles()
    var helperStyles = useHelperStyles()

    const [showFilters, setShowFilters] = useState(true)
    const [errorText, setErrorText] = useState()

    const user = getUser()

    useEffect(
		() => {
			if (hasGlobalAccess === null && user.id) {
                dispatch(fetchHasGlobalAccess(user.id))
			}
		},
		[ hasGlobalAccess, user.id ]
	)

    const handleStatusChange = () => {
        let newFilter = { ...filter }
        newFilter.open = !newFilter.open
        newFilter.completed = !newFilter.completed

        setFilter(newFilter)
        fetchTasksHandler()
    }

    const handleDelegateChange = (field) => {
        let newFilter = { ...filter }
        if (field === 'allDelegates') {
            newFilter.allDelegates = !newFilter.allDelegates
            if (newFilter.allDelegates) {
                newFilter.investigator = false
                newFilter.patient = false
                newFilter.serviceProvider = false
            }
        } else if (field === 'investigator') {
            newFilter.investigator = !newFilter.investigator
            if (newFilter.investigator) {
                newFilter.allDelegates = false
            }
        } else if (field === 'patient') {
            newFilter.patient = !newFilter.patient
            if (newFilter.patient) {
                newFilter.allDelegates = false
            }
        } else if (field === 'serviceProvider') {
            newFilter.serviceProvider = !newFilter.serviceProvider
            if (newFilter.serviceProvider) {
                newFilter.allDelegates = false
            }
        }

        setFilter(newFilter)
        fetchTasksHandler()
    }

    const handleRegistrationFilterChange = (field) => {
        let newFilter = { ...filter }
        if (field === 'anyPatientRegistrationStatus') {
            newFilter.anyPatientRegistrationStatus = !newFilter.anyPatientRegistrationStatus
            if (newFilter.anyPatientRegistrationStatus) {
                newFilter.onlyNotRegisteredPatients = false
                newFilter.onlyRegisteredPatients = false
            }
        } else if (field === 'onlyNotRegisteredPatients') {
            newFilter.onlyNotRegisteredPatients = !newFilter.onlyNotRegisteredPatients
            if (newFilter.onlyNotRegisteredPatients) {
                newFilter.anyPatientRegistrationStatus = false
                newFilter.onlyRegisteredPatients = false
            }
        } else if (field === 'onlyRegisteredPatients') {
            newFilter.onlyRegisteredPatients = !newFilter.onlyRegisteredPatients
            if (newFilter.onlyRegisteredPatients) {
                newFilter.anyPatientRegistrationStatus = false
                newFilter.onlyNotRegisteredPatients = false
            }
        }

        setFilter(newFilter)
        fetchTasksHandler()
    }

    const handlePeriodChange = (field, value) => {
        setErrorText()

        if (!value) {
            setErrorText(intl.formatMessage({ id: 'app.tasks.periodFilterErrorText' }))
            return
        }

        let date = new Date(value)
        if (date instanceof Date && !isNaN(date)) {
            let newFilter = { ...filter }
            if (field === 'from')
                newFilter.fromPeriod = value
            else
                newFilter.toPeriod = value

                setFilter(newFilter)
                fetchTasksHandler()
        }
    }

    const fetchTasksHandler = useConstant(() =>
		debounce(fetchTasks, 500, { leading: false })
	)

    return (
        <>
        <Grid data-testid="mytasks-filters" container spacing={7} className={classes.filterRow}>
            {showFilters && <Fragment>

                
                <Grid item className={classes.columnStatus}>
                    <Typography
                        data-testid="mytasks-filters-status"
                        variant="subtitle1"
                        className={classes.filterLabel}
                    >
                        {intl.formatMessage({ id: 'app.tasks.statusFilterLabel' })}
                    </Typography>
                    <Grid container direction='row' spacing={2}>
                        <Grid item style={{ width: '100%' }}>
                                <Chip
                                style={{ minWidth: '136px'}}
                                label={intl.formatMessage({ id: 'app.tasks.statusFilterOpenLabel' })}
                                className={filter.open ? classes.selectedChip : classes.chip}
                                onClick={() => !filter.open && handleStatusChange()}
                                icon={filter.open && <Check />}
                            />
                        </Grid>
                        <Grid item>
                            <Chip
                                style={{ minWidth: '136px'}}
                                label={intl.formatMessage({ id: 'app.tasks.statusFilterCompletedLabel' })}
                                className={filter.completed ? classes.selectedChip : classes.chip}
                                onClick={() => !filter.completed && handleStatusChange()}
                                icon={filter.completed && <Check />}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                    {
                        (hasGlobalAccess || !user.isServiceProviderMember)
                        && <Grid item className={classes.columnDelegate}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <Typography
                                        data-testid="mytasks-filters-delegate"
                                        variant="subtitle1"
                                        className={classes.filterLabel}
                                    >
                                        {intl.formatMessage({ id: 'app.tasks.delegateFilterLabel' })}
                                    </Typography>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.delegateFilterAllLabel' })}
                                                className={filter.allDelegates ? classes.selectedChip : classes.chip}
                                                onClick={() => !filter.allDelegates && handleDelegateChange('allDelegates')}
                                                icon={filter.allDelegates && <Check />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.delegateFilterInvestigatorLabel' })}
                                                className={filter.investigator ? classes.selectedChip : classes.chip}
                                                onClick={() => (!filter.investigator || filter.patient || filter.serviceProvider) && handleDelegateChange('investigator')}
                                                icon={filter.investigator && <Check />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.delegateFilterPatientLabel' })}
                                                className={filter.patient ? classes.selectedChip : classes.chip}
                                                onClick={() => (filter.investigator || !filter.patient || filter.serviceProvider) && handleDelegateChange('patient')}
                                                icon={filter.patient && <Check />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.delegateFilterServiceProviderLabel' })}
                                                className={filter.serviceProvider ? classes.selectedChip : classes.chip}
                                                onClick={() => (filter.investigator || filter.patient || !filter.serviceProvider) && handleDelegateChange('serviceProvider')}
                                                icon={filter.serviceProvider && <Check />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        data-testid="mytasks-filters-delegate"
                                        variant="subtitle1"
                                        className={classes.filterLabel}
                                    >
                                        {intl.formatMessage({ id: 'app.tasks.patientStatusFilterLabel' })}
                                    </Typography>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.patientStatusFilterAllLabel' })}
                                                className={filter.anyPatientRegistrationStatus ? classes.selectedChip : classes.chip}
                                                onClick={() => !filter.anyPatientRegistrationStatus && handleRegistrationFilterChange('anyPatientRegistrationStatus')}
                                                icon={filter.anyPatientRegistrationStatus && <Check />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.patientStatusFilterRegisteredLabel' })}
                                                className={filter.onlyRegisteredPatients ? classes.selectedChip : classes.chip}
                                                onClick={() => !filter.onlyRegisteredPatients && handleRegistrationFilterChange('onlyRegisteredPatients')}
                                                icon={filter.onlyRegisteredPatients && <Check />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={intl.formatMessage({ id: 'app.tasks.patientStatusFilterNotRegisteredLabel' })}
                                                className={filter.onlyNotRegisteredPatients ? classes.selectedChip : classes.chip}
                                                onClick={() => !filter.onlyNotRegisteredPatients && handleRegistrationFilterChange('onlyNotRegisteredPatients')}
                                                icon={filter.onlyNotRegisteredPatients && <Check />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                <Grid item className={classes.columnPeriod}>
                    <Typography
                        data-testid="mytasks-filters-period"
                        variant="subtitle1"
                        className={classes.filterLabel}
                    >
                        {intl.formatMessage({ id: filter.open ? 'app.tasks.periodFilterDueLabel' : 'app.tasks.periodFilterCompletedLabel' })}
                    </Typography>
                    <Grid container direction='row' spacing={2} style={{ marginRight: '0' }}>
                        <Grid item className={classes.inputDate}>
                            <LocalizedDatePicker
                                label={intl.formatMessage({ id: 'app.tasks.periodFilterFromLabel' })}
                                onDateChange={(value) => handlePeriodChange('from', value)}
                                defaultDate={filter.fromPeriod}
                                externalInputStyles={inputStyles}
                                externalLabelStyles={labelStyles}
                                externalHelperStyles={helperStyles}
                            />
                        </Grid>
                        <Grid item className={classes.inputDate}>
                            <LocalizedDatePicker
                                label={intl.formatMessage({ id: 'app.tasks.periodFilterToLabel' })}
                                onDateChange={(value) => handlePeriodChange('to', value)}
                                defaultDate={filter.toPeriod}
                                externalInputStyles={inputStyles}
                                externalLabelStyles={labelStyles}
                                externalHelperStyles={helperStyles}
                            />
                        </Grid>
                    </Grid>
                    {errorText && (
                        <Grid item style={{ paddingBottom: '30px', width: '100%' }}>
                            <Typography data-testid="mytask-filters-error" variant="caption" style={{ color: 'red' }}>
                                {errorText}
                            </Typography>
                        </Grid>
                    )}
                </Grid>

            </Fragment>}
            
        </Grid>

        <Grid data-testid="mytasks-filters-button" container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant='text'
                    color='primary'
                    onClick={() => setShowFilters(!showFilters)}
                    endIcon={showFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    style={{ marginTop: showFilters ? '0' : '52px' }}
                >
                    {intl.formatMessage({ id: showFilters ? 'app.tasks.hideFiltersLabel' : 'app.tasks.showFiltersLabel' })}
                </Button>
            </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    filter: taskFilterSelector(state),
    hasGlobalAccess: hasGlobalAccessSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFilter: (id) => dispatch(setServiceProviderTaskFilter(id)),
    fetchTasks: () => dispatch(fetchServiceProviderTasks())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(TaskFilters)

import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { sortCircleDirectionSelector, sortCircleFieldSelector } from '../../../redux/selectors'
import { setCircleSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const CircleHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow data-testid="circle-header">
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0 }} />
			<TableCell data-testid="circle-header-name" align="left">
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"						
					>
						{intl.formatMessage({ id: 'app.circles.name' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="circle-header-number" align="left">
				<TableSortLabel
					active={field === 'isBilateral'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('isBilateral', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.laterality.lateralityLabel' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="circle-header-number" align="left">
				<TableSortLabel
					active={field === 'numberUsers'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('numberUsers', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.circle.subscribers' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="circle-header-shared" align="left">
				<TableSortLabel
					active={field === 'totalSharedCases'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('totalSharedCases', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.circle.casesShared' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="circle-header-role" align="left">
				<TableSortLabel
					active={field === 'roleName'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('roleName', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					{' '}
					<Typography
						variant="caption"						
					>
						{intl.formatMessage({ id: 'app.circles.myRole' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortCircleDirectionSelector(state),
	field: sortCircleFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setCircleSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(CircleHeader)

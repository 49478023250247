import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { signIn, resetFailedLogin } from '../../redux/actions'
import { verifyPhoneMaskSelector } from '../../redux/selectors'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm } from 'redux-form'
import { required } from '../../utilities/validators'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import Container from '@material-ui/core/Container'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { isMobile } from './../../utilities/mobileDetector'
import VerifyPhoneNumber from '../../components/VerifyPhoneNumber'
import FormPassword from '../../components/FormPassword'


const Login = ({ login, handleSubmit, intl, resetError, location, verifyPhoneMask }) => {
	const theme = useTheme()

	const [ returnPath, setReturnPath ] = useState(null)
	const [rememberMe, setRememberMe] = useState(true);

	const [authRequest, setAuthRequest] = useState(() => () => {})

	const onSubmitForm = (values) => {
		setAuthRequest(() => () => login(values.email, values.password, rememberMe, returnPath, intl.locale))
		login(values.email, values.password, rememberMe, returnPath, intl.locale)
	}

    const handleChange = event => {
        setRememberMe(event.target.checked);
    };

	useEffect(() => {
		resetError()
		const values = queryString.parse(location.search)

		if (values.returnUrl) {
			setReturnPath(values.returnUrl)
		}
	}, [])

	return (
		verifyPhoneMask
			?
			<Grid item style={{ maxWidth: '610px', width: 'calc(100% - 60px)', margin: '15px' }} >
				<Grid container direction="column" alignItems="center" justifyContent="center"
					spacing={isMobile ? 0 : 5}
					style={{ backgroundColor: theme.palette.secondary.main, marginTop: '0', marginBottom: '0' }}>
					<Grid item xs={isMobile ? 9 : 10} style={{ width: '100%', paddingTop: isMobile ? '0' : '15px', paddingBottom: isMobile ? '0' : '15px' }}>
						<VerifyPhoneNumber mode="verify-sign-in" returnUrl={returnPath} resendHandler={authRequest}/>
					</Grid>
				</Grid>
			</Grid>
			:
			<form data-testid="form-login" onSubmit={handleSubmit(onSubmitForm)} style={{ maxWidth: '610px', width: 'calc(100% - 60px)', margin: '15px' }}>			
				<Grid container direction="column" alignItems="center" justifyContent="center"
					spacing={isMobile ? 0 : 5}
					style={{ backgroundColor: theme.palette.secondary.main, marginTop: '0', marginBottom: '0' }}
				>
					<Grid item xs={isMobile ? 9 : 10} style={{ width: '100%', paddingTop: isMobile ? '30px' : '60px', paddingBottom: isMobile ? '30px' : '60px' }}>
						<Grid item>
							<Typography
								data-testid="form-login-title"
								variant='h1'
								style={{
									color: '#fff',
									textAlign: 'center',
									marginBottom: '40px'
								}}
							>
								{intl.formatMessage({ id: 'app.login.title' })}
							</Typography>
						</Grid>
						<Grid item style={{ minWidth: '100%', minHeight: '90px' }}>
							<FormTextField
								data-testid="form-login-email"
								label={intl.formatMessage({ id: 'app.login.email' })}
								fullWidth
								type="text"
								name="email"
								validators={[required]}
								variant="filled"
								maxLength={255}
							/>
						</Grid>
						<Grid item style={{ minWidth: '100%', minHeight: '50px' }}>
							<FormPassword
								data-testid="form-login-password"
								label={intl.formatMessage({ id: 'app.login.password' })}
								fullWidth
								name="password"
								variant="filled"
								validators={[required]}
								maxLength={99}
							/>
						</Grid>
						<Grid item style={{ minWidth: '100%' }}>
							<Grid item>
								<Button to="/auth/forgotPassword"
									data-testid="form-login-button-forgot"
									component={Link}
									style={{
										padding: '5px 0',
									}}
									variant="text"
									color="secondary"
									size="small"
								>
									{intl.formatMessage({ id: 'app.forgotPass.label' })}
								</Button>
							</Grid>
						</Grid>
						<Grid item style={{ minWidth: '100%', margin: '15px 0 40px' }}>
							<FormControlLabel
								control={
									<Checkbox
										data-testid="form-login-checkbox-keep-signed"
										onChange={handleChange}
										checked={rememberMe}
										color="secondary"
									/>
								}
								label={
									<Grid data-testid="form-login-keep-label">
										<div style={{ fontSize: '12px', color: '#fff' }}>
											{intl.formatMessage({ id: 'app.keepMeSignedIn.label' })}
										</div>
										<div style={{ fontSize: '12px', color: '#fff' }}>
											{intl.formatMessage({ id: 'app.keepMeSignedIn.message' })}
										</div>
									</Grid>
								}
							/>
						</Grid>
						<Grid item style={{ minWidth: '100%' }}>
							<Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: 'calc(100% + 60px)', margin: '0 -30px' }}>
								<Grid item style={{ margin: '10px' }}>
									{/* <Button
										data-testid="form-login-button-register"
										component={Link}
										to="/auth/register"
										variant="outlined"
										color="primary"
									>
										{intl.formatMessage({ id: 'app.login.signupLabelText' })}
									</Button> */}
									<a href="https://www.rgnmed.com/support/request-a-demo">
										<Button
											data-testid="form-login-button-register"
											variant="outlined"
											color="primary"
										>
											{intl.formatMessage({ id: 'app.login.signupLabelText' })}
										</Button>
									</a>
								</Grid>
								<Grid item style={{ margin: '10px' }}>
									<Button
										data-testid="form-login-button-login"
										name="loginButton"
										variant="contained"
										color="primary"
										type="submit"
									>
										{intl.formatMessage({ id: 'app.login.signinLabelText' })}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	failed: state.auth.failedLogin,
	verifyPhoneMask: verifyPhoneMaskSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	login: (email, password, rememberMe, returnUrl, language) => {
		return dispatch(signIn(email, password, rememberMe, returnUrl, language))
	},
	resetError: () => dispatch(resetFailedLogin())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'login'
	}),
	withRouter
)

export default enhance(Login)

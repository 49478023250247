import React, { useEffect } from 'react'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { Typography } from '@material-ui/core'
import FormSwitch from '../../../components/FormSwitch'
import { jurisdictionsSelector, languagesSelector, protocolVersionsSelector } from '../../../redux/selectors'
import { fetchJurisdictions, fetchLanguages } from '../../../redux/actions'
import JurisdictionSelector from '../../../components/JurisdictionSelector'
import { connect } from 'react-redux'
import LanguageSelectorV2 from '../../../components/LanguageSelectorV2'
import SharedWithSelector from './SharedWithSelector'
import ProtocolVersionSelector from './ProtocolVersionSelector'
import { useState } from 'react'


const AdvancedCaseSettings = ({
	jurisdictions,
	languages,
	loadLanguages,
	loadJurisdictions,
	intl,
	useExistingPatient,
	protocolVersions,
	protocolVersionId,
	onProtocolVersionChange,
	piRegionLocked
}) => {
	useEffect(() => {
		loadLanguages()
		loadJurisdictions()
	}, [])

	const handleProtocolVersionChange = (id) => {
		if(onProtocolVersionChange) {
			const value = protocolVersions.find(x => x.id === id)

			onProtocolVersionChange(value)
		}
	}

	return (
		<div>
			<Grid
				item
				style={{
					paddingTop: '6px',
					width: '100%',
					marginBottom: '-22px'
				}}>
				<div style={{ marginLeft: '20px', minHeight: '20px' }}>
					<Grid container spacing={2}>
						<Grid data-testid="add-new-patient-jurisdiction" item xs={12} sm={6}>
							<JurisdictionSelector
								label={intl.formatMessage({ id: 'app.general.jurisdictionLabel' })}
								fullWidth
								data={jurisdictions}
								name="jurisdictionId"
								variant="filled"
								disabled={useExistingPatient || piRegionLocked}
								regionLocked={piRegionLocked}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LanguageSelectorV2
								label={intl.formatMessage({ id: 'app.general.communicationOnLabel' })}
								fullWidth
								data={languages}
								name="languageId"
								variant="filled"
							/>
						</Grid>
					</Grid>
				</div>
			</Grid>
			<Grid
				item
				style={{
					width: '100%',
					marginBottom: '17px'
				}}>
				<div style={{ marginLeft: '20px', minHeight: '20px' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} style={{ display: 'flex' }}>
							<Grid container style={{ flexDirection: 'row', minHeight: '20px', flexWrap: 'nowrap' }}>
								<Grid data-testid="add-case-modal-edit-completed-date-switch" item>
									<FormSwitch name='allowEditCompletedDate' />
								</Grid>
								<Grid item style={{ paddingTop: '6px' }} data-testid="add-case-modal-completed-date-label">
									<Typography
										variant='body2'
										style={{ fontSize: '17px', color: '#fff', fontWeight: '500' }}>
										{
											intl.formatMessage({ id: 'app.addcase.allowEditCompletedDateLabel' })
										}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid data-testid="add-case-modal-send-email-label" item xs={12} sm={6}>
							<Grid container style={{ flexWrap: 'nowrap' }}>
								<Grid data-testid="update-circle-send-email-switch" item>
									<FormSwitch name='sendRegistrationEmail' />
								</Grid>
								<Grid item style={{ marginTop: '5px' }}>
									<Typography
										variant='body2'
										style={{ fontSize: '17px', color: '#fff', fontWeight: '500' }}>
										{
											intl.formatMessage({ id: 'app.addcase.sendEmailLabel' })
										}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

					</Grid>
				</div>
			</Grid>
			<div style={{ marginLeft: '20px', minHeight: '80px' }}>
				<Grid item xs={12}>
					<SharedWithSelector
						name='sharedCircles'
						label={intl.formatMessage({
							id: 'app.cases.step4Label'
						})}
						noMatchText={intl.formatMessage({
							id: 'app.noMatches'
						})}
						protocolVersionId={protocolVersionId}
					/>
				</Grid>
			</div>
			<div style={{ marginLeft: '20px', minHeight: '20px' }}>
				<Grid item xs={12}>
					<ProtocolVersionSelector
						label={intl.formatMessage({ id: 'app.cases.protocolVersion' })}
						fullWidth
						data={protocolVersions}
						name="protocolVersionId"
						variant="filled"
						onChange={handleProtocolVersionChange}
					/>
				</Grid>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	jurisdictions: jurisdictionsSelector(state),
	languages: languagesSelector(state),
	protocolVersions: protocolVersionsSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	loadLanguages: () => dispatch(fetchLanguages()),
	loadJurisdictions: () => dispatch(fetchJurisdictions())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AdvancedCaseSettings)

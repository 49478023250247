import * as subscription from '../../../apis/subscription'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'

const FETCH_SUBSCRIPTION_REQUESTED = 'FETCH_SUBSCRIPTION_REQUESTED'
const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS'
const FETCH_SUBSCRIPTION_FAILED = 'FETCH_SUBSCRIPTION_FAILED'

const SUBSCRIPTION_READY = 'SUBSCRIPTION_READY'

export const fetchSubscription = (forceUpdate) => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscription && !forceUpdate) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_REQUESTED, data: {} })
		subscription.fetchSubscription().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_SUBSCRIPTION_USER_CASE_REQUESTED = 'FETCH_SUBSCRIPTION_USER_CASE_REQUESTED'
const FETCH_SUBSCRIPTION_USER_CASE_SUCCESS = 'FETCH_SUBSCRIPTION_USER_CASE_SUCCESS'
const FETCH_SUBSCRIPTION_USER_CASE_FAILED = 'FETCH_SUBSCRIPTION_USER_CASE_FAILED'

export const fetchSubscriptionUsersCases = (forceUpdate) => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscriptionUser && state.subscription.subscriptionCase && !forceUpdate) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_REQUESTED, data: {} })
		subscription.fetchSubscriptionUsersCases().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_PLAN_REQUESTED = 'FETCH_PLAN_REQUESTED'
const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS'
const FETCH_PLAN_FAILED = 'FETCH_PLAN_FAILED'

export const fetchPlan = () => (dispatch, getState) => {
	dispatch({ type: FETCH_PLAN_REQUESTED, data: {} })
	subscription.fetchPlan().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_PLAN_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_PLAN_FAILED, data: {} })
		}
	})
}

const FETCH_CIRCLE_PLAN_REQUESTED = 'FETCH_CIRCLE_PLAN_REQUESTED'
const FETCH_CIRCLE_PLAN_SUCCESS = 'FETCH_CIRCLE_PLAN_SUCCESS'
const FETCH_CIRCLE_PLAN_FAILED = 'FETCH_CIRCLE_PLAN_FAILED'

export const fetchCirclePlan = (id) => (dispatch, getState) => {
	dispatch({ type: FETCH_CIRCLE_PLAN_REQUESTED, data: {} })
	subscription.fetchCirclePlan(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CIRCLE_PLAN_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_CIRCLE_PLAN_FAILED, data: {} })
		}
	})
}

const FETCH_CIRCLE_PLAN_STATISTICS_REQUESTED = 'FETCH_CIRCLE_PLAN_STATISTICS_REQUESTED'
const FETCH_CIRCLE_PLAN_STATISTICS_SUCCESS = 'FETCH_CIRCLE_PLAN_STATISTICS_SUCCESS'
const FETCH_CIRCLE_PLAN_STATISTICS_FAILED = 'FETCH_CIRCLE_PLAN_STATISTICS_FAILED'

export const fetchCirclePlanStatistics = (id) => (dispatch, getState) => {
	dispatch({ type: FETCH_CIRCLE_PLAN_STATISTICS_REQUESTED, data: {} })
	subscription.fetchCirclePlanStatistics(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CIRCLE_PLAN_STATISTICS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_CIRCLE_PLAN_STATISTICS_FAILED, data: {} })
		}
	})
}

const FETCH_SUBSCRIPTION_CASE_REQUESTED = 'FETCH_SUBSCRIPTION_CASE_REQUESTED'
const FETCH_SUBSCRIPTION_CASE_SUCCESS = 'FETCH_SUBSCRIPTION_CASE_SUCCESS'
const FETCH_SUBSCRIPTION_CASE_FAILED = 'FETCH_SUBSCRIPTION_CASE_FAILED'

export const fetchSubscriptionCases = () => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscriptionCase) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_CASE_REQUESTED, data: {} })
		subscription.fetchSubscriptionCases().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_CASE_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_CASE_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_SUBSCRIPTION_USER_REQUESTED = 'FETCH_SUBSCRIPTION_USER_REQUESTED'
const FETCH_SUBSCRIPTION_USER_SUCCESS = 'FETCH_SUBSCRIPTION_USER_SUCCESS'
const FETCH_SUBSCRIPTION_USER_FAILED = 'FETCH_SUBSCRIPTION_USER_FAILED'

export const fetchSubscriptionUsers = () => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscriptionUser) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_USER_REQUESTED, data: {} })
		subscription.fetchSubscriptionUsers().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_CIRCLE_USER_CASE_REQUESTED = 'FETCH_CIRCLE_USER_CASE_REQUESTED'
const FETCH_CIRCLE_USER_CASE_SUCCESS = 'FETCH_CIRCLE_USER_CASE_SUCCESS'
const FETCH_CIRCLE_USER_CASE_FAILED = 'FETCH_CIRCLE_USER_CASE_FAILED'

export const fetchCircleUsersCases = (circleId) => (dispatch, getState) => {
	dispatch({ type: FETCH_CIRCLE_USER_CASE_REQUESTED, data: {} })
	subscription.fetchCircleUsersCases(circleId).then((circleInfo) => {
		if (circleInfo) {
			return dispatch({ type: FETCH_CIRCLE_USER_CASE_SUCCESS, data: circleInfo })
		} else {
			return dispatch({ type: FETCH_CIRCLE_USER_CASE_FAILED, data: {} })
		}
	})
	return
}

const CHECK_SUBSCRIPTION_STATUS_REQUESTED = 'CHECK_SUBSCRIPTION_STATUS_REQUESTED'
const CHECK_SUBSCRIPTION_STATUS_SUCCESS = 'CHECK_SUBSCRIPTION_STATUS_SUCCESS'
const CHECK_SUBSCRIPTION_STATUS_FAILED = 'CHECK_SUBSCRIPTION_STATUS_FAILED'

export const checkSubscriptionStatusByEmail = (circleId, email) => (dispatch, getState) => {
	dispatch({ type: CHECK_SUBSCRIPTION_STATUS_REQUESTED, data: {} })
	subscription.checkSubscriptionStatusByEmail(circleId, email).then((subscriptionStatus) => {
		if (subscriptionStatus) {
			return dispatch({ type: CHECK_SUBSCRIPTION_STATUS_SUCCESS, data: true })
		} else {
			return dispatch({ type: CHECK_SUBSCRIPTION_STATUS_FAILED, data: false })
		}
	})
	return
}

const FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_REQUESTED = 'FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_REQUESTED'
const FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_SUCCESS = 'FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_SUCCESS'
const FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_FAILED = 'FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_FAILED'

const SUBSCRIPTION_SPONSOR_CIRCLE_READY = 'SUBSCRIPTION_SPONSOR_CIRCLE_READY'

export const fetchSubscriptionSponsorCircle = () => (dispatch) => {
	dispatch({ type: FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_REQUESTED, data: {} })
	subscription.fetchSubscriptionSponsorCircle().then((sponsor) => {
		if (sponsor && sponsor.isSuccessful) {
			return dispatch({ type: FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_SUCCESS, data: sponsor })
		} else {
			return dispatch({ type: FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_FAILED, data: {} })
		}
	})
	return
}

const CHECK_SUBSCRIPTION_VALIDITY_REQUESTED = 'CHECK_SUBSCRIPTION_VALIDITY_REQUESTED'
const CHECK_SUBSCRIPTION_VALIDITY_SUCCESS = 'CHECK_SUBSCRIPTION_VALIDITY_SUCCESS'
const CHECK_SUBSCRIPTION_VALIDITY_FAILED = 'CHECK_SUBSCRIPTION_VALIDITY_FAILED'
const BAD_GATEWAY_ERROR = 'BAD_GATEWAY_ERROR'

export const checkSubscriptionValidity = () => (dispatch) => {
	dispatch({ type: CHECK_SUBSCRIPTION_VALIDITY_REQUESTED, data: {} })
	subscription.checkSubscriptionValidity().then((subscriptionStatus) => {
		if (subscriptionStatus) {
			if (subscriptionStatus === 'BAD_GATEWAY') {
				return dispatch({ type: BAD_GATEWAY_ERROR })
			} else {
				return dispatch({ type: CHECK_SUBSCRIPTION_VALIDITY_SUCCESS, data: true })
			}
		} else {
			return dispatch({ type: CHECK_SUBSCRIPTION_VALIDITY_FAILED, data: false })
		}
	})
	return
}

const FETCH_AVAILABLE_CASES_REQUESTED = 'FETCH_AVAILABLE_CASES_REQUESTED'
const FETCH_AVAILABLE_CASES_SUCCESS = 'FETCH_AVAILABLE_CASES_SUCCESS'
const FETCH_AVAILABLE_CASES_FAILED = 'FETCH_AVAILABLE_CASES_FAILED'

export const fetchAvailableCases = () => (dispatch) => {
	dispatch({ type: FETCH_AVAILABLE_CASES_REQUESTED, data: {} })
	subscription.fetchAvailableCases().then((caseInfo) => {
		if (caseInfo) {
			return dispatch({ type: FETCH_AVAILABLE_CASES_SUCCESS, data: caseInfo })
		} else {
			return dispatch({ type: FETCH_AVAILABLE_CASES_FAILED, data: false })
		}
	})
	return
}

const initial = {
	subscription: null,
	subscriptionUser: null,
	subscriptionCase: null,
	subscriptionUserCaseSuccess: null,
	circleUser: null,
	circleCase: null,
	subscriptionStatus: null,
	subscriptionSponsorCircle: null,
	subscriptionValidity: null,
	serviceProviderSubscriptionValidity: null,
	availableCases: null,
	badGateway: false,
	plan: {},
	circlePlan: {},
	circlePlanStatistics: {},
	hasFetchedSubscription: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PLAN_REQUESTED:
			return { ...state, plan: {} }
		case FETCH_CIRCLE_PLAN_REQUESTED:
			return { ...state, circlePlan: {} }
		case FETCH_CIRCLE_PLAN_STATISTICS_REQUESTED:
			return { ...state, circlePlanStatistics: {} }
		case FETCH_PLAN_SUCCESS:
			return { ...state, plan: action.data }
		case FETCH_CIRCLE_PLAN_SUCCESS:
			return { ...state, circlePlan: action.data }
		case FETCH_CIRCLE_PLAN_STATISTICS_SUCCESS:
			return { ...state, circlePlanStatistics: action.data }
		case FETCH_SUBSCRIPTION_SUCCESS:
			return { ...state, subscription: action.data, hasFetchedSubscription: true }
		case FETCH_SUBSCRIPTION_FAILED:
			return { ...state, hasFetchedSubscription: true }
		case FETCH_SUBSCRIPTION_USER_CASE_SUCCESS:
			return {
				...state,
				subscriptionUser: action.data.subscriptionUserInfo,
				subscriptionCase: action.data.subscriptionCaseInfo,
				subscriptionUserCaseSuccess: true
			}
		case FETCH_SUBSCRIPTION_CASE_SUCCESS:
			return { ...state, subscriptionCase: action.data.subscriptionCaseInfo }
		case FETCH_SUBSCRIPTION_USER_SUCCESS:
			return { ...state, subscriptionUser: action.data.subscriptionUserInfo }
		case FETCH_CIRCLE_USER_CASE_SUCCESS:
			return {
				...state,
				circleUser: action.data.circleUserInfo,
				circleCase: action.data.circleCaseInfo
			}
		case CHECK_SUBSCRIPTION_STATUS_SUCCESS:
		case CHECK_SUBSCRIPTION_STATUS_FAILED:
			return { ...state, subscriptionStatus: action.data }
		case FETCH_SUBSCRIPTION_SPONSOR_CIRCLE_SUCCESS:
			return { ...state, subscriptionSponsorCircle: action.data }
		case CHECK_SUBSCRIPTION_VALIDITY_SUCCESS:
			return { ...state, subscriptionValidity: action.data, serviceProviderSubscriptionValidity: true /* TODO: change */ }
		case CHECK_SUBSCRIPTION_VALIDITY_FAILED:
			return { ...state, subscriptionValidity: action.data }
		case FETCH_AVAILABLE_CASES_SUCCESS:
			return { ...state, availableCases: action.data.availableToDistribute }
		case BAD_GATEWAY_ERROR:
			return { ...state, badGateway: true }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

export const circlePlanSelector = (state) => state.subscription.circlePlan
export const circlePlanStatisticsSelector = (state) => state.subscription.circlePlanStatistics

export const hasFetchedSubscriptionSelector = (state) => state.subscription.hasFetchedSubscription
export const subscriptionSelector = (state) => state.subscription.subscription
export const subscriptionUserSelector = (state) => state.subscription.subscriptionUser
export const subscriptionCaseSelector = (state) => state.subscription.subscriptionCase
export const subscriptionUserCaseSuccessSelector = (state) => state.subscription.subscriptionUserCaseSuccess
export const circleUserSelector = (state) => state.subscription.circleUser
export const circleCaseSelector = (state) => state.subscription.circleCase
export const subscriptionStatusSelector = (state) => state.subscription.subscriptionStatus
export const subscriptionSponsorCircleSelector = (state) => state.subscription.subscriptionSponsorCircle
export const subscriptionValiditySelector = (state) => state.subscription.subscriptionValidity
export const serviceProviderSubscriptionValiditySelector = (state) => state.subscription.serviceProviderSubscriptionValidity
export const availableCasesSelector = (state) => state.subscription.availableCases
export const badGatewaySelector = (state) => state.subscription.badGateway

export const mainSelector = (state) => state.subscription

export const subscriptionSponsorNameSelector = createSelector(mainSelector, (state) => {
	return state.subscription && state.subscription.sponsorName
})

export const planSelector = createSelector(mainSelector, (state) => {
	if (state && state.plan) {
		return {
			totalPaidCases: state.plan.totalPaidCases && state.plan.totalPaidCases > 0 ? state.plan.totalPaidCases : 0,
			totalCaseCreditUsed:
				state.plan.totalCaseCreditUsed && state.plan.totalCaseCreditUsed > 0
					? state.plan.totalCaseCreditUsed
					: 0,
			totalCaseCreditsAllocated:
				state.plan.totalCaseCreditsAllocated && state.plan.totalCaseCreditsAllocated > 0
					? state.plan.totalCaseCreditsAllocated
					: 0,
			totalCaseCreditsUnallocated:
				state.plan.totalCaseCreditsUnallocated && state.plan.totalCaseCreditsUnallocated > 0
					? state.plan.totalCaseCreditsUnallocated
					: 0,
			totalPaidUsers: state.plan.totalPaidUsers && state.plan.totalPaidUsers > 0 ? state.plan.totalPaidUsers : 0,
			totalUsedUsers: state.plan.totalUsedUsers && state.plan.totalUsedUsers > 0 ? state.plan.totalUsedUsers : 0,
			totalUserCreditsAllocated:
				state.plan.totalUserCreditsAllocated && state.plan.totalUserCreditsAllocated > 0
					? state.plan.totalUserCreditsAllocated
					: 0,
			totalUserCreditsUnallocated:
				state.plan.totalUserCreditsAllocated && state.plan.totalUserCreditsAllocated > 0
					? state.plan.totalUserCreditsAllocated
					: 0,
			subscriptionJoined: state.plan.subscriptionJoined,
			subscriptionExpires: state.plan.subscriptionExpires,
			planType: state.plan.planType
		}
	}
	return []
})

import { authenticatedFetch } from './base'

export const fetchSubscription = () => {
	return authenticatedFetch(`/api/subscription`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionUsersCases = () => {
	return authenticatedFetch(`/api/subscription/usersCases`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionCases = () => {
	return authenticatedFetch(`/api/subscription/cases`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchPlan = () => {
	return authenticatedFetch(`/api/subscription/plan`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchCirclePlan = (id) => {
	return authenticatedFetch(`/api/subscription/plan/circle/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchCirclePlanStatistics = (id) => {
	return authenticatedFetch(`/api/subscription/plan/circle/${id}/statistics`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionUsers = () => {
	return authenticatedFetch(`/api/subscription/users`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchCircleUsersCases = (circleId) => {
	return authenticatedFetch(`/api/subscription/circleUsersCases/${circleId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const checkSubscriptionStatusByEmail = (circleId, email) => {
	return authenticatedFetch(`/api/subscription/checkbyemail/${circleId}/${email}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return true
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchSubscriptionSponsorCircle = () => {
	return authenticatedFetch(`/api/subscription/sponsor/circle`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const checkSubscriptionValidity = () => {
	return authenticatedFetch(`/api/subscription/valid`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return true
			} else if (response.status === 502) {
				return 'BAD_GATEWAY'
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchAvailableCases = () => {
	return authenticatedFetch(`/api/subscription/availableCases`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const checkCanSponsor = (email, circleId) => {
	let url = `/api/subscription/canSponsor/${encodeURIComponent(email.trim())}`;

 	if(circleId)
    {
    	url += `?circleId=${circleId}`;
    }

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(resp) {
			return resp
		})
}

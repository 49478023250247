import React from 'react'
import Paper from '@material-ui/core/Paper'
import * as UI from '../utilities/UIConstants'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Grid from '@material-ui/core/Grid'
import InfoIcon from '@material-ui/icons/Info'
import Box from '@material-ui/core/Box';
import { palette } from '@material-ui/system';

const LabelPaper = ({
	Component,
	SearchComponent,
	infoAlert,
	label,
	filters,
	filter,
	filterSelected,
	classes,
	minHeight = 200,
	...other
}) => (

		<Paper data-testid="component-label-paper-wrapper" elevation={UI.PAPER_ELEVATION} square={true} style={{ width: '100%', minHeight: minHeight, height: '100%' }} className={classes}>
			{label && (
					<Box
						className="component-label-paper"
						data-testid="component-label-paper"
						square={true}
					>
						{!filters && (
							<Typography
								className="component-label-paper-title"
								data-testid="component-label-paper-title"
							>
								{label}
							</Typography>
						)}
						{filters && (
							<FormControl data-testid="component-label-paper-formcontrol">
								<Select
									data-testid="component-label-paper-select"
									value={filter}
									onChange={filterSelected}
									autoWidth
									displayEmpty={true}>
									{filters.map((filter) => (
										<MenuItem data-testid="paper-select-menu-item" value={filter.option} id={filter.option}>
											{filter.label} <KeyboardArrowDownIcon/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					</Box>
			)}
			{infoAlert && (
				<Grid data-testid="info-alert" item className="infoAlert infoAlertInPaper">
					<InfoIcon />
					<span className="infoAlertText">
						{infoAlert}								
					</span>
				</Grid>						
			)}
			{SearchComponent && (
				<div style={{ margin: '32px 32px 36px 32px' }}>
					<SearchComponent data-testid="component-label-paper-search" />
				</div>
			)}
			<div className="component-label-paper-content-wrapper" style={{ overflow: 'auto', height: '100%' }}>
				<div 
					className="component-label-paper-content" 
					data-testid="component-label-paper-content" 
					style={{ margin: '0 32px 1%' }}
				>
					{Component && <Component {...other} />}
				</div>
			</div>
		</Paper>

)

export default LabelPaper

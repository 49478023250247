import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const CaseDetail = lazy(() => componentLoader(() => import('./components/CaseDetail')))

export default () => (
	<div data-testid="case-details-components-wrapper">
		<Suspense fallback={<div />}>
			<section data-testid="case-details-components-inner">
				<CaseDetail />
			</section>
		</Suspense>
	</div>
)

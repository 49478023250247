import React, { useEffect } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import MuiTextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = {
	input: {
		color: 'white',
		fontSize: '1rem',
		backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		color: 'white'
	},
	focused: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'white',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		color: 'white'
	},
	focused: {
		color: 'white',
		'&$focused': {
			color: 'white'
		}
	}
})

const useInputStyles = makeStyles({
	root: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	},
	focused: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'white',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
})

const TextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error, form, dispatch },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	labelStyles,
	inputStyles,
	maxLength,
	value,
	...custom
}) => {
	useEffect(() => {
		dispatch(change(form, input.name, value))
	}, [])
	const onKeyPress = (evt) => {
		var charCode = evt.which ? evt.which : evt.keyCode

		if (charCode < 48 || charCode > 57) {
			evt.preventDefault()
			return false
		}
		return true
	}

	const onChange = (evt) => {
		var reg = /^[1-9]\d*$/
		var value = evt.target.value
		var isNumber = reg.test(value)

		if (!value) {
			dispatch(change(form, input.name, ''))
			return true
		}
		if (!isNumber && !value.startsWith('0')) {
			evt.preventDefault()
			return false
		}
		if (value.startsWith('0')) {
			value = value.substring(1, value.length - 1)

			if (!reg.test(value)) {
				evt.preventDefault()
				return false
			}
		}

		dispatch(change(form, input.name, value))
	}

	return (
		<div className="global-input-wrapper">
			<MuiTextField
				data-testid="component-form-number"
				label={label}
				placeholder={label}
				error={touched && invalid}
				helperText={touched && error}
				{...input}
				margin='dense'
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused
					}
				}}
				FormHelperTextProps={{ classes: { root: inputStyles.error, contained: inputStyles.contained } }}
				InputProps={{ ...inputProps, inputProps: { maxLength: maxLength } }}
				style={style}
				classes={{ root: inputStyles.root, error: inputStyles.error }}
				fullWidth
				margin={margin}
				variant={variant}
				{...custom}
				type={type}
				shrink='true'
				onKeyPress={onKeyPress}
				onChange={onChange}
			/>
		</div>
	)
}

const FormNumberTextField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	value = 0,
	maxLength = 255
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()

	return (
		<Field
			data-testid="component-form-number-field"
			name={name}
			component={TextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			value={value}
			maxLength={maxLength}
			classes={classes}
			labelStyles={labelStyles}
			inputStyles={inputStyles}
		/>
	)
}

export default withStyles(styles)(FormNumberTextField)

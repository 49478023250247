import * as treatment from '../../../apis/treatment'
import { createSelector } from 'reselect'

const initial = {
	treatment: null,
	treatments: [],
	autoCompleteSuggestions: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.treatment

const FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED = 'FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED'
const FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS = 'FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS'
const FETCH_TREATMENT_AUTOCOMPLETE_FAILED = 'FETCH_TREATMENT_AUTOCOMPLETE_FAILED'

export const fetchTreatmentAutoComplete = (searchPhrase, noMatchesText) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED, data: {} })

	treatment.fetchTreatmentAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			{
				if (response.treatments && response.treatments.length > 0) {
					return dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS, data: response.treatments })
				} else {
					return dispatch({
						type: FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS,
						data: [ { id: -1, name: noMatchesText } ]
					})
				}
			}
		} else {
			return dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

export const treatmentAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})
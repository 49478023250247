import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import useTheme from '@material-ui/core/styles/useTheme'

const phi = ({ theme, input, meta, intl, value, ...rest }) => {
	return (
		<Grid data-testid="phi-selection" container direction="column" justifyContent="flex-start">
			<Grid item>
				<FormControl data-testid="phi-selection-formcontrol" style={{ width: '100%', minHeight: '47px' }}>
					<RadioGroup data-testid="phi-selection-radiogroup" {...input} {...rest}>
						<Grid container direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
							<Grid item  xs={12} sm={6} style={{ color: '#fff', justifySelf: 'flex-start' }}>
								<Paper
									data-testid="phi-selection-non-phi"
									elevation={0}
									style={{
										borderRadius: 0,
										padding: 0,
										margin: 0,
										height: '67px',
										backgroundColor: 'rgb(24, 35, 48)'
									}}
								>
									<Grid container alignItems="center" style={{ height: '100%' }} spacing={1}>
										<Grid item>
											<Radio color="secondary" data-testid="phi-selection-non-phi-radio" style={{ width: '100%' }} value="1" />
										</Grid>
										<Grid item>
											<LockOpen
												data-testid="phi-selection-non-phi-icon"
												style={{
													fontSize: '28px',
													color: '#86ceb4'
												}}
											/>
										</Grid>
										<Grid item style={{ maxWidth: '200px' }}>
											<Typography data-testid="phi-selection-non-phi-text" data-testid="phi-selection-text" style={{ color: '#fff' }}>
												{intl.formatMessage({ id: 'app.circles.phiFree' })}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>

							<Grid
								item  xs={12} sm={6}
								style={{
									color: '#fff',
									justifySelf: 'flex-start'
								}}
							>
								<Paper
									data-testid="phi-selection-true-phi"
									elevation={0}
									style={{
										borderRadius: 0,
										padding: 0,
										margin: 0,
										height: '67px',
										backgroundColor: 'rgb(24, 35, 48)'
									}}
								>
									<Grid container alignItems="center" style={{ height: '100%' }} spacing={1}>
										<Grid item>
											<Radio color="secondary" data-testid="phi-selection-true-phi-radio" style={{ width: '100%' }} value="2" />
										</Grid>
										<Grid item>
											<Lock
												data-testid="phi-selection-true-phi-icon"
												style={{
													fontSize: '28px',
													color: '#86ceb4'
												}}
											/>
										</Grid>
										<Grid item style={{ maxWidth: '200px' }}>
											<Typography data-testid="phi-selection-true-phi-text" style={{ color: '#fff' }}>
												{intl.formatMessage({ id: 'app.circles.phi' })}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</RadioGroup>
				</FormControl>
			</Grid>
			<Grid item style={{ alignSelf: 'flex-start', marginTop: '10px', minHeight: '90px' }}>
				{input.value === '1' ? (
					<Typography data-testid="phi-selection-non-phi-message" variant="body2" style={{ color: '#fff', paddingTop: '4px', textAlign: 'left' }}>
						{intl.formatMessage({ id: 'app.circles.phiFreeMessage' })}
						<a href='https://www.clinician.rgnmed.com/support-post/incytes-tm-license-agreement' target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
							{intl.formatMessage({ id: 'app.terms.title' })}.
						</a>
					</Typography>
				) : (
					<Typography data-testid="phi-selection-true-phi-message" variant="body2" style={{ color: '#fff', paddingTop: '4px', textAlign: 'left' }}>
						{intl.formatMessage({ id: 'app.circles.phiMessage' })}
						<a href='https://www.clinician.rgnmed.com/support-post/incytes-tm-license-agreement' target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
							{intl.formatMessage({ id: 'app.terms.title' })}.
						</a>
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

const PhiSelection = ({ intl }) => {
	var theme = useTheme()
	return (
		<div data-testid="phi-selection-wrapper" style={{ color: '#fff' }}>
			<Field name="phi" component={phi} intl={intl} theme={theme} />
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(PhiSelection)

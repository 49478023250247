import { authenticatedFetch } from './base'

export const fetchCurrentUser = () => {
	return authenticatedFetch(`/api/users/current`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchUserByEmail = (email) => {
	return authenticatedFetch(`/api/users/email/${encodeURIComponent(email.trim())}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchTasks = () => {
	return authenticatedFetch(`/api/alert/tasks`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchFullUserById = () => {
	return authenticatedFetch(`/api/users/full`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchUserStats = () => {
	return authenticatedFetch(`/api/users/stats`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchUserNotificationSettingsById = () => {
	return authenticatedFetch(`/api/users/notificationsettings`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}
export const updateUserNotificationSettingsById = (id, outlier, invitation, protocol) => {
	let notifications = [
		{
			id: 1,
			interval: outlier
		},
		{
			id: 2,
			interval: protocol
		},
		{
			id: 3,
			interval: invitation
		}
	]

	return authenticatedFetch(`/api/users/${id}/notificationsettings`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ notifications: notifications })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const updateUserDetailsById = (user) => {
	return authenticatedFetch(`/api/users/${user.id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(user)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const requestPhoneVerificationCode = (phoneCountryCode, phoneNumber) => {
    return authenticatedFetch(`/api/users/verifyPhone`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                phoneNumber,
                phoneCountryCode
            })
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
		.then(function (response) {
			return response
        })
}

export const completePhoneVerification = (code) => {
    return authenticatedFetch(`/api/users/verifyPhone/code/${code}`, {
            method: 'post'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}

export const fetchMFAState = () => {
	return authenticatedFetch(`/api/teams/mfa`, { method: 'get' })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
		.then(function (response) {
			return response
        })
}

export const updateMFAState = (type, state, mfaSign) => {
    return authenticatedFetch(`/api/teams/mfa/${type}/${state}`,
            {
				method: 'put',
				headers: mfaSign ? { 'mfaSign': mfaSign } : {}
            })
        .then(function(response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function(response) {
            return response
        })
}

export const ignoreInitialPathologiesAndProms = (id) => {
	return authenticatedFetch(`/api/users/${id}/ignoreinitialpathologies`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const updateUserLanguage = (id, language) => {
	return authenticatedFetch(`/api/users/${id}/language/${language}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ langauge: language })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setPathologiesAndProms = (id, pathology, proms = [], isInitialSetting = false, type) => {
	return authenticatedFetch(`/api/users/${id}/addinitialpathologies`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ pathology, proms, type })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchTeamMembers = () => {
	return authenticatedFetch(`/api/users/members`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setUserImage = (entity, id, photo) => {
	//users or circle or team
	let url = `/api/${entity}/${id}/photo`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setTeamLogo = (id, photo) => {
	let url = `/api/teams/${id}/logo`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const resetUserImage = (entity, id) => {
	let url = `/api/${entity}/${id}/photo`

	return authenticatedFetch(url, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchAlerts = () => {
	return authenticatedFetch(`/api/users/alerts`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchHasGlobalAccess = (id) => {
	return authenticatedFetch(`/api/users/${id}/hasGlobalAccess`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(access) {
			return access
		})
}

export const getUserRegistrationDataByEmail = (email) => {
	return authenticatedFetch(`/api/users/registrationData/email/${encodeURIComponent(email.trim())}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const generateApiKey = () => {
	let url = `/api/users/openApiKey/generate`

	return authenticatedFetch(url, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const activateApiKey = () => {
	let url = `/api/users/openApiKey/activate`

	return authenticatedFetch(url, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const deactivateApiKey = () => {
	let url = `/api/users/openApiKey/deactivate`

	return authenticatedFetch(url, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

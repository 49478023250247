import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required } from '../../../utilities/validators'
import SponsorSelectorControl from './SponsorSelectorControl'
import { formattedCirclesSelector } from '../../../redux/selectors'
import { fetchCircles } from '../../../redux/actions'
import AddNewCase from './AddNewCase'

const requiredValidator = required

// const selector = formValueSelector('addCircle')

const SponsorSelector = ({ intl, circles, getCircles, onCircleSelected }) => {
	const theme = useTheme()
	const [ isUseExistingCircle, setIsUseExistingCircle ] = useState(true)

	useEffect(() => {
		getCircles()
	}, [])

	return (
		<div data-testid="sponsored-selector">
				<Grid
					data-testid="sponsored-selector-existing"
					container
					direction='row'
					alignItems='flex-start'
					justifyContent='flex-start'
					style={{ paddingLeft: '20px' }}
					spacing={2}>
					<Grid item xs={12}>
						{isUseExistingCircle && (
							<SponsorSelectorControl
								data-testid="sponsored-selector-is-existing"
								name='sponsorId'
								label={intl.formatMessage({ id: 'app.cases.step3Label' })}
								noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
								suggestions={circles}
								validators={[ requiredValidator ]}
								onCircleSelected={onCircleSelected}
							/>
						)}
						{!isUseExistingCircle && <AddNewCase />}
					</Grid>
				</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circles: formattedCirclesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getCircles: () => dispatch(fetchCircles())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter)

export default enhance(SponsorSelector)

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FormattedDate, injectIntl } from 'react-intl'

const DateSponsorCell = ({ intl, circle }) => (
	<Grid container>
		<Grid item align='left'>
			{circle && (
				<div data-testid="sponsored-circles-date-sponsor-cell">
					<Typography variant="subtitle2">
						<FormattedDate year='numeric' month='short' day='2-digit' value={circle.createDate} />
					</Typography>
					<Typography variant='body2'>{intl.formatMessage({ id: 'app.circles.dateFounded' })}</Typography>
				</div>
			)}
		</Grid>
	</Grid>
)

export default injectIntl(DateSponsorCell)

import * as caseApi from '../../../apis/case'
import * as cookies from '../../../utilities/cookies'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import queryString from 'query-string'
import { statesSelector } from '../state'

const FETCH_CASE_REQUESTED = 'FETCH_CASE_REQUESTED'
const FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS'
const FETCH_CASE_FAILED = 'FETCH_CASE_FAILED'

export const fetchCase = (id) => (dispatch) => {
	dispatch({ type: FETCH_CASE_REQUESTED, data: null })
	return caseApi.fetchCase(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CASE_SUCCESS, data: response })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.case.fetchCaseFailed`, isSuccess: false }
			})
		}
	})
	return
}

const CLOSE_CASE_REQUESTED = 'CLOSE_CASE_REQUESTED'
const CLOSE_CASE_SUCCESS = 'CLOSE_CASE_SUCCESS'
const CLOSE_CASE_FAILED = 'CLOSE_CASE_FAILED'

export const closeCase = (id) => (dispatch) => {
	dispatch({ type: CLOSE_CASE_REQUESTED, data: null })
	return caseApi.closeCase(id).then((response) => {
		if (response && response.isSuccessful) {
			let sp = ''
			if (history && history.location && history.location.search) {
				let params = queryString.parse(history.location.search)
				if (params && params.searchPhrase) {
					sp = params.searchPhrase
				}
			}
			dispatch(fetchCases(sp))
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.cases.closeCaseSuccess`, isSuccess: true }
			})
			return dispatch({ type: CLOSE_CASE_SUCCESS, data: null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.cases.closeCaseFailed`, isSuccess: false }
			})
		}
	})
	return
}

const ARCHIVE_CASE_REQUESTED = 'ARCHIVE_CASE_REQUESTED'
const ARCHIVE_CASE_SUCCESS = 'ARCHIVE_CASE_SUCCESS'
const ARCHIVE_CASE_FAILED = 'ARCHIVE_CASE_FAILED'

export const archiveCase = (id) => (dispatch) => {
	dispatch({ type: ARCHIVE_CASE_REQUESTED, data: null })
	return caseApi.archiveCase(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.cases.archiveCaseSuccess`, isSuccess: true }
			})
			history.push('/cases')
			return dispatch({ type: ARCHIVE_CASE_SUCCESS, data: null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.case.fetchCaseFailed`, isSuccess: false }
			})
		}
	})
	return
}

const DEACTIVATE_CASE_REQUESTED = 'DEACTIVATE_CASE_REQUESTED'
const DEACTIVATE_CASE_SUCCESS = 'DEACTIVATE_CASE_SUCCESS'
const DEACTIVATE_CASE_FAILED = 'DEACTIVATE_CASE_FAILED'

export const deactivateCase = (id) => (dispatch) => {
	dispatch({ type: DEACTIVATE_CASE_REQUESTED, data: {} })
	return caseApi.deactivateCase(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchCases())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.cases.successfullyDeactivated`,
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.cases.deactivatedionFailed`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const ADD_CASE_REQUESTED = 'ADD_CASE_REQUESTED'
const ADD_CASE_SUCCESS = 'ADD_CASE_SUCCESS'
const ADD_CASE_FAILED = 'ADD_CASE_FAILED'
const NEW_CASE_SUCCESS = 'NEW_CASE_SUCCESS'

export const addCase = (addCaseModel) => (dispatch) => {
	if (addCaseModel) {
		dispatch({ type: ADD_CASE_REQUESTED, data: {} })
		try {
			return caseApi.addCase(addCaseModel).then((caseModel) => {
				if (caseModel && caseModel.isSuccessful) {
					//dispatch(fetchCases())
					history.push(`/cases/${caseModel.id}/survey/0/instance/0/delegate/0/status/0`)
					dispatch(hideModal())
					return dispatch({ type: NEW_CASE_SUCCESS, data: caseModel })
					//return dispatch({ type: ADD_CASE_SUCCESS, data: caseModel })
				} else {
					dispatch({ type: ADD_CASE_FAILED, data: null })

					if (caseModel.errorCode === 6) {
						return dispatch({
							type: 'SET_SNACKBAR_MESSAGE',
							data: {
								message: 'app.case.duplicatePatient',
								isSuccess: false
							}
						})
					} else if (caseModel.errorCode === 30) {
						return dispatch({
							type: 'SET_SNACKBAR_MESSAGE',
							data: {
								message: 'app.case.wrongPiRegion',
								isSuccess: false
							}
						})
					} else {
						return dispatch({
							type: 'SET_SNACKBAR_MESSAGE',
							data: {
								message: 'app.case.createCaseFailed',
								isSuccess: false
							}
						})
					}
				}
			})
		} catch (error) {
			dispatch({ type: ADD_CASE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.case.createCaseFailed', isSuccess: false }
			})
		}
	}
	return dispatch({
		type: 'SET_SNACKBAR_MESSAGE',
		data: {
			message: 'app.case.createCaseFailed',
			isSuccess: false
		}
	})
}

const UPDATE_CASE_REQUESTED = 'UPDATE_CASE_REQUESTED'
const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS'
const UPDATE_CASE_FAILED = 'UPDATE_CASE_FAILED'

export const updateCase = (updateCaseModel, searchPhrase) => (dispatch) => {
	dispatch({ type: UPDATE_CASE_REQUESTED, data: {} })

	var model = {
		...updateCaseModel,
		sharedCircles:
			updateCaseModel.sharedCircles && Array.isArray(updateCaseModel.sharedCircles)
				? updateCaseModel.sharedCircles
						.filter((r) => {
							if (r) return r
						})
						.map((r) => {
							return isNaN(r) ? r.id : r
						})
				: []
	}

	model.countryId = model.countryId > 0 ? model.countryId : null
	caseApi.updateCase(model).then((caseModel) => {
		if (caseModel && caseModel.isSuccessful) {
			dispatch({ type: UPDATE_CASE_SUCCESS, data: {} })
			dispatch(fetchCase(model.id))
			dispatch(fetchCases(searchPhrase));

			return dispatch(hideModal())
		} else {
			dispatch({ type: UPDATE_CASE_FAILED, data: {} })

			if (caseModel.errorCode === 6) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.case.duplicatePatient',
						isSuccess: false
					}
				})
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.updateCasesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const GET_CASES_REQUESTED = 'GET_CASES_REQUESTED'
const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS'
const GET_CASES_FAILED = 'GET_CASES_FAILED'

export const fetchCases = (searchPhrase) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: GET_CASES_REQUESTED, data: {} })

	caseApi.fetchCases(searchPhrase, state.case.filter).then((response) => {
		if (response && response.isSuccessful) {
			if (response.cases && response.cases.length > 0) {
				var data = response.cases
				return dispatch({
					type: GET_CASES_SUCCESS,
					data: {
						items: data,
						hasMore: response.hasMore,
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			} else {
				return dispatch({
					type: GET_CASES_SUCCESS,
					data: {
						items: [],
						hasMore: false,
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			}
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.fetchCasesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const GET_ADDITIONAL_CASES_REQUESTED = 'GET_ADDITIONAL_CASES_REQUESTED'
const GET_ADDITIONAL_CASES_SUCCESS = 'GET_ADDITIONAL_CASES_SUCCESS'
const GET_ADDITIONAL_CASES_FAILED = 'GET_ADDITIONAL_CASES_FAILED'

export const fetchAdditionalCases = (searchPhrase, offset) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: GET_ADDITIONAL_CASES_REQUESTED, data: {} })

	caseApi.fetchCases(searchPhrase, state.case.filter, offset).then((response) => {
		if (response && response.isSuccessful) {
			if (response.cases && response.cases.length > 0) {
				var data = response.cases
				return dispatch({
					type: GET_ADDITIONAL_CASES_SUCCESS,
					data: {
						items: data,
						hasMore: response.hasMore,
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			} else {
				return dispatch({
					type: GET_ADDITIONAL_CASES_SUCCESS,
					data: {
						items: [],
						hasMore: false,
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			}
		} else {
			dispatch({ type: GET_ADDITIONAL_CASES_FAILED, data: {}})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.fetchCasesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

export const fetchCasesByCircle = (circleId, searchPhrase) => (dispatch) => {
	dispatch({ type: GET_CASES_REQUESTED, data: {} })

	caseApi.fetchCasesByCircle(circleId, searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.cases && response.cases.length > 0) {
				var data = response.cases
				return dispatch({
					type: GET_CASES_SUCCESS,
					data: {
						items: data,
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			} else {
				return dispatch({
					type: GET_CASES_SUCCESS,
					data: {
						items: [],
						field: 'modifiedDateSeconds',
						isDescending: true
					}
				})
			}
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.fetchCasesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const CLEAR_CASES_REQUESTED = 'CLEAR_CASES_REQUESTED'

export const clearCases = () => (dispatch) => {
	dispatch({ type: CLEAR_CASES_REQUESTED, data: {} })

	return
}

const GET_CASE_PROTOCOL_REQUESTED = 'GET_CASE_PROTOCOL_REQUESTED'
const GET_CASE_PROTOCOL_SUCCESS = 'GET_CASE_PROTOCOL_SUCCESS'
const GET_CASE_PROTOCOL_FAILED = 'GET_CASE_PROTOCOL_FAILED'

export const fetchCaseProtocol = (id) => (dispatch) => {
	dispatch({ type: GET_CASE_PROTOCOL_REQUESTED, data: {} })

	caseApi.fetchCaseProtocol(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: GET_CASE_PROTOCOL_SUCCESS,
				data: response.surveys
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.fetchCasesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}


const VIEW_PATIENT_RESULTS_REQUESTED = 'VIEW_PATIENT_RESULTS_REQUESTED'
const VIEW_PATIENT_RESULTS_SUCCESS = 'VIEW_PATIENT_RESULTS_SUCCESS'
const VIEW_PATIENT_RESULTS_FAILED = 'VIEW_PATIENT_RESULTS_FAILED'

export const viewPatientResults = (windowReference, patientId, caseId) => (dispatch) => {
	dispatch({ type: VIEW_PATIENT_RESULTS_REQUESTED, data: {} })

	caseApi.viewPatientResults(patientId, caseId).then((response) => {
		if (response && response.isSuccessful) {
			windowReference.location = response.link
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.cases.viewPatientsResultsFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const GET_HL7_MESSAGES_REQUESTED = 'GET_HL7_MESSAGES_REQUESTED'
const GET_HL7_MESSAGES_SUCCESS = 'GET_HL7_MESSAGES_SUCCESS'
const GET_HL7_MESSAGES_FAILED = 'GET_HL7_MESSAGES_FAILED'

export const getHl7Messages = (id) => (dispatch) => {
	dispatch({ type: GET_HL7_MESSAGES_REQUESTED, data: {} })

	caseApi.getHl7Messages(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: GET_HL7_MESSAGES_SUCCESS,
				data: response
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.case.fetchHl7MessagesFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const SET_CASE_SORT_REQUESTED = 'SET_CASE_SORT_REQUESTED'

export const setSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.case.cases.sort((a, b) => {
		if (isDescending) {
			return b[field] > a[field] ? 1 : -1
		} else {
			return a[field] > b[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_CASE_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_CASES_FILTER_REQUESTED = 'SET_CASES_FILTER_REQUESTED'
const SET_CASES_FILTER_SUCCESS = 'SET_CASES_FILTER_SUCCESS'
const SET_CASES_FILTER_FAILED = 'SET_CASES_FILTER_FAILED'

export const setCaseFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_CASES_FILTER_REQUESTED, data: {} })
	dispatch({ type: SET_CASES_FILTER_SUCCESS, data: filter })
	return dispatch(fetchCases(state.case.searchTerm))
}

const SET_CASE_SEARCH_TERM_REQUESTED = 'SET_CASE_SEARCH_TERM_REQUESTED'
const SET_CASE_SEARCH_TERM_SUCCESS = 'SET_CASE_SEARCH_TERM_SUCCESS'
const SET_CASE_SEARCH_TERM_FAILED = 'SET_CASE_SEARCH_TERM_FAILED'

export const setCaseSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_CASE_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_CASE_SEARCH_TERM_SUCCESS, data: value })
}

const initial = {
	cases: null,
	case: null,
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	isCreating: false,
	filter: 0,
	newCase: null,
	caseProtocol: [],
	hl7Messages: null,
	hasMore: true,
	isLoading: false,
	searchTerm: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case GET_CASE_PROTOCOL_FAILED:
		case GET_CASE_PROTOCOL_REQUESTED:
			return { ...state, caseProtocol: [] }
		case GET_CASE_PROTOCOL_REQUESTED:
			return { ...state, caseProtocol: null }
		case GET_CASE_PROTOCOL_SUCCESS:
			return { ...state, caseProtocol: action.data }
		case SET_CASES_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case SET_CASE_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case FETCH_CASE_REQUESTED:
		case FETCH_CASE_SUCCESS:
			return {
				...state,
				case: action.data
			}
		case ADD_CASE_SUCCESS:
			return {
				...state,
				case: action.data,
				isCreating: false,
				cases: [ ...state.cases, action.data ]
			}
		case ADD_CASE_FAILED:
			return { ...state, isCreating: false }
		case UPDATE_CASE_REQUESTED:
			return { ...state, isCreating: true }
		case UPDATE_CASE_SUCCESS:
			return {
				...state,
				case: action.data,
				isCreating: false
			}
		case UPDATE_CASE_FAILED:
			return { ...state, isCreating: false }
		case GET_CASES_SUCCESS:
			return {
				...state,
				cases: action.data.items,
				hasMore: action.data.hasMore,
				sortField: action.data.field,
				isDescending: action.data.isDescending,
				isLoading: false
			}
		case GET_CASES_REQUESTED:
			return {
				...state,
				cases: null,
				isLoading: true
			}
		case GET_CASES_FAILED:
			return {
				...state,
				isLoading: false
			}
		case GET_ADDITIONAL_CASES_SUCCESS:
			return {
				...state,
				cases: [ ...state.cases, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case GET_ADDITIONAL_CASES_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case GET_ADDITIONAL_CASES_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case CLEAR_CASES_REQUESTED:
			return {
				...state,
				cases: null,
				sortField: null,
				isDescending: false
			}
		case SET_CASE_SORT_REQUESTED:
			return {
				...state,
				cases: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case ADD_CASE_REQUESTED:
			return { ...state, isCreating: true }
		case NEW_CASE_SUCCESS:
			return { ...state, newCase: action.data, isCreating: false }
		case GET_HL7_MESSAGES_SUCCESS:
			return { ...state, hl7Messages: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.case

export const newCaseSelector = createSelector(mainSelector, (state) => {
	return state.newCase
})

export const caseProtocolSurveySelector = createSelector(mainSelector, (state) => {
	return state && state.caseProtocol ? state.caseProtocol : []
})

export const isCreatingCaseSelector = createSelector(mainSelector, (state) => state.isCreating)

Date.prototype.yyyymmdd = function() {
	var mm = this.getMonth() + 1 // getMonth() is zero-based
	var dd = this.getDate()

	return [ this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd ].join('')
}

export const editGroupCaseSelector = createSelector(mainSelector, (state) => {
	if (state.case) {
		var tDate = new Date(state.case.treatmentDate)
		var cDate = new Date(state.case.commencementDate)
		var bDate = state.case.patientBirthDate != null ? new Date(state.case.patientBirthDate) : null

		return {
			sponsorId: state.case.sponsorId,
			id: state.case.id,
			id36: state.case.id36,
			email: state.case.patientEmail,
			patientId: state.case.patientId,
			patientId36: state.case.patientId36,
			countryName: state.case.patientCountryName,
			countryId: state.case.patientCountryId,
			birthDate: state.case.patientBirthDate,
			firstName: state.case.patientFirstName,
			lastName: state.case.patientLastName,
			languageId: state.case.patientLanguageId,
			middleName: state.case.patientMiddleName,
			phoneNumber: state.case.patientPhoneNumber,
			phoneCountryCode: state.case.patientphoneCountryCode,
			jurisdictionId: state.case.patientJurisdictionId,
			sponsorName: state.case.sponsorName,
			treatmentDate: state.case.treatmentDate,
			commencementDate: state.case.commencementDate,
			protocolName: state.case.protocolName,
			allowEditCompletedDate: state.case.allowEditCompletedDate,
			protocolVersionId: state.case.protocolVersionId,
			protocolIsBilateral: state.case.protocolIsBilateral,
			isBilateral: state.case.isBilateral,
			selectedAreaName: state.case.selectedAreaName,
			area1Name: state.case.area1Name,
			area2Name: state.case.area2Name,
			bilateralArea: state.case.bilateralArea,
			sharedCircles:
				state.case.circles &&
				state.case.circles.map((r) => {
					if (r.id !== state.sponsorId) {
						return {
							name: r.name,
							id: r.id,
							isPhiFree: r.isPhiFree
						}
					}
				})
		}
	}
	return null
})

export const cohortCasesSelector = createSelector(mainSelector, (state) => {
	if (state && state.cases && state.cases.length > 0) {
		let collection = state.cases.map((r) => {
			return {
				id: r.id,
				id36: r.id36,
				email: r.patientEmail,
				name:r.patientName
			}
		})
		return collection
	}
	return []
})

export const readonlyCaseSelector = createSelector(mainSelector, (state) => {
	if (state && state.case) {
		var tDate = state.case.treatmentDate
		var cDate = state.case.createDate
		var commencementDate = state.case.commencementDate

		return {
			id: state.case.id,
			id36: state.case.id36,
			canEdit: state.case.canEdit,
			canViewPhi: state.case.canViewPhi,
			phi: state.case.phi,
			patientId: state.case.patientId,
			patientId36: state.case.patientId36,
			patientPhone: state.case.patientPhoneNumber,
			patientEmail: state.case.patientEmail,
			patientHasJoined: state.case.patientHasJoined,
			patientJurisdictionId: state.case.patientJurisdictionId,
			patientTermsAccepted: state.case.patientTermsAccepted,
			patientHasIssuesWithEmail: state.case.patientHasIssuesWithEmail,
			sponsorName: state.case.sponsorName,
			name:
				state.case.patientFirstName && state.case.patientLastName
					? `${state.case.patientFirstName} ${state.case.patientMiddleName ? state.case.patientMiddleName + " " : ""}${state.case.patientLastName}`
					: state.case.patientEmail,
			caseCreatorName:
				state.case.creatorFirstName && state.case.creatorLastName
					? `${state.case.creatorFirstName} ${state.case.creatorLastName}`
					: state.case.creatorEmail,
			creatorCredential: state.case.creatorCredentials,
			creatorUserId: state.case.creatorId,
			treatmentDate: tDate,
			commencementDate: commencementDate,
			createDate: cDate,
			protocol: state.case.protocolName,
			protocolId: state.case.protocolId,
			clinician:
				state.case.creatorFirstName && state.case.creatorLastName
					? `${state.case.creatorFirstName} ${state.case.creatorLastName}${state.case.creatorCredentials &&
							`, ${state.case.creatorCredentials}`}`
					: state.case.creatorEmail,
			hasHl7Messages: state.case.hasHl7Messages,
			allowEditCompletedDate: state.case.allowEditCompletedDate,
			languageName: state.case.patientLanguageName,
			protocolVersionNumber: state.case.protocolVersionNumber,
			protocolVersionName: state.case.protocolVersionName,
			sendRegistrationSms: state.case.sendRegistrationSms,
			sendReminderSms: state.case.sendReminderSms,
			funderName: state.case.funderName,
			protocolIsBilateral: state.case.protocolIsBilateral,
			isBilateral: state.case.isBilateral,
			selectedAreaName: state.case.selectedAreaName,
			area1Name: state.case.area1Name,
			area2Name: state.case.area2Name,
			bilateralArea: state.case.bilateralArea,
		}
	}
	return null
})

export const caseSharedCirclesSelector = createSelector(mainSelector, (state) => {
	if (state && state.case) {
		let sharedWith = []
		if (state.case.circles) {
			let s = state.case.circles.filter((r) => {
				if (r && r.id !== state.case.sponsorId) return r
			})
			if (s && s.length > 0) {
				sharedWith = s.map((r) => {
					return {
						name: r.name,
						id: r.id,
						isPhiFree: r.isPhiFree
					}
				})
			}
		}

		return sharedWith
	}
	return null
})

export const sortDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const hl7MessagesSelector = createSelector(mainSelector, (state) => {
	if (state && state.hl7Messages) {
		return {
			...state.hl7Messages,
			firstMessageSentOn: state.hl7Messages.firstMessageSentOn != null ? new Date(state.hl7Messages.firstMessageSentOn).toLocaleString() : null,
			secondMessageSentOn: state.hl7Messages.secondMessageSentOn != null ? new Date(state.hl7Messages.secondMessageSentOn).toLocaleString() : null
		}
	}

	return null
})

export const casesSelector = createSelector(mainSelector, (state) => {
	if (state && state.cases && state.cases.length > 0) {
		let collection = state.cases.map((r) => {
			return {
				id: r.id,
				id36: r.id36,
				patientName: r.patientName ? r.patientName : r.patientEmail,
				patientId36: r.patientId36,
				patientBirthDate: r.patientBirthDate,
				birthDateSeconds: r.birthDateSeconds,
				protocol:
					r.observationProtocolName && r.observationProtocolName.length > 64
						? r.observationProtocolName.substring(0, 64) + '...'
						: r.observationProtocolName,
				treatmentDate: r.treatmentDate,
				commencementDate: r.commencementDate,
				treatmentDateSeconds: r.treatmentDateSeconds,
				totalSurveys: r.totalSurveyCount,
				totalSurveysCompleted: r.totalCompletedCount,
				completed: r.isCompleted || r.totalSurveyCount === r.totalCompletedCount,
				completedPercent: r.completedPercent,
				modifiedDate: r.modifiedDate,
				modifiedName:
					r.modifiedName && r.modifiedName.length > 50
						? r.modifiedName.substring(0, 50) + '...'
						: r.modifiedName,
				modifiedDateSeconds: r.modifiedDateSeconds,
				canViewPhi: r.canViewPhi,
				phi: r.phi
			}
		})
		return collection
	}
	return []
})

const filterSelector = (state) => state.case.filter

export const filterCasesSelector = createSelector(
	mainSelector,
	filterSelector,
	casesSelector,
	(state, filter, cases) => {
		switch (filter) {
			case 0:
				return cases
			case 1:
				return cases.filter((r) => {
					return !r.completed
				})
			case 2:
				return cases.filter((r) => {
					return r.completed
				})
		}
	}
)

export const hasMoreCasesSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingCasesSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const caseSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})
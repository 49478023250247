import React, { useEffect, useState } from 'react'
import {reduxForm} from 'redux-form'
import { compose } from 'recompose'
import {injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import {
    makeAdministratorMember,
    leaveCircle,
    canLeaveCircle
} from "../../redux/actions";
import {
    canLeaveCircleSelector
} from "../../redux/selectors";


const LeaveCircle = ({userId, handleSubmit, intl, options, circle,
    submitLeaveCircleRequest, getCanLeaveCircle, canLeaveCircle }) => {
    const theme = useTheme()

    const [ cantLeaveMessage, setCantLeaveMessage ] = useState(false)

    useEffect(() => {
        if (circle) {
            getCanLeaveCircle(circle.id);
        }
    }, [circle]);
    
    useEffect(() => {
        if (canLeaveCircle) {
            if (!canLeaveCircle.isSuccessful) {
                setCantLeaveMessage(canLeaveCircle.errorMessage)
            }
        }
    }, [canLeaveCircle])

    const onClickLeaveCircle = () => {
        if (circle.id)
            submitLeaveCircleRequest(circle.id)
    }

    return (
        <Grid
            data-testid="leave-circle"
            container
            direction="column"
            style={{
                padding: '0 30px 30px',
                backgroundColor: '#011020'
            }}>
            <Grid item style={{textAlign: 'center' }}>
                <Typography
                    data-testid="leave-circle-title"
                    variant='h1'
                    style={{color: '#fff', paddingTop: '40px', paddingBottom: '30px' }}
                >
                    {intl.formatMessage({id: 'app.circle.leaveCircleHeading'}).replace('%NAME%', circle? circle.name : '')}
                </Typography>
            </Grid>

            { cantLeaveMessage && <Grid item style={{textAlign: 'center' }}>
                <Typography
                    data-testid="leave-circle-cant-leave"
                    variant='h3'
                    style={{color: '#fff', fontWeight: '400', paddingTop: '0px', paddingBottom: '30px' }}
                >
                    {cantLeaveMessage}
                </Typography>
            </Grid> }

            { !cantLeaveMessage && <Grid item style={{textAlign: 'center' }}>
                <Typography
                    data-testid="leave-circle-confirmation"
                    variant='h3'
                    style={{color: '#fff', fontWeight: '400', paddingTop: '0px', paddingBottom: '30px' }}
                >
                    {intl.formatMessage({id: 'app.circle.leaveCircleConfirmation'})}
                </Typography>
            </Grid> }

            { canLeaveCircle !== null && !cantLeaveMessage && <Grid item>
                <Grid item style={{ textAlign: 'center' }}>
                    <Button
                        data-testid="leave-circle-button-submit"
						variant="contained"
						color="primary"
                        onClick={onClickLeaveCircle}
                        size="large"
                    >
                        {intl.formatMessage({id: 'app.circle.confirmAction'})}
                    </Button>
                </Grid>
            </Grid> }
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
    canLeaveCircle: canLeaveCircleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    submitLeaveCircleRequest: (circleId) => dispatch(leaveCircle(circleId)),
    getCanLeaveCircle: (circleId) => dispatch(canLeaveCircle(circleId))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(LeaveCircle)

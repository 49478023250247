import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CircleHeader from './CircleHeader'
import CircleRow from './CircleRow'
import TableBody from '@material-ui/core/TableBody'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const CircleTable = ({ data, user, subscriptionUser }) => {
	return (
		<Table data-testid="sponsored-circles-table" className="label-paper-inner">
			<TableHead>
				<CircleHeader />
			</TableHead>
			<TableBody>
				{data &&
					data.map((circle) => {
						return (
							<CircleRow
								circle={circle}
								user={user}
								subscriptionUser={subscriptionUser}
								key={circle.id}
							/>
						)
					})}
			</TableBody>
		</Table>
	)
}

const enhance = compose(withStyles(styles))

export default enhance(CircleTable)

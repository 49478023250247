import * as pathology from '../../../apis/pathology'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_PATHOLOGIES_REQUESTED = 'FETCH_PATHOLOGIES_REQUESTED'
const FETCH_PATHOLOGIES_SUCCESS = 'FETCH_PATHOLOGIES_SUCCESS'
const FETCH_PATHOLOGIES_FAILED = 'FETCH_PATHOLOGIES_FAILED'

export const fetchPathologies = () => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGIES_REQUESTED, data: {} })
	pathology.fetchPathologies().then((proms) => {
		if (proms) {
			return dispatch({ type: FETCH_PATHOLOGIES_SUCCESS, data: proms })
		} else {
			return dispatch({ type: FETCH_PATHOLOGIES_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PATHOLOGY_PROMS_REQUESTED = 'FETCH_PATHOLOGY_PROMS_REQUESTED'
const FETCH_PATHOLOGY_PROMS_SUCCESS = 'FETCH_PATHOLOGY_PROMS_SUCCESS'
const FETCH_PATHOLOGY_PROMS_FAILED = 'FETCH_PATHOLOGY_PROMS_FAILED'

export const fetchPromsByPathology = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_PROMS_REQUESTED, data: {} })
	pathology.fetchProms(id).then((proms) => {
		if (proms) {
			return dispatch({ type: FETCH_PATHOLOGY_PROMS_SUCCESS, data: proms })
		} else {
			return dispatch({ type: FETCH_PATHOLOGY_PROMS_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	pathology: null,
	pathologies: [],
	proms: [],
	autoCompleteSuggestions: []
}

const mainSelector = (state) => state.pathology

const FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED = 'FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED'
const FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS = 'FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS'
const FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED = 'FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED'

export const fetchPathologyAutoComplete = (searchPhrase, noMatchesText) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED, data: {} })

	pathology.fetchPathologyAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			{
				if (response.pathologies && response.pathologies.length > 0) {
					return dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS, data: response.pathologies })
				} else {
					return dispatch({
						type: FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS,
						data: [ { id: -1, name: noMatchesText } ]
					})
				}
			}
		} else {
			return dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PATHOLOGIES_SUCCESS:
			return { ...state, pathologies: action.data }
		case FETCH_PATHOLOGY_PROMS_SUCCESS:
			return { ...state, proms: action.data }
		case FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

export const pathologyAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const pathologiesSelector = (state) => state.pathology.pathologies

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedDate } from 'react-intl'

export default ({ alert }) => (
	<div data-testid="myalerts-outlier-alert-datecell">
		<Typography variant="subtitle2" >
			{alert && alert.createDate && <FormattedDate year='numeric' month='short' day='2-digit' value={alert.createDate} />}
		</Typography>
	</div>
)

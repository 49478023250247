import { authenticatedFetch } from './base'

export const inviteCircleMember = (id, email, circleId, languageId, sponsored, caseFunding) => {
	return authenticatedFetch(
		`/api/circleinvitation/${id}/invitemember/${circleId}/${encodeURIComponent(email.trim())}`,
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({ sponsored: sponsored, languageId: languageId, caseFunding: caseFunding }),
		}
	)
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (member) {
			return member
		})
}

export const sendAndAcceptCircleInvitation = (data) => {
	return authenticatedFetch(
		`/api/circleinvitation/autoinvitemember`,
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({ data: data }),
		}
	)
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (member) {
			return member
		})
}

export const resendCircleInvitation = (invitationId, circleId) => {
	return authenticatedFetch(
		`/api/circleinvitation/${circleId}/${invitationId}/resend`,
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({}),
		}
	).then(function (response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}

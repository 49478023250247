import React, { useEffect, useState, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import useTheme from '@material-ui/core/styles/useTheme'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required, requiredId } from '../../../utilities/validators'
import NumberedRow from '../../../components/NumberedRow'
import PatientSelector from './PatientSelector'
import AddNewPatient from './AddNewPatient'
import { formattedPatientsSelector } from '../../../redux/selectors'
import { fetchPatients } from '../../../redux/actions'
import SearchIcon from '@material-ui/icons/Search';

const requiredValidator = requiredId

const selector = formValueSelector('addCircle')

const SetCasePatient = ({ intl, patients, loadPatients, setIsNewPatient, validators, onPatientSelected, onUseExistingChange }) => {
	const theme = useTheme()
	const [ isUseExistingPatient, setIsUseExistingPatient ] = useState(false)

	useEffect(() => {
		loadPatients()
	}, [])

	useEffect(
		() => {
			setIsNewPatient(!isUseExistingPatient)
		},
		[ isUseExistingPatient ]
	)

	const updateUseExistingPatient = () => {
		let newValue = !isUseExistingPatient
		setIsUseExistingPatient(newValue)
		if(onUseExistingChange) {
			onUseExistingChange(newValue)
		}
	}

	return (
		<div data-testid="set-case-patient">
			<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
				<Grid container justifyContent='space-between'>
					<Grid item data-testid="set-case-patient-numbered-label">
						<NumberedRow number={2} label={`${intl.formatMessage({ id: 'app.cases.step2Label' })}`} />
					</Grid>
					<Grid item>
						<Button
							data-testid="set-case-patient-button-use-existing"
							variant='contained'
							color='primary'
							onClick={updateUseExistingPatient}
							endIcon={<SearchIcon style={{ marginTop: '2px' }}/>}
						>							
								{intl.formatMessage({
									id: isUseExistingPatient
										? 'app.cases.newPatientButtonText'
										: 'app.cases.useExistingPatientButtonText'
								})}							
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
				<Grid
					container
					direction='row'
					alignItems='flex-start'
					justifyContent='flex-start'
					style={{ paddingLeft: '20px' }}>
					<Grid item style={{ width: '100%' }}>
						{isUseExistingPatient ? (
							<div className={isUseExistingPatient ? 'fade-in' : 'fade-out'}>
								<PatientSelector
									data-testid="set-case-patient-selector"
									name='patientId'
									label={intl.formatMessage({ id: 'app.patients.search' })}
									validators={validators}
									noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
									onPatientSelected={onPatientSelected}
								/>
							</div>
						) : (
							<div data-testid="set-case-patient-add-new" className={!isUseExistingPatient ? 'fade-in' : 'fade-out'}>
								<AddNewPatient />
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	patients: formattedPatientsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadPatients: () => dispatch(fetchPatients())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter)

export default enhance(SetCasePatient)

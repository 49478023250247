import * as prom from '../../../apis/prom'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_PROMS_REQUESTED = 'FETCH_PROMS_REQUESTED'
const FETCH_PROMS_SUCCESS = 'FETCH_PROMS_SUCCESS'
const FETCH_PROMS_FAILED = 'FETCH_PROMS_FAILED'

export const fetchProms = () => (dispatch) => {
	dispatch({ type: FETCH_PROMS_REQUESTED, data: {} })
	prom.fetchProms().then((proms) => {
		if (proms) {
			return dispatch({ type: FETCH_PROMS_SUCCESS, data: proms })
		} else {
			return dispatch({ type: FETCH_PROMS_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	proms: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PROMS_SUCCESS:
			return { ...state, proms: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

export const promsSelector = (state) => state.prom.proms

import { authenticatedFetch } from './base'

export const addPatient = (email, birthDate, jurisdictionId, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId) => {
	return authenticatedFetch(`/api/patient`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			email: email,
			firstName: firstName,
			lastName: lastName,
			middleName: middleName,
			countryId: countryId,
			birthDate: birthDate,
			phoneNumber: phoneNumber,
			jurisdictionId: jurisdictionId, 
			phoneCountryCode: phoneCountryCode,
			languageId: languageId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updatePatient = (id, email, birthDate, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId) => {
	return authenticatedFetch(`/api/patient/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			email: email,
			firstName: firstName,
			lastName: lastName,
			middleName: middleName,
			countryId: countryId,
			birthDate: birthDate,
			phoneNumber: phoneNumber, 
			phoneCountryCode: phoneCountryCode,
			languageId: languageId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const resendInvitation = (id) => {
	return authenticatedFetch(`/api/patient/${id}/resend`, {
		method: 'post',
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPatients = (searchPhrase, offset) => {
	var url = `/api/patient`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	if(offset){
		url += `${url.indexOf('?searchPhrase') > 0 ? '&' : '?'}offset=${offset}`
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPatientAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(`/api/patient/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, {
		method: 'get'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchEmailVerification = (email) => {
	return authenticatedFetch(`/api/patient/validateEmail?email=${encodeURIComponent(email)}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

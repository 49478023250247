import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const ReportBuilder = lazy(() => componentLoader(() => import('./components/ReportBuilder')))

export default () => (
    <div data-testid="report-builder">
        <Suspense fallback={<div />}>
            <section>
                <ReportBuilder />
            </section>
        </Suspense>
    </div>
)

import React, {useEffect} from 'react'
import {withStyles} from "@material-ui/core"
import {injectIntl} from "react-intl"
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Typography, Grid } from "@material-ui/core"
import LabelPaper from '../../../../components/LabelPaper'
import SubscriptionSponsorInner from "./SubscriptionSponsorInner"
import { subscriptionSelector } from "../../../../redux/selectors"
//import { fetchSubscriptionChanges } from "../../../../redux/actions"


const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    gradient: {
        background:
            'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
        filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
    }
})

const SubscriptionSponsor = ({ subscription, classes, intl }) => {

    return (
        <div className="print-wrapper" style={{ padding: '50px 5% 0' }}>
                <Grid container direction="column" style={{ color: '#000' }}>
                    <Grid item style={{ width: '100%' }}>
                        <LabelPaper
                            label={intl.formatMessage({ id: 'app.userProfile.subscriptionSponsor' })}
                            Component={
                                SubscriptionSponsorInner
                            }
                            subscription={subscription}
                        />
                    </Grid>
                </Grid>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    subscription: subscriptionSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    //fetchUser: (id) => dispatch(getUserDetailsById(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(SubscriptionSponsor)

import React from 'react'
import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import AlertStatusCell from './InvitationStatusCell'
import AlertCell from './InvitationAlertCell'
import AlertTypeCell from './InvitationTypeCell'
import AlertDateCell from './InvitationAlertDateCell'
import AlertDelegateCell from './InvitationAlertDelegateCell'
import Menu from './InvitationAlertMenu'

export default ({ alert }) => {
	return (
		<TableRow data-testid="myalerts-invitation-alert-row">
			<TableCell
				data-testid="invitation-alert-row-status"
				align="left" style={{ padding: '0', position: 'relative' }}>
				<div
					style={{
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px',
						backgroundColor: 'rgb(134, 206, 180)'
					}}
				></div>
				<div style={{ textAlign: 'center', paddingLeft: '16px' }}>
					<AlertStatusCell alert={alert} />
				</div>
			</TableCell>
			<TableCell
				data-testid="invitation-alert-row-alert-cell"
				align="left" size="small">
				<AlertCell alert={alert} />
			</TableCell>

			<TableCell
				data-testid="invitation-alert-row-type"
				size="small">
				<AlertTypeCell alert={alert} />
			</TableCell>
			<TableCell
				data-testid="invitation-alert-row-date"
				size="small">
				<AlertDateCell alert={alert} />
			</TableCell>
			{/* <TableCell size="small">
			<AlertDelegateCell alert={alert} />
		</TableCell> */}
			<TableCell
				data-testid="invitation-alert-row-options"
				size="small" align="right">
				<Menu alert={alert} />
			</TableCell>
		</TableRow>
	)
}

import { authenticatedFetch } from './base'

export const createReport = (addReportModel) => {
	return authenticatedFetch(`/api/report`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(addReportModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const fetchReports = (searchPhrase) => {
	return authenticatedFetch(`/api/report?searchPhrase=${encodeURIComponent(searchPhrase)}`, {
		method: 'GET'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const deleteReport = (id) => {
	return authenticatedFetch(`/api/report/${id}`, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateReport = (id, updateReportModel) => {
	return authenticatedFetch(`/api/report/${id}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(updateReportModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const fetchReport = (reportId, chartId) => {
	// return authenticatedFetch(`/api/report/${reportId}/${chartId}`, {
	return authenticatedFetch(`/api/report/${reportId}/configuration`, {
		method: 'GET'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const fetchReportCircles = () => {
	return authenticatedFetch(`/api/report/circles`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const setReportCircle = (id, circleId, data) => {
	return authenticatedFetch(`/api/report/${id}/circles/${circleId}`, { 
		method: 'POST', 
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const setReportScoringGroupId = (data) => {
	return authenticatedFetch(`/api/report/${data.id}/scoringgroups/${data.scoringGroupId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const setXAxis = (chartId, xAxisModel) => {
	return authenticatedFetch(`/api/report/${chartId}/chart/xAxis`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(xAxisModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const setYAxis = (chartId, yAxisModel) => {
	return authenticatedFetch(`/api/report/${chartId}/chart/yAxis`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(yAxisModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const setPathologiesAndProms = (id, pathologies = [], proms = [], isInitialSetting = false, type) => {
	let url = `/api/report/${id}/addinitialpathologies`

	return authenticatedFetch(url, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ pathologies: pathologies, proms: proms, type: type })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchQuestionDefault = (reportId, id, typeId) => {
	var url = `/api/report/questions/${id}/report/${reportId}/types/${typeId}`
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchQuestionSurveys = (circleId, id, questionType) => {
	var url = `/api/report/circle/${circleId}/question/${id}/questionType/${questionType}/surveys`
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchScoringGroupSurveys = (circleId, id) => {
	var url = `/api/report/circle/${circleId}/scoringGroup/${id}/surveys`
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCohortCase = (reportId, cohortId) => {
	var url = `/api/report/${reportId}/cohorts/${cohortId}/cases`
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateCohortCase = (reportId, cohortId, caseId) => {
	var url = `/api/report/${reportId}/cohorts/${cohortId}/cases/update`
	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ cases: [caseId] })
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (object) {
			return object
		})
}

export const fetchCohortAutoComplete = (circleId, searchPhrase) => {
	var url = `/api/report/cohorts/circles/${circleId}/autocomplete`
	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchYAxis = (circleId) => {
	var url = `/api/report/circles/${circleId}/scoringgroups`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createCohort = (reportId, name, cases, hasCaseFilter, color) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ reportId: reportId, name: name, cases: cases, hasCaseFilter: hasCaseFilter, color: color })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const addCohortSingleValueFilter = (
	reportId,
	cohortId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/filter/single`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			cohortId: cohortId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			primaryDateValue: primaryDateValue,
			secondaryDateValue: secondaryDateValue,
			textValue: textValue,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const addCohortMultipleValueFilter = (
	reportId,
	cohortId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/filter/multiple`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			cohortId: cohortId,
			answerIds: answerIds,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const addCohortScoringGroupValueFilter = (
	reportId,
	cohortId,
	primaryValue,
	secondaryValue,
	sortOrder,
	comparisonType,
	scoringGroupId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/filter/scoringGroup`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			cohortId: cohortId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			entityId: scoringGroupId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const updateCohortMultipleValueFilter = (
	filterId,
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/multiple`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id: filterId,
			reportId: reportId,
			answerIds: answerIds,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const addPopulationSingleValueFilter = (
	reportId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/single`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			primaryDateValue: primaryDateValue,
			secondaryDateValue: secondaryDateValue,
			textValue: textValue,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const addPopulationMultipleValueFilter = (
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/multiple`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			answerIds: answerIds,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const addPopulationScoringGroupValueFilter = (
	reportId,
	primaryValue,
	secondaryValue,
	sortOrder,
	comparisonType,
	scoringGroupId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/scoringGroup`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			entityId: scoringGroupId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const updatePopulationMultipleValueFilter = (
	filterId,
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/multiple`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			reportId: reportId,
			id: filterId,
			answerIds: answerIds,
			sortOrder: sortOrder,
			comparisonType: comparisonType,
			questionType: questionType,
			entityId: questionId,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const fetchConfiguration = (id) => {
	return authenticatedFetch(`/api/report/${id}/configuration`, {
		method: 'GET'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const deleteCohort = (reportId, cohortId) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}`, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const getReportData = (id) => {
	return authenticatedFetch(`/api/report/${id}/data`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const getReportDataExport = (id) => {
	return authenticatedFetch(`/api/report/${id}/data/export`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (object) {
			return object
		})
}


export const getCircleReportDataExport = (id) => {
	return authenticatedFetch(`/api/report/circle/${id}/data/export`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (object) {
			return object
		})
}

export const deleteCohortFilter = (reportId, cohortId, filterId) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/filter/${filterId}`, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const deletePopulationFilter = (reportId, filterId) => {
	// [Route("{id}/filter/{filterId}")]
	return authenticatedFetch(`/api/report/${reportId}/filter/${filterId}`, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const updateCohortSingleValueFilter = (
	filterId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/single`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id: filterId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			primaryDateValue: primaryDateValue,
			secondaryDateValue: secondaryDateValue,
			textValue: textValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateCohortScoringGroupValueFilter = (
	filterId,
	primaryValue,
	secondaryValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId
) => {
	return authenticatedFetch(`/api/report/${reportId}/filter/scoringGroup`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id: filterId,
			primaryValue: primaryValue,
			secondaryValue: secondaryValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId: surveyInstanceId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}



export const setCohortDomain = (reportId, cohortId, option) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/domain/${option}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateCohortName = (reportId, cohortId, name) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/rename`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ name: name })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateReportName = (reportId, name) => {
	return authenticatedFetch(`/api/report/${reportId}/rename`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ name: name })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateXAxis = (values) => {
	return authenticatedFetch(`/api/report/${values.id}/xaxis`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const getReportXAxisValues = (id) => {
	return authenticatedFetch(`/api/report/${id}/xaxis/values`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const updateCohortVisibility = (reportId, cohortId) => {
	return authenticatedFetch(`/api/report/${reportId}/cohort/${cohortId}/changeVisibility`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const updateDataFreezeSettings = (values) => {
	return authenticatedFetch(`/api/report/${values.id}/dataFreezeSettings`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(object) {
			return object
		})
}

export const searchExportedReports = (searchPhrase, offset) => {
	var url = `/api/report/exported`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const getNewCompletedExportedReports = () => {
	return authenticatedFetch(`/api/report/getNewCompletedReports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const clearNewCompletedExportedReports = () => {
	return authenticatedFetch(`/api/report/clearNewCompletedReports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const downloadReportExport = (id) => {
	return authenticatedFetch(`/api/report/export/${id}/downloadReport`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				let header = response.headers.get("content-disposition")
				if (!header) {
					return { isSuccessful: false }
				}

				let encodedFileName = header.substring(header.indexOf("%22") + 3)
				let decodedFileName = decodeURIComponent(encodedFileName)
				let fileName = decodedFileName.substring(0, decodedFileName.length - 1)
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}
import {authenticatedFetch} from "./base"

export const distribute = (circleId, numberUsers, numberCases) => {
    return authenticatedFetch(`/api/resource/circle/${circleId}`, {
        method: 'put',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({
            numberCases,
            numberUsers
        })
    })
        .then(function(response){
            if(response.status === 200){
                return response.json()
            }
        }).then(function(circle){
            return circle
        })
}
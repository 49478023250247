import React from 'react'
import { useIntl } from 'react-intl'
import LabelPaper from '../../../../components/LabelPaper'
import ApiStatusWidget from './ApiStatusWidget'


export default ({}) => {
	const intl = useIntl()

	return (
		<div data-testid="api-status-section" className="print-wrapper" style={{ padding: '50px 5% 0' }}>
			<LabelPaper
				data-testid="api-status-section-label"
				label={intl.formatMessage({ id: 'app.api.statusSectionTitle' })}
				Component={ApiStatusWidget}
			/>
		</div>
	)
}

import React from 'react'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import LateralityValue from './LateralityValue'

export default ({ isBilateral }) => {
	const intl = useIntl()

	return (
		<Typography
			style={{
				fontSize: '14px',
				lineHeight: '20px',
				letterSpacing: '0.24',
				wordBreak: 'break-word',
				maxWidth: '250px'
			}}
		>
			<LateralityValue isBilateral={isBilateral}/>
		</Typography>
	)
}

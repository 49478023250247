import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { setCircleFilter } from '../../../redux/actions'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	selectStyle: {
		// This class used in scss
	}
})

const Filter = ({ intl, setFilter }) => {
	const f = [
		{ id: 0, label: intl.formatMessage({ id: 'app.circles.noFilter' }) },
		{ id: 1, label: intl.formatMessage({ id: 'app.circles.sponsoredFilter' }) },
		{ id: 2, label: intl.formatMessage({ id: 'app.circles.manageFilter' }) },
		{ id: 3, label: intl.formatMessage({ id: 'app.circles.joinedFilter' }) },
		{ id: 4, label: intl.formatMessage({ id: 'app.circles.archivedFilter' }) }
	]
	const [ filter, setCurrentFilter ] = useState(0)

	const onChangeFilter = (event, item) => {
		setCurrentFilter(event.target.value)
		setFilter(event.target.value)
	}

	var classes = useStyles()
	return (
		<FormControl
			data-testid="circle-component-filter"
			style={{ textAlign: 'center', width: '100%', overflow: 'hidden', minHeight: '48px' }}
		>
			<Select
				data-testid="circle-component-filter-select"
				value={filter}
				onChange={onChangeFilter}
				autoWidth
				displayEmpty={true}
				classes={classes.selectStyle}
				style={{
					color: '#fff',
					fontSize: '20px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '700',
					padding: '0 0 0 14px',
					minHeight: '48px'
				}}
			>
				{f.map((filter) => (
					<MenuItem data-testid="circle-component-filter-menu-item" value={filter.id} id={filter.id}>
						{filter.label} <KeyboardArrowDownIcon style={{ color: '#fff', fontSize: '22px', margin: '0 0 -5px 5px' }} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setFilter: (id) => dispatch(setCircleFilter(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)
export default enhance(Filter)

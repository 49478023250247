import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedNumber, formatNumber, intlShape } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrencyCode } from '../../utilities/CurrencyCodes'
import locale2 from 'locale2'

const Total = ({ intl, totalPrice }) => {
	const currency = 'USD' //getCurrencyCode(locale2);
	const theme = useTheme()

	return (
		<Grid data-testid="total" container spacing={3} style={{ paddingTop: '30px' }}>
			<Grid item xs={12} sm={6} />
			<Grid item xs={12} sm={3}>
				<Typography data-testid="total-title" variant='h2' style={{ color: theme.palette.light }}>
					{intl.formatMessage({ id: 'app.account.addCasesUsers.total' })}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={3}>
				<Typography data-testid="total-text" variant='h2' style={{ color: theme.palette.light }}>
					{intl.formatNumber(totalPrice, {
						style: 'currency',
						currency: currency,
						currencyDisplay: 'symbol'
					})}{' '}
					USD
				</Typography>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withRouter)

export default enhance(Total)

import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { setServiceProviderTaskFilter } from '../../../redux/actions'

const Filter = ({ intl, setFilter }) => {
        const
    f = [
        { id: 0, label: intl.formatMessage({ id: 'app.tasks.filter.todayTasks' }) },
        { id: 1, label: intl.formatMessage({ id: 'app.tasks.filter.all' }) },
        { id: 2, label: intl.formatMessage({ id: 'app.tasks.filter.thisWeek' }) },
        { id: 3, label: intl.formatMessage({ id: 'app.tasks.filter.overdue' }) },
        { id: 4, label: intl.formatMessage({ id: 'app.tasks.filter.completedTasks' }) }
    ]
const [ filter, setCurrentFilter ] = useState(0)

	const onChangeFilter = (event, item) => {
		setCurrentFilter(event.target.value)
		//setFilter(event.target.value)
	}

	return (
		<FormControl data-testid="mytasks-filter"
			style={{ textAlign: 'center', width: '100%', overflow: 'hidden', minHeight: '48px' }}
		>
			<Select
				data-testid="mytasks-filter-select"
				value={filter}
				onChange={onChangeFilter}
				autoWidth
				displayEmpty={true}
				style={{
					color: '#fff',
					fontSize: '20px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '700',
					padding: '0 0 0 14px',
					minHeight: '48px',
				}}
			>
				{f.map((filter) => (
					<MenuItem data-testid="mytasks-filter-menu-item" value={filter.id} id={filter.id}>
						{filter.label} <KeyboardArrowDownIcon style={{ color: '#fff', fontSize: '22px', margin: '0 0 -5px 5px' }} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)
export default enhance(Filter)

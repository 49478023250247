import history from '../../../utilities/history'
import { hideModal } from '../../actions'
import * as report from '../../../apis/report'
import * as users from '../../../apis/user'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { setSnackbarMessage } from '../../actions'
import { stopSaving } from '../core'

const ADD_REPORT_REQUESTED = 'ADD_REPORT_REQUESTED'
const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS'
const ADD_REPORT_FAILED = 'ADD_REPORT_FAILED'

export const addReport = (values) => (dispatch) => {
	dispatch({ type: ADD_REPORT_REQUESTED, data: {} })

	report.createReport(values).then((report) => {
		if (report && report.isSuccessful && report.chartId && report.reportId) {
			dispatch(stopSaving())
			dispatch({ type: ADD_REPORT_SUCCESS, data: report })
			dispatch(hideModal())
			return history.push('/report/' + report.reportId + '/' + report.chartId)
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.addReportFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_REPORTS_REQUESTED = 'FETCH_REPORTS_REQUESTED'
const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED'

export const fetchReports = (searchTerm) => (dispatch) => {
	dispatch({ type: FETCH_REPORTS_REQUESTED, data: {} })

	report.fetchReports(searchTerm).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_REPORTS_SUCCESS, data: data.reports })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.reports.fetchReportsFailed', isSuccess: false }
			})
		}
	})
}

const REMOVE_REPORT_REQUESTED = 'REMOVE_REPORT_REQUESTED'
const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS'
const REMOVE_REPORT_FAILED = 'REMOVE_REPORT_FAILED'

export const removeReport = (id) => (dispatch) => {
	dispatch({ type: REMOVE_REPORT_REQUESTED, data: {} })

	report.deleteReport(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: REMOVE_REPORT_SUCCESS, data: id })
			dispatch(hideModal())

			history.push('/reports')

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.reports.removeReportSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.reports.removeReportFailed', isSuccess: false }
			})
		}
	})
}

const UPDATE_REPORT_REQUESTED = 'UPDATE_REPORT_REQUESTED'
const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS'
const UPDATE_REPORT_FAILED = 'UPDATE_REPORT_FAILED'

export const updateReport = (id, updateModel) => (dispatch) => {
	dispatch({ type: UPDATE_REPORT_REQUESTED, data: {} })

	report.updateReport(id, updateModel).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_REPORT_SUCCESS, data: response })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.reports.updateReportSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.reports.updateReportFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_REPORT_REQUESTED = 'FETCH_REPORT_REQUESTED'
const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
const FETCH_REPORT_FAILED = 'FETCH_REPORT_FAILED'

export const fetchReport = (reportId, chartId) => (dispatch) => {
	dispatch({ type: FETCH_REPORT_REQUESTED, data: {} })

	report.fetchReport(reportId, chartId).then((data) => {
		if (data && data.isSuccessful) {
			dispatch({ type: FETCH_REPORT_SUCCESS, data: data })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const SET_XAXIS_REQUESTED = 'SET_XAXIS_REQUESTED'
const SET_XAXIS_SUCCESS = 'SET_XAXIS_SUCCESS'
const SET_XAXIS_FAILED = 'SET_XAXIS_FAILED'

export const setXAxis = (chartId, values) => (dispatch) => {
	dispatch({ type: SET_XAXIS_REQUESTED, data: {} })

	report.setXAxis(chartId, values).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: SET_XAXIS_SUCCESS, data: response })
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.xAxisFailed', isSuccess: false }
			})
		}
	})
}

const SET_REPORT_PATHOLOGIES_AND_PROMS_REQUESTED = 'SET_REPORT_PATHOLOGIES_AND_PROMS_REQUESTED'
const SET_REPORT_PATHOLOGIES_AND_PROMS_SUCCESS = 'SET_REPORT_PATHOLOGIES_AND_PROMS_SUCCESS'
const SET_REPORT_PATHOLOGIES_AND_PROMS_FAILED = 'SET_REPORT_PATHOLOGIES_AND_PROMS_FAILED'

export const setInitialReportPathologiesAndProms = (pathology, proms, type) => (dispatch) => {
	dispatch({ type: SET_REPORT_PATHOLOGIES_AND_PROMS_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report.setPathologiesAndProms(user.id, [ pathology ], [ proms ], true, type).then((response) => {
		if (response) {
			return dispatch(hideModal())
		} else {
			dispatch(setSnackbarMessage('Unable to edit profile. Please enter all required fields.'))
			return dispatch({ type: SET_REPORT_PATHOLOGIES_AND_PROMS_FAILED, data: {} })
		}
	})
}

const FETCH_EXPORTED_REPORTS_REQUESTED = 'FETCH_EXPORTED_REPORTS_REQUESTED'
const FETCH_EXPORTED_REPORTS_SUCCESS = 'FETCH_EXPORTED_REPORTS_SUCCESS'
const FETCH_EXPORTED_REPORTS_FAILED = 'FETCH_EXPORTED_REPORTS_FAILED'

export const fetchExportedReports = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_EXPORTED_REPORTS_REQUESTED, data: {} })
	report.searchExportedReports(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_EXPORTED_REPORTS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_EXPORTED_REPORTS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_EXPORTED_REPORTS_REQUESTED = 'FETCH_ADDITIONAL_EXPORTED_REPORTS_REQUESTED'
const FETCH_ADDITIONAL_EXPORTED_REPORTS_SUCCESS = 'FETCH_ADDITIONAL_EXPORTED_REPORTS_SUCCESS'
const FETCH_ADDITIONAL_EXPORTED_REPORTS_FAILED = 'FETCH_ADDITIONAL_EXPORTED_REPORTS_FAILED'

export const searchAdditionalExportedReports = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_EXPORTED_REPORTS_REQUESTED, data: {} })
	report.searchExportedReports(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_EXPORTED_REPORTS_SUCCESS, 
				data: response
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_EXPORTED_REPORTS_FAILED, data: {} })
		}
	})
	return
}

const SET_EXPORTED_REPORT_SORT_REQUESTED = 'SET_EXPORTED_REPORT_SORT_REQUESTED'

export const setExportedReportSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.report.exportedReports ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || -(a[field]>b[field])||+(a[field]<b[field])
		} else {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return b[field].toLowerCase() > a[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || +(a[field]>b[field])||-(a[field]<b[field])
		}
	})

	return dispatch({
		type: SET_EXPORTED_REPORT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const DOWNLOAD_REPORT_EXPORT_REQUESTED = 'DOWNLOAD_REPORT_EXPORT_REQUESTED'
const DOWNLOAD_REPORT_EXPORT_SUCCESS = 'DOWNLOAD_REPORT_EXPORT_SUCCESS'
const DOWNLOAD_REPORT_EXPORT_FAILED = 'DOWNLOAD_REPORT_EXPORT_FAILED'

export const dowloadReportExport = (reportId) => (dispatch) => {
	dispatch({ type: DOWNLOAD_REPORT_EXPORT_REQUESTED, data: {reportId} })
	
	report.downloadReportExport(reportId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: DOWNLOAD_REPORT_EXPORT_SUCCESS, data: {reportId} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.exportSuccessful', isSuccess: true }
			})
		} else {
			dispatch({ type: DOWNLOAD_REPORT_EXPORT_FAILED, data: {reportId} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const GET_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED = 'GET_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED'
const GET_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS = 'GET_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS'
const GET_NEW_COMPLETED_EXPORTED_REPORTS_FAILED = 'GET_NEW_COMPLETED_EXPORTED_REPORTS_FAILED'

export const getNewCompletedExportedReports = () => (dispatch, getState) => {
	dispatch({ type: GET_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED, data: {} })

	report.getNewCompletedExportedReports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: GET_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: GET_NEW_COMPLETED_EXPORTED_REPORTS_FAILED, data: {} })
		}
	})
}

const SET_EXPORTED_REPORT_SEARCH_TERM_REQUESTED = 'SET_EXPORTED_REPORT_SEARCH_TERM_REQUESTED'
const SET_EXPORTED_REPORT_SEARCH_TERM_SUCCESS = 'SET_EXPORTED_REPORT_SEARCH_TERM_SUCCESS'

export const setExportedReportSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_EXPORTED_REPORT_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_EXPORTED_REPORT_SEARCH_TERM_SUCCESS, data: value })
}

const CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED = 'CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED'
const CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS = 'CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS'
const CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_FAILED = 'CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_FAILED'

export const clearNewCompletedExportedReports = () => (dispatch) => {
	dispatch({ type: CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED, data: {} })

	report.clearNewCompletedExportedReports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_FAILED, data: {} })
		}
	})
}

const SET_REPORT_SEARCH_TERM_REQUESTED = 'SET_REPORT_SEARCH_TERM_REQUESTED'
const SET_REPORT_SEARCH_TERM_SUCCESS = 'SET_REPORT_SEARCH_TERM_SUCCESS'

export const setReportSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_REPORT_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_REPORT_SEARCH_TERM_SUCCESS, data: value })
}

const initial = {
	reports: [],
	report: null,
	circles: [],
	exportedReports: [],
	exportedReportsSortField: 'name',
	exportedReportsIsDescending: false,
	exportedReportsSearchTerm: '',
	exportedReportsHasMore: true,
	exportedReportsIsLoading: false,
	newCompletedExportedReports: false,
	downloadingExportedReports: [],
	reportsSearchTerm: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case 'SIGN_OUT_REQUESTED':
			return initial
		case ADD_REPORT_SUCCESS:
			return {
				...state,
				reports: [ ...state.reports.slice(0, action.data), action.data, ...state.reports.slice(action.pos) ]
			}
		case FETCH_REPORTS_SUCCESS:
			return { ...state, reports: action.data }
		case REMOVE_REPORT_SUCCESS:
			return {
				...state,
				reports: state.reports.filter((item) => item.reportId !== action.data)
			}
		case UPDATE_REPORT_SUCCESS:
			return {
				...state,
				reports: state.reports.map((item, index) => {
					if (item.reportId !== action.data.reportId) {
						return item
					}
					return {
						...item,
						...action.data
					}
				})
			}
		case FETCH_REPORT_SUCCESS:
			return { ...state, report: action.data }
		case SET_XAXIS_SUCCESS:
			return {
				...state,
				report: {
					xAxisMinimum: action.data.min,
					xAxisMaximum: action.data.max,
					xAxisTimeInterval: action.data.timeInterval
				}
			}
		case FETCH_EXPORTED_REPORTS_REQUESTED:
			return { ...state, exportedReports: [] }
		case FETCH_EXPORTED_REPORTS_SUCCESS:
			return { 
				...state, 
				exportedReports: action.data.reports,
				exportedReportsHasMore: action.data.hasMore,
				exportedReportsSortField: 'name',
				exportedReportsIsDescending: false
			 }
		case FETCH_ADDITIONAL_EXPORTED_REPORTS_SUCCESS:
			return {
				...state,
				exportedReports: [ ...state.exportedReports, ...action.data.reports],
				exportedReportsHasMore: action.data.hasMore,
				exportedReportsIsLoading: false
			}
		case FETCH_ADDITIONAL_EXPORTED_REPORTS_FAILED:
			return {
				...state,
				exportedReportsHasMore: false,
				exportedReportsIsLoading: false
			}
		case FETCH_ADDITIONAL_EXPORTED_REPORTS_REQUESTED:
			return {
				...state,
				exportedReportsIsLoading: true
			}
		case SET_EXPORTED_REPORT_SORT_REQUESTED:
			return {
				...state,
				exportedReports: action.data.items,
				exportedReportsSortField: action.data.field,
				exportedReportsIsDescending: action.data.isDescending
			}
		case SET_EXPORTED_REPORT_SEARCH_TERM_SUCCESS:
			return { ...state, exportedReportsSearchTerm: action.data }
		case GET_NEW_COMPLETED_EXPORTED_REPORTS_SUCCESS:
			return { ...state, newCompletedExportedReports: action.data }
		case CLEAR_NEW_COMPLETED_EXPORTED_REPORTS_REQUESTED:
			return { ...state, newCompletedExportedReports: false }
		case DOWNLOAD_REPORT_EXPORT_REQUESTED:
			return { ...state, downloadingExportedReports: [...state.downloadingExportedReports, action.data.reportId] }
		case DOWNLOAD_REPORT_EXPORT_SUCCESS:
			return { ...state, downloadingExportedReports: state.downloadingExportedReports.filter((item) => item !== action.data.reportId) }
		case DOWNLOAD_REPORT_EXPORT_FAILED:
			return { ...state, downloadingExportedReports: state.downloadingExportedReports.filter((item) => item !== action.data.reportId) }
		case SET_REPORT_SEARCH_TERM_SUCCESS:
			return { ...state, reportsSearchTerm: action.data }
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.report

export const reportsSelector = createSelector(mainSelector, (state) => {
	if (state && state.reports && state.reports.length > 0) return state.reports

	return null
})

export const countReportsSelector = createSelector(mainSelector, (state) => {
	if (state && state.reports && state.reports.length > 0) return state.reports.length
	return 0
})

export const showPreferenceSelector = createSelector(mainSelector, (state) => {
	let user = cookies.get('user')
	return user && !user.hasReportPreference
})

export const yAxisLabelSelector = createSelector(mainSelector, (state) => {
	if (state && state.report && state.report.yAxisBundleId > 0 && state.report.yAxisBundleName) {
		return state.report.yAxisBundleName
	}
	return 'None Selected'
})

export const xAxisLabelSelector = createSelector(mainSelector, (state) => {
	var ranges = [ 'Choose', 'Days', 'Weeks', 'Months', 'Years' ]

	if (state && state.report && state.report.xAxisMinimum > 0 && state.report.xAxisMaximum > 0) {
		return (
			state.report.xAxisMinimum + '-' + state.report.xAxisMaximum + ' ' + ranges[state.report.xAxisTimeInterval]
		)
	}

	return 'Click pencil to adjust'
})

export const xAxisSelector = createSelector(mainSelector, (state) => {
	if (state && state.report) {
		return {
			min: state.report.xAxisMinimum,
			max: state.report.xAxisMaximum,
			timeInterval: state.report.xAxisTimeInterval
		}
	}
})

export const reportSelector = createSelector(mainSelector, (state) => {
	return state && state.report
})

export const numberCirclesSelector = createSelector(mainSelector, (state) => state.circles.length)

export const numberCasesSelector = createSelector(mainSelector, (state) => {
	var numberCases = 0

	return numberCases
})

export const chartDataSelector = createSelector(mainSelector, (state) => {
	var data = [
		{
			date: 20190101,
			'Sample Results': 0,
			COHORT: 0
		},
		{
			date: 20190201,
			'Sample Results': 58,
			COHORT: 80
		},
		{
			date: 20190301,
			'Sample Results': 53.3,
			COHORT: 70
		},
		{
			date: 20190401,
			'Sample Results': 55.7,
			COHORT: 70
		},
		{
			date: 20190501,
			'Sample Results': 63.4,
			COHORT: 70
		},
		{
			date: 20190601,
			'Sample Results': 58,
			COHORT: 70
		},
		{
			date: 20190701,
			'Sample Results': 53.3,
			COHORT: 70
		},
		{
			date: 20190801,
			'Sample Results': 40,
			COHORT: 70
		},
		{
			date: 20190901,
			'Sample Results': 63.4,
			COHORT: 70
		},
		{
			date: 20191001,
			'Sample Results': 58,
			COHORT: 70
		},
		{
			date: 20191101,
			'Sample Results': 53.3,
			COHORT: 70
		},
		{
			date: 20191201,
			'Sample Results': 40,
			COHORT: 70
		}
	]
	return data
})

export const yAxisAutoCompleteSelector = createSelector(mainSelector, (state) => {
	return state.yAxisAutocomplete
})

export const exportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.exportedReports
})

export const hasExportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.exportedReports && state.exportedReports.length > 0
})

export const sortExportedReportsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.exportedReportsIsDescending
})

export const sortExportedReportsFieldSelector = createSelector(mainSelector, (state) => {
	return state.exportedReportsSortField
})

export const exportedReportSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.exportedReportsSearchTerm
})

export const hasMoreExportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.exportedReportsHasMore
})

export const isLoadingExportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.exportedReportsIsLoading
})

export const newCompletedExportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.newCompletedExportedReports
})

export const downloadingExportedReportsSelector = createSelector(mainSelector, (state) => {
	return state.downloadingExportedReports
})

export const reportSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.reportsSearchTerm
})
import React, { Fragment } from 'react'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { showModal } from '../../../../redux/actions'
import { injectIntl } from 'react-intl'
import Add from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/core/styles'
// import ManagePlanModal from "./ManagePlanModal";
import AddCasesModal from "../../../../components/StripeCheckout/AddCasesModal"

const styles = {

}

const AddCasesUsersButton = ({ onAddClick, intl, subscriptionUser, subscriptionCase }) => {
	const theme = useTheme()

	return (
		<div>
			<Button
				data-testid="button-add-cases-users"
				variant="contained"
				color="primary"
				onClick={() => onAddClick(subscriptionUser, subscriptionCase)}
				startIcon={<Add style={{ fontSize: '22px' }} />} > 
					{intl.formatMessage({ id: 'app.account.managePlan.addCasesUsersButtonText' })}
			</Button>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onAddClick: (subscriptionUser, subscriptionCase) => dispatch(showModal(AddCasesModal, {subscriptionUser: subscriptionUser, subscriptionCase: subscriptionCase}))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl, withStyles(styles))

export default enhance(AddCasesUsersButton)

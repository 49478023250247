import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Grid, Typography, Switch, FormControlLabel, CircularProgress, useTheme } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import LabelPaper from '../../../components/LabelPaper'
import { mFAStateSelector, isUserMFAStateLoadingSelector, isPatientMFAStateLoadingSelector } from '../../../redux/selectors'
import { fetchMFAState, updateMFAState } from '../../../redux/actions'

const styles = () => ({
    icons: {
        '& svg': {
			transform: 'scale(2.4)',
			margin: '22px',
			color: '#b00020'
        }
    },
})

const Warning = ({ _classes, message }) => {
	return (
		<React.Fragment>
            <Grid item className={_classes.icons}>
                <WarningIcon />
            </Grid>
            <Grid item>
                <Typography
                    data-testid="account-security-settings-warning"
                    variant="h6"
                >
					{message}
                </Typography>
            </Grid>
        </React.Fragment>
        )
}


const Component = ({ mFAState, setMFAState, isUserMFAStateLoading, isPatientMFAStateLoading, _classes, intl, user }) =>
{
	const onToggle = (event) => {

		let state = event.target.checked;

		if (event.target.name === 'account-security-settings-user-switch') {
            setMFAState(1, state);
        }
		else if (event.target.name === 'account-security-settings-patient-switch') {
            setMFAState(2, state);
        }
    }

    const theme = useTheme()
    const COLOR_BLUE = theme.palette.primary.main

    useEffect(
        () => {
        },
        []
	)

	return (
		<React.Fragment>
			<Grid container direction='column' spacing={3} style={{ marginBottom: '10px' }}>
				<Grid item>
					<Typography
						data-testid="account-security-settings-user-title"
						variant='h3'
					>
						{intl.formatMessage({ id: 'app.userProfile.security.account.user' })}
					</Typography>
				</Grid>
				{
					isUserMFAStateLoading
						? <Grid item><CircularProgress /></Grid>
						:
						<React.Fragment>
							<Grid item>
								<FormControlLabel
									data-testid="account-security-settings-user-control"
									control={
										<Switch
											name="account-security-settings-user-switch"
											data-testid="account-security-settings-user-switch"
											color="primary"
											onChange={onToggle}
											checked={mFAState ? mFAState.user2FAEnabled : false}
										/>
									}
									label={intl.formatMessage({ id: 'app.userProfile.security.account.user.switch' })}
									style={{ color: COLOR_BLUE, textTransform: 'uppercase', alignItems: 'center' }}
								/>
							</Grid>
							{
								mFAState && mFAState.user2FAEnabled && mFAState.teamUsers.some(x => !x.state) &&
								<Grid item container direction="row" alignItems="center">
									<Warning _classes={_classes} message={
										intl.formatMessage(
											{ id: 'app.userProfile.security.account.user.warning' },
											{ numerator: mFAState.teamUsers.filter(x => !x.state).length, denominator: mFAState.teamUsers.length }
										)}
									/>
								</Grid>
							}
							{
								mFAState && mFAState.user2FAEnabled && user && user.phoneNumber && user.phoneNumberVerified &&
								<Grid item container direction="row" alignItems="center" style={{marginLeft: '22px'}}>
                                    <Typography
                                        variant="body2">
                                        {intl.formatMessage({ id: 'app.general.phone' })}
									</Typography>
                                    <Typography
										variant="h6"
                                        style={{margin: '0 2em'}}
									>
                                        {user.phoneNumber}
                                    </Typography>
								</Grid>
                            }
						</React.Fragment>
                }
			</Grid>
			<hr style={{ height: '1px', backgroundColor: 'LightGray', borderWidth: 0 }} />
			<Grid container direction='column' spacing={3} style={{ marginBottom: '10px' }}>
				<Grid item>
					<Typography
						data-testid="account-security-settings-patient-title"
						variant='h3'
					>
						{intl.formatMessage({ id: 'app.userProfile.security.account.patient' })}
					</Typography>
				</Grid>
				{
					isPatientMFAStateLoading
						? <Grid item><CircularProgress /></Grid>
						:
						<React.Fragment>
							<Grid item>
								<FormControlLabel
									data-testid="account-security-settings-patient-control"
									control={
										<Switch
											name="account-security-settings-patient-switch"
											data-testid="account-security-settings-patient-switch"
											color="primary"
											onChange={onToggle}
											checked={mFAState ? mFAState.patient2FAEnabled : false}
										/>
									}
									label={intl.formatMessage({ id: 'app.userProfile.security.account.patient.switch' })}
									style={{ color: COLOR_BLUE, textTransform: 'uppercase', alignItems: 'center' }}
								/>
							</Grid>
							{
								mFAState && mFAState.patient2FAEnabled && mFAState.teamPatients.some(x => !x.state) &&
								<Grid item container direction="row" alignItems="center">
									<Warning _classes={_classes} message={
										intl.formatMessage(
											{ id: 'app.userProfile.security.account.patient.warning' },
											{ numerator: mFAState.teamPatients.filter(x => !x.state).length, denominator: mFAState.teamPatients.length }
										)}
									/>
								</Grid>
							}
						</React.Fragment>
                }
			</Grid>
		</React.Fragment>
	);
    }

const AccountSecuritySettings = ({ loadMFAState, mFAState, setMFAState, isUserMFAStateLoading, isPatientMFAStateLoading, classes, intl, user }) => {
	useEffect(() => {
        loadMFAState();
    }, [])
	
	return (
		<div data-testid="team-members" style={{ position: 'relative' }}>
			<div style={{ padding: '0' }}>
				<Grid container direction="column" style={{ color: '#000' }}>
					<Grid item style={{ width: '100%' }}>
                        <LabelPaper
							data-testid="account-security-settings"
							label={intl.formatMessage({ id: 'app.userProfile.security' })}
							_classes={classes}
							intl={intl}
							Component={Component}
							mFAState={mFAState}
							setMFAState={setMFAState}
							user={user}
							isUserMFAStateLoading={isUserMFAStateLoading}
							isPatientMFAStateLoading={isPatientMFAStateLoading}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	mFAState: mFAStateSelector(state),
	isUserMFAStateLoading: isUserMFAStateLoadingSelector(state),
	isPatientMFAStateLoading: isPatientMFAStateLoadingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadMFAState: () => dispatch(fetchMFAState()),
	setMFAState: (type, state) => dispatch(updateMFAState(type, state))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(AccountSecuritySettings)

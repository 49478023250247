import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Pencil from '../../../assets/pencil'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CameraAlt from '@material-ui/icons/CameraAlt'
import loginBackground from '../../../assets/login/arrows-bright.jpg'
import { resetPhoto, setPhoto } from '../../../redux/actions'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

const styles = (theme) => ({
	bgImage: {
		height: '301px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		[theme.breakpoints.down('md')]: {
			height: '200px',
			margin: '0 0 10px 0'
		},
		[theme.breakpoints.only('md')]: {
			height: '250px',
		}
    },
    buttonPhotoAction: {
        color: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.dark,
        opacity: '0.6',
        borderRadius: '0',
        margin: '5px 0 0 0',
        opacity: '0.7',
        "&:hover, &:focus": {
            opacity: '1',
        }
    }
})

const TeamBackgroundImage = ({ data, uploadBackground, onBackgroundUpdated, canEdit, intl, reset, classes }) => {
	const theme = useTheme()

	const [ imageOpacity, setImageOpacity ] = useState(0)
	const [ pencilIndex, setPencilIndex ] = useState(0)
	const [ imageLoaded, setImageLoaded ] = useState(false)

	const uploadImage = (e) => {
		var file = e.target.files[0]
		if (data && file) uploadBackground(data.id, file)
		e.target.files = null
	}

	return (
		<Grid container
			className={classes.bgImage}
			style={{
				backgroundImage: `url(${data && data.teamImageUrl ? data.teamImageUrl : loginBackground})`,
			}}
			onMouseOver={() => {
				setImageOpacity(1)
				setPencilIndex(100)
			}}
			onMouseOut={() => {
				setImageOpacity(0)
				setPencilIndex(0)
			}}
		>

			<Grid item>
				{canEdit && (
                    <Grid container justifyContent="center" direction="column" style={{ opacity: imageOpacity }}>
						<Grid data-testid="bg-file-input-wrapper" item onClick={() => document.getElementById('file-input2').click()}>
							<input
								data-testid="bg-file-input-input"
								id="file-input2"
								type="file"
								name="name2"
								style={{ display: 'none' }}
								accept=".jpg, .png, .bmp"
								onChange={uploadImage}
							/>
                            <Button
                                startIcon={<CameraAlt />}
								data-testid="bg-file-input-button-update"
                                className={classes.buttonPhotoAction}	
                                variant="contained"
                                color="secondary"
                                size="small"
							>
								{intl.formatMessage({ id: 'app.general.updatePhoto' })}
							</Button>							
						</Grid>
						<Grid item>
                            <Button
                                startIcon={<SettingsBackupRestoreIcon />}
								data-testid="bg-file-input-button-reset"
                                onClick={() => reset(data.id)}
                                className={classes.buttonPhotoAction}
                                variant="contained"
                                color="secondary"
                                size="small"
							>
								Reset
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	uploadBackground: (id, photo) => {
		dispatch(setPhoto('teams', id, photo, ownProps.onBackgroundUpdated))
	},
	reset: (id) => dispatch(resetPhoto('teams', id, ownProps.onBackgroundUpdated))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(TeamBackgroundImage)

import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'

export default({value, thickness = 7, transitionDuration = 1500, height = 192, width = 192}) => {
    const canvas = useRef(null)

    useEffect(() => {
        if(!value) value = 0

        if(canvas.current){
            let radialChart = drawChart()
            radialChart.update(value)
        }
    }, [])

    const drawChart = () => {
        if(canvas.current){

            const svgCanvas = d3.select(canvas.current)
                .append('svg')
                .attr('width', width)
                .attr('height', height)

            const outerRadius = Math.min(width, height) * 0.45

            const firstArc = d3.arc()
                .startAngle(0)
                .endAngle(Math.PI * 2)
                .innerRadius(outerRadius-thickness)
                .outerRadius(outerRadius)

            svgCanvas.append('path')
                .attr('class', 'progress-bar-bg')
                .attr('transform', `translate(${width/2}, ${height/2})`)
                .attr('fill', '#E3E3E3')
                .attr('d', firstArc())

            const firstArcPath = svgCanvas.append('path')
                .attr('fill', '#007AFE')
                .attr('transform', `translate(${width/2}, ${height/2})`)

            const middleLabel = svgCanvas.append('text')
                .attr('transform', `translate(${width/2}, ${height/2})`)
                .attr('font-size', '48px')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'central')
                .attr('font-weight', '200')
                .text(`${value}%`)
            
            return{
                update: function(percent) {
                    const startValue = value
                    const startAngle = Math.PI * startValue / 50
                    const angleDiff = Math.PI * percent / 50 - startAngle

                    firstArcPath.transition().duration(transitionDuration).attrTween('d', function() {
                        return function(t){
                            firstArc.endAngle(startAngle + angleDiff * t)
                            return firstArc()
                        }
                    })

                    middleLabel.transition().duration(transitionDuration).tween('', function(){
                        return function(t){
                            middleLabel.text(`${Math.round(startValue + (percent - startValue) * t)}%`)
                        }
                    })

                    value = percent
                }
            }
        }
    }

    return(<div data-testid="component-radial-chart" ref={canvas}></div>)
}

import React from 'react'
import TrackChanges from '@material-ui/icons/TrackChanges'

export default () => {
	return (
		<div data-testid="myalerts-outlier-status-cell" className="status-cell">
			<TrackChanges style={{ color: '#FF8556', transform: 'translateY(3px)' }} />
		</div>
	)
}

import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { deleteUserMember, resendInvitationEmail } from '../../../redux/actions'
import { injectIntl } from 'react-intl'

const ITEM_HEIGHT = 48

const MemberMenu = ({ member, deleteUserInvitation, intl, user, resendInvitation }) => {
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onDeleteInvitation = () => {
		if (member) {
			if(member.dateJoined)
			{
				deleteUserInvitation(member.memberId)	
			}
			else
			{
				deleteUserInvitation(null, member.invitationId)
			}
			
			handleClose()
		}
	}
	const onMailUser = () => {
		if (member) {
			window.location.href = `mailto:${member.email}`
			handleClose()
		}
	}

	const onResendInvite = () => {
		if (member) {
			resendInvitation()
			handleClose()
		}
	}

	return (
		<div data-testid="member-menu">
			<IconButton data-testid="member-menu-button" aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				data-testid="member-menu-long"
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: 200,
						backgroundColor: '#243041',
						color: '#fff'
					}
				}}
			>
				<MenuItem data-testid="team-member-email" onClick={onMailUser}>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberEmail' })}
				</MenuItem>
				{member &&
				user &&
				user.id === member.ownerId &&
				member.status === 0 && (
					<MenuItem data-testid="team-member-resend" onClick={onResendInvite}>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberMenuResend' })}
					</MenuItem>
				)}
				{member &&
				user &&
				user.id === member.ownerId &&
				user.id !== member.memberId && (
					<MenuItem data-testid="team-member-remove" onClick={onDeleteInvitation}>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberMenuRemove' })}
					</MenuItem>
				)}
			</Menu>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	deleteUserInvitation: (id, invitationId) => dispatch(deleteUserMember(id, invitationId)),
	resendInvitation: () => dispatch(resendInvitationEmail(ownProps.member.invitationId))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(MemberMenu)

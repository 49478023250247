import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect, useDispatch } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import { compose } from 'recompose'
import { Grid, Typography, useTheme } from '@material-ui/core'
import FormTextField from './FormTextField'
import { PhoneNumberField } from './PhoneNumberField'
import CalmButton from './CalmButton'
import { phoneNumber, required } from '../utilities/validators'
import { getUser } from '../utilities/userUtilities'
import { getCurrentUserDetails, requestPhoneVerificationCode, completePhoneVerification, resetPhoneVerificationCode, completeSignInPhoneVerification } from '../redux/actions'
import { isSavingSelector, verifyPhoneMaskSelector, userDetailsSelector } from '../redux/selectors'

const formName = 'verifyPhoneNumber';

const AttachPhoneNumber = ({ intl, theme, isLoading, userDetails }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (userDetails) {
            dispatch(change(formName, 'customPhoneNumber', userDetails.customPhoneNumber))
            dispatch(change(formName, 'customPhoneCountryCode', userDetails.customPhoneCountryCode))
        }
    }, [userDetails && userDetails.customPhoneNumber && userDetails.customPhoneCountryCode])

    return [
        <Typography
            variant="subtitle2"
            style={{
                color: '#fff',
                margin: '30px 0',
            }}
            align="justify"
        >
            {intl.formatMessage({ id: 'app.security.mfa.setup.message' })}
        </Typography>,
        userDetails && <PhoneNumberField
            label={intl.formatMessage({ id: 'app.addpatient.phoneLabel' })}
            name='phoneNumber'
            phoneField='customPhoneNumber'
            phoneCountryCodeField='customPhoneCountryCode'
            validators={[phoneNumber]}
        />,
        <CalmButton
            style={{
                marginTop: '40px'
            }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
        >
            {intl.formatMessage({ id: 'app.security.mfa.sendVerificationCode' })}
        </CalmButton>
    ];
}

const EnterCode = ({ intl, theme, isLoading, verifyPhoneMask, resetCode, fetchVerificationCode, autoFetch, userDetails, goBackButtonEnabled, resendHandler}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (autoFetch && userDetails && userDetails.customPhoneNumber) {
            fetchVerificationCode(userDetails.customPhoneCountryCode, userDetails.customPhoneNumber);
        }
    }, [])

    const [resendSecondsLeft, setResendSecondsLeft] = useState(0);

    useEffect(
        () => {

            const f = () => dispatch(function () {
                if (verifyPhoneMask && verifyPhoneMask.sentUtc) {
                    let seconds = Math.round(60 - (new Date() - new Date(verifyPhoneMask.sentUtc)) / 1000);

                    setResendSecondsLeft(seconds)
                }
            });

            f();

            let timer = setInterval(f, 1000)

            return () => {
                clearInterval(timer)
            }
        }
        , [verifyPhoneMask && verifyPhoneMask.sentUtc]
    )

    return (
        <React.Fragment>
            <Typography
                variant="subtitle2"
                style={{
                    color: '#fff',
                    margin: '30px 0',
                }}
                align="center">
                {intl.formatMessage({ id: 'app.security.mfa.successfullySentMessage' })}:
             </Typography>
            <Typography
                variant="subtitle2"
                style={{
                    color: '#fff',
                    margin: '30px 0',
                }}
                align="center">
                {verifyPhoneMask && verifyPhoneMask.mask}
            </Typography>
            <Typography
                variant="subtitle2"
                style={{
                    color: '#fff',
                    margin: '30px 0',
                }}
                align="center">
                {intl.formatMessage({ id: 'app.security.mfa.enterCodeMessage' })}
            </Typography>
            <FormTextField
                label={intl.formatMessage({ id: 'app.security.mfa.verificationCodeLabel' })}
                fullWidth
                type="text"
                name="verificationCode"
                variant="filled"
                validators={[required]}
                align="center" />
            <Grid style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
               {
                    goBackButtonEnabled && <CalmButton
                        variant="outlined"
                        disabled={isLoading}
                        color="primary"
                        onClick={() => resetCode()}
                    >
                        {intl.formatMessage({ id: 'app.security.mfa.backButtonText' })}
                    </CalmButton>
            }
                <CalmButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: 'app.security.mfa.submitButtonText' })}
                </CalmButton>
            </Grid>
            {
                resendSecondsLeft <= 0
                    ? <CalmButton
                        style={{
                            margin: '30px auto 10px'
                        }}
                        variant="text"
                        disabled={isLoading}
                        color="secondary"
                        onClick={() => resendHandler ? resendHandler() : fetchVerificationCode(verifyPhoneMask.phoneCountryCode, verifyPhoneMask.phoneNumber, true)}
                    >
                        {intl.formatMessage({ id: 'app.security.mfa.resendButtonText' })}
                    </CalmButton>
                    : <Typography
                        variant="subtitle2"
                        style={{
                            color: '#fff',
                            marginTop: '30px',
                            marginBottom: '10px',
                            textAlign: 'center', 
                            minHeight: '27.75px'
                        }}>
                        {intl.formatMessage({ id: 'app.security.mfa.resendPlaceHolder' }, { secondsLeft: resendSecondsLeft })}
                    </Typography>
            }
        </React.Fragment>
    )
}

const VerifyPhoneNumber = ({
    mode,
    intl,
    handleSubmit,
    userDetails,
    fetchUser,
    fetchVerificationCode,
    completeVerification,
    completeSignInVerification,
    resetCode,
    isLoading,
    verifyPhoneMask,
    returnUrl,
    resendHandler
}) => {
    const theme = useTheme()

    useEffect(() => {
        const user = getUser()

        if (user)
            fetchUser()
    }, [])

    //useEffect(() => {
       
    //}, [mode, verifyPhoneMask])

    const getFormSubmitHandler = (mode) => {
        switch (mode) {
            case 'attach':
            case 'attach-cookie':
            case 'verify':
                if (verifyPhoneMask)
                {
                    return (values) => completeVerification(values.verificationCode, mode);
                }
                else
                {
                    return (values) => fetchVerificationCode(values.customPhoneCountryCode, values.customPhoneNumber);
                }
            case 'verify-sign-in':
                return (values) => completeSignInVerification(values.verificationCode, returnUrl);
        }
    }

    const getView = (mode) => {
        switch (mode) {
            case 'attach':
            case 'attach-cookie':
                if (verifyPhoneMask) {
                    return (<EnterCode goBackButtonEnabled={true} intl={intl} theme={theme} userDetails={userDetails} isLoading={isLoading} fetchVerificationCode={fetchVerificationCode} completeVerification={completeVerification} verifyPhoneMask={verifyPhoneMask} resetCode={resetCode} />)
                }
                else {
                    return (<AttachPhoneNumber intl={intl} theme={theme} isLoading={isLoading} userDetails={userDetails} />);
                }
            case 'verify':
                return (<EnterCode autoFetch={true} intl={intl} theme={theme} userDetails={userDetails} isLoading={isLoading} fetchVerificationCode={fetchVerificationCode} completeVerification={completeVerification} verifyPhoneMask={verifyPhoneMask} resetCode={resetCode} />);
            case 'verify-sign-in':
                return (<EnterCode resendHandler={resendHandler} autoFetch={true} intl={intl} theme={theme} userDetails={userDetails} isLoading={isLoading} fetchVerificationCode={fetchVerificationCode} completeVerification={completeVerification} verifyPhoneMask={verifyPhoneMask} resetCode={resetCode} />);
                break;
        }
    }

    const getTitles = (mode) => {
        switch (mode) {
            case 'attach':
            case 'attach-cookie':
                return ['app.security.mfa.title.setup'];
            case 'verify':
                return ['app.security.mfa.title.verify'];
            case 'verify-sign-in':
                return ['app.security.mfa.title.verify'];
        }
    }

    return (
        <form onSubmit={handleSubmit(getFormSubmitHandler(mode))}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{ width: '100%', padding: '3rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
            >
                {
                    getTitles(mode).map((k, v) =>
                        <Grid item style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography
                                variant='h1'
                                style={{
                                    color: '#fff',
                                    marginBottom: '11px',
                                    width: '80%'
                                }}
                                align="center"
                            >
                                {intl.formatMessage({ id: k })}
                            </Typography>
                        </Grid>
                    )
                }
                <Grid item style={{ maxWidth: '400px', textAlign: 'center' }}>
                    {getView(mode)}
                </Grid>
            </Grid>
        </form>
    )
}

const mapStateToProps = (state) => ({
    isLoading: isSavingSelector(state),
    verifyPhoneMask: verifyPhoneMaskSelector(state),
    initialValues: userDetailsSelector(state),
    userDetails: userDetailsSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchUser: (id) => dispatch(getCurrentUserDetails()),
    fetchVerificationCode: (phoneCountryCode, phoneNumber, resend) => dispatch(requestPhoneVerificationCode(phoneCountryCode, phoneNumber, resend)),
    completeVerification: (code, mode) => dispatch(completePhoneVerification(code, mode)),
    completeSignInVerification: (code, mfaSign) => dispatch(completeSignInPhoneVerification(code, mfaSign)),
    resetCode: () => dispatch(resetPhoneVerificationCode())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl,
    reduxForm({
        form: formName,
        touchOnChange: true,
        touchOnBlur: true
    }))

export default enhance(VerifyPhoneNumber)

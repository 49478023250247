import React, { useState } from 'react'
import { Field, change } from 'redux-form'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'

const useASStyles = makeStyles((theme) => ({
	root: {
		height: 250,
		flexGrow: 1
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'WebkitOverflowScrolling': 'touch',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
	divider: {
		height: theme.spacing(2)
	}
}))

const styles = {
	input: {
		color: '#fff',
		fontSize: '1rem',
		backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		color: '#fff'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		color: '#fff',
		marginLeft: 0,
		'&$focused': {
			color: '#fff',
		}
	},
	focused: {
		color: '#fff',
		'&$focused': {
			color: '#fff',
		}
	}
})

const useInputStyles = makeStyles({
	root: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
})

const TypeAhead = ({
	suggestions,
	label,
	input,
	name,
	inputLabelProps,
	noMatchText,
	maxLength,
	style,
	meta: { initial, dispatch, form, touched, error, invalid }
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()

	const classes = useASStyles()

	const [ anchorEl, setAnchorEl ] = useState(null)
	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(false)
	const [ state, setState ] = useState({ value: initial || input && input.value || '' })
	const [ stateSuggestions, setSuggestions ] = useState([])

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem data-testid="typeahead-text-menu-item" selected={isHighlighted} component='div'>
				<div>
					<span>{suggestion}</span>
				</div>
			</MenuItem>
		)
	}
	function renderInputComponent(inputProps) {
		const { inputRef = () => {}, ref, maxLength, ...other } = inputProps
		return (
			<TextField
				data-testid="component-form-typeahead-text"
				fullWidth
				autoComplete='none'
				name={name}
				error={touched && invalid}
				helperText={touched && error}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused,
						shrink: labelStyles.focused
					}
				}}
				classes={{
					root: inputStyles.root,
					error: inputStyles.error
				}}
				style={styles}
				InputProps={{
					maxLength: maxLength,

					inputRef: (node) => {
						ref(node)
						inputRef(node)
					}
				}}
				inputProps={{ maxLength: maxLength, autocomplete: 'dont' }}
				shrink='true'
				variant='filled'
				{...other}
			/>
		)
	}

	function getSuggestions(value) {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length
		let count = 0
		setNoMatch(false)
		setMatchSelected(false)
		let result =
			inputLength === 0
				? []
				: suggestions.filter((suggestion) => {
						const keep = count < 5 && suggestion.slice(0, inputLength).toLowerCase() === inputValue

						if (keep) {
							count += 1
						}

						return keep
					})

		if (inputLength === 0 && result.length === 0) {
			return result
		} else if (result.length === 1 && inputValue === result[0]) {
			setNoMatch(false)
			setMatchSelected(true)
			setState({
				...state,
				value: result[0]
			})
			setAnchorEl(null)
			return []
		} else if (result.length === 0) {
			setNoMatch(true)
			setMatchSelected(false)
			return [ noMatchText ]
		} else {
			return result
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const handleSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value))
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		setMatchSelected(true)
		dispatch(change(form, input.name, suggestion))
	}

	const handleChange = (event, { newValue }) => {
		setAnchorEl(event.currentTarget)
		input.onChange(newValue)
		setState({
			...state,
			value: newValue
		})
		event.cancelBubble = true
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion
	}

	return (
		<div data-testid="component-form-typeahead-text-wrap" className="global-input-wrapper" style={{ ...style }}>
			<Autosuggest
				data-testid="component-form-typeahead-text-autosuggest"
				style={style}
				highlightFirstSuggestion={true}
				{...autosuggestProps}
				inputProps={{
					label: label,
					value: state.value,
					name: input.name,
					onChange: (e, x) => handleChange(e, x),
					onBlur: (e, x) => {
						setAnchorEl(null)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: ''
							})
							dispatch(change(form, input.name, ''))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					},
					maxLength: maxLength
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				onSuggestionSelected={onSuggestionSelected}
				renderSuggestionsContainer={(options) => (
					<Popper data-testid="component-form-typeahead-text-popper" anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 10000 }}
						placement="bottom-start"
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}
					>
						<Paper data-testid="component-form-typeahead-text-paper" square {...options.containerProps}
						className={classes.optionStyle}
						style={{
							maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
							width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
							marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined}}
						>
							{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	)
}

const renderTextField = (field) => {
	return <TypeAhead {...field} />
}

export const FormTypeAheadTextField = ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	suggestions,
	maxLength = 255,
	noMatchText = 'No Entries Found',
	...other
}) => {
	return (
		<Field
			data-testid="component-form-typeahead-text-field"
			name={name}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			maxLength={maxLength}
			style={style}
			fullWidth
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			suggestions={suggestions}
			noMatchText={noMatchText}
			{...other}
		/>
	)
}

import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { fetchCountries, fetchEmailVerification, fetchLanguages } from '../../../redux/actions'
import { countryListSelector, emailVerificationStartedSelector, emailVerifiedSelector, languagesSelector, verifyEmailSelector } from '../../../redux/selectors'
import FormTextField from '../../../components/FormTextField'
import { email, date, phoneNumber } from '../../../utilities/validators'
import FormDateField from '../../../components/FormDateField'
import { Person, PersonAddDisabled } from '@material-ui/icons'
import { FormTypeAheadTextField } from '../../../components/FormTypeAheadTextField'
import { PhoneNumberField } from '../../../components/PhoneNumberField'
import { CircularProgress, InputAdornment } from '@material-ui/core'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../utilities/constants'

var debounce = require('lodash/debounce')

const selector = formValueSelector('addCase')

const dateValidator = date
const emailValidator = email
const phoneNumberValidator = phoneNumber

const EMAIL_STATUS = {
	NONE: 1,
	VERIFING: 2,
	VERIFIED: 3,
	NOTVERIFIED: 4
}

const AddNewPatient = ({
	intl,
	loadCountries,
	loadLanguages,
	countries,
	resetFirstName,
	resetLastName,
	resetCountryId,
	resetEmail,
	resetPhoneNumber,
	resetPatientId,
	verifyEmail,
	email,
	verificationStarted,
	verified,
	inputEmail
}) => {
	const theme = useTheme()
	const [showOptionalFields, setShowOptionalFields] = useState(false)
	const [ emailStatus, setEmailStatus ] = useState(EMAIL_STATUS.NONE)

	useEffect(() => {
		resetFirstName()
		resetLastName()
		resetCountryId()
		resetEmail()
		resetPhoneNumber()
		resetPatientId()

		loadLanguages()
		loadCountries()
	}, [])

	useEffect(
		() => {
			if (!!email) {
				if (inputEmail != email) {
					setEmailStatus(EMAIL_STATUS.NONE)
				}
				else if (verificationStarted) {
					setEmailStatus(EMAIL_STATUS.VERIFING)
				}
				else if (verified) {
					setEmailStatus(EMAIL_STATUS.VERIFIED)
				}
				else {
					setEmailStatus(EMAIL_STATUS.NOTVERIFIED)
				}
			}
		},
		[email, verificationStarted, verified]
	)

	const showOptional = (show) => {
		if (!show) {
			// change('firstName', null)
			// change('lastName', null)
			// change('phoneNumber', null)
			// if (MODE.EDIT !== mode) {
			// 	change('countryId', 0)
			// }
		}
		setShowOptionalFields(show)
	}

	const emailChangeHandler = (email) => {
		if(email && !emailValidator(email))
			verifyEmail(email)
	}

	const onEmailChanged = useConstant(() => debounce(emailChangeHandler, debounceMilliseconds))

	return (
		<Grid data-testid="add-new-patient" container direction='column' alignItems='center' justifyContent='center' style={{ width: '100%' }}>
			<Grid item style={{ width: '100%' }}>
				<Grid
					container
					direction='column'
					alignItems='flex-start'
					justifyContent='flex-start'
					style={{
						backgroundColor: theme.palette.secondary.main,
						width: '100%'
					}}>
					<Grid item xs={12} style={{ width: '100%' }}>
						<Grid container spacing={2}>
							<Grid data-testid="add-new-patient-email" item xs={12}>
								<FormTextField
									label={intl.formatMessage({ id: 'app.addpatient.emailLabel' })}
									fullWidth
									type='text'
									name='email'
									maxLength={255}
									validators={[emailValidator]}
									variant='filled'
									onChange={(e) => onEmailChanged(e.target.value)}
									inputProps={{
										endAdornment:
											emailStatus === EMAIL_STATUS.NOTVERIFIED
												? <InputAdornment position="end">
													<CancelOutlinedIcon style={{ color: 'red', fontSize: '28px' }} />
												</InputAdornment>
												: emailStatus === EMAIL_STATUS.VERIFING
													? <CircularProgress size={20} color="warning" />
													: null
									}}
									customInvalid={emailStatus !== EMAIL_STATUS.VERIFIED}
							/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div style={{ padding: '0 0 4px 0'}}>
							<Button
								data-testid="optional-fields"
								variant='text'
								color='primary'
								endIcon={ showOptionalFields ? <Person /> : <PersonAddDisabled /> }
								onClick={() => showOptional(!showOptionalFields)}>								
									{showOptionalFields ?
										intl.formatMessage({ id: 'app.addpatient.hideText' })
										:
										intl.formatMessage({ id: 'app.addpatient.showText' })
									}
							</Button>
						</div>
					</Grid>
					{showOptionalFields && (
						<Grid item style={{ width: '100%' }}>
							<Grid container spacing={2}>
								<Grid data-testid="add-new-patient-first-name" item xs={12} sm={4}>
									<FormTextField
										label={intl.formatMessage({ id: 'app.addpatient.firstNameLabel' })}
										type='text'
										name='firstName'
										variant='filled'
										maxLength={255}
									/>
								</Grid>

								<Grid data-testid="add-new-patient-middle-name" item xs={12} sm={4}>
									<FormTextField
										label={intl.formatMessage({ id: 'app.addpatient.middleNameLabel' })}
										type='text'
										name='middleName'
										variant='filled'
										maxLength={255}
									/>
								</Grid>

								<Grid data-testid="add-new-patient-last-name" item xs={12} sm={4}>
									<FormTextField
										label={intl.formatMessage({ id: 'app.addpatient.lastNameLabel' })}
										type='text'
										name='lastName'
										variant='filled'
										maxLength={255}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
					{showOptionalFields && (
						<Grid item style={{ width: '100%' }}>
							<Grid container spacing={2}>
								<Grid data-testid="add-new-patient-phone" item xs={12} sm={4}>
									<PhoneNumberField
										label={intl.formatMessage({ id: 'app.addpatient.phoneLabel' })}
										name='phoneNumber'
										phoneField='phoneNumber'
										phoneCountryCodeField='phoneCountryCode'
										validators={[phoneNumberValidator]}
									/>
								</Grid>
								<Grid data-testid="add-new-patient-birthdate" item xs={12} sm={4}>
									<FormDateField
										label={intl.formatMessage({ id: 'app.addpatient.birthdateLabel' })}
										fullWidth
										type='text'
										name='birthDate'
										validators={[dateValidator]}
										variant='filled'
										openToYear={true}
									/>
								</Grid>
								<Grid data-testid="add-new-patient-country" item xs={12} sm={4}>
									<FormTypeAheadTextField
										suggestions={countries.map((r) => r.label)}
										label={intl.formatMessage({ id: 'app.addpatient.countryLabel' })}
										name='countryName'
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	countries: countryListSelector(state),
	languages: languagesSelector(state),
	email: verifyEmailSelector(state),
	verificationStarted: emailVerificationStartedSelector(state),
	verified: emailVerifiedSelector(state),
	inputEmail: selector(state, 'email'),
})

const mapDispatchToProps = (dispatch) => ({
	loadCountries: () => dispatch(fetchCountries()),
	loadLanguages: () => dispatch(fetchLanguages()),
	resetPatientId: () => dispatch(change('addCase', 'patientId', null)),
	resetFirstName: () => dispatch(change('addCase', 'firstName', null)),
	resetLastName: () => dispatch(change('addCase', 'lastName', null)),
	resetBirthDate: () => dispatch(change('addCase', 'birthDate', null)),
	resetCountryId: () => dispatch(change('addCase', 'countryName', null)),
	resetEmail: () => dispatch(change('addCase', 'email', null)),
	resetPhoneNumber: () => dispatch(change('addCase', 'phoneNumber', null)),
	setJurisdiction: (value) => dispatch(change('addCase', 'jurisdictionId', value)),
	verifyEmail: (email) => dispatch(fetchEmailVerification(email))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AddNewPatient)

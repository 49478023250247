import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedDate } from 'react-intl'

const MemberDateJoined = ({ member, intl }) => (
	<Grid container>
		<Grid item>
			<Typography variant="body2" style={{ fontWeight: '400' }}>
				{
					member?.isOwner
					&& <Fragment>
						{
							!member.lastLogin 
							&& <span>{ `${intl.formatMessage({ id: 'app.userProfile.teamMemberPendingText' })} - ` }</span>
						}
						<span>
							<FormattedDate value={member.dateJoined || member.createDate} />
						</span>
					</Fragment>
				}
				{
					!member?.isOwner
					&& <Fragment>
						{
							!member.dateJoined 
							&& <span>{ `${intl.formatMessage({ id: 'app.userProfile.teamMemberPendingText' })} - ` }</span>
						}
						<span>
							{
								(
									!!member.dateJoined
									|| !!member.createDate
								)
								&& <FormattedDate value={member.dateJoined || member.createDate} />
							}
						</span>
					</Fragment>
				}
			</Typography>
		</Grid>
	</Grid>
)

export default injectIntl(MemberDateJoined)

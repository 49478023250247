import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FormattedDate, useIntl } from 'react-intl'

const dateLabelMap = {
	0: 'app.case.alerts.upcomingLabel',
	1: 'app.case.alerts.dueLabel',
	2: 'app.tasks.overdueLabel',
	3: 'app.case.alerts.expiredLabel',
	4: 'app.case.alerts.completedLabel'
}

export default ({ task, color }) => {
	const intl = useIntl()

	let dateOnly;
    let today = new Date();
        today.setHours(0, 0, 0, 0);

	if (task && task.dueDate) {
        dateOnly = new Date(task.dueDate);
        dateOnly.setHours(0, 0, 0, 0);
    }

    return (
		<div data-testid="mytasks-survey-alert-date">
				<span
					data-testid="mytasks-survey-alert-overdue"
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						letterSpacing: '0.51px',
						lineHeight: '22px'
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Typography
								data-testid="mytasks-survey-alert-overdue-date"
								variant="subtitle2"
								style={{
									color: 'rgba(0,0,0,0.87)'
								}}
							>
								<FormattedDate year='numeric' month='short' day='2-digit' value={task.dueDate} />
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								component={'span'}
								style={{
									fontWeight: '400',
									fontSize: '13px',
									lineHeight: '16px',
									letterSpacing: '0.38px'
								}}
							>
								<Grid container style={{ flexWrap: 'nowrap' }}>
									<Grid item>
										<div
											data-testid="mytasks-survey-alert-overdue-status"
											style={{
												backgroundColor: color,
												width: '8px',
												height: '8px',
												borderRadius: '50%',
												marginRight: '5px', marginTop: '3px'
											}}
										/>
									</Grid>
									<Grid item>
										<Typography
											data-testid="mytasks-survey-alert-overdue-label"
											style={{
												fontWeight: '400',
												fontSize: '13px',
												lineHeight: '16px',
												letterSpacing: '0.38px'
											}}
										>
											{intl.formatMessage({ id: dateLabelMap[task.type] })}
										</Typography>
									</Grid>
								</Grid>
							</Typography>
						</Grid>
					</Grid>
				</span>
		</div>
	)
}

import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import useTheme from '@material-ui/core/styles/useTheme'

const SwitchControl = ({ theme, input, meta, intl, value, ...rest }) => {
	return (
		<Switch
			data-testid="component-form-switch"
			color="primary"
			checked={input.value ? true : false}
			{...input}
			inputProps={{ 'aria-label': 'checkbox' }}
		/>
	)
}

const FormSwitch = ({ intl, name }) => {
	var theme = useTheme()
	return (
		<div>
			<Field data-testid="component-form-switch-field" name={name} component={SwitchControl} intl={intl} theme={theme} />
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(FormSwitch)

import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import LeaveCircle from '../LeaveCircle'
import {
	canEmailAdministratorSelector,
	canLeaveCircleSelector,
	canInviteMemberCircleSelector,
	canReinstateCircleSelector,
	canDistributeResourcesSelector,
	isAdministratorSelector
} from '../../../redux/selectors'
import { reinstateCircle } from '../../../redux/modules/circle'
import InviteCircleMember from '../../circleDetails/InviteCircleMember'
import DistributeResources from '../DistributeResources'

const ITEM_HEIGHT = 48

const Options = ({
	circle,
	circleId,
	onInviteUserClick,
	intl,
	canViewDetails,
	canInviteMember,
	onReinstateCircleClick,
	user,
	onLeaveCircleClick,
	canLeave,
	canEmailAdmin,
	canInvite,
	canReinstate,
	canDistributeResources,
	onDistributeResourcesClick,
	isAdministrator
}) => {
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleInviteClick = () => {
		if (circle) onInviteUserClick(circle.id, circle.numberUsers - circle.sponsoredUsers)
		handleClose()
	}

	const handleReinstateClick = () => {
		if (circle) onReinstateCircleClick(circle.id)
		handleClose()
	}

	const handleLeaveClick = () => {
		if (circle) onLeaveCircleClick(circle)
		handleClose()
	}

	const onMailAdministrator = () => {
		if (circle && circle.administratorEmail) window.location.href = `mailto:${circle.administratorEmail}`
		handleClose()
	}

	const handleDistributeResources = () => {
		if (circle) onDistributeResourcesClick(circle)
		handleClose()
	}

	return (
		<div data-testid="circle-options">
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				data-testid="circle-options-menu"
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: 270, backgroundColor: '#243041' } }}
			>
				<MenuItem  style={{color: '#fff'}}>
					<Link
						data-testid="view-details"
						to={`/circles/${circle.id}`}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24',
							width: '100%'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.viewDetails' })}
					</Link>
				</MenuItem>
				{canInvite && (
					<MenuItem
						data-testid="invite-member"
						onClick={handleInviteClick}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.inviteMember' })}
					</MenuItem>
				)}
				{canReinstate && (
					<MenuItem
					data-testid="reinstate"
						onClick={handleReinstateClick}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.reinstate' })}
					</MenuItem>
				)}
				{
					!isAdministrator
					&& !circle?.isAdministrator
					&& <MenuItem
						data-testid="leave"
						onClick={handleLeaveClick}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.leaveCircle' })}
					</MenuItem>
				}
				{canEmailAdmin && (
					<MenuItem
						data-testid="mail-admin"
						onClick={onMailAdministrator}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.mailAdministrator' })}
					</MenuItem>
				)}
				{canDistributeResources && (
					<MenuItem
						data-testid="distribute-resources"
						onClick={handleDistributeResources}
						style={{
							textDecoration: 'none',
							color: '#fff',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.distributeResources' })}
					</MenuItem>
				)}
			</Menu>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	canInvite: canInviteMemberCircleSelector(ownProps),
	canLeave: canLeaveCircleSelector(ownProps),
	canEmailAdmin: canEmailAdministratorSelector(ownProps),
	canReinstate: canReinstateCircleSelector(ownProps),
	canDistributeResources: canDistributeResourcesSelector(ownProps),
	isAdministrator: isAdministratorSelector(ownProps)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onInviteUserClick: (circleId, availableCredits) =>
		dispatch(showModal(InviteCircleMember, { circleId: circleId, availableCredits: availableCredits })),
	onReinstateCircleClick: (circleId) => dispatch(reinstateCircle(circleId)),
	onLeaveCircleClick: (circle) => dispatch(showModal(LeaveCircle, { circle: circle })),
	onDistributeResourcesClick: (circle) => dispatch(showModal(DistributeResources, { circle: circle }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(Options)

import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const search = (searchPhrase) => {
	var url = `/api/units`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addUnit = (name, suffix) => {
	return authenticatedFetch(`/api/units`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			suffix: suffix
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const editUnit = (id, name, suffix) => {
	return authenticatedFetch(`/api/units/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			suffix: suffix,
			id: id
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateUnit = (id, name, suffix) => {
	return authenticatedFetch(`/api/units/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id,
			name,
			suffix
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

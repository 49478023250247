import React from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
const AlertCell = ({ alert, intl }) => (
	<div data-testid="myalerts-outlier-alert-cell" style={{ maxWidth: '300px' }}>
		<Typography
			variant="body2"
			style={{
				fontSize: '13px',
				wordBreak: 'break-all',
			}}
		>
			{intl.formatMessage({ id: 'app.alerts.outlierSystemLabel' })}
		</Typography>
	</div>
)

const enhance = injectIntl

export default enhance(AlertCell)

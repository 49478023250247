import * as patient from '../../../apis/patient'
import * as alert from '../../../apis/alert'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'

const ADD_PATIENT_REQUESTED = 'ADD_PATIENT_REQUESTED'
const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS'
const ADD_PATIENT_FAILED = 'ADD_PATIENT_FAILED'

export const addPatient = (email, birthDate, jurisdictionId, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId) => (dispatch) => {
	dispatch({ type: ADD_PATIENT_REQUESTED, data: {} })
	patient.addPatient(email, birthDate, jurisdictionId, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId).then((patient) => {
		if (patient && patient.isSuccessful) {
			dispatch(fetchPatients())
			dispatch(hideModal())
			return dispatch({ type: ADD_PATIENT_SUCCESS, data: patient })
		} else {
			dispatch({ type: ADD_PATIENT_FAILED, data: patient })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${patient && patient.errorCode ? patient.errorCode : 0}`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const UPDATE_PATIENT_REQUESTED = 'UPDATE_PATIENT_REQUESTED'
const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS'
const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED'

export const updatePatient = (id, email, birthDate, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId) => (dispatch) => {
	dispatch({ type: UPDATE_PATIENT_REQUESTED, data: {} })
	patient.updatePatient(id, email, birthDate, firstName, lastName, middleName, countryId, phoneNumber, phoneCountryCode, languageId).then((patient) => {
		if (patient && patient.isSuccessful) {
			dispatch(fetchPatients())
			dispatch(hideModal())

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.patients.patientUpdated', isSuccess: true }
			})

			return dispatch({ type: UPDATE_PATIENT_SUCCESS, data: patient })
		} else {
			if (
				!!patient
				&& !!patient.errorCode
			) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.errorMessages.${patient.errorCode}`, isSuccess: false }
				})
			}
			return dispatch({ type: UPDATE_PATIENT_FAILED, data: {} })
		}
	})
	return
}

const RESEND_INVITATION_REQUESTED = 'RESEND_INVITATION_REQUESTED'
const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS'
const RESEND_INVITATION_FAILED = 'RESEND_INVITATION_FAILED'

export const resendInvitation = (id) => (dispatch) => {
	dispatch({ type: RESEND_INVITATION_REQUESTED, data: {} })
	patient.resendInvitation(id).then((patient) => {
		dispatch(hideModal())
		if (patient && patient.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.patients.invitationSent', isSuccess: true }
			})
		} else {
			dispatch({ type: RESEND_INVITATION_FAILED, data: {} })
			if (patient && patient.errorCode === 38) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.patient.resendReminderLimitExceededError', isSuccess: false }
				})
			}
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.errorMessages.${patient.errorCode}`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PATIENT_REQUESTED = 'FETCH_PATIENT_REQUESTED'
const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS'
const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED'

export const fetchPatients = (search) => (dispatch) => {
	dispatch({ type: FETCH_PATIENT_REQUESTED, data: {} })
	patient.fetchPatients(search).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_PATIENT_SUCCESS, 
				data: {
					items: response.patients,
					hasMore: response.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_PATIENT_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_PATIENT_REQUESTED = 'FETCH_ADDITIONAL_PATIENT_REQUESTED'
const FETCH_ADDITIONAL_PATIENT_SUCCESS = 'FETCH_ADDITIONAL_PATIENT_SUCCESS'
const FETCH_ADDITIONAL_PATIENT_FAILED = 'FETCH_ADDITIONAL_PATIENT_FAILED'

export const fetchAdditionalPatients = (search, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_PATIENT_REQUESTED, data: {} })
	patient.fetchPatients(search, offset).then((response) => {
		if (response) {
			return dispatch({
				type: FETCH_ADDITIONAL_PATIENT_SUCCESS,
				data: {
					items: response.patients,
					hasMore: response.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_PATIENT_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PATIENTS_AUTOCOMPLETE_REQUESTED = 'FETCH_PATIENTS_AUTOCOMPLETE_REQUESTED'
const FETCH_PATIENTS_AUTOCOMPLETE_SUCCESS = 'FETCH_PATIENTS_AUTOCOMPLETE_SUCCESS'
const FETCH_PATIENTS_AUTOCOMPLETE_FAILED = 'FETCH_PATIENTS_AUTOCOMPLETE_FAILED'

export const fetchPatientAutoComplete = (searchPhrase, noMatchText) => (dispatch) => {
	dispatch({ type: FETCH_PATIENTS_AUTOCOMPLETE_REQUESTED, data: {} })
	patient.fetchPatientAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.patients && response.patients.length > 0) {
				return dispatch({ type: FETCH_PATIENTS_AUTOCOMPLETE_SUCCESS, data: response.patients })
			} else {
				return dispatch({ type: FETCH_PATIENTS_AUTOCOMPLETE_SUCCESS, data: [ { id: -1, email: noMatchText } ] })
			}
		} else {
			return dispatch({ type: FETCH_PATIENTS_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}


const FETCH_EMAIL_VERIFICATION_REQUESTED = 'FETCH_EMAIL_VERIFICATION_REQUESTED'
const FETCH_EMAIL_VERIFICATION_SUCCESS = 'FETCH_EMAIL_VERIFICATION_SUCCESS'
const FETCH_EMAIL_VERIFICATION_FAILED = 'FETCH_EMAIL_VALIDATION_FAILED'

export const fetchEmailVerification = (email) => (dispatch) => {
	dispatch({ type: FETCH_EMAIL_VERIFICATION_REQUESTED, data: email })
	patient.fetchEmailVerification(email && email.trim()).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_EMAIL_VERIFICATION_SUCCESS, data: response.data })
		} else {
			dispatch({ type: FETCH_EMAIL_VERIFICATION_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.patientEmailValidation.failMessage', isSuccess: false }
			})
		}
	})
	return
}


const initial = {
	patient: null,
	patients: null,
	isCreating: false,
	autoCompleteSuggestions: [],
	hasMore: true,
	isLoading: false,
	emailVerification: {
		started: false,
		email: null,
		verified: false
	}
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PATIENTS_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case ADD_PATIENT_SUCCESS:
			return { ...state, isCreating: false, patients: [ ...state.patients, action.data ] }
		case ADD_PATIENT_FAILED:
			return { ...state, isCreating: false }
		case FETCH_PATIENT_SUCCESS:
			return { 
				...state, 
				patients: action.data.items, 
				patient: action.data.items[0], 
				hasMore: action.data.hasMore 
			}
		case FETCH_ADDITIONAL_PATIENT_SUCCESS:
			return {
				...state,
				patients: [...state.patients, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_PATIENT_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_PATIENT_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case ADD_PATIENT_REQUESTED:
			return { ...state, isCreating: true }
		case UPDATE_PATIENT_REQUESTED:
			return { ...state, isCreating: true }
		case UPDATE_PATIENT_SUCCESS:
			return { ...state, isCreating: false }
		case UPDATE_PATIENT_FAILED:
			return { ...state, isCreating: false }
		case FETCH_EMAIL_VERIFICATION_REQUESTED:
			return {
				...state,
				emailVerification: {
					started: true,
					email: action.data,
					verified: false
				}
			}
		case FETCH_EMAIL_VERIFICATION_SUCCESS:
			return {
				...state,
				emailVerification: {
					...(state.emailVerification),
					started: false,
					verified: action.data
				}
			}
		case FETCH_EMAIL_VERIFICATION_FAILED:
			return {
				...state,
				emailVerification: {
					...(state.emailVerification),
					started: false
				}
			}
	
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.patient

export const isCreatingPatientSelector = createSelector(mainSelector, (state) => state.isCreating)

export const patientsSelector = createSelector(mainSelector, (state) => state.patients)
export const patientSelector = createSelector(mainSelector, (state) => state.patient)

export const formattedPatientsSelector = createSelector(mainSelector, (state) => {
	return state.patients
		? state.patients.map((r) => {
				return {
					firstName: r.firstName,
					lastName: r.lastName,
					email: r.email,
					label: r.firstName ? `${r.firstName} ${r.lastName} | ${r.email}` : r.email,
					id: r.id
				}
			})
		: []
})

export const patientAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const hasMorePatientsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingPatientsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const verifyEmailSelector = createSelector(mainSelector, (state) => {
	return state.emailVerification.email
})

export const emailVerificationStartedSelector = createSelector(mainSelector, (state) => {
	return state.emailVerification.started
})

export const emailVerifiedSelector = createSelector(mainSelector, (state) => {
	return state.emailVerification.verified
})

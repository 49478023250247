import React from 'react'
import Typography from '@material-ui/core/Typography'

export default ({ alert }) => (
	<div data-testid="myalerts-invitation-alert-cell" style={{ maxWidth: '300px' }}>
		{alert &&
		alert.sourceName && (
			<Typography
				data-testid="invitation-alert-cell-source"
				variant="subtitle1"
				style={{
					wordBreak: 'break-all',
				}}
			>
				{alert.sourceName}
			</Typography>
		)}
		{alert &&
		alert.sourceFirstName &&
		alert.sourceLastName && (
			<Typography
				data-testid="invitation-alert-cell-contact"
				variant="subtitle1"
				style={{
					wordBreak: 'break-all',
					fontSize: '13px',
				}}
			>
				{`${alert.sourceFirstName} ${alert.sourceLastName}`}
			</Typography>
		)}
	</div>
)

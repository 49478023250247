import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { reduxForm } from 'redux-form'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { email, required } from '../../utilities/validators'
import { connect } from 'react-redux'
import { updateInviteUserMember } from '../../redux/modules/user'
import { userInviteMemberSelector } from '../../redux/modules/user'
import Button from '@material-ui/core/Button'
import cookie from 'react-cookies'

const InvitationConfirmation = ({ classes, intl, handleSubmit, submitRequest, inviteDetails, userInvites }) => {
	const theme = useTheme()

	const onSubmitForm = (event) => {
		submitRequest(event.currentTarget.id)
	}

	return (
		<Grid
			data-testid="registration-invitation-confirmation"
			container
			direction="column"
			style={{
				padding: '4rem',
				backgroundColor: '#011020'
			}}
		>
			asdfasfgasdag
			<Grid item style={{ textAlign: 'center' }}>
				<Typography
					data-testid="registration-invitation-title"
					variant='h1'
					style={{
						color: '#fff',
						marginBottom: '4rem'
					}}
				>
					{intl.formatMessage({
						id: 'app.userProfile.teamMemberInvitation'
					})}
				</Typography>
			</Grid>
			<Grid item>
				{userInvites && (
					<Grid item style={{ width: '100%', textAlign: 'center' }}>
						<Typography
							data-testid="registration-invitation-member"
							style={{ color: '#fff' }}>
							{intl.formatMessage({
								id: 'app.userProfile.teamMemberInvites'
							})}{' '}
							{userInvites.team &&
								userInvites.team.teamName +
									' ' +
									intl.formatMessage({
										id: 'app.userProfile.teamMemberBy'
									})}
							{userInvites.firstName} {userInvites.lastName}.
						</Typography>
					</Grid>
				)}
				<Grid
					item
					style={{
						textAlign: 'center',
						paddingTop: '54px',
						paddingBottom: '50px'
					}}
				>
					<Grid container spacing={1} justifyContent="center">
						<Grid item>
							<Button
								data-testid="registration-invitation-button-accept"
								style={{
									marginRight: '10px'
								}}
								variant="contained"
								color="primary"
								onClick={onSubmitForm}
								id="1"
								size="large"
							>
								{intl.formatMessage({
									id: 'app.general.accept'
								})}
							</Button>
						</Grid>
						<Grid item>
							<Button
								data-testid="registration-invitation-button-decline"
								variant="outlined"
								color="primary"
								style={{
									marginLeft: '10px'
								}}
								onClick={onSubmitForm}
								id="2"
								type="submit"
								size="large"
							>
								{intl.formatMessage({
									id: 'app.general.decline'
								})}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	inviteDetails: userInviteMemberSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (optionSelected) => {
		dispatch(updateInviteUserMember(optionSelected))
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'invitationConfirmation'
	}),
	injectIntl
)

export default enhance(InvitationConfirmation)

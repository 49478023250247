import { IconButton, InputAdornment } from '@material-ui/core'
import React from 'react'
import FormTextField from './FormTextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const FormPassword = (props) => {
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
      });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleChange = (event) => {
        setValues({ ...values, password: event.target.value });
    };

	return (
		<FormTextField
			{...props}
			type={values.showPassword ? 'text' : 'password'}
			value={values.password}
			onChange={handleChange}
			inputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
							style={{color: "white"}}
						>
							{values.showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	)
}

export default FormPassword

import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { reduxForm, change } from 'redux-form'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../../redux/actions'
import Checkout from '../../../../components/StripeCheckout/Checkout'
import { hideModal, fetchStripePublishableKey } from '../../../../redux/actions'
import { stripePublishableKeySelector } from '../../../../redux/selectors'

const CreateSubscriptionModal = ({
	intl,
	handleSubmit,
	updateSubscription,
	change,
	close,
	price,
	showCheckout,
	stripePublishableKey,
	getStripePublishableKey
}) => {
	const theme = useTheme()
	const [ showOptionalFields, setShowOptionalFields ] = useState(false)

	useEffect(() => {
		getStripePublishableKey()
	}, [])

	const onCheckout = (values) => {
		showCheckout(price.userPrice, stripePublishableKey)
	}

	return (
		<form data-testid="form-create-subscription-modal" onSubmit={handleSubmit(onCheckout)}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{
					backgroundColor: theme.palette.secondary.main,
					padding: '4.01rem'
				}}>
				<Grid item>
					<Grid
						container
						direction='column'
						justifyContent='center'
					>
						<Grid item>
							<Typography
								data-testid="create-subscription-title"
								variant='h1'
								style={{
									color: '#fff',
									marginBottom: '25px'
								}}
								align='left'>
								{intl.formatMessage({ id: 'app.dashboard.personalPlans.title' })}
							</Typography>

							<Typography
								data-testid="create-subscription-subtitle"
								variant='h3'
								style={{
									color: '#fff',
									marginBottom: '25px'
								}}
								align='left'>
								{intl
									.formatMessage({ id: 'app.dashboard.personalPlans.subtitle' })
									.replace('%USERPRICE%', price ? price.userPrice : null)}
							</Typography>
						</Grid>

						<Grid
							item
							style={{
								width: '100%'
							}}>
							<div style={{ textAlign: 'center ' }}>
								<Button
									data-testid="create-subscription-button-submit"
									label='Password'
									variant="contained"
									color="primary"
									type='submit'
									size='medium'>
									{intl.formatMessage({ id: 'app.dashboard.personalPlans.submitButtonText' })}
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	stripePublishableKey: stripePublishableKeySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getStripePublishableKey: () => dispatch(fetchStripePublishableKey()),
	close: () => dispatch(hideModal()),
	showCheckout: (totalPrice, stripePublishableKey) =>
		dispatch(
			showModal(Checkout, {
				userCount: 1,
				caseCount: 0,
				totalPrice: totalPrice,
				stripePublishableKey: stripePublishableKey
			})
		)
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'managePlan'
	}),
	withRouter
)

export default enhance(CreateSubscriptionModal)

import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { sortSponsoredCircleDirectionSelector, sortSponsoredCircleFieldSelector } from '../../../../redux/selectors'
import { setSponsoredCircleSort } from '../../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const CircleHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow data-testid="sponsored-circles-table-header">
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0 }} />
			<TableCell data-testid="sponsored-circles-header-name" align="left">
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.circles.name' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="sponsored-circles-header-members" align="left">
				<TableSortLabel
					active={field === 'sponsoredUserCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('sponsoredUserCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.account.sponsoredMembers' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="sponsored-circles-header-unused-members" align="left">
				<TableSortLabel
					active={field === 'unusedMembers'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('unusedMembers', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.account.sponsoredUnusedMembers' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="sponsored-circles-header-used-cases" align="left">
				<TableSortLabel
					active={field === 'usedCaseCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('usedCaseCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.circles.usedCases' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell data-testid="sponsored-circles-header-available" align="left">
				<TableSortLabel
					active={field === 'availableCases'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('availableCases', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.circles.availableCases' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>

			<TableCell data-testid="sponsored-circles-header-date" align="left">
				<TableSortLabel
					active={field === 'createDateSeconds'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('createDateSeconds', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="caption"
					>
						{intl.formatMessage({ id: 'app.general.date' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortSponsoredCircleDirectionSelector(state),
	field: sortSponsoredCircleFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setSponsoredCircleSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(CircleHeader)

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Pencil from '../../../assets/pencil'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { setTeamLogo } from '../../../redux/actions'

const UploadTeamLogo = ({
	data,
	uploadPhoto,
	onImageUpdated,
	canEdit,
	width = 128,
	height = 128,
	translate = -65
}) => {
	const theme = useTheme()

	const [ imageOpacity, setImageOpacity ] = useState(1)
	const [ pencilIndex, setPencilIndex ] = useState(0)
	const [ imageLoaded, setImageLoaded ] = useState(false)

	const uploadTeamLogo = (e) => {
		var file = e.target.files[0]

		uploadPhoto(data.id, file)
		e.target.files = null
	}

	return (
		<Grid data-testid="upload-small-img-wrap" item style={{ width: '100%' }}>
			<Grid container justifyContent='center' style={{ maxHeight: '10px' }}>
				<Grid
					data-testid="upload-small-img"
					item
					onMouseOver={() => {
						canEdit && setImageOpacity(0.4)
						canEdit && setPencilIndex(100)
					}}
					onMouseOut={() => {
						canEdit && setImageOpacity(1)
						canEdit && setPencilIndex(0)
					}}
					onClick={() => canEdit && document.getElementById('photo-input').click()}>
					<input
						data-testid="upload-small-img-input"
						id='photo-input'
						type='file'
						accept='.jpg, .png,.bmp'
						name='smallImg'
						style={{ display: 'none' }}
						onChange={uploadTeamLogo}
					/>
					{imageLoaded && (
						<div
							data-testid="upload-small-img-pencil"
							style={{
								position: 'absolute',
								transform: 'translate(25px, 0px)',
								zIndex: pencilIndex,
								borderRadius: '50%',
								cursor: 'pointer'
							}}>
							<Pencil fill={theme.palette.primary.main} height={height / 2} viewBox='0 0 30 30' />
						</div>
					)}

					<Avatar
						data-testid="upload-small-img-avatar-square"
						variant='square'
						onLoad={() => setImageLoaded(true)}
						style={{
							height: height + 'px',
							width: width + 'px',
							transform: 'translateY(' + translate + 'px)',
							opacity: imageOpacity,
							boxShadow: 'grey 3px 6px 12px -4px',
							'-webkit-box-shadow': 'grey 3px 6px 12px -4px',
							'-moz-box-shadow': 'grey 3px 6px 12px -4px'
						}}
						src={data && data.teamLogoUrl ? data.teamLogoUrl : '/camera-square.png'}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	uploadPhoto: (id, photo) => {
		dispatch(setTeamLogo(id, photo, ownProps.onImageUpdated))
	}
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(UploadTeamLogo)

import { authenticatedFetch } from './base'

export const addCircle = (name, status, PHI, numberCases, numberUsers, isSponsored, observationalProtocolId, pathologyId, treatmentId) => {
	return authenticatedFetch(`/api/circle`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name,
			status,
			isPhi: PHI,
			numberCases,
			numberUsers,
			isSponsored,
			observationalProtocolId,
			pathologyId,
			treatmentId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleAutoComplete = (searchPhrase = '', protocolVersionId = null) => {
	return authenticatedFetch(`/api/circle/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}&protocolVersionId=${protocolVersionId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircles = (searchPhrase = '', filter, offset) => {
	let url = `/api/circle?searchPhrase=${encodeURIComponent(searchPhrase)}`
	
	if(offset){
		url += `&offset=${offset}`
	}

	if(Number.isInteger(filter)){
		url += `&filter=${filter}`
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCirclesWithCaseCount = () => {
	return authenticatedFetch(`/api/circle/caseCount`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircle = (id) => {
	return authenticatedFetch(`/api/circle/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleMembers = (id, entity) => {
	return authenticatedFetch(`/api/circle/${id}/members`, { method: 'get' }).then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
}

export const fetchTreatment = (id) => {
	var url = `/api/treatment/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const fetchPathology = (id) => {
	var url = `/api/pathologies/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const setBackgroundImage = (entity, id, photo) => {
	//users or circle
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const resetBackgroundImage = (entity, id) => {
	//users or circle
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const updateCircleDetailsById = (circle) => {
	return authenticatedFetch(`/api/circle/${circle.id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(circle)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const updateCircleTreatment = (data) => {
	return authenticatedFetch(`/api/circle/${data.circleId}/updateTreatment/${data.treatmentId}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const updateCirclePathology = (data) => {
	return authenticatedFetch(`/api/circle/${data.circleId}/updatePathology/${data.pathologyId}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const reinstateCircleArchive = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/reinstate`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const makeAdminMemberByCircleUserId = (userId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/makeAdministrator`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const removeUserCircleById = (email, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${email}/removeUserCircle`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const submitLeaveCircleById = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/leave`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const canLeaveCircleById = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/leave`, { method: 'get' })
		.then(function(response) {
			return response.json()
		})
		.then(function(user) {
			return user
		})
}

export const fetchSponsoredCircles = () => {
	return authenticatedFetch(`/api/circle/sponsored`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const changeCaseFunding = (circleId, userId, value) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/changeCaseFunding/${value}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const fetchCircleCaseFundingSettings = (id) => {
	return authenticatedFetch(`/api/circle/${id}/getCaseFundingSettings`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleMemberPatientInvitationLink = (circleId, userId) => {
	return authenticatedFetch(`/api/circle/${circleId}/generatelink/${userId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const changeMemberCasesRate = (circleId, userId, casesRate) => {
	return authenticatedFetch(`/api/circle/changeMemberCasesRate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			circleId,
			userId,
			casesRate
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const exportNonComplainSubscribers = (circleId, startDate, endDate) => {
	let searchParams = {
		startDate,
		endDate
	}

	const urlPart = `/api/circle/${circleId}/exportNonCompliantSubscribers?${new URLSearchParams(searchParams).toString()}`

	return authenticatedFetch(urlPart, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if(!header) {
					return { isSuccessful: false }
				}
				
				const fileName = header.split('"')[1];
				return response.blob().then(blob => ({fileName, blob}))
			}
			else {
				return { isSuccessful: false }
			}
		})
		.then(function (response) {
			try {
				const {fileName, blob} = response

				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', fileName)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const QuestionType = {
	SingleValue: 1,
	MultipleValue: 2,
	Grouped: 3,
	TrueFalse: 4,
	Text: 5,
	Number: 6,
	Date: 7,
	Copy: 8,
	Analog: 9
}

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const Member2FAState = ({ member, intl }) => (
	<Grid container>
		<Grid item>
			<Typography data-testid="member-2fa-state" variant="body2" style={{ textTransform: 'uppercase' }}>
				{intl.formatMessage({ id: member && member.isMFAEnabled ? 'app.general.on' : 'app.general.off' })}
			</Typography>
		</Grid>
	</Grid>
)

export default injectIntl(Member2FAState)

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import TrackChanges from '@material-ui/icons/TrackChanges'
import { isMobile } from './../../../utilities/mobileDetector'

const AlertEmpty = ({ intl }) => {
	const theme = useTheme()
	return (
		<Grid data-testid="myalerts-alert-empty" container spacing={3} direction='row' style={{ paddingBottom: '32px' }}>
			<Grid item xs={12} sm={7} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px',  }}>
				<Typography
					data-testid="myalerts-alert-empty-no-alerts"
					variant='h2'
					style={{ fontWeight: '300', color: theme.palette.primary.A800, paddingBottom: '12px' }}>
					{intl.formatMessage({ id: 'app.alerts.noAlertsV2' })}
				</Typography>
				<Typography
					data-testid="myalerts-alert-empty-desc"
					variant='body2' style={{ fontWeight: '600' }}>
					{intl.formatMessage({ id: 'app.alerts.descriptionV2' })}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={5} style={{ padding: isMobile ? '15px 15px 32px' : '17px 25px 52px',  }}>
				<Typography
					data-testid="myalerts-alert-empty-outliers"
					variant='body2' style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#FF8556' }}>
					<TrackChanges style={{ fontSize: isMobile ? '20px' : '32px', color: '#FF8556', transform: 'translateY(3px)' }} />{' '}
					{intl.formatMessage({ id: 'app.alerts.outliers' }).toUpperCase()}
				</Typography>
				<Typography
					data-testid="myalerts-alert-empty-phifree"
					variant='body2' style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#6FD0B2' }}>
					<LockOpen style={{ fontSize: isMobile ? '20px' : '32px', color: '#6FD0B2', transform: 'translateY(3px)' }} />{' '}
					{intl.formatMessage({ id: 'app.alerts.phiFreeCircle' }).toUpperCase()}
				</Typography>
				<Typography
					data-testid="myalerts-alert-empty-phi"
					variant='body2' style={{ fontWeight: '600', fontSize: isMobile ? '20px' : '32px', color: '#414E60' }}>
					<Lock style={{ fontSize: isMobile ? '20px' : '32px', color: '#414E60', transform: 'translateY(3px)' }} />{' '}
					{intl.formatMessage({ id: 'app.alerts.phiCircle' }).toUpperCase()}
				</Typography>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(AlertEmpty)

export const ComparisonType = {
	Invalid: 0,
	LessThan: 1,
	GreaterThan: 2,
	Between: 3,
	StartsWith: 4,
	EndsWith: 5,
	Contains: 6,
	GreaterThanOrEqual: 7,
	LessThanOrEqual: 8,
	Equal: 9
}

import { createSelector } from 'reselect'
import * as utility from '../../../apis/utility'
import translations from '../../../utilities/languages/locales'
import * as cookies from '../../../utilities/cookies'
import VerifyPhoneNumber from '../../../components/VerifyPhoneNumber'
import locale2 from 'locale2'
const SHOW_MODAL = 'SHOW_MODAL'

export const showModal = (component, options, canClose = true) => (dispatch) => {
	return dispatch({
		type: SHOW_MODAL,
		data: { component: component, options: options, show: true, canClose: canClose }
	})
}

export const pageLoadHandler = (p) => (dispatch) => {
	dispatch(refreshIntlLocale());

	if (p !== 'SecureRoot') {
        dispatch(checkMFASetupRequired());
    }
}

const checkMFASetupRequired = () => (dispatch) => {
	let mFASetupRequired = cookies.get('mFASetupRequired');

	if (mFASetupRequired === 'True') {
        dispatch({
            type: 'SHOW_MODAL',
            data: {
                component: VerifyPhoneNumber,
                options: {
                    mode: 'attach-cookie',
                    modalContainerStyle: { fullWidth: false }
                },
                show: true,
                canClose: false
            }
        })
    }
}

const REFRESH_INTL_LOCALE_REQUESTED = 'REFRESH_INTL_LOCALE_REQUESTED'
const REFRESH_INTL_LOCALE_SUCCESS = 'REFRESH_INTL_LOCALE_SUCCESS'

export const refreshIntlLocale = () => (dispatch) => {
	dispatch({ type: REFRESH_INTL_LOCALE_REQUESTED, data: {}})

    let locale = 'en';
    let localeCookie = cookies.get('languageAbbreviation');

    if (localeCookie) {
        locale = localeCookie;
    }
	else if (locale2) {
		locale = locale2.substring(0, 5)
		if (locale !== 'en-GB') {
			locale = locale2.substring(0, 2)
		}
	}

    if (!translations[locale]) {
        locale = 'en'
    }

    return dispatch({ type: REFRESH_INTL_LOCALE_SUCCESS, data: { locale: locale, messages: translations[locale] } });
}

const SET_INTL_LOCALE_REQUESTED = 'SET_INTL_LOCALE_REQUESTED'
export const setIntlLocale = (locale) => (dispatch) => {

	if(translations[locale])
	{
		cookies.set('languageAbbreviation', locale)
	}

	dispatch(refreshIntlLocale());

    return dispatch({ type: SET_INTL_LOCALE_REQUESTED, data: {}});
}

const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE'
export const setSnackbarMessage = (message, isSuccess = null) => (dispatch) => {
	return dispatch({ type: SET_SNACKBAR_MESSAGE, data: { message: message, isSuccess: isSuccess } })
}

export const hideModal = () => (dispatch, getState) =>
{
    let state = getState();

	if (state && state.core && state.core.onClose) {
		dispatch(state.core.onClose)
    }

    return dispatch({ type: SHOW_MODAL, data: { component: null, show: false, options: null } })
}

const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE'
export const clearSnackbarMessage = () => (dispatch) => {
	return dispatch({ type: SET_SNACKBAR_MESSAGE, data: { message: null, isSuccess: null } })
}

const START_SAVING_REQUESTED = 'START_SAVING_REQUESTED'
export const startSaving = () => (dispatch) => {
	return dispatch({ type: START_SAVING_REQUESTED, data: {} })
}

const STOP_SAVING_REQUESTED = 'STOP_SAVING_REQUESTED'
export const stopSaving = () => (dispatch, getState) => {
	return dispatch({ type: STOP_SAVING_REQUESTED, data: {} })
}

const FETCH_CURRENT_BACKEND_VERSION_REQUESTED = 'FETCH_CURRENT_BACKEND_VERSION_REQUESTED'
const FETCH_CURRENT_BACKEND_VERSION_SUCCESS = 'FETCH_CURRENT_BACKEND_VERSION_SUCCESS'
const FETCH_CURRENT_BACKEND_VERSION_FAILED = 'FETCH_CURRENT_BACKEND_VERSION_FAILED'

export const fetchCurrentBackendVersion = () => (dispatch) => {
    dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_REQUESTED, data: {} })

    return utility.fetchCurrentBackendVersion().then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_SUCCESS, data: response.data })
        } else {
            return dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_FAILED, data: response.data })
        }
    })
}

const SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS = 'SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS'

export const setVersionMismatchAlertVisibility = (state) => (dispatch) => {
    dispatch({ type: SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS, data: state })
}

const initial = {
	show: false,
	component: null,
    onClose: null,
	options: null,
	params: null,
	message: null,
	isSnackMessageSuccess: false,
	canClose: true,
	isSaving: false,
    currentBackendVersion: null,
    isVersionMismatchAlertVisible: false,
    locales: {
        locale: 'en',
        messages: []
    },
	localeIsUpdated: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case START_SAVING_REQUESTED:
			return { ...state, isSaving: true }
		case STOP_SAVING_REQUESTED:
			return { ...state, isSaving: false }
		case SHOW_MODAL:
			return {
				...state,
				show: action.data.show,
				component: action.data.component,
				params: action.data.options,
				canClose: action.data.canClose,
				onClose: action.data.onClose
			}
		case SET_SNACKBAR_MESSAGE:
			return { ...state, message: action.data.message, isSnackMessageSuccess: action.data.isSuccess }
		case CLEAR_SNACKBAR_MESSAGE:
			return { ...state, message: null, isSnackMessageSuccess: false }
		case 'SIGN_OUT_REQUESTED':
			return { ...initial, locales : state.locales }
        case FETCH_CURRENT_BACKEND_VERSION_SUCCESS:
            return {
                ...state, currentBackendVersion: action.data
            }
        case SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS:
            return { ...state, isVersionMismatchAlertVisible: action.data }
		case REFRESH_INTL_LOCALE_REQUESTED:
			return { ...state, localeIsUpdated: false }
        case REFRESH_INTL_LOCALE_SUCCESS:
            return { ...state, locales: action.data, localeIsUpdated: true }
        default:
			return { ...state }
	}
}

const mainSelector = (state) => state.core

export const confirmedSelector = (state) => {
	return state.auth.confirmed
}

export const errorSelector = (state) => {
	return state.auth.error
}

export const isSavingSelector = createSelector(mainSelector, (state) => {
	return state.isSaving
})

export const intlLocaleSelector = createSelector(mainSelector, (state) => {
    return state.locales
})

export const currentBackendVersionSelector = createSelector(mainSelector, (state) => {
    return state.currentBackendVersion
})

export const isVersionMismatchAlertVisibleSelector = createSelector(mainSelector, (state) => {
    return state.isVersionMismatchAlertVisible
})

export const localeIsUpdatedSelector = createSelector(mainSelector, (state) => {
    return state.localeIsUpdated
})
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TeamTableHeader from './MemberTableHeader'
import TeamMemberRow from './MemberRow'

export default ({ data, user, circle }) => (
	<Table data-testid="user-profile-member-table"
		className="label-paper-inner"
		style={{ margin: '50px -32px 0' }}>
		<TeamTableHeader />
		<TableBody>
			{data && data.map((member) => <TeamMemberRow member={member} user={user} key={member.invitationId} />)}
		</TableBody>
	</Table>
)

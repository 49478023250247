import React, { useEffect, Fragment } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import * as UI from '../../../../utilities/UIConstants'
import LabelPaper from '../../../../components/LabelPaper'
import YourSponsor from './YourSponsor'
import { fetchSubscriptionSponsorCircle } from '../../../../redux/actions'
import { subscriptionSponsorCircleSelector, subscriptionSponsorNameSelector } from '../../../../redux/selectors'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const SubscriptionSponsor = ({
	intl,
	classes,
	getSubscriptionSponsorCircle,
	sponsorName,
	sponsorCircle
}) => {
	useEffect(() => {
		getSubscriptionSponsorCircle()
	}, [])

	return (
		<div data-testid="subscription-sponsor" className="print-wrapper" style={{ padding: '50px 5% 0' }}>
			{(sponsorName || sponsorCircle) && (
				<div data-testid="subscription-sponsor-inner">
					<LabelPaper
						label={intl.formatMessage({ id: 'app.account.subscriptionSponsor.headerText' })}
						classes={classes.gradient}
						Component={YourSponsor}
						sponsor={sponsorName}
						circle={sponsorCircle}
					/>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	sponsorCircle: subscriptionSponsorCircleSelector(state),
	sponsorName: subscriptionSponsorNameSelector(state)
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	getSubscriptionSponsorCircle: () => dispatch(fetchSubscriptionSponsorCircle())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl, withStyles(styles))

export default enhance(SubscriptionSponsor)

import { createTheme } from '@material-ui/core/styles'

import { isMobile } from './mobileDetector'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

let theme = createTheme({
	palette: {
		light: '#FFFFFF',
		dark: '#F5F5F5',
		lightCell: '#F6FCFF',
		darkCell: '#14202E',
		blue: '#0076F6',
		primary: {
			light: '#ebf8ff',  // main background color
			A100: '#ededf1', // border color
			A200: '#e1eeff', // menu links hover color
			A300: '#e9f1ff',
			main: '#007aff', // main blue color
			A500: '#357AFF',
			A600: '#008dff',
			link: '#0167D8',
			A800: '#1368ec',
			dark: '#2045cd' // primary buttons hover color

		},
		secondary: {
			light: '#fff',
			A100: '#bbe1ff',
			A200: '#c1cee4', // close button color
			A300: '#444e5e', // lock status
			main: '#011020', // main black color
			contrast: '#fff', 
			input: '#212c3b', // dark inputs color
			inputHover: '#1f2a39', // dark inputs hover old #243041
			additional: '#556173', // additional color
			A600: '#243041', 
			A700: '#007aff',
			A800: '#00264f', // menu bottom background color
			dark: '#000'
		},
        text: {
            primary: '#222721', 
			secondary: '#fff',  // color of text secondary buttons on dark background
            disabled: '#B1B1B1',
            hint: '#222721',
        },
		background: {
            default: '#fff',
        },
		action: {
            disabledBackground: '#818183',
            disabled: '#B1B1B1'
		},
		success: {
			// main: '#86ceb4',
			main: '#6ed1b2'
		},
		error: {
			main: '#f1493e', // error message background
			light: '#b00020', // overdue
		},
		warning: {
			main: '#ff8556' // warning
		},
		info: {
			main: '#40C9FF'
		},
	},
	typography: {
		fontFamily: [ 'Source Sans Pro', 'sans-serif' ]
	},
	breakpoints: {
		values: {
		  xs: 0,
		  sm: 767,
		  md: 900,
		  lg: 1200,
		  xl: 1536,
		},
	},
});

theme = createTheme(theme, {
	overrides: {
		MuiButton: {
			root: {
				fontSize: '16px',
				// minWidth: '100px',
				color: '#222721',
				margin: '5px',
				boxShadow: 'none',
				letterSpacing: '1.29px',
				"&:hover": {
					boxShadow: '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
				},
				"&:focus": {
					boxShadow: 'none',
				}
			},
			text: {
				display: 'table',
				minWidth: '0',
				color: theme.palette.primary.link,
				padding: '2px',
				margin: '0 -2px',
				boxShadow: 'none',
				fontSize: '13px',
				letterSpacing: '1.29px',
				"&:hover": {
					boxShadow: 'none',
					backgroundColor: 'initial'
				},
				"&:focus": {
					boxShadow: 'none',
					backgroundColor: 'initial'
				},
			},
			textSizeLarge: {
				fontSize: '16px',
			},
			textPrimary: {
				color: theme.palette.primary.link,
				letterSpacing: '0.1px',
			
				"&:hover": {
					boxShadow: 'none',
					backgroundColor: 'initial'
				},
				"&:focus": {
					boxShadow: 'none',
					backgroundColor: 'initial'
				},
			},
			textSecondary: {
				color: theme.palette.text.secondary,
				"&:hover": {
					boxShadow: 'none',
					backgroundColor: 'initial',
					color: theme.palette.text.secondary,
				},
				"&:focus": {
					boxShadow: 'none',
					backgroundColor: 'initial',
					color: theme.palette.text.secondary,
				},				
			},
			textSizeSmall: {
				fontSize: '13px',
			},
			contained: {
				fontSize: '16px',
				lineHeight: '16px',
				letterSpacing: '1.29px',
				height: '36px',
				fontWeight: '500',
				padding: '10px 20px',
				whiteSpace: 'nowrap',
				borderRadius: '4px',
				color: '#fff',
				boxShadow: 'none',
				[breakpoints.down('sm')]: {
					padding: '10px 15px',
				},
				boxShadow: 'none',
				"&:hover": {
					boxShadow: '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
				},
				"&:active": {
					boxShadow: 'none',
				},
			},
			outlined: {
				fontSize: '16px',
				lineHeight: '16px',
				letterSpacing: '1.29px',
				height: '36px',
				fontWeight: '500',
				padding: '10px 20px',
				whiteSpace: 'nowrap',
				borderRadius: '4px',
				boxShadow: 'none',
				[breakpoints.down('sm')]: {
					padding: '10px 15px',
				},
				boxShadow: 'none',
				"&:hover": {
					boxShadow: '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
				},
				"&:active": {
					boxShadow: 'none',
				},

			},
			containedSizeSmall: {
				height: '32px',
				padding: '4px 14px',
				fontSize: '14px'
			},
			outlinedSizeSmall: {
				height: '32px',
				padding: '4px 14px',
				fontSize: '14px',
				[breakpoints.down('sm')]: {
					padding: '12px 20px',
				},
			},
			containedSizeLarge: {
				height: '48px',
				padding: '10px 54px',
				fontSize: 'inherit',
				[breakpoints.down('sm')]: {
					padding: '14px 24px',
				},
			},
			outlinedSizeLarge: {
				height: '48px',
				padding: '10px 54px',
				fontSize: 'inherit',
				[breakpoints.down('sm')]: {
					padding: '14px 24px',
				},
			},
			// shadows: 'none',
			containedPrimary: {
				fontSize: '16px',
				lineHeight: '16px',
				letterSpacing: '1.29px',
				height: '36px',
				fontWeight: '500',
				padding: '10px 20px',
				whiteSpace: 'nowrap',
				borderRadius: '4px',
				color: '#fff',
				boxShadow: 'none',
				[breakpoints.down('sm')]: {
					padding: '10px 15px',
				},
				boxShadow: 'none',
				"&:hover": {
					boxShadow: '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
				},
				"&:active": {
					boxShadow: 'none',
				},
			},
			outlinedPrimary: {
				fontSize: '16px',
				lineHeight: '16px',
				letterSpacing: '1.29px',
				height: '36px',
				fontWeight: '500',
				padding: '10px 20px',
				whiteSpace: 'nowrap',
				borderRadius: '4px',
				boxShadow: 'none',
				borderColor: theme.palette.primary.main,
				[breakpoints.down('sm')]: {
					padding: '10px 15px',
				},
				boxShadow: 'none',
				"&:hover": {
					boxShadow: '0px 1px 8px rgba(0,0,0,0.12), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.2)',
				},
				"&:active": {
					boxShadow: 'none',
				},
			},
		},
		MuiTypography: {
			h1: {
				fontSize: isMobile ? '35px' : '53px',
				letterSpacing: 0,
				fontWeight: '300'
			},
			h2: {
				fontSize: isMobile ? '28px' : '35px',
				letterSpacing: 0.25,
				fontWeight: 400
			},
			h3: {
				fontSize: isMobile ? '24px' : '26px',
				letterSpacing: 0,
				fontWeight: '300'
			},
			h4: {
				fontSize: '20px',
				letterSpacing: 0.25,
				fontWeight: 600,
				marginBottom: '5px'
			},
			h5: {
			},
			h6: {
			},
			body1: {
				fontSize: '16px',
				letterSpacing: '0.5px',
				lineHeight: '1.43',
				fontWeight: '400'
			},
			body2: {
				fontSize: '14px',
				fontWeight: '400',
				lineHeight: '1.43',
				letterSpacing: '0.5px'
			},
			subtitle1: {
				fontSize: '17px',
				letterSpacing: '0.5px',
				lineHeight: '1.43',
				fontWeight: '700',
				marginBottom: '3px'
			},
			subtitle2: {
				fontSize: '17px',
				letterSpacing: '0.5px',
				lineHeight: '1.43',
				fontWeight: '400',
				marginBottom: '3px'
			},
			caption: {
				fontWeight: 400,
				letterSpacing: '0.4px',
				lineHeight: '1.66',
				fontSize: '13px',
				color: theme.palette.secondary.input,
			},
			button: {
				fontSize: '16px',
				letterSpacing: 1.25
			},
			overline: {
				fontSize: '16px',
				letterSpacing: 2.0
			}
        },
        MuiFormControl: {
            root: {
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
            }
        },
		MuiFormControlLabel: {
			root: {
				color: theme.palette.text.primary,
				alignItems: 'flex-start',
				marginLeft: '0'
			}
		},
		MuiCheckbox: {
			root: {
				alignItems: 'flex-start',
			}
		},
		MuiRadio: {
			root: {
				color:'rgba(0, 0, 0, 0.54)',
				alignItems: 'flex-start',
				padding: '5px',
				"& .MuiSvgIcon-root": {
					height: '20px',
					width: '20px',
				}
			},
		},
		MuiInputLabel: {
			root: {
				color: '#fff',
				marginLeft: '10px'
			},
		},
		MuiFilledInput: {
			root: {
				fontSize: '16px',
				color: '#fff'
			},
		},
		MuiSelectLabel: {
			root: {
				color: '#fff',
				marginLeft: '10px'
			},
			select: {
				color: '#fff',
				marginLeft: '100px'
			}
		},
		MuiFormHelperText: {
			root: {
				fontSize: '1.2rem',
			},
			contained: {
				backgroundColor: theme.palette.secondary.main,
				margin: 0,
				
			}
		},
		MuiMenuItem: {
			root: {
				fontSize: '1.7rem'
			}
		},
		MuiSwitch: {
			colorPrimary: {
				"&$checked": {
					color: theme.palette.primary.link,
				},
			},
			track: {
				backgroundColor: theme.palette.secondary.additional,
				"$checked$colorPrimary + &": {
					backgroundColor: theme.palette.primary.link,
				},
			}

		},
		MuiTableSortLabel: {
			root: {
				color:  theme.palette.text.primary,
				"&:hover": {
				color: theme.palette.text.primary,

				'&& $icon': {
					opacity: 1,
					color: theme.palette.text.primary
				},
				},
				"&$active": {
				color: theme.palette.text.primary,
				'&& $icon': {
					opacity: 1,
					color: theme.palette.text.primary
				},
				},
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#e8e8e8',
			}
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: '#000'
			},
			toolbarBtnSelected: {
				color: '#000'
			}
		},
		MuiAlert: {
			standardInfo: {
				color: theme.palette.secondary.dark,
				backgroundColor: theme.palette.primary.A300,
				padding: '10px 16px',
				"& .MuiAlert-icon": {
					color: 'inherit',
					"& svg": {
						fontSize: '22px'
					},
					"& .text-icon": {
						fontSize: '34px',
						textAlign: 'center',
						lineHeight: '1.4'
					}
				}
			}
		}
	},

	
});


export default theme;

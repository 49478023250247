import * as api from '../../../apis/units'
import * as cookies from '../../../utilities/cookies'
import history from '../../../utilities/history'
import { hideModal } from '../../actions'
import { createSelector } from 'reselect'

const ADD_UNIT_REQUESTED = 'ADD_UNIT_REQUESTED'
const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS'
const ADD_UNIT_FAILED = 'ADD_UNIT_FAILED'

export const addUnit = (name, suffix) => (dispatch) => {
	dispatch({ type: ADD_UNIT_REQUESTED, data: {} })

	api.addUnit(name, suffix).then((unit) => {
		if (unit && unit.isSuccessful) {
			dispatch(searchUnits())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `TODO`, isSuccess: false }
			})
		}
	})
	return
}

const EDIT_UNIT_REQUESTED = 'EDIT_UNIT_REQUESTED'
const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS'
const EDIT_UNIT_FAILED = 'EDIT_UNIT_FAILED'

export const editUnit = (id, name, suffix) => (dispatch) => {
	dispatch({ type: ADD_UNIT_REQUESTED, data: {} })

	api.editUnit(id, name, suffix).then((unit) => {
		if (unit && unit.isSuccessful) {
			dispatch(searchUnits())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `TODO`, isSuccess: false }
			})
		}
	})
	return
}

const SEARCH_UNITS_REQUESTED = 'SEARCH_UNITS_REQUESTED'
const SEARCH_UNITS_SUCCESS = 'SEARCH_UNITS_SUCCESS'
const SEARCH_UNITS_FAILED = 'SEARCH_UNITS_FAILED'

export const searchUnits = (searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_UNITS_REQUESTED, data: {} })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful && response.units) {
			let data = response.units.sort((a, b) => {
				return a['modifiedDateSeconds'] > b['modifiedDateSeconds'] ? -1 : 1
			})
			return dispatch({ type: SEARCH_UNITS_SUCCESS, data: data })
		} else {
			return dispatch({ type: SEARCH_UNITS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_UNITS_REQUESTED = 'FETCH_UNITS_REQUESTED'
const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
const FETCH_UNITS_FAILED = 'FETCH_UNITS_FAILED'

export const fetchUnits = () => (dispatch) => {
	dispatch({ type: FETCH_UNITS_REQUESTED, data: {} })
	api.search().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_UNITS_SUCCESS, data: response.units })
		} else {
			return dispatch({ type: FETCH_UNITS_FAILED, data: {} })
		}
	})
	return
}

const SET_UNIT_SORT_REQUESTED = 'SET_UNIT_SORT_REQUESTED'

export const setUnitSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.units.searchUnits ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})

	return dispatch({
		type: SET_UNIT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_UNIT_FILTER_REQUESTED = 'SET_UNIT_FILTER_REQUESTED'
const SET_UNIT_FILTER_SUCCESS = 'SET_UNIT_FILTER_SUCCESS'
const SET_UNIT_FILTER_FAILED = 'SET_UNIT_FILTER_FAILED'

export const setQuestionFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_UNIT_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_UNIT_FILTER_SUCCESS, data: filter })
}

const initial = {
	units: [],
	searchUnits: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	filter: 0
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_UNITS_SUCCESS:
			return { ...state, units: action.data }
		case SEARCH_UNITS_REQUESTED:
			return { ...state, searchUnits: [] }
		case SEARCH_UNITS_SUCCESS:
			return { ...state, searchUnits: action.data }
		case SET_UNIT_SORT_REQUESTED:
			return {
				...state,
				searchUnits: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_UNIT_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.unit

export const searchUnitsSelector = createSelector(mainSelector, (state) => {
	return state.searchUnits
})

export const hasUnitsSelector = createSelector(mainSelector, (state) => {
	return state && state.units && state.units.length > 0
})

export const unitsSelector = createSelector(mainSelector, (state) => {
	return state && state.units
})

export const unitSuffixSelector = createSelector(mainSelector, unitsSelector, (state, units) => (id) => {
	if (units && units.length > 0) {
		let x = units.find((r) => r.id === id)
		if (x) return x.suffix
	}
	return null
})

export const sortUnitsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortUnitsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

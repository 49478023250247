import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { processInvitation } from '../../../../redux/actions'
import { injectIntl } from 'react-intl'

const ITEM_HEIGHT = 48
const ACCEPT = 1
const DECLINE = 2

const Menu = ({ intl, alert, process }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	//action 1 is accept, decline is 0
	const onAccept = () => {
		process(alert.invitationId, 1)
		handleClose()
	}

	const onDecline = () => {
		process(alert.invitationId, 2)
		handleClose()
	}

	const onEmailFounder = () => {
		if (alert && alert.sourceEmail) {
			window.location.href = `mailto:${alert.sourceEmail}`
			handleClose()
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div data-testid="myalerts-invitation-alert-menu">
			<div>
				<IconButton
					data-testid="invitation-alert-menu-button-more"
					aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					data-testid="invitation-alert-menu-long"
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200,
							backgroundColor: '#243041',
							color: '#fff'
						}
					}}
				>
					<MenuItem data-testid="email-founder" onClick={onEmailFounder} id="1">
						{intl.formatMessage({ id: 'app.dashboard.emailFounder' })}
					</MenuItem>
					<MenuItem data-testid="accept" onClick={onAccept} id="1">
						{intl.formatMessage({ id: 'app.general.accept' })}
					</MenuItem>
					<MenuItem data-testid="decline" onClick={onDecline} id="2">
						{intl.formatMessage({ id: 'app.general.decline' })}
					</MenuItem>
				</MuiMenu>
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	process: (id, action) => {
		dispatch(processInvitation(id, action))
	}
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(Menu)

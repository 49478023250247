import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required } from '../../utilities/validators'
import NumberedRow from '../../components/NumberedRow'
import Button from '@material-ui/core/Button'
import SponsoredSelector from './components/SponsoredSelector'
import SetCasePatient from './components/SetCasePatient'
import CircularProgress from '@material-ui/core/CircularProgress'
import { addCase, clearProtocolVersions, fetchProtocolVersionsForCircle, getCurrentUserDetails } from '../../redux/actions'
import { defaultProtocolVersionSelector, emailVerificationStartedSelector, emailVerifiedSelector, formattedCirclesSelector, isCreatingCaseSelector, userDetailsSelector, userJurisdictionSelector, verifyEmailSelector } from '../../redux/selectors'
import CalmButton from '../../components/CalmButton'
import AdvancedCaseSettings from './components/AdvancedCaseSettings'
import Box from '@material-ui/core/Box';
import CaseFormInputDates from './components/CaseFormInputDates'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

const selector = formValueSelector('addCase')

const requiredValidator = required

const calculateDate = (dueValue, dueUnit) => {
	var today = new Date()
	if (dueValue > 0) {
		switch (dueUnit) {
			case 0:
				today.setDate(today.getDate() + dueValue)
				break
			case 1:
				today.setDate(today.getDate() + dueValue * 7)
				break
			case 2:
				today.setDate(today.getDate() + dueValue * 30)
				break
			default:
				break
		}
	}

	return today.toLocaleDateString('en-US')
}

const EMAIL_STATUS = {
	NONE: 1,
	VERIFING: 2,
	VERIFIED: 3,
	NOTVERIFIED: 4
}

const AddCaseModal = ({
	intl,
	handleSubmit,
	add,
	isCreating,
	resetSendRegistrationEmail,
	resetAllowEditCompletedDate,
	resetTreatmentDate,
	resetCommencementDate,
	changeLanguage,
	user,
	loadUser,
	setJurisdiction,
	defaultJurisdiction,
	loadProtocolVersions,
	defaultProtocolVersion,
	setProtocolVersion,
	clearProtocolVersions,
	resetProtocolVersion,
	email,
	verificationStarted,
	verified,
	inputEmail
}) => {
	
	const [isNewPatient, setIsNewPatient] = useState(false)
	const [showPatientEmailExistsError, setShowPatientEmailExistsError] = useState(false)
	const [notEnoughCasesError, setNotEnoughCasesError] = useState(false)
	const [treatmentDateError, setTreatmentDateError] = useState(false)
	const [, setShowProgress] = useState(false)
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
	const [selectedCircle, setSelectedCircle] = useState(null)
	const [defaultTreatmentDate, setDefaultTreatmentDate] = useState()
	const [defaultCommencementDate, setDefaultCommencementDate] = useState()
	const [defaultLanguageId, setDefaultLanguageId] = useState()
	const [useExistingPatient, setUseExistingPatient] = useState(false)
	const [selectedProtocolVersion, setSelectedProtocolVersion] = useState(null)
	const [piRegionLocked, setPiRegionLocked] = useState(false)
	const [ emailStatus, setEmailStatus ] = useState(EMAIL_STATUS.NONE)
	const [ isBilateral, setIsBilateral ] = useState(false)
	const [ bilateralArea, setBilateralArea ] = useState(0)
	const [ protocolVersionIdValue, setProtocolVersionIdValue ] = useState(null)

	useEffect(() => {
		if (selectedCircle) {
			var commencementDate = calculateDate(selectedCircle.commencementDue, selectedCircle.commencementDueUnit)
			setDefaultCommencementDate(commencementDate)
			resetCommencementDate(commencementDate)

			var treatmentDate = calculateDate(selectedCircle.treatmentDue, selectedCircle.treatmentDueUnit)
			setDefaultTreatmentDate(treatmentDate)
			resetTreatmentDate(treatmentDate)
			loadProtocolVersions(selectedCircle.id)
			setPiRegionLocked(selectedCircle.patientPiRegionIsRequired)

			if(selectedCircle.patientPiRegion) {
				setJurisdiction(selectedCircle.patientPiRegion)
			}
			else if(user) {
				setJurisdiction(user.teamOwnerJurisdictionId)
			}

			setIsBilateral(selectedCircle.isBilateral)
		}
		else {
			resetProtocolVersion()
			clearProtocolVersions()
			setPiRegionLocked(false)
			setIsBilateral(false)

			if(defaultJurisdiction){
				setJurisdiction(defaultJurisdiction)
			}
		}
	}, [selectedCircle])

	useEffect(() => {
		console.log("====>>", selectedProtocolVersion)

		if (selectedProtocolVersion) {
			var commencementDate = calculateDate(selectedProtocolVersion.commencementDue, selectedProtocolVersion.commencementDueUnit)
			setDefaultCommencementDate(commencementDate)
			resetCommencementDate(commencementDate)

			var treatmentDate = calculateDate(selectedProtocolVersion.treatmentDue, selectedProtocolVersion.treatmentDueUnit)
			setDefaultTreatmentDate(treatmentDate)
			resetTreatmentDate(treatmentDate)
		}
	}, [selectedProtocolVersion])

	useEffect(() => {
		resetCommencementDate()
		resetTreatmentDate()
		resetSendRegistrationEmail()
		resetAllowEditCompletedDate()
		loadUser()
		resetProtocolVersion()
		clearProtocolVersions()
		setPiRegionLocked(false)
	}, [])

	useEffect(() => {
		setJurisdiction(defaultJurisdiction)
	}, [defaultJurisdiction])	

	useEffect(() => {
		if(defaultProtocolVersion && selectedCircle) {
			console.log("====>>", defaultProtocolVersion.id)

			setProtocolVersionIdValue(defaultProtocolVersion.id)

			setProtocolVersion(defaultProtocolVersion.id)
		}
	}, [defaultProtocolVersion])

	useEffect(() => {
		if(user && user.languageId) {
			changeLanguage(user.languageId)
			setDefaultLanguageId(user.languageId)
		}
	}, [user])	

	useEffect(
		() => {
			if (!!email) {
				if (inputEmail != email) {
					setEmailStatus(EMAIL_STATUS.NONE)
				}
				else if (verificationStarted) {
					setEmailStatus(EMAIL_STATUS.VERIFING)
				}
				else if (verified) {
					setEmailStatus(EMAIL_STATUS.VERIFIED)
				}
				else {
					setEmailStatus(EMAIL_STATUS.NOTVERIFIED)
				}
			}
		},
		[email, verificationStarted, verified]
	)


	const handleCircleSelected = (value) => {
		setSelectedCircle(value)
	}

	const toggleAdvancedSettings = (show) => {
		setShowAdvancedSettings(show)
	}

	const onSubmitForm = async (values) => {
		if (
			!useExistingPatient
			&& !!inputEmail
			&& EMAIL_STATUS.VERIFIED !== emailStatus
			&& EMAIL_STATUS.NOTVERIFIED !== emailStatus
		) {
			return
		}

		setShowPatientEmailExistsError(false)
		setNotEnoughCasesError(false)
		setTreatmentDateError(false)
		setShowProgress(true)

		if (values.treatmentDate && values.commencementDate && new Date(values.treatmentDate) < new Date(values.commencementDate)) {
			setTreatmentDateError(true)
			setShowProgress(false)
			return
		}

		if(!values.treatmentDate) {
			values.treatmentDate = defaultTreatmentDate
		}

		if(!values.commencementDate) {
			values.commencementDate = defaultCommencementDate
		}

		values.isBilateral = isBilateral
		values.bilateralArea = bilateralArea

		// const circleInfo = await fetchCircleUsersCases(values.sponsorId)
		// if (circleInfo.circleCaseInfo.unusedInCircle > 0) {
		// 	addCase(values)
		// } else {
		// 	setNotEnoughCasesError(true)
		// 	setShowProgress(false)
		// }

		addCase(values)
	}

	const addCase = (values) => {
		let x = { ...values, email: values.email?.trim() }
		x.phi = x && x.phi === '2'
		if (x && x.sharedCircles) {
			//if the array is empty it comes across as "[]" which will not deserialize
			if (typeof x.sharedCircles === 'string' && x.sharedCircles.startsWith('[]')) {
				x.sharedCircles = null
			}
		}

		add(x)
	}

	const onPatientSelected = (value) => {
		let languageId = value && value.languageId || defaultLanguageId
		changeLanguage(languageId)
		if (!piRegionLocked) {
			let jurisdictionId = value && value.jurisdictionId || defaultJurisdiction
			setJurisdiction(jurisdictionId)
		}
	}

	const onUseExistingChange = (value) => {
		setUseExistingPatient(value)
		if(!value) {
			changeLanguage(defaultLanguageId)
			if(selectedCircle && selectedCircle.patientPiRegion) {
				setJurisdiction(selectedCircle.patientPiRegion)
			}
			else if(user) {
				setJurisdiction(user.teamOwnerJurisdictionId)
			}
		}
	}

	return (
		<form data-testid="add-case-modal" onSubmit={handleSubmit(onSubmitForm)}>
			<Box bgcolor="secondary.main">
				<Grid
					container
					direction='column'
					justifyContent='flex-start'
					style={{
						padding: '4rem 4rem 88px 4rem',
						margin: 'auto',
					}}>
					<Grid data-testid="add-case-modal-title" item>
						<Typography
							variant='h1'
							color="textSecondary"
							style={{
								marginBottom: '4rem',
								textAlign: 'center'
							}}
						>
							{intl.formatMessage({ id: 'app.cases.addCaseTitle' })}
						</Typography>
					</Grid>
					<div data-testid="add-case-modal-sponsored-selector">
						<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
							<Grid container justifyContent='space-between'>
								<Grid item data-testid="sponsored-selector-numbered-row">
									<NumberedRow number={1} label={`${intl.formatMessage({ id: 'app.cases.step1Label' })}`} />
								</Grid>
								<Grid item>
								</Grid>
							</Grid>
						</Grid>

						<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
							<SponsoredSelector
								name='sponsorId' label={intl.formatMessage({ id: 'app.cases.step3Label' })} validators={[requiredValidator]} onCircleSelected={handleCircleSelected} />

							{/* {selectedCircle && selectedCircle.isBilateral &&
								<Grid item style={{ marginTop: '10px', marginBottom: '20px', width: '100%', paddingLeft: '20px' }}>
									<Grid container direction='column'>
										<Grid item>
											<RadioGroup>
												<Grid
													container
													direction='row'
													style={{ width: '100%' }}
												>
													<Grid item xs="12" sm="6">
														<FormControlLabel
															label={intl.formatMessage({ id: 'app.addcase.isBilateralLabel' })}
															control={
																<Radio
																	onChange={() => setIsBilateral(true)}
																	checked={isBilateral}
																/>
															}
														/>
													</Grid>
													<Grid item xs="12" sm="6">
														<FormControlLabel
															label={intl.formatMessage({ id: 'app.addcase.isNotBilateralLabel' })}
															control={
																<Radio
																	onChange={() => setIsBilateral(false)}
																	checked={!isBilateral}
																/>
															}
														/>
													</Grid>
												</Grid>
											</RadioGroup>
										</Grid>
										{!isBilateral && <Grid item>
											<RadioGroup>
												<Grid
													container
													direction='row'
													style={{ width: '100%' }}
												>
													<Grid item xs={6}/>
													<Grid item xs={6} sm={3}>
														<FormControlLabel
															label={selectedProtocolVersion ? selectedProtocolVersion.area1Name : selectedCircle.area1Name}
															control={
																<Radio
																	onChange={() => setBilateralArea(0)}
																	checked={bilateralArea === 0}
																/>
															}
														/>
													</Grid>
													<Grid item xs={6} sm={3}>
														<FormControlLabel
															label={selectedProtocolVersion ? selectedProtocolVersion.area2Name : selectedCircle.area2Name}
															control={
																<Radio
																	onChange={() => setBilateralArea(1)}
																	checked={bilateralArea === 1}
																/>
															}
														/>
													</Grid>
												</Grid>
											</RadioGroup>
										</Grid>}
									</Grid>
								</Grid>
							} */}

							<Grid item style={{ paddingLeft: '20px' }}>
								<CaseFormInputDates
									defaultCommencementDate={defaultCommencementDate}
									defaultTreatmentDate={defaultTreatmentDate}
								/>
							</Grid>

							<div data-testid="add-case-modal-hide-advanced-settings" style={{ paddingLeft: '20px' }}>
								<Button
									variant='text'
									color='primary' onClick={() => toggleAdvancedSettings(!showAdvancedSettings)}>
									
										{showAdvancedSettings ?
											intl.formatMessage({ id: 'app.addcase.hideAdvSettingsText' })
											:
											intl.formatMessage({ id: 'app.addcase.showAdvSettingsText' })
										}
									
								</Button>
							</div>

							{showAdvancedSettings &&
								<AdvancedCaseSettings
									key={`protocol-version-id-${protocolVersionIdValue}`}
									defaultTreatmentDate={defaultTreatmentDate}
									defaultCommencementDate={defaultCommencementDate}
									useExistingPatient={useExistingPatient}
									onProtocolVersionChange={setSelectedProtocolVersion}
									//defaultPiRegion={defaultPiRegion}
									piRegionLocked={piRegionLocked}
									protocolVersionId={protocolVersionIdValue}
								/>
							}

						</Grid>
					</div>


					<div data-testid="add-case-modal-set-case">
						<SetCasePatient
							setIsNewPatient={setIsNewPatient} validators={[requiredValidator]} onPatientSelected={onPatientSelected} onUseExistingChange={onUseExistingChange}/>
					</div>


					{isCreating && (
						<Grid item style={{ textAlign: 'center', marginTop: '40px' }}>
							<CircularProgress />
						</Grid>
					)}
					{!isCreating && (
						<Grid data-testid="add-case-modal-button-create" item style={{ textAlign: 'center' }}>
							<CalmButton
								label={intl.formatMessage({
									id: 'app.cases.createCaseButtonText'
								})}
								style={{
									marginTop: '40px'
								}}
								color="primary"
								variant="contained"
								type='submit'
								size='large'
								disabled={isCreating}>
								{intl.formatMessage({
									id: 'app.cases.createCaseButtonText'
								})}
							</CalmButton>

							{showPatientEmailExistsError && (
								<div data-testid="add-case-modal-patient-email">
									<Typography
										variant='caption'
										color='error'
										align='center'
										paddingTop='10px'
										display='block'>
										{intl.formatMessage({
											id: 'app.cases.patientEmailExists'
										})}
									</Typography>
								</div>
							)}

							{notEnoughCasesError && (
								<div data-testid="add-case-modal-not-enough">
									<Typography
										variant='caption'
										color='error'
										align='center'
										paddingTop='10px'
										display='block'>
										{intl.formatMessage({
											id: 'app.cases.notEnoughCases'
										})}
									</Typography>
								</div>
							)}

							{treatmentDateError && (
								<div data-testid="add-case-modal-not-enough">
									<Typography
										variant='caption'
										color='error'
										align='center'
										paddingTop='10px'
										display='block'>
										{intl.formatMessage({
											id: 'app.case.wrongTreatmentDate'
										})}
									</Typography>
								</div>
							)}
						</Grid>
					)}
				</Grid>
			</Box>
		</form>
	)
}

const mapStateToProps = (state) => ({
	circles: formattedCirclesSelector(state),
	isCreating: isCreatingCaseSelector(state),
	user: userDetailsSelector(state),
	defaultJurisdiction: userJurisdictionSelector(state),
	defaultProtocolVersion: defaultProtocolVersionSelector(state),
	email: verifyEmailSelector(state),
	verificationStarted: emailVerificationStartedSelector(state),
	verified: emailVerifiedSelector(state),
	inputEmail: selector(state, 'email')
})

const mapDispatchToProps = (dispatch) => ({
	add: (values) => dispatch(addCase(values)),
	resetSendRegistrationEmail: () => dispatch(change('addCase', 'sendRegistrationEmail', true)),
	resetTreatmentDate: (value) => dispatch(change('addCase', 'treatmentDate', value)),
	resetCommencementDate: (value) => dispatch(change('addCase', 'commencementDate', value)),
	resetAllowEditCompletedDate: () => dispatch(change('addCase', 'allowEditCompletedDate', false)),
	changeLanguage: (languageId) => dispatch(change('addCase', 'languageId', languageId)),
	loadUser: () => dispatch(getCurrentUserDetails()),
	setJurisdiction: (value) => dispatch(change('addCase', 'jurisdictionId', value)),
	loadProtocolVersions: (circleId) => dispatch(fetchProtocolVersionsForCircle(circleId)),
	clearProtocolVersions: () => dispatch(clearProtocolVersions()),
	setProtocolVersion: (value) => dispatch(change('addCase', 'protocolVersionId', value)),
	resetProtocolVersion: () => dispatch(change('addCase', 'protocolVersionId', null)),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addCase',
		touchOnChange: true,
		touchOnBlur: true,
		initialValues: {
			sendRegistrationEmail: true
		}
	}),
	withRouter
)

export default enhance(AddCaseModal)

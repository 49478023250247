import {authenticatedFetch} from "./base";

export const fetchStates = (countryId) => {
    return authenticatedFetch(`/api/states/country/${countryId}`, {method: 'get'})
        .then(function(response){
            if(response.status === 200){
                return response.json()
            }
        })
        .then(function(states){
            return states
        })
}
import React, { useEffect } from 'react'
import { reduxForm, change } from 'redux-form'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import InvitationForm from '../../../../components/InvitationForm'
import { checkSubscriptionStatusByEmail, inviteCircleMember, fetchCirclePlan } from '../../../../redux/actions'
import { subscriptionStatusSelector, subscriptionUserSelector, circleSelector, circlePlanSelector } from '../../../../redux/selectors'

const formName = 'inviteCircleMember'

const InviteCircleMember = ({
	dispatch,
	circle,
	circleId,
	handleSubmit,
	submitCircleInvitationRequest,
	getSubscriptionStatusByEmail,
	availableCredits,
	changeLanguage,
	getPlan,
	plan
}) => {
	useEffect(() => {
		getPlan(circleId)
	}, [])

	useEffect(() => {
		dispatch(change(formName, 'languageId', circle.administrator.languageId))
	}, circle && circle.administrator && circle.administrator.languageId )

	const onSubmitInvitationForm = (values) => {
		submitCircleInvitationRequest(values.email.trim(), circleId, values.languageId, values.sponsored)
	}

	return (
		<div data-testid="sponsored-circles-invite" >
			{plan && (
				<InvitationForm
					availableCredits={plan.totalUserCreditsAllocatedInCircle - plan.totalUsedUsers}
					handleSubmit={handleSubmit}
					onSubmitForm={onSubmitInvitationForm}
				/>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circle: circleSelector(state),
	circleId: state.core.params.circleId,
	subscriptionStatus: subscriptionStatusSelector(state),
	subscriptionUser: subscriptionUserSelector(state),
	plan: circlePlanSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitCircleInvitationRequest: (email, circleId, languageId, sponsored) => {
		dispatch(inviteCircleMember(email, circleId, languageId, sponsored))
	},
	getSubscriptionStatusByEmail: (circleId, email) => {
		dispatch(checkSubscriptionStatusByEmail(circleId, email))
	},
	getPlan: (circleId) => dispatch(fetchCirclePlan(circleId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: formName
	}),
	injectIntl
)

export default enhance(InviteCircleMember)

import React from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { showModal } from '../../../../redux/actions'
import { injectIntl } from 'react-intl'
import CreateSubscriptionModal from "./CreateSubscriptionModal";

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const CreateSubscriptionButton = ({ onAddClick, intl, price }) => {
	return (
		<Button
			data-testid="create-subscription-button"
			variant="contained"
			color="primary"
			onClick={() => onAddClick(price)}>
				{intl.formatMessage({ id: 'app.dashboard.personalPlans.createSubscriptionButtonText' })}
		</Button>
	)
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onAddClick: (price) => dispatch(showModal(CreateSubscriptionModal, { price: price }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl, withStyles(styles))

export default enhance(CreateSubscriptionButton)

import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { Field, change } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'

const useASStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'WebkitOverflowScrolling': 'touch',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
	divider: {
		height: theme.spacing(2)
	}
}))


export const CountrySelector = ({
	suggestions,
	label,
	meta: { initial, dispatch, form, touched, invalid, error },
	input,
	noMatchText,
	onCountryChange
}) => {

	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(false)
	const [ stateSuggestions, setSuggestions ] = useState(suggestions)
	const classes = useASStyles()
	const [ anchorEl, setAnchorEl ] = React.useState(null)

	const [ state, setState ] = React.useState({
		label: ''
	})

	useEffect(() => {
		if (suggestions && suggestions.length > 0) {
			var name = suggestions.filter((r) => {
				if (r.id == initial) {
					return r
				}
			})

			if (name && name.length > 0) {
				setState({ label: name[0].label })
			}
		}
	}, [initial, suggestions.length])

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem data-testid="country-selector-menu-item" selected={isHighlighted} component="div">
				<div>{suggestion.label}</div>
			</MenuItem>
		)
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps

		return (
			<TextField
				data-testid="country-selector-text-field"
				fullWidth
				autoComplete={false}
				error={touched && invalid}
				helperText={touched && error}
				color="secondary"
				InputProps={{
					...inputProps,
					autocomplete: 'off',
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					}
				}}
				inputProps={{ autocomplete: 'dont' }}
				shrink="true"
				variant="filled"
				{...other}
			/>
		)
	}

	const handleSuggestionsFetchRequested = ({ value }) => {
		var s = getSuggestions(value)
		setSuggestions(s)
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (suggestion && suggestion.id > -1) {
			dispatch(change(form, input.name, suggestion.id))
			setNoMatch(false)
			setMatchSelected(true)
		} else {
			setState({
				...state,
				value: null,
				label: ''
			})
			dispatch(change(form, input.name, ''))
		}
	}

	function getSuggestions(value) {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length
		let count = 0
		//setNoMatch(false)
		//setMatchSelected(false)
		let result =
			inputLength === 0
				? []
				: suggestions.filter((suggestion) => {
						const keep = count < 5 && suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

						if (keep) {
							count += 1
						}

						return keep
					})

		if (inputLength === 0 && result.length === 0) {
			return result
		} else if (result.length === 1 && inputValue === result[0]) {
			//setNoMatch(false)
			//setMatchSelected(true)
			setState({
				...state,
				value: result[0]
			})
			setAnchorEl(null)
			return []
		} else if (result.length === 0) {
			//setNoMatch(true)
			//setMatchSelected(false)
			return [ { label: noMatchText, id: -1 } ]
		} else {
			return result
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: onSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	}

	const handleChange = (event, { newValue }) => {
		if (newValue & newValue.id && newValue.id > 0) {
			dispatch(change(form, input.name, newValue.id))
			setNoMatch(false)
			setMatchSelected(true)
			if(onCountryChange) {
				onCountryChange(newValue.id)
			}
		} else {
			dispatch(change(form, input.name, null))
			setNoMatch(true)
			setMatchSelected(false)
			if(onCountryChange) {
				onCountryChange(null)
			}
		}
		setState({
			...state,
			value: newValue,
			label: newValue && newValue.label ? newValue.label : newValue
		})
	}

	return (
		<div data-testid="country-selector-wrapper" className={classes.root}>
			<Autosuggest
				data-testid="country-selector-autosuggest"
				{...autosuggestProps}
				inputProps={{
					classes,
					id: 'react-autosuggest-popper',
					label: label,
					value: state.label,
					onChange: handleChange,
					onBlur: (e, x) => {
						setAnchorEl(null)

						//input.onChange(e.target.value)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: '',
								label: ''
							})
							//dispatch(change(form, input.name, ''))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={(options) => (
					<Popper data-testid="country-selector-popper" anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 1300 }}	
                		placement="bottom-start"       
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}	
					>
						<Paper
							data-testid="country-selector-paper"
							square
							{...options.containerProps}
							className={classes.optionStyle}
							style={{
								maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
								width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
								marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined }}
						>
							{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	)
}

export default ({
	label,
	name,
	validators,
	suggestions,
	maxLength = 255,
	noMatchText = 'No Entries Found',
	onCountryChange,
	...other
}) => {
	return (
		<Field
			name={name}
			component={CountrySelector}
			label={label}
			maxLength={maxLength}
			fullWidth
			validate={validators}
			suggestions={suggestions}
			noMatchText={noMatchText}
			onCountryChange={onCountryChange}
			{...other}
		/>
	)
}

import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getCurrentUserDetails } from '../../redux/actions'
import { userDetailsSelector } from '../../redux/selectors'
import { getUser } from '../../utilities/userUtilities'
import { injectIntl } from 'react-intl'
import TeamMembers from './components/TeamMembers'
import AccountSecuritySettings from './components/AccountSecuritySettings'
import TeamBackgroundImage from './components/TeamBackgroundImage'

const UserProfile = ({ match, intl, fetchUser, userDetails }) => {
	const [ id, setId ] = useState()
	const [ user, setUser ] = useState(getUser())
	const [ canEdit, setCanEdit ] = useState(false)

	useEffect(() => {
		let user = getUser()
		if (user && userDetails) {
			if (user.id === userDetails.id) {
				setCanEdit(true)
			}
		}
	})

	useEffect(() => {
		if (match.params.userId) {
			setId(match.params.userId)
			fetchUser(match.params.userId)
		} else {
			let user = getUser()
			if (user) {
				setId(user.id)
				fetchUser(user.id)
			}
		}
	}, [])

	return (
		<div data-testid="user-profile">
			<Grid container style={{ marginTop: '52px' }}>
				<Grid item style={{ paddingLeft: '7%', width: '100%' }}>
					<Typography
						data-testid="user-profile-title"
						variant='h1'
						style={{
							color: '#011020',
							fontWeight: '300',
							size: '53px',
							lineHeight: '1.167',
							letterSpacing: '0',
							marginBottom: '30px'
						}}
					>
						{intl.formatMessage({ id: 'app.userProfile.HeaderText' })}
					</Typography>
				</Grid>
				<Grid item />
			</Grid>
			<TeamBackgroundImage
						data-testid="user-profile-bg-image"
						data={userDetails}
						onBackgroundUpdated={getCurrentUserDetails}
						canEdit={canEdit}
					/>
			<div
				data-testid="user-profile-header-wrapper"
				className="wide-container"
				style={{ padding: '0 5% 0 5%', marginTop: '0px', zIndex: 100, minHeight: '301px' }}>
				<Header
					userDetails={userDetails}
					onImageUpdated={getCurrentUserDetails}
					onLogoUpdated={getCurrentUserDetails}
				/>
			</div>
            <div
                data-testid="user-profile-team-members-wrapper"
				className="wide-container"
                style={{ padding: '0 5% 45px 5%', marginBottom: '40px', zIndex: 100, minHeight: '301px' }}>
                <TeamMembers user={userDetails} />
            </div>
            {
				userDetails && userDetails.teamOwner === userDetails.id &&
				<div
					data-testid="user-profile-security-wrapper"
					className="wide-container"
					style={{ padding: '0 5% 0 5%', marginBottom: '40px', zIndex: 100, minHeight: '301px' }}>
					<AccountSecuritySettings user={userDetails} />
				</div>
            }
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userDetails: userDetailsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchUser: (id) => dispatch(getCurrentUserDetails())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl)

export default enhance(UserProfile)

import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'

const TaskCell = ({ task, intl, setHeightCallback }) => {
	let ref = React.createRef()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				setHeightCallback(ref.current.offsetHeight)
			}
		},
		[ ref ]
	)
	return (
		<div data-testid="mytasks-survey-alert-cell" style={{ maxWidth: '300px' }} ref={ref}>
			<Typography
				data-testid="mytasks-survey-alert-cell-name"
				variant="subtitle2"
				style={{
					wordBreak: 'break-all',
				}}
			>
				{task && task.surveyName}
			</Typography>
			<Typography
				style={{
					textAlign: 'left',
					fontWeight: '400',
					fontSize: '13px',
					lineHeight: '16px',
					wordBreak: 'break-all',
					letterSpacing: '0.16px'
				}}
			>
				{`${intl.formatMessage({ id: 'app.general.taskId' })}: ${task.taskId36}`}
			</Typography>
		</div>
	)
}

const enhance = injectIntl

export default enhance(TaskCell)

import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Circles = lazy(() => componentLoader(() => import('./components/Circles')))

export default () => (
	<div data-testid="circle">
		<Suspense data-testid="circle-inner" fallback={<div />}>
			<section>
				<Circles />
			</section>
		</Suspense>
	</div>
)

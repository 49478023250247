import * as price from '../../../apis/price'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_PRICE_PROMOTION_REQUESTED = 'FETCH_PRICE_PROMOTION_REQUESTED'
const FETCH_PRICE_PROMOTION_SUCCESS = 'FETCH_PRICE_PROMOTION_SUCCESS'
const FETCH_PRICE_PROMOTION_FAILED = 'FETCH_PRICE_PROMOTION_FAILED'

const PRICE_PROMOTION_READY = 'PRICE_PROMOTION_READY'

export const fetchPricePromotion = () => (dispatch, getState) => {
	let state = getState();
	if (state.price.price && state.price.promotion) {
		return dispatch({ type: PRICE_PROMOTION_READY, data: {} })
	}
	else {
    dispatch({ type: FETCH_PRICE_PROMOTION_REQUESTED, data: {} })
		price.fetchPricePromotion().then((pricePromotion) => {
			if (pricePromotion) {
				return dispatch({ type: FETCH_PRICE_PROMOTION_SUCCESS, data: pricePromotion })
			} else {
				return dispatch({ type: FETCH_PRICE_PROMOTION_FAILED, data: {} })
			}
		})
		return
	}
}

const initial = {
	price: null,
	promotion: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PRICE_PROMOTION_SUCCESS:
			return { ...state, price: action.data.price, promotion: action.data.promotion }
		default:
			return { ...state }
	}
}

export const priceSelector = state => state.price.price
export const promotionSelector = state => state.price.promotion

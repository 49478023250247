import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
// import PlanPromotionText from './PlanPromotionText';
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import AddUsersRow from './AddUsersRow'
import AddCasesRow from './AddCasesRow'
import Total from './Total'
import CheckoutSummary from './CheckoutSummary'
import Discount from './Discount'
import Checkout from './Checkout'
import { useSelector, shallowEqual } from 'react-redux'
import { useDispatch } from 'react-redux'
import { fetchStripePublishableKey, showModal, fetchPlan, resetPaymentState } from '../../redux/actions'
import { promotionSelector, priceSelector, stripePublishableKeySelector, planSelector } from '../../redux/selectors'

const AddCasesModal = ({
	intl,
	handleSubmit,
	price,
	promotion,
	subscriptionUser,
	subscriptionCase,
	showCheckout,
	stripePublishableKey,
	getStripePublishableKey
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const plan = useSelector(planSelector, shallowEqual)
	const [ userCount, setUserCount ] = useState(0)
	const [ caseCount, setCaseCount ] = useState(0)
	const [ rawPrice, setRawPrice ] = useState(0)
	const [ totalPrice, setTotalPrice ] = useState(0)

	const onCheckout = (values) => {
		showCheckout(userCount, caseCount, totalPrice, stripePublishableKey)
	}

	useEffect(() => {
		dispatch(resetPaymentState())
		dispatch(fetchPlan())
		getStripePublishableKey()
	}, [])

	useEffect(
		() => {
			updateRawPrice()
		},
		[ userCount, caseCount ]
	)

	const updateRawPrice = () => {
		setRawPrice(price.userPrice * userCount + price.casePrice * caseCount)
	}

	const applyDiscount = (discountAmount) => {
		// setTotalPrice(rawPrice - discountAmount)
		setTotalPrice(rawPrice) // disabling discounts for now
	}

	return (
		<form data-testid="form-add-cases-modal" onSubmit={handleSubmit(onCheckout)}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{
					backgroundColor: theme.palette.secondary.main,
					padding: '4.01rem'
				}}>
				<Grid item>
					<Grid
						container
						direction='column'
						justifyContent='center'
					>
						<Grid item>
							<Typography
								data-testid="form-add-cases-title"
								variant='h1'
								style={{
									color: '#fff',
									marginBottom: '25px'
								}}
								align='left'>
								{intl.formatMessage({ id: 'app.account.addCasesUsers.title' })}
							</Typography>
							{/* <PlanPromotionText promotion={promotion} /> */}
							<Typography
								data-testid="form-add-cases-subtitle"
								variant='h3'
								style={{
									color: '#fff',
									marginBottom: '25px'
								}}
								align='left'>
								{intl.formatMessage({ id: 'app.account.addCasesUsers.subtitle' })}
							</Typography>
						</Grid>

						{/* <AddUsersRow userPrice={price && price.userPrice} subscriptionUser={subscriptionUser} onUserCountChange={c => setUserCount(c)} /> */}
						<AddCasesRow
							data-testid="form-add-cases-row"
							casePrice={price && price.casePrice}
							plan={plan}
							onCaseCountChange={(c) => setCaseCount(c)}
						/>

						<Discount
							data-testid="form-add-cases-discount"
							rawPrice={rawPrice}
							caseCount={caseCount}
							promotion={promotion}
							onApplyDiscount={applyDiscount}
						/>
						<Total data-testid="form-add-cases-total" totalPrice={totalPrice} />

						<Grid
							item
							style={{
								width: '100%'
							}}>
							<div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
								<Button
									data-testid="form-add-cases-button-submit"
									variant="contained"
									color="primary"
									type='submit'
									size='medium'
									disabled={userCount == 0 && caseCount == 0}>
									{intl.formatMessage({ id: 'app.account.addCasesUsers.submitButtonText' })}
								</Button>
							</div>
						</Grid>

						<CheckoutSummary data-testid="form-add-cases-chackout-summary" users={userCount} cases={caseCount} />
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	promotion: promotionSelector(state),
	price: priceSelector(state),
	stripePublishableKey: stripePublishableKeySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getStripePublishableKey: () => dispatch(fetchStripePublishableKey()),
	showCheckout: (userCount, caseCount, totalPrice, stripePublishableKey) =>
		dispatch(
			showModal(Checkout, {
				userCount: userCount,
				caseCount: caseCount,
				totalPrice: totalPrice,
				stripePublishableKey: stripePublishableKey
			})
		)
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'managePlan'
	}),
	withRouter
)

export default enhance(AddCasesModal)

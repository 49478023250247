import React from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Add from '@material-ui/icons/Add'
import { showModal } from '../../../redux/actions'
import AddCircles from '../AddCircles'

const AddCircleButton = ({ onAddClick, intl }) => {

	return (
		<Button
			data-testid="add-circle-button"
			variant="contained"
			color="primary"
			onClick={() => onAddClick()}
			startIcon={<Add style={{ fontSize: '18px', fontWeight: '700' }} />}
		>
			{intl.formatMessage({ id: 'app.circles.addCircleText' })}
		</Button>
	)
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onAddClick: () => dispatch(showModal(AddCircles))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl)

export default enhance(AddCircleButton)

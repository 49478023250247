import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { Field, change } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { fetchProtocolAutoComplete } from '../../../redux/actions'
import { useSelector, shallowEqual } from 'react-redux'
import { protocolAutoCompleteSuggestionSelector } from '../../../redux/selectors'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds, minimumSearchCharacters } from '../../../utilities/constants'

var debounce = require('lodash/debounce')

const useASStyles = makeStyles((theme) => ({
	root: {
		//height: 75,
		color: '#fff',
		flexGrow: 1
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1300,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'WebkitOverflowScrolling': 'touch',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
	divider: {
		height: theme.spacing(2)
	}
}))

const styles = {
	input: {
		color: '#fff',
		fontSize: '1rem',
		backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		color: '#fff'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		color: '#fff',
		marginLeft: '-2px'
	}
})

const useInputStyles = makeStyles({
	root: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	},
	focused: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: '#fff',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
})

const useFormHelperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})

export const ProtocolSelectorControl = ({
	suggestions,
	label,
	meta: { initial, form, touched, invalid, error },
	input,
	onProtocolSelected,
	noMatchText
}) => {
	const protocols = useSelector(protocolAutoCompleteSuggestionSelector, shallowEqual)
	const dispatch = useDispatch()
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	var helperStyles = useFormHelperTextStyles()
	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(false)
	const [ stateSuggestions, setSuggestions ] = useState(protocols)
	const classes = useASStyles()
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const [ state, setState ] = React.useState({
		label: ''
	})

	const handleSuggestionsFetchRequested = ({ value }) => {
		if (value && value.length > minimumSearchCharacters) {
			dispatch(fetchProtocolAutoComplete(value, noMatchText))
		}
	}

	const onSuggestionRequested = useConstant(() =>
		debounce(handleSuggestionsFetchRequested, debounceMilliseconds, { leading: true })
	)

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem data-testid="protocol-selector-control-menu-item" selected={isHighlighted} component='div'>
				<div>{suggestion.name}</div>
			</MenuItem>
		)
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps

		return (
			<TextField
				data-testid="protocol-selector-control"
				fullWidth
				autoComplete={false}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.root,
						shrink: labelStyles.root
					}
				}}
				error={touched && invalid}
				helperText={touched && error}
				classes={{
					root: inputStyles.root,
					error: inputStyles.error
				}}
				FormHelperTextProps={{ classes: { root: helperStyles.root, contained: helperStyles.root } }}
				style={styles}
				InputProps={{
					...inputProps,
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					}
				}}
				inputProps={{ autocomplete: 'dont' }}
				shrink='true'
				variant='filled'
				{...other}
			/>
		)
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (suggestion && suggestion.id > -1) {
			dispatch(change(form, input.name, suggestion.id))
			setNoMatch(false)
			setMatchSelected(true)
			if (onProtocolSelected) {
				if (suggestion.hasChildren) {
					onProtocolSelected(suggestion.id)
				} else {
					onProtocolSelected(-1)
				}
			}
		} else {
			setState({
				...state,
				value: null,
				label: ''
			})
			if (onProtocolSelected) {
				onProtocolSelected(-1)
			}
			dispatch(change(form, input.name, ''))
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: protocols,
		onSuggestionsFetchRequested: onSuggestionRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: onSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	}

	const handleChange = (event, { newValue }) => {
		if (newValue && newValue.id) {
			dispatch(change(form, input.name, newValue.id))
			setNoMatch(false)
			setMatchSelected(true)
			if (onProtocolSelected) {
				onProtocolSelected(newValue.id)
			}
		} else {
			dispatch(change(form, input.name, null))
			setNoMatch(true)
			setMatchSelected(false)
			if (onProtocolSelected) {
				onProtocolSelected(null)
			}
		}
		setState({
			...state,
			value: newValue,
			label: newValue && newValue.name ? newValue.name : newValue
		})
	}

	return (
		<div className={classes.root}>
			<Autosuggest
				 data-testid="protocol-selector-autosuggest"
				{...autosuggestProps}
				inputProps={{
					classes,
					id: input.name,
					label: label,
					value: state.label,
					onChange: handleChange,
					onBlur: (e, x) => {
						setAnchorEl(null)

						//input.onChange(e.target.value)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: '',
								label: ''
							})
							dispatch(change(form, input.name, ''))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={(options) => (
					<Popper data-testid="protocol-selector-popper" anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 1300 }}
                		placement="bottom-start"
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}
					>
						<Paper
							data-testid="protocol-selector-paper"
							square
							{...options.containerProps}
							className={classes.optionStyle}
							style={{
								maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
								width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
								marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined }}>
							{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	)
}

export default ({
	label,
	name,
	validators,
	maxLength = 255,
	noMatchText = 'No Entries Found',
	onProtocolSelected,
	...other
}) => {
	return (
		<Field
			data-testid="protocol-selector-field"
			name={name}
			component={ProtocolSelectorControl}
			label={label}
			maxLength={maxLength}
			fullWidth
			validate={validators}
			noMatchText={noMatchText}
			onProtocolSelected={onProtocolSelected}
			{...other}
		/>
	)
}

import React, { useImperativeHandle, forwardRef } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { TextField, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useConstant } from '../../utilities/useConstant'
import { search } from '../../redux/actions'
import {
	globalSearchCasesSelector,
	globalSearchCirclesSelector,
	globalSearchProtocolsSelector,
	globalSearchSurveysSelector,
	globalSearchServiceProvidersSelector,
	globalSearchServiceProviderTasksSelector,
	hasSearchCasesSelector,
	hasSearchCirclesSelector,
	hasSearchProtocolsSelector,
	hasSearchSurveysSelector,
	hasSearchServiceProvidersSelector,
    hasSearchServiceProviderTasksSelector
} from '../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import { debounceMilliseconds, minimumSearchCharacters } from '../../utilities/constants'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import SearchResult from './SearchResult'
import { Link } from 'react-router-dom'
import { isMobile } from './../../utilities/mobileDetector'
import { useWindowDimensions } from './../../utilities/useWindowDimensions'

var debounce = require('lodash/debounce')


export default forwardRef((props, ref) => {

	const dispatch = useDispatch()
	const intl = useIntl()
	const [ open, setOpen ] = React.useState(false)
	const [ hasSearch, setHasSearch ] = React.useState(false)

	const hasProtocols = useSelector(hasSearchProtocolsSelector, shallowEqual)
	const hasCircles = useSelector(hasSearchCirclesSelector, shallowEqual)
	const hasCases = useSelector(hasSearchCasesSelector, shallowEqual)
	const hasSurveys = useSelector(hasSearchSurveysSelector, shallowEqual)
	const hasServiceProviders = useSelector(hasSearchServiceProvidersSelector, shallowEqual)
	const hasServiceProviderTasks = useSelector(hasSearchServiceProviderTasksSelector, shallowEqual)

	const cases = useSelector(globalSearchCasesSelector, shallowEqual)
	const protocols = useSelector(globalSearchProtocolsSelector, shallowEqual)
	const circles = useSelector(globalSearchCirclesSelector, shallowEqual)
	const surveys = useSelector(globalSearchSurveysSelector, shallowEqual)
	const serviceProviders = useSelector(globalSearchServiceProvidersSelector, shallowEqual)
	const serviceProviderTasks = useSelector(globalSearchServiceProviderTasksSelector, shallowEqual)

	useImperativeHandle(ref, () => ({
		open() {
			dispatch(search())
			setOpen(true)
		}
	}))

	const toggleDrawer = () => (event) => {
		if (event.key === 'Tab' || event.key === 'Shift') {
			return
		}

		setOpen(!open)
	}

	const onChange = (value) => {
		if (value && value.length >= minimumSearchCharacters) {
			setHasSearch(true)
			dispatch(search(value))
		} else {
			setHasSearch(true)
		}
	}

	const OnSearchChanged = useConstant(() => debounce(onChange, debounceMilliseconds, { leading: true }))
	
	const { height, width } = useWindowDimensions();
	const sideList = (side) => (
		<div
			data-testid="global-search"
			style={{ minWidth: isMobile || width<1280 ? '100vw' : '350px' }}
			role="presentation"
			// onClick={toggleDrawer(side, false)}
			//onKeyDown={toggleDrawer()}
		>
			<Grid container direction="column" style={{ padding: '20px' }}>
				<Grid item style={{ alignSelf: 'space-between' }}>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Typography data-testid="global-search-title" variant="subtitle1">
								{intl.formatMessage({ id: 'app.general.searchResults' })}
							</Typography>{' '}
						</Grid>
						<Grid item>
							<IconButton  data-testid="global-search-button-close" aria-label="close" onClick={() => setOpen(false)}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ marginTop: '15px' }}>
					<TextField
						data-testid="global-search-input"
						variant="filled"
						onChange={(e) => OnSearchChanged(e.target.value)}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment data-testid="global-search-input-inner" position="start">
									<SearchIcon data-testid="global-search-input-icon" style={{ color: '#000' }} />
								</InputAdornment>
							)
						}}
						inputProps={{ style: { color: '#000' } }}
					/>
				</Grid>

				<SearchResult
					data-testid="search-results-cases"
					headerText={intl.formatMessage({ id: 'app.general.cases' })}
					hasSearch={hasSearch}
					hasData={hasCases}
					data={cases}
					getContent={(r) => (
						<div>
							<Link
								data-testid="global-search-cases-link"
								to={`/cases/${r.id}`}
								style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: '#000', wordBreak: 'break-all' }}
							>
								<Typography
									variant="subtitle2"
									style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'left' }} 
									onClick={toggleDrawer()}
								>
									{r.patientName}
								</Typography>
							</Link>
							<Typography variant="body2" style={{ textAlign: 'left' }}>
								{r.protocolName}
							</Typography>
						</div>
					)}
				/>

				<SearchResult
					data-testid="search-results-circles"
					headerText={intl.formatMessage({ id: 'app.general.circles' })}
					hasSearch={hasSearch}
					hasData={hasCircles}
					data={circles}
					getContent={(r) => (
						<div>
							<Link
								data-testid="search-results-circles-link"
								to={`/circles/${r.id}`}
								style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: '#000', wordBreak: 'break-all' }}
							>
								<Typography
									variant="subtitle2"
									style={{ color: 'rgba(0,0,0,0.6)' }}
									onClick={toggleDrawer()}
								>
									{r.name}
								</Typography>
							</Link>
							<Typography variant="body2">
								{r.protocolName}
							</Typography>
						</div>
					)}
				/>

                <SearchResult
					data-testid="search-results-providers"
                    headerText={intl.formatMessage({ id: 'app.general.serviceProviders' })}
                    hasSearch={hasSearch}
					hasData={hasServiceProviders}
					data={serviceProviders}
                    getContent={(r) => (
						<div style={{ cursor: 'pointer' }}>
							<Link
								data-testid="search-results-providers-link"
								to={`/serviceProvider/${r.id}`}
								style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: '#000', wordBreak: 'break-all' }}
							>
								<Typography
									variant="subtitle2"
									style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'left' }}
									onClick={toggleDrawer()}
								>
									{r.name}
								</Typography>
							</Link>
						</div>
					)}
				/>

                <SearchResult
					data-testid="search-results-tasks"
                    headerText={intl.formatMessage({ id: 'app.general.tasks' })}
                    hasSearch={hasSearch}
					hasData={hasServiceProviderTasks}
					data={serviceProviderTasks}
                    getContent={(r) => (
						<div style={{cursor: 'pointer'}}>
							<Link
								data-testid="search-results-tasks-link"
								to={`/tasks/${r.taskId}`}
								style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: '#000', wordBreak: 'break-all' }}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography
										data-testid="search-results-tasks-case"
                                        variant="subtitle2"
                                        style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'left' }}
                                        onClick={toggleDrawer()}
                                    >
                                        {`${intl.formatMessage({ id: 'app.general.caseId' })}: ${r.caseId36}`}
									</Typography>
                                    <Typography
										data-testid="search-results-tasks-task"
                                        variant="subtitle2"
                                        style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'left' }}
                                        onClick={toggleDrawer()}
                                    >
                                        {`Task ID: ${r.taskId36}`}
                                    </Typography>
								</div>
								<Typography variant="body2" style={{ textAlign: 'left' }}>
									{r.serviceProviderName}
								</Typography>
							</Link>
						</div>
					)}
                />

			</Grid>
		</div>
	)

	return (
		<div>
			<Drawer data-testid="drawer" open={open} onClose={toggleDrawer()}>
				{sideList('left')}
			</Drawer>
		</div>
	)
})

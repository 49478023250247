import React from 'react'
import { TableCell, Typography } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'

export default ({ data }) => {
	return (
		<TableRow data-testid="sponsor-circle-cases-usage-row">
			<TableCell
				data-testid="sponsor-circle-cases-usage-row-circle"
				width="60%"
				align="left">
				<Typography
					variant='subtitle1'
					data-testid="sponsor-subscriptions-usage-row-user-name"
					className="tracking-hide-content"
					style={{ wordBreak: 'break-word' }}>
					{data && data.circleName}
				</Typography>
			</TableCell>
			<TableCell
				data-testid="sponsor-circle-cases-usage-row-cases-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.casesCount}
				</Typography>
			</TableCell>
			<TableCell
				data-testid="sponsor-circle-cases-usage-row-sms-count"
				width="20%"
				align="left">
				<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
					{data && data.smsCount}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import LeftNavigation from '../components/LeftNavigation'
import Dashboard from './dashboard'
import PrivateRoute from '../routes/PrivateRoute'
import ModalContainer from '../components/ModalContainer'
import Snackbar from '../components/Snackbar'
import UserProfile from './userProfile'
import Patients from './patient'
import Circles from './circle'
import ServiceProviders from './serviceProvider'
import TaskDetails from './taskDetails'
import Cases from './cases'
import CircleDetails from './circleDetails'
import CaseDetail from './caseDetails'
import ServiceProviderDetails from './serviceProviderDetails'
import Terms from './terms'
import { ErrorBoundary } from '../components/ErrorBoundary'
import Account from './account'
import Reports from './report'
import Test from './test'
import { getNewCompletedExportedReports, pageLoadHandler } from '../redux/actions'
import ReportBuilder from './reportBuilder'
import MyTasks from './myTasks/components/MyTasks'
import Alerts from './myAlerts/components/Alerts'
import AutoLogoutAlertModal from '../components/AutoLogoutAlertModal'
import RedirectToCaseDetail from './redirect/RedirectToCaseDetail'

import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import { localeIsUpdatedSelector } from '../redux/selectors'
import { Route } from 'react-router'
import sponsorStatistics from './sponsorStatistics'
import NewCompletedExportedReportsNotificationHeader from '../components/NewCompletedExportedReportsNotificationHeader'
import ReportExportInitiatedNotificationHeader from '../components/ReportExportInitiatedNotificationHeader'



const styles = (theme) => ({
	mainContent: {
		position: 'relative',
		minWidth: '320px',
		backgroundColor: theme.palette.dark,
		overflowX: 'hidden',
		display: 'flex',
		[theme.breakpoints.up('lg')]: {
			minWidth: '1200px',
		},
		'& .navOpened': {
			minWidth: '220px',
			maxWidth: '220px',
			minHeight: '100vh',
			maxHeight: '100vh',
			backgroundColor: theme.palette.secondary.main,
			zIndex: 1,
			transition: 'all 0.2s ease',
			overflow: 'hidden',
		},
		'& .navClosed': {
			backgroundColor: theme.palette.secondary.main,
			minHeight: '100vh',
			maxHeight: '100vh',
			minWidth: '0',
			maxWidth: '0',
			width: '0',
			transition: 'all 0.2s ease',
			overflow: 'hidden',
		}
	},
	mainContentInner: {
		minHeight: '100vh',
		maxHeight: '100vh',
		overflowX: 'hidden',
		flexGrow: '100',
		minWidth: '320px',
		zIndex: '2',
		'WebkitOverflowScrolling': 'touch',
		[theme.breakpoints.down('md')]: {
			paddingTop: '45px',
		},
		'@media (max-device-width: 1279px)': {
			paddingTop: '45px',
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: '31px',			
		},
		'@media (max-device-width: 599px)': {
			paddingTop: '31px',	
		},
	},
	// Set css/scss variables colors, in the same palette as in theme.js
	'@global': {
		':root': {
			'--palette-light': theme.palette.light,
			'--palette-dark': theme.palette.dark,
			'--palette-lightCell': theme.palette.lightCell,
			'--palette-darkCell': theme.palette.darkCell,
			'--palette-blue': theme.palette.blue,
			'--palette-primary-light': theme.palette.primary.light,
			'--palette-primary-A100': theme.palette.primary.A100,
			'--palette-primary-A200': theme.palette.primary.A200,
			'--palette-primary-A300': theme.palette.primary.A300,
			'--palette-primary-main': theme.palette.primary.main,
			'--palette-primary-A500': theme.palette.primary.A500,
			'--palette-primary-A600': theme.palette.primary.A600,
			'--palette-primary-link': theme.palette.primary.link,
			'--palette-primary-A800': theme.palette.primary.A800,
			'--palette-primary-dark': theme.palette.primary.dark,
			'--palette-secondary-light': theme.palette.secondary.light,
			'--palette-secondary-A100': theme.palette.secondary.A100,
			'--palette-secondary-A200': theme.palette.secondary.A200,
			'--palette-secondary-A300': theme.palette.secondary.A300,
			'--palette-secondary-main': theme.palette.secondary.main,
			'--palette-secondary-contrast': theme.palette.secondary.contrast,
			'--palette-secondary-input': theme.palette.secondary.input,
			'--palette-secondary-inputHover': theme.palette.secondary.inputHover,
			'--palette-secondary-additional': theme.palette.secondary.additional,
			'--palette-secondary-A600': theme.palette.secondary.A600,
			'--palette-secondary-A700': theme.palette.secondary.A700,
			'--palette-secondary-A800': theme.palette.secondary.A800,
			'--palette-secondary-dark': theme.palette.secondary.dark,
			'--palette-text-primary': theme.palette.text.primary,
			'--palette-text-secondary': theme.palette.text.secondary,
			'--palette-text-disabled': theme.palette.text.disabled,
			'--palette-text-hint': theme.palette.text.hint,
			'--palette-background-default': theme.palette.background.default,
			'--palette-action-disabledBackground': theme.palette.action.disabledBackground,
			'--palette-action-disabled': theme.palette.action.disabled,
			'--palette-success-main': theme.palette.success.main,
			'--palette-error-main': theme.palette.error.main,
			'--palette-error-light': theme.palette.error.light,
			'--palette-warning-main': theme.palette.warning.main,
			'--palette-info-main': theme.palette.info.main,

		}
	}
})

const Root = ({ classes }) => {

    const dispatch = useDispatch()
	const localeIsUpdated = useSelector(localeIsUpdatedSelector, shallowEqual)

    useEffect(
        () => {
            dispatch(pageLoadHandler());
        },
        []
    )

	useEffect(
		() => {
			dispatch(getNewCompletedExportedReports())

			let timer = setInterval(() => dispatch(getNewCompletedExportedReports()), 20000)

			return () => {
				clearInterval(timer)
			}
		},
		[]
	)

	return (
		localeIsUpdated && <div data-testid="main">
			<div data-testid="main-content" className={`${classes.mainContent} main-content`}>
				<LeftNavigation />
				<Grid data-testid="main-content-inner" item className={`${classes.mainContentInner} mainBlock`} >
					<AutoLogoutAlertModal/>
					<ReportExportInitiatedNotificationHeader/>
					<NewCompletedExportedReportsNotificationHeader/>
					<ErrorBoundary>
						<PrivateRoute exact={true} path="/dashboard/:modTrigger" component={Dashboard} />
						<PrivateRoute exact={true} path="/dashboard/" component={Dashboard} />
						<PrivateRoute exact={true} path="/tasks/" requireSponsored={true} component={MyTasks} />
						<PrivateRoute exact={true} path="/myalerts/" requireSponsored={true} component={Alerts} />
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/userprofile/:userId"
							component={UserProfile}
						/>
						<PrivateRoute
							exact={true}

							path="/userprofile/"
							component={UserProfile}
						/>
						<PrivateRoute exact={true} requireSponsored={true} path="/patients/" component={Patients} />
						<PrivateRoute exact={true} requireSponsored={true} path="/circles/" component={Circles} />
						<PrivateRoute exact={true} requireSponsored={true} path="/serviceProviders/" component={ServiceProviders} />
						<PrivateRoute exact={true} requireSponsored={true} path="/tasks/:taskId" component={TaskDetails} />
						<PrivateRoute exact={true} requireSponsored={true} path="/tasks/:taskId/status/:status" component={TaskDetails} />
						<PrivateRoute exact={true} requireSponsored={true} path="/cases/" component={Cases} />
						{/* Bug fix for task I30-3628. This is not an obvious error when the address contains only caseId parameter.  */}
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/cases/:caseId"
							component={RedirectToCaseDetail}
						/>
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/cases/:caseId/survey/:surveyId/instance/:instanceId/delegate/:delegate/status/:status/:mode"
							component={CaseDetail}
						/>
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/cases/:caseId/survey/:surveyId/instance/:instanceId/delegate/:delegate/status/:status"
							component={CaseDetail}
						/>
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/circles/:circleId"
							component={CircleDetails}
						/>
						<PrivateRoute
							exact={true}
							requireSponsored={true}
							path="/serviceProvider/:serviceProviderId"
							component={ServiceProviderDetails}
						/>
						<PrivateRoute exact={true} path="/terms" component={Terms} />
						<PrivateRoute exact={true} path="/account" component={Account} />
						<PrivateRoute exact={true} path="/reports" requireSponsored={true} component={Reports} />
						<PrivateRoute exact={true} path="/reports/history" requireSponsored={true} component={Reports} key={Math.random()}/>
						<PrivateRoute exact={true} path="/reports/:reportId/:chartId/details" requireSponsored={true} component={Reports} />
						<PrivateRoute exact={true} path="/report/" requireSponsored={true} component={ReportBuilder} />
						<PrivateRoute exact={true} path="/report/:reportId/:chartId" requireSponsored={true} component={ReportBuilder} />
						{/* <PrivateRoute exact={true} path="/t" component={Test} /> */}
						<PrivateRoute exact={true} path="/" component={Dashboard} />
					</ErrorBoundary>
				</Grid>
			</div>

			<ModalContainer />
			<Snackbar />
		</div>
	)
}

const enhance = compose(withStyles(styles))

export default enhance(Root)

import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrencyCode } from '../../utilities/CurrencyCodes'
import locale2 from 'locale2'

const AddUsersRow = ({ intl, userPrice, subscriptionUser, onUserCountChange, priceLabel }) => {
	const currency = 'USD' //getCurrencyCode(locale2);
	const theme = useTheme()
	const color_gray = 'rgba(255,255,255,0.6)'
	const useStyles = makeStyles({
		inputColor: {
			color: theme.palette.light
		},
		textField: {
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			}
		}
	})
	const classes = useStyles()

	const [ usersInputValue, setUsersInputValue ] = useState(0)

	const getUsersPriceForDisplay = () => {
		if (userPrice && usersInputValue) {
			return (
				intl.formatNumber(usersInputValue * userPrice, {
					style: 'currency',
					currency: currency,
					currencyDisplay: 'symbol'
				}) + ' USD'
			)
		} else {
			return (
				intl.formatNumber(0, {
					style: 'currency',
					currency: currency,
					currencyDisplay: 'symbol'
				}) + ' USD'
			)
		}
	}
	// only allow numerical inputs
	const userValueOnChange = (e) => {
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			setUsersInputValue(e.target.value)
			onUserCountChange(e.target.value)
		} else {
			return 0
			onUserCountChange(0)
		}
	}

	return (
		<Grid data-testid="add-users-row" container spacing={3}
			style={{ borderBottom: '0.3px solid', borderColor: color_gray }}
		>
			<Grid item xs={12} sm={3}>
				<Typography data-testid="add-users-row-title" variant='h3' style={{ color: theme.palette.light }}>
					{intl.formatMessage({ id: 'app.general.users' })}
				</Typography>
				<Typography data-testid="add-users-row-per-year" variant="body2" style={{ color: theme.palette.light }}>
					{intl.formatNumber(userPrice, {
						style: 'currency',
						currency: currency,
						currencyDisplay: 'symbol'
					})}{' '}
					USD
					{intl.formatMessage({ id: 'app.account.addCasesUsers.perYear' })}
				</Typography>
			</Grid>

			<Grid item xs={12} sm={3}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography data-testid="add-users-row-subtitle" variant="subtitle1" style={{ color: color_gray }}>
						{subscriptionUser && subscriptionUser.allocatedUsers}
					</Typography>
					<Typography data-testid="add-users-row-active" variant="body2" style={{ color: color_gray, marginTop: '-2px', paddingLeft: '10px' }}>
						{intl.formatMessage({ id: 'app.account.addCasesUsers.activeUsers' })}
					</Typography>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography data-testid="add-users-row-available" variant="subtitle1" style={{ color: color_gray }}>
						{subscriptionUser && subscriptionUser.availableUsers}
					</Typography>
					<Typography data-testid="add-users-row-available-text" variant="body2" style={{ color: color_gray, marginTop: '-2px', paddingLeft: '10px' }}>
						{intl.formatMessage({ id: 'app.account.addCasesUsers.availableUsers' })}
					</Typography>
				</div>
			</Grid>

			<Grid item xs={12} sm={3}>
				<TextField
					data-testid="add-users-row-textfield"
					style={{ width: '72px' }}
					variant="outlined"
					className={classes.textField}
					InputProps={{
						className: classes.inputColor
					}}
					onChange={userValueOnChange}
					value={usersInputValue}
				/>

				<Typography data-testid="add-users-row-text" variant="body2" style={{ color: color_gray, paddingLeft: '10px' }}>
					{intl.formatMessage({ id: 'app.account.addCasesUsers.addUsers' })}
				</Typography>
			</Grid>

			<Grid item xs={12} sm={3}>
				<Typography data-testid="add-users-row-price" variant='h3' style={{ color: theme.palette.primary.main }}>
					{getUsersPriceForDisplay()}
				</Typography>
				<Typography data-testid="add-users-row-cost" variant="body2" style={{ color: theme.palette.primary.main }}>
					{intl.formatMessage({ id: 'app.account.addCasesUsers.userCost' })}
				</Typography>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addUsersRow'
	}),
	withRouter
)

export default enhance(AddUsersRow)

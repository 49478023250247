import React, { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { sendAndAcceptCircleInvitation } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import history from '../../utilities/history'
import * as cookies from '../../utilities/cookies'
import queryString from 'query-string'

export default ({ location }) => {
	const dispatch = useDispatch()

	useEffect(
		() => {
			let params = queryString.parse(location.search)
			if (params.data) {
				let user = cookies.get('user')
				if (!user) {
					history.push(`/auth/register?autoInvite=${params.data}`)
				} else {
					dispatch(sendAndAcceptCircleInvitation(params.data))
				}
			}
		},
		[]
	)

	return (
		<div style={{ height: '100vh', backgroundColor: '#fff' }}><CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} /></div>
	)
}

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'

const AlertDelegateCell = ({ alert, intl }) => (
	<div data-testid="myalerts-outlier-alert-delegate">
		{alert &&
		alert.firstName &&
		alert.lastName && (
			<Typography
				data-testid="myalerts-outlier-alert-delegate-contact"
				variant="subtitle1"
				style={{
					textAlign: 'left',
					fontWeight: '700',
					wordBreak: 'break-all',
					fontSize: '17px',
					lineHeight: '24px',
					letterSpacing: '0.16px'
				}}
			>
				{`${alert.firstName} ${alert.middleName ? alert.middleName + " " : ""}${alert.lastName}`}
			</Typography>
		)}
		{alert &&
		alert.patientEmail && (
			<Typography
				data-testid="myalerts-outlier-alert-delegate-email"
				variant="subtitle2"
				style={{ wordBreak: 'break-all' }}
			>
				{`${alert.patientEmail}`}
			</Typography>
		)}
		{alert &&
		!alert.patientEmail &&
		alert.patientId36 && (
			<Typography
				data-testid="outlier-alert-delegate-email"
				variant="subtitle2"
			>
				{`${intl.formatMessage({ id: 'app.general.patientId' })}: ${alert.patientId36}`}
			</Typography>
		)}
	</div>
)

const enhance = injectIntl

export default enhance(AlertDelegateCell)

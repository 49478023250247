import React, { useEffect, useState, Fragment, lazy, Suspense } from 'react'
import { compose } from 'recompose'
import '../../styles/index.scss'
import { connect } from 'react-redux'
//import NewUserEntryForm from '../registration/NewUserEntryForm'
import * as cookies from '../../utilities/cookies'
import InvitationConfirmation from '../registration/InvitationConfirmation'
import PatientResponse from './components/PatientResponse'
import dashboardBackground from '../../assets/dashboard/arrows-dashboard.png'
import StripeStatusEnum from '../../utilities/StripeStatusEnum'
import UpdatePaymentMethod from '../../components/StripeCheckout/UpdatePaymentMethod'
import {
	showModal,
	getFullUserById,
	ignoreInitialUserPathologiesAndProms,
	fetchStripePublishableKey,
	updateInviteUserMember,
    checkSubscriptionValidity
} from '../../redux/actions'
import { fullUserSelector, stripePublishableKeySelector, subscriptionValiditySelector } from '../../redux/selectors'
import componentLoader from '../../utilities/componentLoader'

const PersonalPlan = lazy(() => componentLoader(() => import('./components/PersonalPlan/PersonalPlan')))
const Alerts = lazy(() => componentLoader(() => import('./components/Alerts')))
const Tasks = lazy(() => componentLoader(() => import('./components/Tasks')))
const Stats = lazy(() => componentLoader(() => import('./components/Stats')))

const Dashboard = ({
	match,
	//showEntryForm,
	loadUser,
	fullUser,
	declineEntryForm,
	autoAccept,
	alerts,
	loadAlerts,
	showUpdatePaymentMethod,
	getStripePublishableKey,
	stripePublishableKey,
	subscriptionValidity,
    getSubscriptionValidity
}) => {
	const [ user ] = useState(cookies.get('user'))
	const [ anchorOptionsEl, setAnchorOptionsEl ] = useState(undefined)

	useEffect(() => { getSubscriptionValidity() }, [])

	useEffect(() => {
		if (user) loadUser()
		if (user && user.isNew && user.isInvited && user.invites) {
			autoAccept(1)
		} //showInvite(onCloseInvite, user.invites)
	}, [])

	useEffect(
		() => {
			if (fullUser && fullUser.stripeUser) {
				if (fullUser.stripeUser.stripeStatus === StripeStatusEnum.FailedPaymentMethod) {
					getStripePublishableKey()
				}
			}
		},
		[ fullUser ]
	)

	useEffect(
		() => {
			if (stripePublishableKey && fullUser && fullUser.stripeUser) {
				if (fullUser.stripeUser.stripeStatus === StripeStatusEnum.FailedPaymentMethod) {
					// user has failed payment method, lock down site and require new payment method
					showUpdatePaymentMethod(stripePublishableKey)
				}
			}
		},
		[ stripePublishableKey ]
	)

	const onCloseCircleInvite = () => {
		setAnchorOptionsEl(undefined)
	}
	const onCloseInvite = () => {
		setAnchorOptionsEl(undefined)
	}

	const onCloseEntryForm = () => {
		declineEntryForm()
	}

	return (
		<div data-testid="dashboard" style={{ position: 'relative' }}>
			<div className="dashboard-background"
				style={{
					position: 'absolute',
					top: '0',
					left: '0',
					width: '100%',
					height: '1100px',
					backgroundImage: `url(${dashboardBackground})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
				}}
			></div>
			<div style={{ position: 'relative' }}>
				<Suspense fallback={<div />}>
					<section>
						<PersonalPlan user={fullUser} />
						<Tasks increased={!subscriptionValidity && user.isServiceProviderMember} />
						{(subscriptionValidity || !user.isServiceProviderMember) && <Alerts />}
						{(subscriptionValidity || !user.isServiceProviderMember) && <Stats />}
						{
                            //(subscriptionValidity || !user.isServiceProviderMember) && <PatientResponse />
                        }
					</section>
				</Suspense>
			</div>

		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	fullUser: fullUserSelector(state),
	stripePublishableKey: stripePublishableKeySelector(state),
    subscriptionValidity: subscriptionValiditySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	//showEntryForm: (onClose) => dispatch(showModal(NewUserEntryForm, { onClose: onClose })),
	declineEntryForm: () => dispatch(ignoreInitialUserPathologiesAndProms()),
	loadUser: () => dispatch(getFullUserById()),
	autoAccept: () => dispatch(updateInviteUserMember(1)),
    getSubscriptionValidity: () => dispatch(checkSubscriptionValidity()),
	// showInvite: (onClose, userInvites) =>
	// 	dispatch(
	// 		showModal(
	// 			InvitationConfirmation,
	// 			{
	// 				onClose: onClose,
	// 				userInvites: userInvites
	// 			},
	// 			false
	// 		)
	// 	),
	showUpdatePaymentMethod: (stripePublishableKey) =>
		dispatch(showModal(UpdatePaymentMethod, { stripePublishableKey: stripePublishableKey }, false)),
	getStripePublishableKey: () => dispatch(fetchStripePublishableKey())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(Dashboard)

import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useDispatch } from 'react-redux'
import { fetchTaskEmail, showModal } from '../../../../redux/actions'
import SurveyAlertInfoModal from './SurveyAlertInfoModal'
import Button from '@material-ui/core/Button'

export default ({ task, color }) => {
	const dispatch = useDispatch()

	const onClickHandler = () => {
		if(task.patientEmail){
			dispatch(fetchTaskEmail(task.taskId))
		}
		
		dispatch(showModal(SurveyAlertInfoModal, {
			task: task,
			modalContainerStyle : { maxWidth: 'sm' },
		}))
	}

	return (
		<Button data-testid="mytasks-survey-contact-cell" variant="text" className="button-icon">
			{task && task.canSeePatientData && <InfoOutlinedIcon className="button-icon-svg" onClick={onClickHandler} />}
		</Button>
	)
}

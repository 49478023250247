import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default ({ circle }) => (
	<Grid container data-testid="sponsored-circles-case-cell">
		<Grid item>
			<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
				{circle && circle.availableCases}
			</Typography>
		</Grid>
	</Grid>
)

export const fetchStatistics = (token) => {
	var url = `/api/sponsor/statistics/${token}`

	return fetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(statistics) {
			return statistics
		})
}

import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import AddCircleButton from '../../circle/components/AddCircleButton'
import CircleTable from './CircleTable'
import CircleEmpty from './CircleEmpty'
import LabelPaper from '../../../components/LabelPaper'
import { getUser } from '../../../utilities/userUtilities'
import {
	fetchCircles,
	setCircleFilter,
	getCurrentUserDetails,
	fetchSubscription,
	fetchSubscriptionUsersCases,
	getSubscriptionUsersCases
} from '../../../redux/actions'
import {
	subscriptionCaseSelector,
	subscriptionUserSelector,
	circlesFilterSelector,
	filteredCirclesSelector,
	circlesSelector
} from '../../../redux/selectors'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import Filter from './Filter'
import SearchBar from './SearchBar'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36,48,65)'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
}

const Circles = ({
	circles,
	filters,
	setFiltered,
	loadCircles,
	loadCirclesFilter,
	classes,
	fetchUser,
	intl,
	subscriptionUser,
	getSubscription,
	getSubscriptionUsersCases,
	filterSelected
}) => {
	const [ filter, setFilter ] = useState('ALL')
	const [ id, setId ] = useState()
	const [ user, setUser ] = useState(getUser())

	return (
		<div data-testid="circle-container">
			{!circles || circles.length < 1 ? (
				<CompositeLabelPaper
					data-testid="circle-container-no-circles"
					label={intl.formatMessage({ id: 'app.circle.all' })}
					classes={classes.gradient}
					Component={CircleEmpty}
					Filter={Filter}
					filters={filters}
					filterSelected={filterSelected}
					filter={filter}
				/>
			) : (
				<CompositeLabelPaper
					data-testid="circle-container-my-circles"
					label={intl.formatMessage({
						id: 'app.circle.myCircles'
					})}
					Component={CircleTable}
					user={user}
					data={circles}
					subscriptionUser={subscriptionUser}
					Filter={Filter}
					filter={filter}
					filters={filters}
					filterSelected={filterSelected}
				/>
			)}
		</div>
	)
}

const enhance = compose(withRouter, injectIntl, withStyles(styles))

export default enhance(Circles)

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default ({ circle }) => (
	<Grid data-testid="sponsored-circles-used-cases-cell" container>
		<Grid item>
			<Typography style={{ fontSize: '17px', lineHeight: '22px', letterSpacing: '0.51', color: '#011020' }}>
				{circle && circle.usedCaseCount}
			</Typography>
		</Grid>
	</Grid>
)

import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { useIntl } from 'react-intl'

const getResultsText = (data, intl) => {
	if (data && data.length > 0) {
		if (data.length === 1) {
			return intl.formatMessage({ id: 'app.search.singleResult' })
		} else {
			return intl.formatMessage({ id: 'app.search.manyResult' }).replace('%%ResultCount%%', data.length)
		}
	} else {
		return ''
	}
}

export default ({ hasData, data, headerText, getContent, hasSearch }) => {
	const intl = useIntl()

	return (
		<div>
			<div>
				{hasSearch &&
				hasData && (
					<Grid
						data-testid="search-results-has-data"
						item
						style={{
							alignSelf: 'center',
							marginTop: '15px',
							width: '100%',
							borderBottom: '2px solid rgba(0,0,0,0.3)'
						}}>
						<Typography data-testid="results-has-data-title" variant='h4'>
							{headerText}{' '}
							<Typography id='resultHeaderText' variant='caption'>
								{getResultsText(data, intl)}
							</Typography>
						</Typography>
					</Grid>
				)}
				<div>
					{hasSearch &&
					!hasData && (
						<div data-testid="search-results-no-data">
							<Grid
								item
								style={{
									alignSelf: 'center',
									marginTop: '15px',
									width: '100%',
									borderBottom: '2px solid rgba(0,0,0,0.3)'
								}}>
								<Typography variant='h4' data-testid="results-no-data-title "> {headerText} </Typography>
							</Grid>
							<Grid
								item
								style={{
									alignSelf: 'center',
									marginTop: '15px',
									width: '100%'
								}}>
								<Typography data-testid="results-no-data-text" variant='body2'>
									{intl.formatMessage({ id: 'app.general.noMatchesFound' })}
								</Typography>
							</Grid>
						</div>
					)}
				</div>

				<Table>
					<TableBody>
						{hasData &&
							data &&
							data.length > 0 &&
							data.map((r) => {
								return (
									<TableRow style={{ borderBottom: '1px solid rgba(0,0,0,0.3)' }}>
										<TableCell style={{ padding: '16px 0' }}>{getContent(r)}</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</div>
		</div>
	)
}

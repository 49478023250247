import cookie from 'react-cookies'
 

export const get = (name) => {
	return cookie.load(name)
}

export const set = (name, value) => {
	cookie.save(name, value)
}

export const remove = (name) => {
	cookie.remove(name)
}
import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import FormTextField from '../../components/FormTextField'
import { connect } from 'react-redux'
import { fetchJurisdictionsByLocale, getUserRegistrationData, registerUser, setIntlLocale } from '../../redux/actions'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { required, passwordMatch, passwordStrength, email } from '../../utilities/validators'
import translations from '../../utilities/languages/locales'
import { injectIntl } from 'react-intl'
import MobileDetect from 'mobile-detect'
import queryString from 'query-string'
import FormCheckBox from './components/FormCheckbox'
import Container from '@material-ui/core/Container'
import { isMobile } from './../../utilities/mobileDetector'
import JurisdictionSelector from '../../components/JurisdictionSelector'
import { isRegisteringSelector, jurisdictionsSelector, userRegistrationDataSelector } from '../../redux/selectors'
import locale2 from 'locale2'
import { CircularProgress } from '@material-ui/core'
import FormPassword from '../../components/FormPassword'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('password')
const selector = formValueSelector('registration')
const Registration = ({
	classes,
	history,
	login,
	register,
	handleSubmit,
	createFailed,
	intl,
	location,
	setEmail,
	setRegion,
	setLanguage,
	setLocale,
	isTermsAccepted,
	loadJurisdictions,
	jurisdictions,
	match,
	getUserRegistrationData,
	isSaving,
	userData,
	setFirstName,
	setLastName
}) => {
	const theme = useTheme()

	const [ isEmailPrepopulated, setIsEmailPrepopulated ] = useState(false)
	const [ isVerified, setIsVerified ] = useState(false)
	const [ isRegionPrepopulated, setIsRegionPrepopulated ] = useState(false)
	const [ loginLink, setLoginLink ] = useState('/auth/login')
	const [ isAllowed, setIsAllowed ] = useState(false)

	const onSubmitForm = (values) => {
		let params = queryString.parse(location.search)
		if (params && params.autoInvite) {
			values.autoInvite = params.autoInvite
		}
		if (params && params.token) {
			values.verificationToken = params.token
		}
		
		values.timeZoneOffset = -(new Date().getTimezoneOffset() / 60)
		register(values, isEmailPrepopulated && isVerified)
	}

	useEffect(() => {
        let params = queryString.parse(location.search)

		if (params && params.email) {
			setEmail(params.email)
			setIsEmailPrepopulated(true)
			getUserRegistrationData(params.email)
		}

		if (params && params.token) {
			setIsVerified(true)
			setIsAllowed(true)
		}

		if (params && params.region) {
			setRegion(params.region)
			setIsRegionPrepopulated(true)
		}

		if (params && params.autoInvite) {
			setLoginLink(`/auth/login?returnUrl=/circleinvitation?data=${params.autoInvite}`)
			setIsAllowed(true)
		}

        let locale = intl.locale;

		if (locale2) {
			locale = locale2.substring(0, 5)
			if (locale !== 'en-GB') {
				locale = locale2.substring(0, 2)
			}
		}

        if (!translations[locale]) {
        	locale = 'en'
    	}

		setLanguage(params && params.language ? params.language : locale)

        //setLocale(params.language)

        loadJurisdictions(locale)

	}, [])

	useEffect(() => {
        if(userData) {
			setLocale(userData.languageAbbreviation)
			setFirstName(userData.firstName)
			setLastName(userData.lastName)
		}

	}, [userData])

	return (
		isAllowed &&
		<form data-testid="form-registration" onSubmit={handleSubmit(onSubmitForm)} style={{ width: '100%', margin: '15px' }}>
			<input id="language" name="language" type="hidden" />
			<Container maxWidth="sm" >
				<Grid container direction="column" alignItems="center" justifyContent="center"
					spacing={isMobile ? 0 : 10}
					style={{ backgroundColor: theme.palette.secondary.main, marginTop: '0', marginBottom: '0' }}
				>
					<Grid item xs={isMobile ? 9 : 11} style={{ width: '100%', paddingTop: '30px', paddingBottom: '30px' }}>
						<Grid item>
							<Typography
								data-testid="form-registration-title"
								variant='h1'
								style={{
									color: '#fff',
									textAlign: 'center',
									marginBottom: '20px',
									fontSize: isMobile ? '21px' : '53px'
								}}>
								{intl.formatMessage({ id: 'app.registration.title' })}
							</Typography>
						</Grid>

						<Grid item style={{ width: '100%' }}>
							{createFailed && (
								<div style={{ backgroundColor: '#b00020', borderRadius: '3px' }}>
									<Typography
										data-testid="form-registration-error-message"
										variant="body2"
										style={{
											color: '#fff',
											textAlign: 'left',
											fontWeight: '400',
											margin: '0 0 40px'
										}}
									>
										{intl.formatMessage({ id: 'app.errorMessages.2' })}
									</Typography>
								</div>
							)}
						</Grid>
						<Grid item style={{ width: '100%', minHeight: '90px' }}>
							<FormTextField
								data-testid="form-registration-first-name"
								label={intl.formatMessage({ id: 'app.registration.firstName' })}
								fullWidth
								type="text"
								name="firstName"
								variant="filled"
								maxLength={255}
								validators={[requiredValidator]}
							/>
						</Grid>
						<Grid item style={{ width: '100%', minHeight: '90px' }}>
							<FormTextField
								data-testid="form-registration-last-name"
								label={intl.formatMessage({ id: 'app.registration.lastName' })}
								fullWidth
								type="text"
								name="lastName"
								variant="filled"
								maxLength={255}
								validators={[requiredValidator]}
							/>
						</Grid>
						<Grid item style={{ width: '100%', minHeight: '90px' }}>
							<FormTextField
								data-testid="form-registration-email"
								label={intl.formatMessage({ id: 'app.registration.email' })}
								fullWidth
								type="email"
								name="email"
								variant="filled"
								disabled={isEmailPrepopulated}
								maxLength={255}
								validators={[requiredValidator, email]}
							/>
						</Grid>
						{!isRegionPrepopulated && <Grid item style={{ width: '100%', minHeight: '90px' }}>
							<JurisdictionSelector
								data-testid="form-registration-jurisdiction"
								label={intl.formatMessage({ id: 'app.general.jurisdictionLabel' })}
								fullWidth
								data={jurisdictions}
								name="jurisdictionId"
								variant="filled"
								validators={[requiredValidator]}
							/>
						</Grid>}
						<Grid item style={{ width: '100%' }}>
							<Typography
								data-testid="form-registration-help-text"
								variant="caption" style={{ color: '#fff' }}>
								{intl.formatMessage({ id: 'app.registration.passwordHelpText' })}
							</Typography>
							<div style={{ minHeight: '90px' }}>
								<FormPassword
									data-testid="form-registration-password"
									label={intl.formatMessage({ id: 'app.registration.password' })}
									fullWidth
									name="password"
									variant="filled"
									maxLength={99}
									validators={[requiredValidator, passwordStrengthValidator]}
								/>
							</div>
						</Grid>
						<Grid item style={{ width: '100%', minHeight: '90px' }}>
							<FormPassword
								data-testid="form-registration-reentee"
								label={intl.formatMessage({ id: 'app.registration.confirmPassword' })}
								fullWidth
								name="verifyPassword"
								variant="filled"
								maxLength={99}
								validators={[requiredValidator, passwordMatchValidator]}
							/>
						</Grid>
						<Grid item style={{ width: '100%', paddingBottom: '2rem' }}>
							<FormCheckBox
								data-testid="form-registration-terms"
								style={{ color: '#fff' }}
								name="terms"
								validators={[requiredValidator]}
								label={
									<Typography variant="body2">
										<a
											href="https://www.clinician.rgnmed.com/support-post/incytes-tm-license-agreement"
											target="_blank"
											style={{ color: '#fff', fontWeight: '700', textDecoration: 'none' }}
										>
											{intl.formatMessage({ id: 'app.terms.title' })}
										</a>
									</Typography>
								}
							/>
						</Grid>
						<Grid item style={{ width: '100%' }}>
							<Grid container direction="column" alignItems="center" justifyContent="center" style={{}}>
								{isSaving &&
									<Grid item style={{ textAlign: 'center' }}>
										<CircularProgress />
									</Grid>
								}
								{!isSaving && <Button
									data-testid="form-registration-button-submit"
									variant="contained"
									color="primary"
									style={{
										marginBottom: '2.5rem',
									}}
									disabled={!isTermsAccepted}
									fullWidth
									type="submit"
									size="large"
								>
									{intl.formatMessage({ id: 'app.registration.next' })}
								</Button>}
								<Button
									data-testid="form-registration-button-back"
									component={Link}
									to={loginLink}
									color="secondary"
									variant="text"
									size="small"
								>
									{intl.formatMessage({ id: 'app.registration.backLinkText' })}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isTermsAccepted: selector(state, 'terms'),
	jurisdictions: jurisdictionsSelector(state),
	userData: userRegistrationDataSelector(state),
	isSaving: isRegisteringSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setEmail: (email) => dispatch(change('registration', 'email', email)),
	setRegion: (region) => dispatch(change('registration', 'jurisdictionId', region)),
	setLanguage: (language) => dispatch(change('registration', 'language', language)),
	setFirstName: (value) => dispatch(change('registration', 'firstName', value)),
	setLastName: (value) => dispatch(change('registration', 'lastName', value)),
	setLocale: (locale) => dispatch(setIntlLocale(locale)),
	register: (values, emailVerified) => dispatch(registerUser(values, emailVerified)),
	loadJurisdictions: (locale) => dispatch(fetchJurisdictionsByLocale(locale)),
	getUserRegistrationData: (email) => dispatch(getUserRegistrationData(email))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'registration'
	}),
	withRouter,
	injectIntl,
	withStyles(styles)
)

export default enhance(Registration)

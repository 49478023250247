import React from 'react'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import DistributeValue from "./DistributeValue"


const DistributeResourcesAssignation = ({ intl, circle, subscriptionUser, subscriptionCase }) => {
    return (
        <Grid data-testid="distribute-resources-assignation" container direction="column" spacing={2}>
            <Grid item style={{borderBottom: '1px solid #1e2937' }}>
                {subscriptionCase &&
                (<DistributeValue
                    data-testid="distribute-value-cases"
                    label={intl.formatMessage({ id: 'app.general.cases' })}
                    entityType={intl.formatMessage({ id: 'app.general.cases' })}
                    available={subscriptionCase.availableCases}
                    current={subscriptionCase.allocatedCases}
                    name="numberCases"
                />)}
            </Grid>
            <Grid item style={{borderBottom: '1px solid #1e2937' }}>
                {subscriptionUser &&
                (<DistributeValue
                    data-testid="distribute-value-users"
                    label={intl.formatMessage({ id: 'app.general.users' })}
                    entityType={intl.formatMessage({ id: 'app.general.users' })}
                    available={subscriptionUser.availableUsers}
                    current={subscriptionUser.allocatedUser}
                    name="numberUsers"
                />)}
            </Grid>
        </Grid>
    )
}

const enhance = compose(injectIntl)

export default enhance(DistributeResourcesAssignation)

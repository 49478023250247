import React, { useEffect } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { getUser } from '../utilities/userUtilities'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { subscriptionValiditySelector } from '../redux/selectors';

const PrivateRoute = ({ component: Component, location, requireSponsored, subscriptionValidity, ...rest }) => {
	let user = getUser();
	let isServiceProviderPath = location.pathname.search(/^\/(account|userprofile|serviceProvider|serviceProviders|tasks|(cases\/\d+\/survey\/\d+\/instance\/\d+\/delegate\/\d+\/status)|(tasks\/\d+\/status)|mytasks|dashboard)(\/\d+)?$/i) > -1

	return (
		<Route
			{...rest}
			render={(props) =>
				user ? (
					(
						(requireSponsored === false || !requireSponsored) ?
							<Component {...props} {...rest} /> :
							(subscriptionValidity === false
								&& !(user.isServiceProviderMember && isServiceProviderPath)) ?
								// route requires a valid subscription but the user doesn't have one
								<Redirect to={{ pathname: `/dashboard` }} /> :
								<Component {...props} {...rest} />
					)
				) : (
					// no user, redirect to login
					<Redirect
						to={{
							pathname: `/auth/login`,
							search: `?returnUrl=${location.pathname}`
						}}
					/>
				)}
		/>
	)
}

const mapStateToProps = (state, ownProps) => ({
	subscriptionValidity: subscriptionValiditySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)

export default enhance(PrivateRoute)
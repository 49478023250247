import React, { useEffect } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import AddMemberButton from '../components/AddMemberButton'
import LabelPaper from '../../../components/LabelPaper'
import MemberEmpty from '../components/MemberEmpty'
import Member from './Member'
import { userInvitationsSelector, canAddTeamMemberButtonSelector, teamMembersSelector } from '../../../redux/selectors'
import { getTeamMembers } from '../../../redux/actions'
import InfoIcon from '@material-ui/icons/Info'

const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const TeamMembers = ({ user, fetchInvites, members, showAddMemberButton, classes, intl }) => {
	useEffect(() => {
		fetchInvites()
	}, [])

	return (
		<div data-testid="team-members" style={{ position: 'relative' }}>
			<div style={{ padding: '0' }}>
				<Grid container direction="column" style={{ color: '#000' }}>
					<Grid item style={{ width: '100%' }}>
						<Grid container justifyContent="center" style={{ maxHeight: '10px' }}>
							<Grid item style={{ width: '100%' }}>								
								<Grid
									data-testid="team-members-button-wrapper"
									container
									style={{ position: 'absolute', top: '35px', right: '0', paddingRight: '10px' }}>
									<Grid item xs={12} style={{ textAlign: 'right'}}>
										{showAddMemberButton && <AddMemberButton />}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						{members && members.length === 0 ? (
							<LabelPaper
								data-testid="team-members-empty"
								infoAlert={intl.formatMessage({ id: 'app.userProfile.teamMembersInfo' })}
								Component={MemberEmpty}
								label={intl.formatMessage({ id: 'app.userProfile.teamMembers' })}
								classes={classes.gradient}
							/>
						) : (
							<LabelPaper
								data-testid="team-members-items"
								infoAlert={intl.formatMessage({ id: 'app.userProfile.teamMembersInfo' })}
								label={intl.formatMessage({ id: 'app.userProfile.teamMembers' })}
								Component={Member}
								data={members}
								user={user}
							/>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	members: teamMembersSelector(state),
	showAddMemberButton: canAddTeamMemberButtonSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchInvites: () => dispatch(getTeamMembers())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(TeamMembers)

import React from 'react'
import Paper from '@material-ui/core/Paper'
import * as UI from '../utilities/UIConstants'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import { palette } from '@material-ui/system';

const CompositeLabelPaper = ({ Component, SearchComponent, label, Filter, classes, minHeight = 200, ...other }) => (
	<div>
		<Paper
			data-testid="composite-label-paper-wrapper"
			elevation={UI.PAPER_ELEVATION} square={true} style={{ minHeight: minHeight }} className={classes}>
			{label && (

					<Box bgcolor="secondary.main"
						data-testid="composite-label-paper"
						square={true}
						style={{
							minWidth: '200px',
							marginLeft: '32px', marginRight: '32px',
							transform: 'translateY(-50%)',
							display: 'table',
							minHeight: '48px',
							marginBottom: '-24px'
						}}
					>
						{Filter ? (
							<Filter data-testid="composite-label-paper-filter" />
						) : (
							<Typography
								data-testid="composite-label-paper-title"
								style={{
									padding: '14px 24px',
									textAlign: 'center',
									color: palette.light ? palette.light : 'white',
									fontSize: '20px',
									lineHeight: '1',
									letterSpacing: '0.25px',
									fontWeight: '700'
								}}
							>
								{label}
							</Typography>
						)}
					
					</Box>
			)}
			{SearchComponent && (
				<div style={{ margin: '32px 32px 36px 32px' }}>
					<SearchComponent data-testid="composite-label-paper-search" />
				</div>
			)}
			<div className="composite-label-paper-content-wrapper" style={{overflow: 'auto', height: '100%' }}>
				<div className="composite-label-paper-content" data-testid="composite-label-paper-content" style={{ margin: '0 32px 1%' }}>{Component && <Component {...other} />}</div>
			</div>
		</Paper>
	</div>
)

export default CompositeLabelPaper

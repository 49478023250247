import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { hideModal } from '../../../redux/actions'

const PatientHasIssuesWithEmailModal = ({ callback, isRegistration, sendRegistrationSms, sendSurveyReminderSms, intl, close }) => {
	const theme = useTheme()

	return (
		<Grid
			data-testid="issues-with-email-modal"
			container
			direction='column'
			justifyContent='flex-start'
			alignItems='center'
			style={{ padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}>

			<Grid item>
				<Typography
					data-testid="issues-with-email-title"
					variant='h1'
					style={{
						color: '#fff',
						marginBottom: '4rem',
						textAlign: 'center'
					}}
				>
					{intl.formatMessage({ id: 'app.cases.issuesWithEmailModalTitle' })}
				</Typography>
			</Grid>

			<Grid item style={{ alignSelf: 'center' }}>
				<Typography
					data-testid="issues-with-email-modal-body"
					variant='h3' style={{ color: '#fff', marginBottom: '4rem' }}>
					{intl.formatMessage({ id: 'app.cases.issuesWithEmailModalBody' })}
					{isRegistration && sendRegistrationSms && intl.formatMessage({ id: 'app.cases.issuesWithEmailModalSmsNotification' })}
					{!isRegistration && (sendSurveyReminderSms
						? intl.formatMessage({ id: 'app.cases.issuesWithEmailModalSmsOrPushNotification' })
						: intl.formatMessage({ id: 'app.cases.issuesWithEmailModalPushNotification' }))
					}
				</Typography>
			</Grid>
			<Grid item>
				{(!isRegistration || sendRegistrationSms) &&
					<Button
						data-testid="issues-with-email-modal-button"
						onClick={callback}
						variant="contained"
						color="primary"
						style={{
							margin: '10px'
						}}
						type='button'
						size='large'>
						{intl.formatMessage({ id: 'app.cases.issuesWithEmailModalSendButton' })}
					</Button>
				}
				<Button
					data-testid="issues-with-email-modal-button"
					onClick={close}
					variant="outlined"
					color="primary"
					style={{
						margin: '10px'
					}}
					type='button'
					size='large'>
					{(!isRegistration || sendRegistrationSms) ? intl.formatMessage({ id: 'app.general.cancel' }) : intl.formatMessage({ id: 'app.general.closeModalLabel' })}
				</Button>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	close: () => dispatch(hideModal())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(PatientHasIssuesWithEmailModal)

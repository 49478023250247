import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const RoleCell = ({ intl, circle, user }) => {
	const roles = []

	if (circle.isAdministrator) {
		roles.push(intl.formatMessage({ id: 'app.general.administrator' }))
	}

	if (circle.isFounder) {
		roles.push(intl.formatMessage({ id: 'app.general.founder' }))
	}

	if (roles.length == 0) {
		roles.push(intl.formatMessage({ id: 'app.general.member' }))
	}

	return (
		<Grid data-testid="circle-role-cell" container>
			<Grid item>
				{circle && (
					<Typography
						variant='body2'
						style={{
							fontSize: '17px',
							lineHeight: '22px',
							letterSpacing: '0.51',
							color: '#011020'
						}}>
						{roles.join(' / ')}
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(RoleCell)

import React, { useState, useEffect } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { getCurrencyCode } from '../../utilities/CurrencyCodes'
import { fetchPlan } from '../../redux/actions'
import { planSelector } from '../../redux/selectors'
import locale2 from 'locale2'


const AddCasesRow = ({ intl, casePrice, onCaseCountChange }) => {
	const currency = 'USD' //getCurrencyCode(locale2);
	const theme = useTheme()
	const dispatch = useDispatch()
	const plan = useSelector(planSelector, shallowEqual)

	useEffect(() => {
		dispatch(fetchPlan())
	}, [])

	const color_gray = 'rgba(255,255,255,0.6)'
	const useStyles = makeStyles({
		inputColor: {
			color: theme.palette.light
		},
		textField: {
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: color_gray
			}
		}
	})
	const classes = useStyles()

	const [ casesInputValue, setCasesInputValue ] = useState(0)

	const getCasesPriceForDisplay = () => {
		if (casePrice && casesInputValue) {
			return (
				intl.formatNumber(casesInputValue * casePrice, {
					style: 'currency',
					currency: currency,
					currencyDisplay: 'symbol'
				}) + 'USD'
			)
		} else {
			return intl.formatNumber(0, {
				style: 'currency',
				currency: currency,
				currencyDisplay: 'symbol'
			})+' USD'
		}
	}
	// only allow numerical inputs
	const caseValueOnChange = (e) => {
		const re = /^[0-9\b]+$/
		if (e.target.value > 100000) {
			setCasesInputValue(casesInputValue)
			return
		} else if (e.target.value === '' || re.test(e.target.value)) {
			setCasesInputValue(e.target.value)
			onCaseCountChange(e.target.value)
		} else {
			return 0
			onCaseCountChange(0)
		}
	}

	return (
		<Grid data-testid="add-cases-row" container spacing={3} style={{ borderBottom: '0.3px solid', borderColor: color_gray }}>
			<Grid item xs={12} sm={3}>
				<Typography data-testid="add-cases-row-title" variant='h3' style={{ color: theme.palette.light }}>
					{intl.formatMessage({ id: 'app.general.cases' })}
				</Typography>
				<Typography data-testid="add-cases-row-per-case" variant="body2" style={{ color: theme.palette.light }}>
					{intl.formatNumber(casePrice, {
						style: 'currency',
						currency: currency,
						currencyDisplay: 'symbol'
					})}{' '}
					USD 
					{intl.formatMessage({ id: 'app.account.addCasesUsers.perCase' })}
				</Typography>
			</Grid>

			<Grid item xs={12} sm={3}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography data-testid="add-cases-row-subtitle" variant="subtitle1" style={{ color: color_gray }}>
						{plan.totalCaseCreditUsed ? plan.totalCaseCreditUsed : 0}
					</Typography>
					<Typography data-testid="add-cases-row-active" variant="body2" style={{ color: color_gray, marginTop: '-2px', paddingLeft: '10px' }}>
						{intl.formatMessage({ id: 'app.account.addCasesUsers.activeCases' })}
					</Typography>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography data-testid="add-cases-row-total-paid" variant="subtitle1" style={{ color: color_gray }}>
						{plan.totalPaidCases ? plan.totalPaidCases : 0}
					</Typography>
					<Typography data-testid="add-cases-row-available" variant="body2" style={{ color: color_gray, marginTop: '-2px', paddingLeft: '10px' }}>
						{intl.formatMessage({ id: 'app.account.addCasesUsers.availableCases' })}
					</Typography>
				</div>
			</Grid>

			<Grid item xs={12} sm={3}>
				<TextField
					data-testid="add-cases-row-textfield"
					style={{ width: '150px' }}
					variant="outlined"
					className={classes.textField}
					InputProps={{
						className: classes.inputColor
					}}
					onChange={caseValueOnChange}
					value={casesInputValue}
				/>

				<Typography data-testid="add-cases-row-text" variant="body2" style={{ color: color_gray, paddingLeft: '10px' }}>
					{intl.formatMessage({ id: 'app.account.addCasesUsers.addCases' })}
				</Typography>
			</Grid>

			<Grid item xs={12} sm={3}>
				<Typography data-testid="add-cases-row-price" variant='h3' style={{ color: theme.palette.primary.main }}>
					{getCasesPriceForDisplay()}
				</Typography>
				<Typography data-testid="add-cases-row-cost" variant="body2" style={{ color: theme.palette.primary.main }}>
					{intl.formatMessage({ id: 'app.account.addCasesUsers.caseCost' })}
				</Typography>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addCasesRow'
	}),
	withRouter
)

export default enhance(AddCasesRow)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import {compose} from 'recompose'
import {withStyles} from '@material-ui/core'
import RadialChart from '../../../components/RadialChart'

const styles = {
	label: {
        fontSize: '14px',
        textAlign: 'center'
    },
    labelHighlighted: {
        fontSize: '14px',
        textAlign: 'center',
        color: '#0C7EFF'
    }
}

const CurrentPatient = ({intl, currentResponseRate}) => (
    <div data-testid="dashboard-current-patient" className="label-paper-inner">
        <Grid container direction="column" style={{ paddingBottom: '32px', flex: 1, alignItems: 'center' }}>
            <Grid
                data-testid="dashboard-patient-radial-chart"
                item style={{paddingTop: '1%' }}>
                <RadialChart value={currentResponseRate}/>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: '8px' }}>
            <Typography
                data-testid="dashboard-patient-name"
                style={styles.label}>
                {intl.formatMessage({id: 'app.dashboard.currentPatient'})}
            </Typography>
            <Typography
                data-testid="dashboard-patient-response-rate"
                style={styles.label}>
                {intl.formatMessage({id: 'app.dashboard.responseRate'})}
            </Typography>
            <Typography
                data-testid="dashboard-patient-last-days"
                style={styles.labelHighlighted}>
                {intl.formatMessage({id: 'app.dashboard.lastDays'})}
            </Typography>
            </Grid>
        </Grid>
    </div>
)

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(CurrentPatient)
